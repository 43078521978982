import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import {
  DeleteLeagueWaiverDocument,
  LoadWaiversDocument,
  UpsertLeagueWaiverDocument,
  UpsertWaiverDocument,
  UpsertWaiverInput,
  Waiver,
} from '@local/graphql/graphql';
import { ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, HookFormRichEditor, HookFormSwitch, HookFormTextField, Roles, useAuthz } from '@seasonticker/dls';
import { useCallback } from 'react';

export function Waivers() {
  const { leagueContext } = useLeagueContext();
  const [upsertMutation] = useMutation(UpsertWaiverDocument);
  const [afterAddMutation] = useMutation(UpsertLeagueWaiverDocument);
  const [deleteMutation] = useMutation(DeleteLeagueWaiverDocument);
  const [refreshWaivers] = useLazyQuery(LoadWaiversDocument, { fetchPolicy: 'no-cache' });
  const { ifInRole } = useAuthz();
  const permissionFunction = ifInRole({
    context: leagueContext?.league?.id,
    roles: [Roles.admin, Roles.leagueadmin],
  });

  const loadWaivers = useCallback(async () => {
    const response = await refreshWaivers({ variables: { leagueId: String(leagueContext?.league?.id) } });
    return response.data?.league_waivers.map((w) => w.waiver as Waiver) as Waiver[];
  }, [leagueContext?.league?.id, refreshWaivers]);

  const prepareWaiver = useCallback(async (item: Waiver) => {
    const { id, name, required, text } = item;
    const update: UpsertWaiverInput = { id, name, required, text };
    return update;
  }, []);

  const upsertWaiver = useCallback(
    (upsert: UpsertWaiverInput) => async () => {
      const response = await upsertMutation({ variables: { upsert: upsert } });
      if (upsert.id === undefined) {
        await afterAddMutation({
          variables: {
            upsert: { league: String(leagueContext?.league?.id), waiver: String(response.data?.upsert_waiver.id) },
            leagueId: String(leagueContext?.league?.id),
          },
        });
      }
      return response;
    },
    [afterAddMutation, leagueContext?.league?.id, upsertMutation]
  );
  const deleteWaiver = useCallback(
    (item: Waiver) => () => deleteMutation({ variables: { leagueId: String(leagueContext?.league?.id), id: item.id } }),
    [deleteMutation, leagueContext?.league?.id]
  );

  const waiverForm = (
    <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
      <Grid xs={12}>
        <HookFormTextField name="name" label="Title" rules={{ required: 'Name is required' }} fullWidth />
      </Grid>
      <Grid xs={12}>
        <HookFormRichEditor name="text" label="Waiver Text" fullWidth />
      </Grid>
      <Grid xs={12}>
        <HookFormSwitch name="required" label="Make Required" />
      </Grid>
    </Grid>
  );

  const waiverDisplay = (item: Waiver) => <></>;
  const emptyTemplate = {
    name: '',
    required: false,
    text: '',
  } as Waiver;

  return (
    <EditableList<Waiver, UpsertWaiverInput, ReturnType<typeof upsertMutation>, any, ReturnType<typeof deleteMutation>>
      model="Waivers"
      refresh={loadWaivers}
      refreshList={loadWaivers}
      deleteMutation={deleteWaiver}
      prepareUpsert={prepareWaiver}
      upsertMutation={upsertWaiver}
      canEdit={permissionFunction}
      listItemTemplate={(item) => <ListItemText primary={item?.name} />}
      editElement={waiverForm}
      itemTemplate={waiverDisplay}
      emptyTemplate={emptyTemplate}
      editable
      deletable
    />
  );
}

export default Waivers;
