import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { useTeamContext } from '@local/context/league/team-context';
import {
  DeleteFacilityDocument,
  Facility,
  RefreshFacilitiesDocument,
  UpdateFacilityInput,
  UpsertFacilityDocument,
  UpsertFacilityInput,
  UpsertManyFacilitiesDocument,
} from '@local/graphql/graphql';
import { ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, HookFormTextField, Roles, useAuthz } from '@seasonticker/dls';
import { useCallback } from 'react';
import FacilityDisplay from './FacilityDisplay';

export function Facilities() {
  const { registration } = useTeamContext();
  const { leagueContext } = useLeagueContext();
  const [upsertMutation] = useMutation(UpsertFacilityDocument);
  const [upsertManyMutation] = useMutation(UpsertManyFacilitiesDocument);
  const [deleteMutation] = useMutation(DeleteFacilityDocument);
  const [refreshFacilities] = useLazyQuery(RefreshFacilitiesDocument, { fetchPolicy: 'no-cache' });

  const upsertFacilities = useCallback(
    (update: UpsertFacilityInput[]) => () => upsertManyMutation({ variables: { upsert: update, team_id: String(registration?.team?.id) } }),
    [registration?.team?.id, upsertManyMutation]
  );
  const { ifInRole } = useAuthz();
  const permissionFunction = ifInRole({
    context: leagueContext?.league?.id,
    subContext: registration?.team?.id,
    roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
  });

  const loadFacilities = useCallback(async () => {
    const response = await refreshFacilities({ variables: { teamId: String(registration?.team?.id) } });
    return response.data?.facilities as Facility[];
  }, [refreshFacilities, registration?.team?.id]);

  const prepareFacility = useCallback(
    (item: Facility) => {
      const { name, location, id, fieldDescription, sortOrder } = item;
      const { street, city, state, zip } = location || {};
      return id !== undefined
        ? ({ name, location: { street, city, state, zip }, id, fieldDescription, sortOrder } as UpdateFacilityInput)
        : ({ name, location: { street, city, state, zip }, id, fieldDescription, sortOrder, team: registration?.team?.id } as UpdateFacilityInput);
    },
    [registration?.team?.id]
  );

  const upsertFacility = useCallback(
    (upsert: UpdateFacilityInput) => () => upsertMutation({ variables: { upsert: upsert, team_id: String(registration?.team?.id) } }),
    [registration?.team?.id, upsertMutation]
  );

  const deleteFacility = useCallback(
    (item: Facility) => () => deleteMutation({ variables: { id: String(registration?.team?.id), facility: item.id } }),
    [deleteMutation, registration?.team?.id]
  );

  const facilityForm = (
    <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
      <Grid xs={12} md={6}>
        <HookFormTextField name="name" label="Name" rules={{ required: 'Name is required' }} fullWidth />
      </Grid>
      <Grid xs={12} md={6}>
        <HookFormTextField name="fieldDescription" label="Description" fullWidth />
      </Grid>
      <Grid xs={12} md={6}>
        <HookFormTextField name="location.street" label="Street" fullWidth />
      </Grid>
      <Grid xs={12} md={6}>
        <HookFormTextField name="location.city" label="City" fullWidth />
      </Grid>
      <Grid xs={12} md={6}>
        <HookFormTextField name="location.state" label="State" fullWidth />
      </Grid>
      <Grid xs={12} md={6}>
        <HookFormTextField name="location.zip" label="Zip" fullWidth />
      </Grid>
    </Grid>
  );

  const facilityDisplay = (item: Facility) => <FacilityDisplay facility={item} />;
  const emptyTemplate = {
    name: 'Name',
    location: {
      state: '',
      street: '',
      country: '',
      city: '',
      zip: '',
    },
    fieldName: '',
    fieldDescription: '',
    sortOrder: -1,
  } as Facility;

  return (
    <EditableList<Facility, UpsertFacilityInput, ReturnType<typeof upsertMutation>, ReturnType<typeof upsertManyMutation>, ReturnType<typeof deleteMutation>>
      model="Facility"
      refresh={loadFacilities}
      refreshList={loadFacilities}
      deleteMutation={deleteFacility}
      prepareUpsert={prepareFacility}
      upsertMutation={upsertFacility}
      upsertOperation={upsertFacilities}
      canEdit={permissionFunction}
      listItemTemplate={(item) => <ListItemText primary={item?.name} />}
      editElement={facilityForm}
      itemTemplate={facilityDisplay}
      emptyTemplate={emptyTemplate}
      editable
      sortable
      selectable
      deletable
    />
  );
}

export default Facilities;
