import { useLazyQuery } from '@apollo/client';
import { TeamLogo } from '@local/components';
import { MyTeamsDocument, Team } from '@local/graphql/graphql';
import { Button, Card, CardContent, CardHeader, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { RegisterForm, RegistrationStepProps, Steps } from './Steps';

export function ModeSelect(props: RegistrationStepProps<RegisterForm>) {
  const { goToStep, formProps } = props;
  const { setValue } = formProps;
  const { user } = useAuth();
  const { seasonId } = useParams();
  const [myTeams, setMyTeams] = useState<Team[]>([]);
  const [loadMyTeams] = useLazyQuery(MyTeamsDocument, { fetchPolicy: 'no-cache' });
  const teamLoader = useCallback(async () => {
    if (user?.profile?.sub) {
      const response = await loadMyTeams({ variables: { id: user.profile.sub } });
      const teams: Team[] = [
        ...(response.data?.user.managed_teams || []).filter((t) => {
          if (t.registrations?.find((r) => r.season?.id === seasonId)) {
            return false;
          }
          return true;
        }),
        ...(response.data?.user.positions?.filter((t) => t.positionType === 'team administrator')?.map((t) => t.team) || []).filter((t) => {
          if (t?.registrations?.find((r) => r.season?.id === seasonId)) {
            return false;
          }
          return true;
        }),
      ] as Team[];
      setMyTeams(uniqBy(teams, 'id'));
    }
  }, [loadMyTeams, seasonId, user?.profile?.sub]);
  const handleTeamClick = useCallback(
    (team: Team) => {
      setValue('team', team);
      setValue('manager.id', String(user?.profile.sub));
      goToStep(Steps.CompetitionLevels);
    },
    [goToStep, setValue, user?.profile.sub]
  );

  useEffect(() => {
    teamLoader();
  }, [teamLoader]);

  const handleNewTeamClick = () => {
    goToStep(Steps.NewTeam);
  };
  return (
    <Grid container rowSpacing={'.8em'} columnSpacing={'.8em'}>
      <Grid xs={12} md={6}>
        <Card variant="outlined" sx={{ height: '100%' }}>
          <CardHeader title="Register an existing team" />
          <CardContent>
            <List>
              {myTeams
                ?.filter((t) => t?.id)
                .map((t) => {
                  return (
                    <ListItem key={t?.id}>
                      <ListItemButton onClick={() => handleTeamClick(t)}>
                        <ListItemAvatar>
                          <TeamLogo url={t?.logo?.url} />
                        </ListItemAvatar>
                        <ListItemText primary={t?.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
            {myTeams?.length < 1 ? <Typography>No Teams to register</Typography> : <></>}
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} md={6}>
        <Card variant="outlined" sx={{ height: '100%' }}>
          <CardHeader title="or Register a new team" />
          <CardContent>
            <Button variant="contained" color="primary" onClick={handleNewTeamClick}>
              Start a new registration
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ModeSelect;
