import { Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { HtmlTooltip } from '../components/HtmlTooltip';
import { HelpIcon } from '../icons';

export function useHelpLabel(label: ReactNode, required = false, tooltip?: ReactNode) {
  return useMemo(() => {
    const labelText = required ? `${label} *` : label;
    if (tooltip) {
      return (
        <HtmlTooltip title={tooltip}>
          <Typography variant="body1">
            {labelText}
            <HelpIcon sx={{ marginLeft: '5px' }} color="primary" fontSize="small" />
          </Typography>
        </HtmlTooltip>
      );
    }
    return labelText;
  }, [label, required, tooltip]);
}

export default useHelpLabel;
