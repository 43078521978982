import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting, UpdateManySettingsDocument, UpsertSiteSettingInput } from '@local/graphql/graphql';
import { ROSTER_MODULE, RosterSettingsType, SettingDefaults, useSettings } from '@local/hooks';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { FieldSet, HookFormSwitch, HookFormTextField, Legend, useOperations } from '@seasonticker/dls';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export default function RosterSettings() {
  const { operation } = useOperations();
  const { leagueContext } = useLeagueContext();
  const { setting, setSettings } = useSettings();
  const formMethods = useForm<RosterSettingsType>({ defaultValues: SettingDefaults[ROSTER_MODULE] });

  useEffect(() => {
    formMethods.reset({
      min_roster_size: setting('roster.min_roster_size'),
      max_roster_size: setting('roster.max_roster_size'),
      require_player_number: setting('roster.require_player_number'),
      membership_id: setting('roster.membership_id'),
      membershipId: setting('roster.membershipId'),
      membership_label: setting('roster.membership_label'),
      emergency_contacts: setting('roster.emergency_contacts'),
      number: setting('roster.number'),
      position: setting('roster.position'),
      show_years_of_experience: setting('roster.show_years_of_experience'),
      require_years_of_experience: setting('roster.require_years_of_experience'),
      label_years_of_experience: setting('roster.label_years_of_experience'),
      show_off_season_player: setting('roster.show_off_season_player'),
      require_off_season_player: setting('roster.require_off_season_player'),
      label_off_season_player: setting('roster.label_off_season_player'),
      player: {
        firstName: setting('roster.player.firstName'),
        birthday: setting('roster.player.birthday'),
        lastName: setting('roster.player.lastName'),
        grade: setting('roster.player.grade'),
        guardianemail: setting('roster.player.guardianemail'),
        guardian2email: setting('roster.player.guardian2email'),
        phoneNumber: setting('roster.player.phoneNumber'),
        phoneNumber2: setting('roster.player.phoneNumber2'),
        phoneNumber3: setting('roster.player.phoneNumber3'),
      },
      emergencyContact: {
        cellPhone: setting('roster.emergencyContact.cellPhone'),
        email: setting('roster.emergencyContact.email'),
        firstName: setting('roster.emergencyContact.firstName'),
        lastName: setting('roster.emergencyContact.lastName'),
        relationToPlayer: setting('roster.emergencyContact.relationToPlayer'),
      },
    });
  }, [formMethods, setting]);

  const [updateManySettingsDocuments] = useMutation(UpdateManySettingsDocument);
  const onSubmit = async (settings: RosterSettingsType) => {
    const getSettingSaveValue = (setting: SiteSetting): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        return { id, value: String(value) };
      }
      return { module, key, value: String(value), league: leagueContext?.league?.id };
    };
    await operation({
      success: 'Settings were successfully updated',
      error: 'An error occurred updating the settings.',
      operation: () =>
        updateManySettingsDocuments({
          variables: {
            settings: [
              getSettingSaveValue(settings.min_roster_size),
              getSettingSaveValue(settings.require_player_number),
              getSettingSaveValue(settings.membership_id),
              getSettingSaveValue(settings.membershipId),
              getSettingSaveValue(settings.membership_label),
              getSettingSaveValue(settings.emergency_contacts),
              getSettingSaveValue(settings.number),
              getSettingSaveValue(settings.position),
              getSettingSaveValue(settings.show_off_season_player),
              getSettingSaveValue(settings.require_off_season_player),
              getSettingSaveValue(settings.label_off_season_player),
              getSettingSaveValue(settings.show_years_of_experience),
              getSettingSaveValue(settings.require_years_of_experience),
              getSettingSaveValue(settings.label_years_of_experience),
              getSettingSaveValue(settings.player.birthday),
              getSettingSaveValue(settings.player.firstName),
              getSettingSaveValue(settings.player.grade),
              getSettingSaveValue(settings.player.guardian2email),
              getSettingSaveValue(settings.player.guardianemail),
              getSettingSaveValue(settings.player.lastName),
              getSettingSaveValue(settings.player.phoneNumber),
              getSettingSaveValue(settings.player.phoneNumber2),
              getSettingSaveValue(settings.player.phoneNumber3),
              getSettingSaveValue(settings.emergencyContact.cellPhone),
              getSettingSaveValue(settings.emergencyContact.email),
              getSettingSaveValue(settings.emergencyContact.firstName),
              getSettingSaveValue(settings.emergencyContact.lastName),
              getSettingSaveValue(settings.emergencyContact.relationToPlayer),
            ],
          },
        }),
      after: (response) => setSettings(response.data?.update_many_site_setting as SiteSetting[]),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FieldSet>
          <Legend>Roster Settings</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <HookFormTextField
                fullWidth
                label="Minimum Roster Size"
                name="min_roster_size.value"
                type="number"
                tooltip="The lowest amount of roster players to enable scheduling"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormTextField
                fullWidth
                label="Maximum Roster Size"
                name="max_roster_size.value"
                type="number"
                tooltip="The highest amount of roster players to enable scheduling"
              />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Membership ID Field</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Enable the membership id field"
                name="membership_id.value"
                tooltip="Controls whether the membership ID field is collected, useful for parent organization information or other league registrations"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Require the Membership Field"
                name="membershipId.value"
                tooltip="Controls whether the membership ID field is required"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormTextField
                fullWidth
                label="Membership Field Label"
                name="membership_label.value"
                tooltip="The text to display when collecting the membership id"
              />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Years of Experience</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Enable the years of experience field"
                name="show_years_of_experience.value"
                tooltip="Controls whether the years of experience field shows up on the roster registration screen"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Require the years of experience field"
                name="require_years_of_experience.value"
                tooltip="Controls whether the years of experience field is required"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormTextField
                fullWidth
                label="Years of experience Label"
                name="label_years_of_experience.value"
                tooltip="The text to display when collecting the years of experience"
              />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Off Season Player</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Enable the Off Season Player field"
                name="show_off_season_player.value"
                tooltip="Controls whether the off season player field shows up on the roster registration screen"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Require the Off Season Player field"
                name="require_off_season_player.value"
                tooltip="Controls whether the years of experience field is required"
              />
            </Grid>
            <Grid xs={12}>
              <HookFormTextField
                fullWidth
                label="Off Season Player Label"
                name="label_off_season_player.value"
                tooltip="The text to display when collecting the years of experience"
              />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Player Required Fields</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player First Name" name="player.firstName.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Last Name" name="player.lastName.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Position" name="position.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Number" name="number.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Birthday" name="player.birthday.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Grade" name="player.grade.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Guardian Email" name="player.guardianemail.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Guardian 2 Email" name="player.guardian2email.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Phone Number" name="player.phoneNumber.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Phone Number 2" name="player.phoneNumber2.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Player Phone Number 3" name="player.phoneNumber3.value" />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Emergeny Contact Fields</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <HookFormSwitch
                fullWidth
                label="Enable Emergency Contacts"
                name="emergency_contacts.value"
                tooltip="Controls whether any emergency contact information is displayed"
              />
            </Grid>

            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Emergency First Name" name="emergencyContact.firstName.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Emergency Last Name" name="emergencyContact.lastName.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Emergency Email" name="emergencyContact.email.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Emergency Phone" name="emergencyContact.cellPhone.value" />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch fullWidth label="Require Emergency Relation" name="emergencyContact.relationToPlayer.value" />
            </Grid>
          </Grid>
        </FieldSet>

        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
