import { useLeagueContext } from '@local/context/league/league-context';
import { useSettings } from '@local/hooks';
import { Box, Link, styled, Typography } from '@mui/material';
import { VisibleIf } from '@seasonticker/dls';
import Carousel from 'react-material-ui-carousel';

const StyleImg = styled('img')({ width: '100%', height: 'auto' });

export function Carousels() {
  const { leagueContext } = useLeagueContext();
  const { settingValue } = useSettings();
  return (
    <Box>
      {leagueContext?.league?.carousels?.length && leagueContext?.league?.carousels?.length > 0 ? (
        <Carousel sx={{ aspectRatio: settingValue('theme.carousel.aspectRatio') }} autoPlay={true} indicators={true} navButtonsAlwaysInvisible={true}>
          {leagueContext?.league?.carousels?.map((carousel) => {
            return (
              <Box sx={{ position: 'relative' }} key={carousel.id}>
                <StyleImg alt={carousel.title as string} src={carousel.image?.url as string} />
                <VisibleIf cond={Number(carousel.text?.length) > 0}>
                  <>
                    <Box
                      sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        border: '1px solid rgba(0, 0, 0, 0.8)',
                        marginRight: '1em',
                        marginBottom: '1em',
                      }}>
                      <Typography
                        variant="body1"
                        sx={{
                          my: '.8em',
                          mx: '.8em',
                        }}>
                        {Number(carousel.link?.length) > 0 ? (
                          <Link target="_blank" href={String(carousel.link)}>
                            {carousel.text}
                          </Link>
                        ) : (
                          carousel.text
                        )}
                      </Typography>
                    </Box>
                  </>
                </VisibleIf>
              </Box>
            );
          })}
        </Carousel>
      ) : null}
    </Box>
  );
}

export default Carousels;
