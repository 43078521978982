import { useLeagueContext } from '@local/context/league/league-context';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import StandingsWidgetComponent from '../components/standings/StandingsWidget';
import Carousels from './components/Carousels';
import Messages from './components/Messages';
import Sponsors from './components/Sponsors';
import { WaiverDisplay } from './components/WaiverDisplay';
import RegistrationWidget from './registration/RegistrationWidget';

export default function Home() {
  const { leagueContext } = useLeagueContext();
  return (
    <Grid container columnSpacing={'1em'}>
      <Grid xs={12} sm={6} md={8}>
        <Carousels />
        <Typography variant="body1" component={'div'}>
          <div dangerouslySetInnerHTML={{ __html: leagueContext?.league?.description || '' }}></div>
        </Typography>
        <Messages />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <WaiverDisplay />
        <RegistrationWidget />
        <StandingsWidgetComponent />
        <Sponsors />
      </Grid>
    </Grid>
  );
}
