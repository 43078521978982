import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormSelectProps<T extends FieldValues = FieldValues, V = string> = HookFormBaseProps<T> & {
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'fullWidth' | 'helperText' | 'error' | 'type'>;
  options: V[];
  findValue?: (id: string) => V | undefined;
  getLabel?: (item: V) => string;
  getValue?: (item: V) => string;
};

export function HookFormSelect<T extends FieldValues = FieldValues, V = string>(props: HookFormSelectProps<T, V>) {
  const defaultConverter = (item: V) => {
    if (typeof item === 'string') {
      return item;
    } else {
      return String(item);
    }
  };
  const defaultFinder = (id: string) => id as V;
  const {
    name,
    label,
    rules,
    fullWidth,
    control,
    ControllerProps,
    TextFieldProps,
    tooltip,
    children,
    options,
    getLabel = defaultConverter,
    getValue = defaultConverter,
    findValue = defaultFinder,
    ...textFieldProps
  } = props;
  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...otherFieldProps }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => {
        return (
          <TextField
            {...TextFieldProps}
            select
            label={labelValue}
            value={getValue(value)}
            fullWidth={fullWidth}
            helperText={error?.message}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = findValue(value);
              onChange({
                target: {
                  value: newValue,
                },
              });
            }}
            error={!!error}
            {...otherFieldProps}>
            {options.map((option) => (
              <MenuItem key={getValue(option)} value={getValue(option)}>
                {getLabel(option)}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
}

export default HookFormSelect;
