import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting } from '@local/graphql/graphql';
import { CssBaseline } from '@mui/material';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
  StyledEngineProvider,
  ThemeOptions,
} from '@mui/material/styles';
import Color from 'color';
import Context from './Context';

export default function Theme() {
  const { leagueContext } = useLeagueContext();

  let themeConfig: ThemeOptions = {
    palette: {
      primary: { main: '#000000' },
      secondary: { main: '#000000' },
    },
    components: {
      MuiButton: {
        defaultProps: {},
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiCard: {
        defaultProps: {},
        styleOverrides: {
          root: {
            marginBottom: '1em',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.active': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.getContrastText(theme.palette.primary.main),
              '.MuiSvgIcon-root': {
                color: theme.palette.getContrastText(theme.palette.primary.main),
              },
            },
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginBottom: '1em',
            borderBottom: 'none',
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          enterDelay: 500,
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 2000,
      },
    },
    typography: {
      fontSize: 16,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      body1: {
        fontWeight: 400,
      },
      h1: {
        fontSize: '1.6em',
        fontWeight: 500,
        marginTop: 0,
      },
      h2: {
        fontSize: '1.5em',
        marginTop: 0,
      },
      h3: {
        fontSize: '1.4em',
        marginTop: 0,
      },
      h4: {
        fontSize: '1.3em',
        marginTop: 0,
      },
      h5: {
        fontSize: '1.2em',
        marginTop: 0,
      },
      h6: {
        fontSize: '1.1em',
        marginTop: 0,
      },
    },
  };
  if (leagueContext?.league?.color) {
    const settings = leagueContext?.league?.settings as SiteSetting[];
    themeConfig.palette = {
      ...themeConfig.palette,
      mode: getSettingValueOrDefault(settings, 'theme', 'color.mode', 'light'),
      primary: { main: getSettingValueOrDefault(settings, 'theme', 'color.primary', leagueContext?.league?.color) },
      secondary: { main: getSettingValueOrDefault(settings, 'theme', 'color.secondary', new Color(leagueContext?.league?.color).darken(50).hex()) },
    };
    themeConfig.typography = {
      ...themeConfig.typography,
      fontSize: +getSettingValueOrDefault(settings, 'theme', 'font.size', '16'),
    };
    themeConfig.shape = {
      ...themeConfig.shape,
      borderRadius: +getSettingValueOrDefault(settings, 'theme', 'shape.borderRadius', '8'),
    };
    themeConfig.components!.MuiCssBaseline = {
      styleOverrides: (theme) => ({
        h1: theme.typography.h1,
        h2: theme.typography.h2,
        h3: theme.typography.h3,
        h4: theme.typography.h4,
        h5: theme.typography.h5,
        h6: theme.typography.h6,
      }),
    };
  }
  const mdTheme = extendTheme(themeConfig);
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={mdTheme}>
        <CssBaseline />
        <Context />
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}

export function getSettingValue(settings: SiteSetting[], module: string, key: string) {
  return settings?.find((setting) => setting.module === module && setting.key === key)?.value;
}

export function getSettingValueOrDefault<T>(settings: SiteSetting[], module: string, key: string, defaultValue: T) {
  return (getSettingValue(settings, module, key) || defaultValue) as T;
}
