import { TeamLogo } from '@local/components';
import { useTeamContext } from '@local/context/league/team-context';
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

export default function TeamLayout() {
  const { registration } = useTeamContext();

  return (
    <>
      <Box sx={{ display: 'flex', mb: '1em' }}>
        <TeamLogo url={registration?.team?.logo?.url} maxWidth="120px" maxHeight="100%" />
        <Typography sx={{ flex: 1 }} variant="h1">
          {registration?.team?.name}{' '}
          <small>
            {registration?.age_group?.name} {registration?.team_level?.name} {registration?.division?.name}
          </small>
        </Typography>
      </Box>
      <Outlet />
    </>
  );
}
