import { Tab, TabProps } from '@mui/material';
import { Roles, useAuthz } from '@seasonticker/dls';
import { ReactElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type ProtectedTabProps = TabProps &
  LinkProps & {
    roles: Roles[];
    context?: string;
    subContext?: string;
    label: TabProps['label'];
    value: TabProps['value'];
    to: string;
  };

export function ProtectedTab(props: ProtectedTabProps): ReactElement {
  const { context, subContext, roles, to, label, value } = props;
  const { isInRole } = useAuthz();
  return isInRole({ context, subContext, roles }) ? <Tab label={label} value={value} component={Link} to={to} /> : <></>;
}

export default ProtectedTab;
