import { useLazyQuery, useMutation } from '@apollo/client';
import { Address, Game, RefreshGameDocument, UpsertGameDocument } from '@local/graphql/graphql';
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FieldSet, HookFormSwitch, HookFormTextField, Legend, MapDisplay, useOperations } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import GameTeamDisplay from './GameTeamDisplay';

type GameScore = Pick<Game, 'homeTeamScore' | 'awayTeamScore' | 'homeTeamForfeit' | 'awayTeamForfeit'>;

export function AddressDisplay({ address }: { address?: Address | null }) {
  const formatted_address = useMemo(() => {
    if (address?.geocoding?.formatted_address) {
      return address.geocoding.formatted_address;
    } else {
      return `${address?.street || ''} ${address?.city || ''} ${address?.state || ''} ${address?.zip || ''} ${address?.country || ''}`;
    }
  }, [address]);
  return <Typography variant="body2">{formatted_address}</Typography>;
}

export function GameDisplay() {
  const [editingScore, setEditingScore] = useState(false);
  const { id } = useParams();
  const formProps = useForm<GameScore>({
    defaultValues: {
      homeTeamScore: undefined,
      awayTeamScore: undefined,
      homeTeamForfeit: undefined,
      awayTeamForfeit: undefined,
    },
  });
  const { operation } = useOperations();
  const { reset, handleSubmit } = formProps;
  const [game, setGame] = useState<Game>();
  const [gameLoader] = useLazyQuery(RefreshGameDocument);
  const [upsertGame] = useMutation(UpsertGameDocument, { keepRootFields: true });
  const loadGame = useCallback(
    async (id: string) => {
      const response = await gameLoader({
        variables: {
          gameId: String(id),
        },
      });
      setGame(response.data?.game as Game);
      reset({
        homeTeamScore: game?.homeTeamScore,
        awayTeamScore: game?.awayTeamScore,
        homeTeamForfeit: game?.homeTeamForfeit,
        awayTeamForfeit: game?.awayTeamForfeit,
      });
    },
    [game?.awayTeamForfeit, game?.awayTeamScore, game?.homeTeamForfeit, game?.homeTeamScore, gameLoader, reset]
  );

  useEffect(() => {
    if (id) {
      loadGame(id);
    }
  }, [id, loadGame]);

  const updateScore = useCallback(
    async (values: GameScore) => {
      operation({
        success: 'Game Score Updated',
        after: () => setEditingScore(false),
        operation: () => {
          return upsertGame({
            variables: {
              upsert: {
                homeTeamScore: values.homeTeamScore !== undefined && String(values.homeTeamScore) !== '' ? Number(values.homeTeamScore) : null,
                awayTeamScore: values.awayTeamScore !== undefined && String(values.awayTeamScore) !== '' ? Number(values.awayTeamScore) : null,
                homeTeamForfeit: boolean(values.homeTeamForfeit),
                awayTeamForfeit: boolean(values.awayTeamForfeit),
                id: game?.id,
              },
            },
          });
        },
      });
    },
    [game?.id, operation, upsertGame]
  );
  const mapDisplay = useMemo(
    () => <MapDisplay zoom={12} width={'100%'} height={400} address={game?.date?.facility?.location} />,
    [game?.date?.facility?.location]
  );

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container>
            <Grid xs={12} md={6}>
              <GameTeamDisplay game={game} />
              <AddressDisplay address={game?.date?.facility?.location} />
            </Grid>
            <Grid xs={12} md={6}>
              {mapDisplay}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              setEditingScore(true);
            }}>
            Enter Score
          </Button>
        </CardActions>
      </Card>

      {editingScore ? (
        <FormProvider {...formProps}>
          <form onSubmit={handleSubmit(updateScore)}>
            <FieldSet>
              <Legend>Game Score</Legend>
              <Grid container columnSpacing={'.8em'} rowSpacing={'.8em'}>
                <Grid md={8}>
                  <HookFormTextField fullWidth type="number" name="awayTeamScore" label={`${game?.away_team?.team?.name} score`} />
                </Grid>
                <Grid md={4}>
                  <HookFormSwitch sx={{}} name="awayTeamForfeit" label={`${game?.away_team?.team?.name} forfeits`} />
                </Grid>
                <Grid md={8}>
                  <HookFormTextField fullWidth type="number" name="homeTeamScore" label={`${game?.home_team?.team?.name} score`} />
                </Grid>
                <Grid md={4}>
                  <HookFormSwitch name="homeTeamForfeit" label={`${game?.home_team?.team?.name} forfeits`} />
                </Grid>
                <Grid xs={12}>
                  <Button sx={{ marginRight: '.8em' }} variant="outlined" color="primary" type="button" onClick={() => setEditingScore(false)}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Update Score
                  </Button>
                </Grid>
              </Grid>
            </FieldSet>
          </form>
        </FormProvider>
      ) : undefined}
    </Box>
  );
}

export default GameDisplay;
