import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting, UpdateManySettingsDocument, UpsertSiteSettingInput } from '@local/graphql/graphql';
import { REGISTRATION_MODULE, RegistrationSettingsType, SettingDefaults, useSettings } from '@local/hooks';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormRichEditor, HookFormSwitch, useOperations } from '@seasonticker/dls';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export default function RegistrationSettings() {
  const { operation } = useOperations();
  const { setting, setSettings } = useSettings();
  const { leagueContext } = useLeagueContext();
  const formMethods = useForm<RegistrationSettingsType>({ defaultValues: SettingDefaults[REGISTRATION_MODULE] });

  useEffect(() => {
    debugger;
    formMethods.reset({
      require_registration_terms: setting('registration.require_registration_terms'),
      registration_terms: setting('registration.registration_terms'),
      registration_instructions: setting('registration.registration_instructions'),
      enable_payments: setting('registration.enable_payments'),
      show_elite_justification: setting('registration.show_elite_justification'),
      show_prior_year: setting('registration.show_prior_year'),
      include_division: setting('registration.include_division'),
    });
  }, [formMethods, setting]);

  const [updateManySettingsDocuments] = useMutation(UpdateManySettingsDocument);
  const onSubmit = async (settings: RegistrationSettingsType) => {
    const getSettingSaveValue = (setting: SiteSetting): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        return { id, value: String(value) };
      }
      return { module, key, value: String(value), league: leagueContext?.league?.id };
    };
    await operation({
      success: 'Settings were successfully updated',
      error: 'There was an error updating the settings',
      operation: () =>
        updateManySettingsDocuments({
          variables: {
            settings: [
              getSettingSaveValue(settings.require_registration_terms),
              getSettingSaveValue(settings.registration_terms),
              getSettingSaveValue(settings.registration_instructions),
              getSettingSaveValue(settings.enable_payments),
              getSettingSaveValue(settings.show_elite_justification),
              getSettingSaveValue(settings.show_prior_year),
              getSettingSaveValue(settings.include_division),
            ],
          },
        }),
      after: (response) => setSettings(response.data?.update_many_site_setting as SiteSetting[]),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid xs={12}>
            <HookFormSwitch
              fullWidth
              label="Require registration terms"
              name="require_registration_terms.value"
              tooltip="Controls whether teams need to agree to the registration terms before completing a season registration"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormRichEditor
              fullWidth
              label="Registration Terms"
              name="registration_terms.value"
              tooltip="Terms and conditions text to display before allowing a season registration"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormRichEditor
              fullWidth
              label="Registration Instructions"
              name="registration_instructions.value"
              tooltip="The text that will appear as the user is creating a registration for the season"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSwitch
              fullWidth
              label="Include Division"
              name="include_division.value"
              tooltip="Controls whether teams can choose a preferred division in the registration process"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSwitch
              fullWidth
              label="Require a Division"
              name="require_division.value"
              tooltip="Controls whether the team can proceed without choosing a division"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSwitch
              fullWidth
              label="Enable registration payments"
              name="enable_payments.value"
              tooltip="Controls whether the payments module is displayed for registered teams"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSwitch
              fullWidth
              label="Show Elite Justification Question"
              name="show_elite_justification.value"
              tooltip="Controls whether the elite justification text box is displayed on the registration page"
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSwitch
              fullWidth
              label="Ask about prior year league"
              name="show_prior_year.value"
              tooltip="Controls whether the what league did you play last year question is visible on the registration page"
            />
          </Grid>
          <Grid xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
