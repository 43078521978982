import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting, UpdateManySettingsDocument, UpsertSiteSettingInput } from '@local/graphql/graphql';
import { useSettings } from '@local/hooks';
import { SettingDefaults, THEME_MODULE, ThemeSettingsType } from '@local/hooks/useSetting';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormColorPicker, HookFormSelect, HookFormTextField, useOperations } from '@seasonticker/dls';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export default function Design() {
  const { operation } = useOperations();
  const { leagueContext } = useLeagueContext();
  const { setting, setSettings } = useSettings();
  const [updateManySettingsDocuments] = useMutation(UpdateManySettingsDocument);
  const formMethods = useForm<ThemeSettingsType>({
    defaultValues: SettingDefaults[THEME_MODULE],
  });

  useEffect(() => {
    formMethods.reset({
      color: {
        primary: setting('theme.color.primary'),
        secondary: setting('theme.color.secondary'),
        mode: setting('theme.color.mode'),
      },
      font: {
        size: setting('theme.font.size'),
      },
      carousel: {
        aspectRatio: setting('theme.carousel.aspectRatio'),
      },
      shape: {
        borderRadius: setting('theme.shape.borderRadius'),
      },
    });
  }, [formMethods, setting]);

  const onSubmit = async (settings: ThemeSettingsType) => {
    const getSettingSaveValue = (setting: SiteSetting): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        return { id, value };
      }
      return { module, key, value, league: leagueContext?.league?.id };
    };
    await operation({
      success: 'The settings were successfully updated',
      error: 'An error occurred while updating the settings',
      operation: () =>
        updateManySettingsDocuments({
          variables: {
            settings: [
              getSettingSaveValue(settings.color.primary),
              getSettingSaveValue(settings.color.secondary),
              getSettingSaveValue(settings.color.mode),
              getSettingSaveValue(settings.font.size),
              getSettingSaveValue(settings.carousel.aspectRatio),
              getSettingSaveValue(settings.shape.borderRadius),
            ],
          },
        }),
      after: (response) => setSettings(response.data?.update_many_site_setting as SiteSetting[]),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid xs={12} sm={6}>
            <HookFormColorPicker
              name="color.primary.value"
              label="Primary Color"
              fullWidth
              tooltip="The primary color is used for the main visual elements is the most common color used throughout the theme."
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <HookFormColorPicker
              name="color.secondary.value"
              label="Secondary Color"
              fullWidth
              tooltip="The secondary color is used as an accent to distinguish between selected items and should generally be darker than the primary color."
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <HookFormTextField
              name="font.size.value"
              label="Font Size"
              fullWidth
              type="number"
              rules={{ required: true }}
              tooltip="The main font size drives the size of all fonts displayed as well as the spacing.  This theme was designed for 16px, changing this by more than a few pixels
              will have a dramatic and likely undesirable effect."
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <HookFormTextField
              name="shape.borderRadius.value"
              label="Border Radius"
              fullWidth
              type="number"
              rules={{ required: true }}
              tooltip="The controls how rounded elements are on the screen"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <HookFormSelect
              name="color.mode.value"
              label="Color Mode"
              fullWidth
              options={['light', 'dark']}
              tooltip="Determines whether the main background is light or dark."
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <HookFormTextField
              name="carousel.aspectRatio.value"
              label="Carousel Aspect Ratio"
              fullWidth
              tooltip="Determines the aspect ratio for the carousel component.  Represented as w/h for example 16/9"
            />
          </Grid>
          <Grid xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
