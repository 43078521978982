import { AgeGroup } from '@local/graphql/graphql';
import { createContext, ReactElement, useCallback, useContext, useState } from 'react';
export type AgeGroupState = {
  ageGroup: AgeGroup;
  setAgeGroupContext: (season: AgeGroup) => void;
};

/**
 * Note, this is ONLY to be used in the root app, to get access to the values
 * use the usePortalContext hook
 * @returns
 */
function useAgeGroupState(): AgeGroupState {
  const [ageGroup, setAgeGroupState] = useState<AgeGroup>({} as AgeGroup);
  const setAgeGroupContext = useCallback((newAgeGroup: AgeGroup) => {
    localStorage.setItem('ageGroupId', newAgeGroup.id);
    setAgeGroupState(newAgeGroup);
  }, []);
  return {
    ageGroup,
    setAgeGroupContext,
  };
}

export const AgeGroupContext = createContext<AgeGroupState>({} as AgeGroupState);
export const AgeGroupContextProvider = ({ children }: { children: ReactElement }) => {
  const leagueState = useAgeGroupState();
  return <AgeGroupContext.Provider value={leagueState}>{children}</AgeGroupContext.Provider>;
};
export const AgeGroupContextConsumer = AgeGroupContext.Consumer;

/**
 * Returns the current portal state.  There are a few global values stored in the state to control things like header
 * and page titles
 * @returns The Current PortalState
 */
export function useAgeGroupContext(): AgeGroupState {
  return useContext(AgeGroupContext);
}
