import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { List, ListProps } from '@mui/material';
import { cloneElement, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { SortableListItemProps } from './SortableListItem';
import { SortableListItemType } from './SortableListItemType';

/**
 * Properties that can be passed to a sortable list
 */
export type SortableListProps<T extends SortableListItemType> = ListProps & {
  /**
   * Callback applied when the list is sorted
   */
  onSorted?: (sortAction: SetStateAction<T[]>) => void;

  /**
   * Function used to identify the identifier
   */
  getIdentifier?: (item: T) => UniqueIdentifier;

  /**
   *
   */
  sorting?: boolean;

  /**
   * The list of items to be sorted
   */
  items: T[];

  /**
   * The Item template for each row
   */
  ItemTemplate: (item: T) => ReactElement<SortableListItemProps<T>>;
};
/**
 * Creates a sortable list component
 * @param props
 * @returns
 */
export function SortableList<T extends SortableListItemType>(props: SortableListProps<T>) {
  const { sorting, onSorted, items, getIdentifier = (item) => item.id, ItemTemplate } = props;
  const [localItems, setLocalItems] = useState<T[]>(items);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const sortFunction = (items: T[]) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      };
      setLocalItems(sortFunction);
      if (onSorted) {
        onSorted(sortFunction);
      }
    }
  };
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={localItems}>
        <List>
          {localItems.map((item) => {
            const element = ItemTemplate(item);
            return cloneElement(element, { item, getIdentifier, sorting });
          })}
        </List>
      </SortableContext>
    </DndContext>
  );
}
