import { useLazyQuery } from '@apollo/client';
import { useAgeGroupContext } from '@local/context/league/age-group-context';
import { Registration, RegistrationDashboardDocument, Team } from '@local/graphql/graphql';
import { Box, Button, Link, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AgeGroupSelector } from '../components/AgeGroupSelector';

type RegistrationDashboardRecord = {
  registration: Registration;
  team: Team;
  home_dates: number;
  total_games: number;
  away_games: number;
  home_games: number;
  scheduled_games: number;
  games_played: number;
  roster_count: number;
  waiver_signed: number;
};

type TeamLevelGroup = {
  id: string;
  name: string;
  registrations: RegistrationDashboardRecord[];
};

export function RegistrationDashboard() {
  const { ageGroup } = useAgeGroupContext();

  const [results, setResults] = useState<TeamLevelGroup[]>([]);
  const [regQuery] = useLazyQuery(RegistrationDashboardDocument, { fetchPolicy: 'no-cache' });
  const toNumber = (input?: any) => {
    const num = Number(input);
    if (Number.isNaN(num)) {
      return 0;
    }
    return num;
  };
  const loadData = useCallback(async () => {
    const response = await regQuery({
      variables: {
        id: ageGroup?.id,
      },
    });
    const data =
      response.data?.age_group?.team_levels?.map((team_level) => {
        return {
          id: team_level.id,
          name: team_level.name,
          registrations:
            team_level.registrations?.map((reg) => {
              const allGames = [...(reg.home_games || []), ...(reg.away_games || [])];
              return {
                registration: reg as Registration,
                team: reg.team as Team,
                home_dates: reg.home_dates?.length || 0,
                away_games: reg.away_games?.length || 0,
                home_games: reg.home_games?.length || 0,
                total_games: allGames.length,
                scheduled_games: allGames.filter((g) => g.date?.id !== undefined).length,
                games_played: allGames.filter((g) => g.isPlayed).length,
                roster_count: reg.rosters?.length || 0,
                waiver_signed: reg.rosters?.filter((r) => r.waiver).length || 0,
              } as RegistrationDashboardRecord;
            }) || [],
        } as TeamLevelGroup;
      }) || [];
    setResults(data);
  }, [ageGroup?.id, regQuery]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef<RegistrationDashboardRecord>[] = useMemo(() => {
    return [
      {
        headerName: 'Team',
        hideable: false,
        sortable: true,
        flex: 1,
        type: 'string',
        field: 'team.name',
        valueGetter: (params) => params.row.team.name,
        renderCell: (params) => {
          return (
            <Button
              sx={{ textTransform: 'none', fontWeight: 400, padding: '1px' }}
              variant="text"
              component={Link}
              href={`/league/team/${params.row.team?.urlKey}`} /*to={`/team/${params.row.team?.urlKey}`}*/
            >
              {params.row.team?.name}
            </Button>
          );
        },
      },
      {
        headerName: 'hd',
        hideable: true,
        sortable: true,
        width: 75,
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        field: 'home_dates',
        valueGetter: (params) => toNumber(params.row.home_dates),
      },
      {
        headerName: 'tg',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'total_games',
        valueGetter: (params) => toNumber(params.row.total_games),
      },
      {
        headerName: 'ag',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'away_games',
        valueGetter: (params) => toNumber(params.row.away_games),
      },
      {
        headerName: 'hg',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'home_games',
        valueGetter: (params) => toNumber(params.row.home_games),
      },
      {
        headerName: 'sg',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'scheduled_games',
        valueGetter: (params) => toNumber(params.row.scheduled_games),
      },
      {
        headerName: 'gp',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'games_played',
        valueGetter: (params) => toNumber(params.row.games_played),
      },
      {
        headerName: 'ro',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'roster_count',
        valueGetter: (params) => toNumber(params.row.roster_count),
      },
      {
        headerName: 'wa',
        hideable: true,
        sortable: true,
        width: 75,
        align: 'right',
        headerAlign: 'right',
        field: 'waiver_signed',
        valueGetter: (params) => toNumber(params.row.waiver_signed),
      },
    ];
  }, []);
  return (
    <>
      <AgeGroupSelector onChange={() => {}} />
      {results.map((team_level) => (
        <Box key={team_level.id}>
          <Typography variant="body2" sx={{ my: '.8em' }}>
            {team_level.name} ({team_level.registrations?.length || 0})
          </Typography>
          <DataGridPro
            density="compact"
            columns={columns}
            disableColumnMenu
            disableColumnResize
            disableColumnFilter
            disableRowSelectionOnClick
            disableChildrenSorting
            hideFooter
            autoHeight
            rows={team_level.registrations}
            getRowId={(row) => String(row.team.id)}
            initialState={{
              sorting: {
                sortModel: [{ field: 'team.name', sort: 'asc' }],
              },
            }}
          />
        </Box>
      ))}
    </>
  );
}

export default RegistrationDashboard;
