import { AgeGroup, Division, Registration, TeamLevel } from '@local/graphql/graphql';
import { JSXElementConstructor } from 'react';

export type StepDefinition<T> = {
  id: number;
  name: string;
  Component: JSXElementConstructor<T>;
  completed?: boolean;
  optional?: boolean;
  finalStep?: boolean;
};

export enum ScheduleSteps {
  Scope = 0,
  Settings = 1,
  Generate = 2,
}

export type ScheduleWizardStepProps = {
  goToStep: (id: number) => void;
};
export type ScheduleWizardForm = {
  age_group: AgeGroup;
  team_level: TeamLevel;
  divisions: Division[];
  divisionGames: Division[];
  teams: Registration[];
  total_games_each: number;
  max_home_games_each: number;
  use_out_of_division: boolean;
};
