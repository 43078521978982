import { useSnackbar } from 'notistack';
import { useConfirm } from '../components/confirm';

export type OperationFunction<T> = () => T | Promise<T>;
export type AfterFunction<T, R> = (response: T) => R;

/**
 * An extension to confirm with the user before performing an operation
 */
export type ConfirmedOperation<T> = Operation<T> & {
  /**
   * The confirmation message to display
   */
  confirmation: string;
};

/**
 * The options of a basic operation
 */
export type Operation<T, R = any> = {
  /**
   * The success message to show the user
   */
  success: string;
  /**
   * The error message to show the user
   */
  error?: string;
  /**
   * The operation to execute
   */
  operation: OperationFunction<T>;
  /**
   * Something to handle the response of the operation
   */
  after?: AfterFunction<T, R>;
};

/**
 * The options of a basic operation
 */
export type SilentOperation<T, R = any> = {
  /**
   * The error message to show the user
   */
  error?: string;
  /**
   * The operation to execute
   */
  operation: OperationFunction<T>;
  /**
   * Something to handle the response of the operation
   */
  after?: AfterFunction<T, R>;
};

export function useOperations() {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Confirms with the user and then runs the operation if successfully confirmed
   * @param param0
   */
  const confirmedOperation = async <T,>(options: ConfirmedOperation<T>) => {
    const { confirmation, after, success, error, operation: operationHandler } = options;
    if (await confirm({ title: confirmation })) {
      operation({
        success,
        error,
        operation: operationHandler,
        after,
      });
    }
  };

  /**
   * Runs a server call operation via a try/catch and displays a
   * snackbar notification for success and failures
   * @param options The operation options
   */
  const operation = async <T, R>(options: Operation<T, R>) => {
    const { success, after, error = 'An error occurred', operation } = options;
    try {
      const response = await operation();
      enqueueSnackbar(success);
      if (after && response) {
        return after(response);
      } else {
        return response;
      }
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar(`${error}`, { variant: 'error' });
    }
  };

  /**
   * Runs a server call operation via a try/catch and displays a
   * snackbar notification for success and failures
   * @param options The operation options
   */
  const silentOperation = async <T, R>(options: SilentOperation<T, R>) => {
    const { after, error = 'An error occurred', operation } = options;
    try {
      const response = await operation();
      if (after) {
        return after(response);
      } else {
        return response;
      }
    } catch (ex) {
      enqueueSnackbar(`${error} -- ${ex}`, { variant: 'error' });
    }
  };

  return {
    confirmedOperation,
    silentOperation,
    operation,
  };
}

export default useOperations;
