import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Calendar from './Calendar';
import GamesListDisplay from './Games/GamesListDisplay';
import { RosterWidget } from './Roster/RosterWidget';
import { StaffWidget } from './Staff/StaffWidget';
import { StandingsWidget } from './Standings/StandingsWidget';

export default function Dashboard() {
  return (
    <Grid container columnSpacing={'1em'}>
      <Grid xs={12} sm={6} md={8}>
        <GamesListDisplay />
        <Divider sx={{ my: '.8em' }} />
        <Calendar />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <StaffWidget />
        <Divider sx={{ my: '.8em' }} />
        <StandingsWidget />
        <Divider sx={{ my: '.8em' }} />
        <RosterWidget />
      </Grid>
    </Grid>
  );
}
