import { useLazyQuery, useMutation } from '@apollo/client';
import { CreateRoleDocument, GetRolesDocument, Role } from '@local/graphql/graphql';
import { Button, List, ListItem, ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormTextField, useOperations } from '@seasonticker/dls';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type RoleForm = {
  name: string;
};

export function CreateRoles() {
  const [createRoleMutation] = useMutation(CreateRoleDocument);
  const [modified, setModified] = useState(Date.now());
  const [roles, setRoles] = useState<Role[]>([]);
  const [rolesLoader] = useLazyQuery(GetRolesDocument, { fetchPolicy: 'no-cache' });

  const addRoleForm = useForm<RoleForm>();

  const { operation } = useOperations();

  const createRole = useCallback(
    async (role: RoleForm) => {
      await operation({
        success: 'Role created',
        after: () => setModified(Date.now()),
        operation: () =>
          createRoleMutation({
            variables: {
              role: role,
            },
          }),
      });
    },
    [createRoleMutation, operation]
  );

  const loadRoles = useCallback(async () => {
    const result = await rolesLoader({
      variables: {
        roles: ['agegroup_director', 'leagueadmin', 'official'],
      },
    });
    if (result.data) {
      setRoles(result.data.roles as Role[]);
    }
  }, [rolesLoader]);
  useEffect(() => {
    loadRoles();
  }, [loadRoles, modified]);

  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <List>
          {roles?.map((r) => {
            return (
              <ListItem key={r.id}>
                <ListItemText primary={r.name} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid xs={12} md={6}>
        <FormProvider {...addRoleForm}>
          <form onSubmit={addRoleForm.handleSubmit(createRole)}>
            <Grid container rowSpacing={'.8em'}>
              <Grid xs={12}>
                <HookFormTextField name="name" label="Role Name" fullWidth />
              </Grid>
              <Grid xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Create Role
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
}

export default CreateRoles;
