import { Roles } from '@seasonticker/dls';
import { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react';

export type Page = {
  name: string;
  link?: string;
  url?: string;
  icon?: ReactNode;
  end?: boolean;
  roles?: Roles[];
  children?: Page[];
};

export type NavigationState = {
  pages: Page[];
  setPages: (pages: Page[]) => void;
  secondaryPages: Page[];
  setSecondaryPages: (teamPages: Page[]) => void;
};

/**
 * Note, this is ONLY to be used in the root app, to get access to the values
 * use the usePortalContext hook
 * @returns
 */
function useNavigationState(): NavigationState {
  const [pages, setPagesState] = useState<Page[]>([] as Page[]);
  const setPages = useCallback((updatedPages: Page[]) => {
    setPagesState(updatedPages);
  }, []);
  const [secondaryPages, setSecondaryPagesState] = useState<Page[]>([] as Page[]);
  const setSecondaryPages = useCallback((updatedPages: Page[]) => {
    setSecondaryPagesState(updatedPages);
  }, []);
  return {
    pages,
    setPages,
    secondaryPages,
    setSecondaryPages,
  };
}

export const NavigationContext = createContext<NavigationState>({} as NavigationState);
export const NavigationContextProvider = ({ children }: { children: ReactElement }) => {
  const navState = useNavigationState();
  return <NavigationContext.Provider value={navState}>{children}</NavigationContext.Provider>;
};
export const NavigationContextConsumer = NavigationContext.Consumer;

/**
 * Returns the current portal state.  There are a few global values stored in the state to control things like header
 * and page titles
 * @returns The Current PortalState
 */
export function useNavigationContext(): NavigationState {
  return useContext(NavigationContext);
}
