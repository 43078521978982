import { ReactNode, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

export enum Roles {
  authenticated = 'authenticated',
  admin = 'admin',
  official = 'league:{{context}}:official',
  leagueadmin = 'league:{{context}}:leagueadmin',
  manager = 'position:{{subContext}}:manager',
}

export type RoleTypes = keyof typeof Roles;

export type IsInRoleProps = {
  context?: string;
  subContext?: string;
  roles: Roles[];
};
export type IfInRole = (ifNode: ReactNode, elseNode?: ReactNode) => ReactNode;
export function useAuthz() {
  const { user, isAuthenticated } = useAuth();
  const isInRole = useCallback(
    ({ context, subContext, roles }: IsInRoleProps) => {
      if (!isAuthenticated) {
        return false;
      }
      const userRoles: string[] = (user?.profile.roles || []) as string[];
      return roles.reduce((access, role) => {
        if (
          access ||
          userRoles.find(
            (r) =>
              r ===
              role
                .toString()
                .replace('{{context}}', context || '')
                .replace('{{subContext}}', subContext || '')
          ) !== undefined
        ) {
          return true;
        }
        return false;
      }, false);
    },
    [isAuthenticated, user]
  );

  const ifInRole = useCallback(
    (args: IsInRoleProps): IfInRole => {
      return (ifNode: ReactNode, elseNode?: ReactNode) => {
        if (isInRole(args)) {
          return ifNode;
        } else {
          return elseNode || <></>;
        }
      };
    },
    [isInRole]
  );

  return {
    isInRole,
    ifInRole,
  };
}

export default useAuthz;
