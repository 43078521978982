import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context';
import { LoadRosterWaiverStateDocument, LoadWaiverTextDocument, RosterEntry, SignRosterWaiverDocument } from '@local/graphql/graphql';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormTextField, LoadingIndicator, useAuth, useOperations } from '@seasonticker/dls';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

type WaiverForm = {
  name: string;
};

export function WaiverConfirmation() {
  const { user, isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const [rosterId, setRosterId] = useState<string>();
  const [currentRoster, setCurrentRoster] = useState<RosterEntry>();
  const navigate = useNavigate();
  const [loadWaiverText] = useLazyQuery(LoadWaiverTextDocument);
  const [loadCurrentRoster, loadingVariables] = useLazyQuery(LoadRosterWaiverStateDocument);
  const [signWaiverMutation] = useMutation(SignRosterWaiverDocument);
  const [waiverText, setWaiverText] = useState<string>();
  const { leagueContext } = useLeagueContext();
  const form = useForm<WaiverForm>();

  const waiverTextLoader = useCallback(async () => {
    const response = await loadWaiverText({
      variables: {
        leagueId: String(leagueContext?.league?.id),
      },
    });
    if (response.data?.league?.leaguesettings?.waiverText) {
      setWaiverText(response.data?.league?.leaguesettings?.waiverText);
    }
  }, [leagueContext?.league?.id, loadWaiverText]);

  useEffect(() => {
    waiverTextLoader();
  }, [waiverTextLoader]);

  const loadExistingRoster = useCallback(async () => {
    if (rosterId) {
      const response = await loadCurrentRoster({
        variables: {
          rosterId: rosterId,
        },
      });
      if (response.data?.roster_entry?.id) {
        setCurrentRoster(response.data.roster_entry);
      }
    }
  }, [loadCurrentRoster, rosterId]);

  useEffect(() => {
    loadExistingRoster();
  }, [loadExistingRoster]);

  useEffect(() => {
    const id = searchParams.get('roster');
    if (id) {
      setRosterId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isAuthenticated && user?.profile) {
      form.setValue('name', `${user?.profile.given_name} ${user?.profile.family_name}`);
    }
  }, [form, isAuthenticated, user?.profile]);

  const { operation } = useOperations();
  const onSubmit = async (form: WaiverForm) => {
    if (rosterId) {
      await operation({
        operation: () => signWaiverMutation({ variables: { rosterId: rosterId, signature: form.name } }),
        success: 'The waiver was successfully signed',
        after: () => navigate('/'),
      });
    }
  };

  const WaiverTextDisplay = () => {
    if (waiverText) {
      return (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container rowSpacing={'.8em'}>
              <Grid xs={12}>
                <Typography variant="h2">{leagueContext?.league?.name} : Waivers</Typography>
              </Grid>
              <Grid xs={12}>
                <div dangerouslySetInnerHTML={{ __html: waiverText }}></div>
              </Grid>
              <Grid xs={12}>
                <HookFormTextField name="name" label="Your Name" rules={{ required: 'Name is required' }} />
              </Grid>
              <Grid xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  I Agree
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      );
    }
    return <></>;
  };

  if (currentRoster?.id && currentRoster.waiver) {
    return <Typography variant="body1">No action is required at this time</Typography>;
  } else if (currentRoster?.id && !currentRoster.waiver) {
    return <WaiverTextDisplay />;
  }
  return (
    <LoadingIndicator loading={loadingVariables.loading}>
      <Typography>This roster link has expired. Please check with your team manager.</Typography>
    </LoadingIndicator>
  );
}
