import { TeamHomeQueryQuery } from '@local/graphql/graphql';
import { createContext, ReactElement, useCallback, useContext, useState } from 'react';
type RegistrationResponse = TeamHomeQueryQuery['team_registration'];
export type TeamState = {
  registration: RegistrationResponse;
  setTeamContext: (registration: RegistrationResponse) => void;
};

/**
 * Note, this is ONLY to be used in the root app, to get access to the values
 * use the usePortalContext hook
 * @returns
 */
function useTeamState(): TeamState {
  const [registration, setTeamState] = useState<RegistrationResponse>({} as RegistrationResponse);
  const setTeamContext = useCallback((newRegistration: RegistrationResponse) => {
    setTeamState(newRegistration);
  }, []);
  return {
    registration,
    setTeamContext,
  };
}

export const TeamContext = createContext<TeamState>({} as TeamState);
export const TeamContextProvider = ({ children }: { children: ReactElement }) => {
  const leagueState = useTeamState();
  return <TeamContext.Provider value={leagueState}>{children}</TeamContext.Provider>;
};
export const TeamContextConsumer = TeamContext.Consumer;

/**
 * Returns the current portal state.  There are a few global values stored in the state to control things like header
 * and page titles
 * @returns The Current PortalState
 */
export function useTeamContext(): TeamState {
  return useContext(TeamContext);
}
