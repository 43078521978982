import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { useTeamContext } from '@local/context/league/team-context';
import {
  DeleteHomeDateDocument,
  Facility,
  GameDateTime,
  RefreshHomeDatesDocument,
  UpsertGameDateTimeInput,
  UpsertHomeDateDocument,
  UpsertManyHomeDatesDocument,
} from '@local/graphql/graphql';
import { ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, FieldSet, HookFormDateTimePicker, HookFormSelect, Legend, Roles, useAuthz } from '@seasonticker/dls';
import { format, parseJSON } from 'date-fns';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import HomeDatesDisplay from './HomeDatesDisplay';

export function HomeDates() {
  const { registration } = useTeamContext();
  const { leagueContext } = useLeagueContext();
  const [upsertMutation] = useMutation(UpsertHomeDateDocument);
  const [upsertManyMutation] = useMutation(UpsertManyHomeDatesDocument);
  const [deleteMutation] = useMutation(DeleteHomeDateDocument);
  const [refreshFacilities] = useLazyQuery(RefreshHomeDatesDocument, { fetchPolicy: 'no-cache' });

  const upsertItems = useCallback(
    (update: UpsertGameDateTimeInput[]) => () => upsertManyMutation({ variables: { id: String(registration?.id), upsert: update } }),
    [registration?.id, upsertManyMutation]
  );
  const { ifInRole, isInRole } = useAuthz();
  const permissionFunction = ifInRole({
    context: leagueContext?.league?.id,
    subContext: registration?.team?.id,
    roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
  });

  const loadItems = useCallback(async () => {
    const response = await refreshFacilities({ variables: { registrationId: String(registration?.id) } });
    return sortBy(response.data?.game_date_times as GameDateTime[], 'datetime');
  }, [refreshFacilities, registration?.id]);

  const prepareItem = useCallback(
    (item: GameDateTime) => {
      const { id, datetime, facility } = item;
      let { date, time } = item;
      if (datetime instanceof Date) {
        date = format(item.datetime, 'P');
        time = format(item.datetime, 'p');
      }

      return id !== undefined
        ? ({ id, datetime, date, time, facility: facility?.id } as UpsertGameDateTimeInput)
        : ({ datetime, date, time, facility: facility?.id, registration: registration?.id } as UpsertGameDateTimeInput);
    },
    [registration?.id]
  );

  const upsertItem = useCallback(
    (upsert: UpsertGameDateTimeInput) => () => upsertMutation({ variables: { id: String(registration?.id), upsert: upsert } }),
    [registration?.id, upsertMutation]
  );

  const deleteItem = useCallback(
    (item: GameDateTime) => () => deleteMutation({ variables: { id: String(registration?.id), item: item.id } }),
    [deleteMutation, registration?.id]
  );

  const canModifyRule = (item: GameDateTime) => {
    return (
      isInRole({
        context: leagueContext?.league?.id,
        subContext: registration?.team?.id,
        roles: [Roles.admin, Roles.leagueadmin],
      }) || item?.taken !== true
    );
  };

  const itemForm = (
    <FieldSet>
      <Legend>Home Date</Legend>
      <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
        <Grid xs={12} md={6}>
          <HookFormDateTimePicker defaultValue="" rules={{ required: 'The date is required' }} name="datetime" label="Date / Time" fullWidth />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormSelect<any, Facility>
            getValue={(item: Facility) => item?.id}
            getLabel={(item: Facility) => item?.name}
            findValue={(id: string) => registration?.team?.facilities?.find((f) => f.id === id) as Facility}
            options={registration?.team?.facilities as Facility[]}
            rules={{ required: 'The date is required' }}
            name="facility"
            label="Facility"
            fullWidth
          />
        </Grid>
      </Grid>
    </FieldSet>
  );

  const facilityDisplay = (item: GameDateTime) => <HomeDatesDisplay dates={item} />;
  const emptyTemplate = {
    date: '',
    time: '',
    datetime: '',
  } as GameDateTime;

  const labelFormat = (item: GameDateTime) => {
    if (item.datetime) {
      try {
        return format(parseJSON(item?.datetime), 'P p');
      } catch (ex) {
        console.log(ex);
      }
    }
    return `${item.date} ${item.time}`;
  };

  return (
    <EditableList<
      GameDateTime,
      UpsertGameDateTimeInput,
      ReturnType<typeof upsertMutation>,
      ReturnType<typeof upsertManyMutation>,
      ReturnType<typeof deleteMutation>
    >
      dense
      model="Home Dates"
      refresh={loadItems}
      refreshList={loadItems}
      deleteMutation={deleteItem}
      prepareUpsert={prepareItem}
      upsertMutation={upsertItem}
      upsertOperation={upsertItems}
      canEdit={permissionFunction}
      listItemTemplate={(item) => (
        <ListItemText
          primary={<Typography variant="body1" sx={{ textDecoration: item.taken ? 'line-through' : 'none' }}>{`${labelFormat(item)}`}</Typography>}
        />
      )}
      editElement={itemForm}
      itemTemplate={facilityDisplay}
      emptyTemplate={emptyTemplate}
      editable={canModifyRule}
      deletable={canModifyRule}
    />
  );
}

export default HomeDates;
