import { boolean } from 'boolean';
import { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';

export type VisibleIfProps = PropsWithChildren & {
  cond: boolean | (() => boolean) | (() => Promise<boolean>) | undefined | string | (() => string) | (() => Promise<string>);
  children: ReactElement;
};
export function VisibleIf(props: VisibleIfProps) {
  const [visible, setVisible] = useState(false);
  const { cond, children } = props;
  const isVisibleLogic = useCallback(async () => {
    if (typeof cond === 'function') {
      if (cond.constructor.name === 'AsyncFunction') {
        setVisible(boolean(await cond()));
      } else {
        return setVisible(cond() as boolean);
      }
    } else {
      return setVisible(boolean(cond));
    }
  }, [cond]);

  useEffect(() => {
    isVisibleLogic();
  }, [isVisibleLogic]);

  return visible ? children : <></>;
}
