import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { JSXElementConstructor, useEffect } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';

/**
 * A specific data base type that declares only a primary key as an entity
 */
export type DatabaseType = {
  id?: string | null;
};

/**
 * Specific properties that can be passed along on a Modal form implementation
 */
export type ModalFormImplementationProps<ModelType extends DatabaseType, FormType extends DatabaseType> = {
  /**
   * This is the data object that will be the base current state value for the form
   */
  model?: ModelType;
  /**
   * Whether the form appears as an inline card or a dialog
   */
  mode?: 'form' | 'dialog';

  /**
   * The form title
   */
  title?: string;

  /**
   * If the mode is dialog, controls if it's open
   */
  open?: boolean;

  /**
   * Listener for when the user clicks on internal dialog action to close
   * @returns
   */
  handleClose?: () => void;
  /**
   * The main action to perform when the form is submitted
   * @param output
   * @returns
   */
  onSubmit: (output: FormType) => void | Promise<void>;
};

/**
 * This type is really only useful for direct form implementations, these fields shouldn't really be propagated to the subsequent callers
 */
export type ModalFormProps<ModelType extends DatabaseType, FormType extends DatabaseType> = ModalFormImplementationProps<ModelType, FormType> & {
  /**
   * The initial default values for the form
   */
  defaultValues: DeepPartial<FormType>;
  /**
   * The Form implementation must define the fields display
   */
  FormFields: JSXElementConstructor<{ children?: React.ReactElement<any, any> }>;
};

/**
 * An abstract Form wrapper that creates a variety of displays based on mode (form / dialog).  It uses the same fieldset definitions but
 * renders the container differently.
 * @param props
 * @returns
 */
export function ModalForm<ModelType extends DatabaseType, FormType extends DatabaseType>(props: ModalFormProps<ModelType, FormType>) {
  const { model, FormFields, defaultValues, onSubmit, mode = 'form', open = false, handleClose = () => {}, title } = props;
  const formMethods = useForm<FormType>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (model) {
      formMethods.reset(model as unknown as FormType);
    }
  }, [model, formMethods]);

  const SubmitButton = () => {
    return (
      <Button color="primary" variant="contained" type="submit">
        {model?.id ? 'Save' : 'Create'}
      </Button>
    );
  };

  if (mode === 'dialog') {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            {title ? <DialogTitle>{title}</DialogTitle> : <></>}
            <DialogContent>
              <Box sx={{ my: '.8em' }}>
                <FormFields />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <SubmitButton />
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    );
  }
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          {title ? <CardHeader title={title} /> : <></>}
          <CardContent>
            <FormFields />
          </CardContent>
          <CardActions>
            <SubmitButton />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
}
