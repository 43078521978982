import type { GeocodeResult } from '@googlemaps/google-maps-services-js/dist/index';
import { Box, styled } from '@mui/material';
import { useMemo } from 'react';

type Address = {
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  street?: string | null;
  country?: string | null;
  geocoding?: GeocodeResult | null;
};

type MapDisplayProps = {
  zoom?: number;
  width?: string | number;
  height?: string | number;
  address?: Address | null;
};

export const MapDisplay = (props: MapDisplayProps) => {
  const { address, zoom = 15, width = '100%', height = '400px' } = props;
  const { geometry } = address?.geocoding || {};
  const { lat, lng } = geometry?.location || {};
  const formatted_address = useMemo(() => {
    if (address?.geocoding?.formatted_address) {
      return address.geocoding.formatted_address;
    } else {
      return `${address?.street || ''} ${address?.city || ''} ${address?.state || ''} ${address?.zip || ''} ${address?.country || ''}`;
    }
  }, [address]);
  const StyledIframe = styled('iframe')(({ theme }) => {
    return {
      flexGrow: 1,
      border: 'none',
      margin: 0,
      padding: 0,
    };
  });
  const centerParam = (lat?: number, lng?: number) => {
    if (lat && lng) {
      return `&center=${lat},${lng}`;
    }
    return '';
  };
  return formatted_address ? (
    <Box sx={{ width: width, minHeight: height, display: 'flex', flexDirection: 'column' }}>
      <StyledIframe
        title="map link"
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${String(process.env.REACT_APP_GOOGLE_KEY)}&zoom=${zoom}&q=${encodeURIComponent(
          formatted_address
        )}${centerParam(lat, lng)}`}
        allowFullScreen></StyledIframe>
    </Box>
  ) : (
    <></>
  );
};
