import { Box, Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '@seasonticker/dls';
import { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export function UserNavMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user, signinRedirect, signoutRedirect, isAuthenticated, events, isLoading } = useAuth();
  const location = useLocation();

  const onLoginClick = useCallback(() => {
    localStorage.setItem('seasonticker_login_redirect', JSON.stringify(location));
    signinRedirect();
  }, [location, signinRedirect]);

  /**
   * Main logic when the user clicks the logout button
   */
  const onLogoutClick = useCallback(() => {
    signoutRedirect();
  }, [signoutRedirect]);
  if (isAuthenticated && user?.profile) {
    return (
      <Box>
        <Button
          sx={{ my: 2, color: 'white', display: 'block' }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          {user?.profile?.given_name || user?.profile?.email}
        </Button>
        <Menu
          id="user-options-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}>
          <MenuItem component={NavLink} to={'/profile'}>
            My Account
          </MenuItem>
          <MenuItem onClick={onLogoutClick}>Log out</MenuItem>
        </Menu>
      </Box>
    );
  } else {
    return (
      <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => onLoginClick()}>
        Sign On
      </Button>
    );
  }
}

export default UserNavMenu;
