import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import OfficialsIcon from '@mui/icons-material/Sports';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WidgetsIcon from '@mui/icons-material/Widgets';

import { ProtectedRoute } from '@local/auth/ProtectedRoute';
import Standings from '@local/components/standings/Standings';
import { useLeagueContext } from '@local/context/league/league-context';
import { useNavigationContext } from '@local/context/league/navigation-context';
import { useSeasonContext } from '@local/context/league/season-context';
import Home from '@local/league/Home';
import Layout from '@local/league/Layout';
import SettingsPanel from '@local/league/settings/SettingsPanel';
import SetupRouter from '@local/league/setup/SetupRouter';
import Teams from '@local/league/Teams';
import TeamHome from '@local/team/TeamHome';
import { Typography } from '@mui/material';
import { Roles } from '@seasonticker/dls';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Profile } from '../users/Profile';
import { WaiverConfirmation } from './components/WaiverConfirmation';
import ManageRoutes from './manage/ManageRoutes';
import { Officials } from './officials/Officials';
import { Permissions } from './permissions/Permissions';
import { RegisterForSeason } from './registration/RegisterForSeason';
import { ContactsReport } from './reports/Contacts';
import GamesReport from './reports/Games';
import PaymentDashboard from './reports/PaymentDashboard';
import RegistrationDashboard from './reports/RegistrationDashboard';
import StandingsReport from './reports/StandingsReports';
import WaiverReport from './reports/WaiverReport';

export default function Router() {
  const { leagueContext } = useLeagueContext();
  const { season } = useSeasonContext();
  const { setPages } = useNavigationContext();
  useEffect(() => {
    setPages([
      { name: `${leagueContext?.league?.acronym} Home`, link: '/', icon: <DashboardIcon />, end: true },
      { name: 'Teams', link: '/teams', icon: <PeopleIcon /> },
      { name: 'Standings', link: '/standings', icon: <FormatListNumberedIcon /> },
      { name: 'Officials', link: '/officials', icon: <OfficialsIcon />, roles: [Roles.admin, Roles.leagueadmin, Roles.official] },
      {
        name: 'Setup',
        link: '/setup',
        icon: <WidgetsIcon />,
        roles: [Roles.admin, Roles.leagueadmin],
        children: [
          { name: 'Season', link: '/setup/season', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Age Groups', link: '/setup/agegroup', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Team Levels', link: '/setup/teamlevel', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Divisions', link: '/setup/division', roles: [Roles.admin, Roles.leagueadmin] },
        ],
      },
      {
        name: 'Manage',
        link: '/manage',
        icon: <AssignmentIcon />,
        roles: [Roles.admin, Roles.leagueadmin],
        children: [
          { name: 'Registrations', link: '/manage/registrations', icon: <ListAltIcon />, roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Assign Divisions', link: '/manage/divisions', icon: <AssignmentIcon />, roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Generate Standings', link: '/manage/standings', icon: <FormatListNumberedIcon />, roles: [Roles.admin] },
          { name: 'Generate Schedule', link: '/manage/schedule', icon: <FormatListNumberedIcon />, roles: [Roles.admin] },
          { name: 'Scheduling', link: '/manage/scheduling', icon: <FormatListNumberedIcon />, roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Games', link: '/manage/games', icon: <FormatListNumberedIcon />, roles: [Roles.admin] },
        ],
      },
      { name: 'Settings', link: '/settings', icon: <SettingsIcon />, roles: [Roles.admin, Roles.leagueadmin] },
      { name: 'Permissions', link: '/permissions', icon: <ManageAccountsIcon />, roles: [Roles.admin] },
      {
        name: 'Reports',
        link: '/reports',
        icon: <SummarizeIcon />,
        roles: [Roles.admin, Roles.leagueadmin],
        children: [
          { name: 'Registrations', link: '/reports/registrationdashboard', roles: [Roles.admin, Roles.leagueadmin] },
          //{ name: 'Registrations', icon: <SummarizeIcon />, url: '/league/reports/registrationdashboard', roles: [Roles.admin, Roles.leagueadmin] },
          //{ name: 'Payments', icon: <SummarizeIcon />, link: '/reports/payments', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Payments', url: '/league/reports/paymentdashboard', roles: [Roles.admin, Roles.leagueadmin] },
          //{ name: 'Waivers', icon: <SummarizeIcon />, url: '/reports/waivers', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Waivers', url: '/league/reports/roster', roles: [Roles.admin, Roles.leagueadmin] },
          // { name: 'Standings', icon: <SummarizeIcon />, link: '/reports/standings', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Standings', url: '/league/reports/playoffstandings', roles: [Roles.admin, Roles.leagueadmin] },
          //{ name: 'Games', icon: <SummarizeIcon />, link: '/reports/games', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Games', url: '/league/reports/games', roles: [Roles.admin, Roles.leagueadmin] },
          //{ name: 'Contacts', icon: <SummarizeIcon />, link: '/reports/contacts', roles: [Roles.admin, Roles.leagueadmin] },
          { name: 'Contacts', url: `/league/reports/contacts/${season?.id}`, roles: [Roles.admin, Roles.leagueadmin] },
        ],
      },
    ]);
  }, [leagueContext?.league?.acronym, season?.id, setPages]);
  return (
    <Routes>
      <Route path="/" element={<Layout />} errorElement={<Typography>Error</Typography>}>
        <Route path="/" element={<Home />} />
        <Route path="/waiver-confirmation" element={<WaiverConfirmation />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute roles={[Roles.authenticated]}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/officials"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin, Roles.official]}>
              <Officials />
            </ProtectedRoute>
          }
        />
        <Route path="/standings" element={<Standings />} />
        <Route
          path="/reports/registrationdashboard"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <RegistrationDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/payments"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <PaymentDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/waivers"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <WaiverReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/standings"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <StandingsReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/games"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <GamesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/contacts"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <ContactsReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/*"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <SetupRouter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage/*"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <ManageRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/*"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <SettingsPanel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/permissions/*"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <Permissions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register/:seasonId"
          element={
            <ProtectedRoute roles={[Roles.authenticated]}>
              <RegisterForSeason />
            </ProtectedRoute>
          }
        />
        <Route path="/teams" element={<Teams />} />
        <Route path="/team/:urlKey/*" element={<TeamHome />} />
      </Route>
    </Routes>
  );
}
