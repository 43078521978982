import { useLazyQuery, useMutation } from '@apollo/client';
import { useSeasonContext } from '@local/context/league/season-context';
import {
  AgeGroup,
  DeleteAgeGroupDocument,
  LoadAgeGroupDocument,
  LoadAgeGroupsDocument,
  UpsertAgeGroupDocument,
  UpsertAgeGroupInput,
  UpsertManyAgeGroupsDocument,
} from '@local/graphql/graphql';
import { useSorting } from '@local/hooks';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Card, CardActions, CardContent, IconButton, ListItemButton, ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { SortableList, SortableListItem, useOperations } from '@seasonticker/dls';
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import AgeGroupForm from './AgeGroupForm';

export default function AgeGroupSetup() {
  const { operation, confirmedOperation } = useOperations();
  const { season } = useSeasonContext();
  const sort = useSorting();
  const [loadAgeGroups] = useLazyQuery(LoadAgeGroupsDocument, {
    fetchPolicy: 'no-cache',
  });
  const [loadAgeGroup] = useLazyQuery(LoadAgeGroupDocument);
  const [upsertAgeGroup] = useMutation(UpsertAgeGroupDocument);
  const [upsertManyAgeGroup] = useMutation(UpsertManyAgeGroupsDocument);
  const [removeAgeGroup] = useMutation(DeleteAgeGroupDocument);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup>();
  const [ageGroups, setAgeGroups] = useState<AgeGroup[]>([]);
  const [modified, setModified] = useState(new Date());

  const loader = useCallback(async () => {
    const response = await loadAgeGroups({
      variables: {
        seasonId: season?.id,
      },
    });
    setAgeGroups(sort(response.data?.age_groups as AgeGroup[]));
    setSelectedAgeGroup(undefined);
  }, [loadAgeGroups, season?.id, sort]);

  useEffect(() => {
    if (season?.id) {
      loader();
    }
  }, [loader, modified, season?.id]);

  const loadLatestAgeGroup = useCallback(
    async (id: string) => {
      const response = await loadAgeGroup({
        variables: {
          id,
        },
      });
      setSelectedAgeGroup(response.data?.age_group as AgeGroup);
    },
    [loadAgeGroup]
  );

  const selectAgeGroup = useCallback(
    (id: string) => {
      const foundAgeGroup = ageGroups?.find((ag) => ag.id === id);
      if (foundAgeGroup) {
        loadLatestAgeGroup(foundAgeGroup.id);
      }
    },
    [ageGroups, loadLatestAgeGroup]
  );

  const ageGroupNextOrder = useMemo(() => {
    return ageGroups?.length || 0;
  }, [ageGroups?.length]);

  const onSubmit = useCallback(
    async (data: UpsertAgeGroupInput) => {
      const { name, description } = data;
      const updateValue: UpsertAgeGroupInput = {
        name,
        description,
        id: selectedAgeGroup?.id,
      };
      if (selectedAgeGroup?.id !== undefined && selectedAgeGroup?.id.length === 0) {
        updateValue.season = season?.id;
        updateValue.sortOrder = ageGroupNextOrder;
      }
      await operation({
        success: 'The age group was successfully updated',
        error: 'There was an error updating the age group',
        operation: () =>
          upsertAgeGroup({
            variables: {
              upsert: updateValue,
            },
          }),
        after: () => setModified(new Date()),
      });
    },
    [ageGroupNextOrder, operation, season?.id, selectedAgeGroup?.id, upsertAgeGroup]
  );

  const add = useCallback(() => {
    const newItem = {
      id: '',
      name: '',
      sortOrder: ageGroups.length,
    } as UpsertAgeGroupInput;
    setSelectedAgeGroup(newItem as unknown as AgeGroup);
  }, [ageGroups.length]);

  const remove = useCallback(
    async (ageGroup: AgeGroup) => {
      await confirmedOperation({
        confirmation: 'Are you sure you would like to remove this age group?',
        success: 'The age group was successfully deleted',
        error: 'There was an error updating the age group',
        operation: () =>
          removeAgeGroup({
            variables: {
              id: ageGroup.id,
            },
          }),
        after: () => setModified(new Date()),
      });
    },
    [confirmedOperation, removeAgeGroup]
  );

  const [sorting, setSorting] = useState(false);
  const toggleSort = () => {
    if (sorting) {
      saveSortOrder();
    }
    setSorting(!sorting);
  };

  const saveSortOrder = useCallback(async () => {
    await operation({
      success: 'Sort order saved',
      error: 'There was an error saving the sort order',
      operation: () =>
        upsertManyAgeGroup({
          variables: {
            update: ageGroups.map((ag, idx) => ({ id: ag.id, sortOrder: idx })),
          },
        }),
    });
  }, [ageGroups, operation, upsertManyAgeGroup]);

  const onSortedHandler = (action: SetStateAction<AgeGroup[]>) => {
    setAgeGroups(action);
  };

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid xs={4}>
        <Card>
          <CardContent>
            <SortableList<AgeGroup>
              items={ageGroups}
              sorting={sorting}
              onSorted={onSortedHandler}
              ItemTemplate={(age_group) => (
                <SortableListItem
                  key={age_group.id}
                  secondaryAction={
                    <IconButton color="error" edge="end" aria-label="delete" onClick={() => remove(age_group)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  }>
                  <ListItemButton selected={selectedAgeGroup?.id === age_group.id} onClick={() => selectAgeGroup(age_group.id)}>
                    <ListItemText primary={age_group.name} />
                  </ListItemButton>
                </SortableListItem>
              )}
            />
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={add}>
              Add
            </Button>
            <Button variant="outlined" onClick={toggleSort}>
              {sorting ? 'Save Order' : 'Sort'}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      {selectedAgeGroup ? (
        <Grid xs={8}>
          <AgeGroupForm model={selectedAgeGroup} onSubmit={onSubmit} />
        </Grid>
      ) : null}
    </Grid>
  );
}
