import { Route, Routes } from 'react-router-dom';
import GameDisplay from './Games/GameDisplay';
import Layout from './Layout';
import TeamTabs from './TeamTabs';

export default function Router() {
  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route path="game/:id" element={<GameDisplay />} />
        <Route path="*" element={<TeamTabs />} />
      </Route>
    </Routes>
  );
}
