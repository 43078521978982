import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AgeGroup,
  DeleteTeamLevelDocument,
  LoadTeamLevelsDocument,
  TeamLevel,
  UpsertManyTeamLevelsDocument,
  UpsertTeamLevelDocument,
  UpsertTeamLevelInput,
} from '@local/graphql/graphql';
import { useSorting } from '@local/hooks';
import { AgeGroupSelector } from '@local/league/components/AgeGroupSelector';
import { Button, Card, CardActions, CardContent, IconButton, ListItemButton, ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { SortableList, SortableListItem, useOperations } from '@seasonticker/dls';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import TeamLevelForm from './TeamLevelForm';

export default function TeamLevelSetup() {
  const { operation, confirmedOperation } = useOperations();
  const sort = useSorting();
  const [ageGroup, setAgeGroup] = useState<AgeGroup>({} as AgeGroup);
  const [modified, setModified] = useState(new Date());
  const [loadTeamLevels] = useLazyQuery(LoadTeamLevelsDocument, { fetchPolicy: 'no-cache' });
  const [upsertTeamLevel] = useMutation(UpsertTeamLevelDocument);
  const [removeTeamLevel] = useMutation(DeleteTeamLevelDocument);
  const [upsertTeamLevels] = useMutation(UpsertManyTeamLevelsDocument);
  const [teamLevels, setTeamLevels] = useState<TeamLevel[]>([] as TeamLevel[]);
  const [selectedTeamLevel, setSelectedTeamLevel] = useState<TeamLevel>();
  const ageGroupChange = (ag: AgeGroup) => {
    setAgeGroup(ag);
  };

  const loader = useCallback(async () => {
    const response = await loadTeamLevels({
      variables: {
        ageGroupId: ageGroup?.id,
      },
    });
    setTeamLevels(sort(response.data?.team_levels as TeamLevel[]));
    setSelectedTeamLevel(undefined);
  }, [ageGroup?.id, loadTeamLevels, sort]);

  useEffect(() => {
    if (ageGroup && ageGroup.id) {
      loader();
    }
  }, [ageGroup, loader, modified]);

  const selectTeamLevel = (id: string) => {
    const foundTeamLevel = teamLevels?.find((tl) => tl.id === id);
    if (foundTeamLevel) {
      setSelectedTeamLevel(foundTeamLevel);
    }
  };

  const onSubmit = async (data: UpsertTeamLevelInput) => {
    const { name } = data;
    const updateValue: UpsertTeamLevelInput = {
      name,
      id: selectedTeamLevel?.id,
    };
    if (selectedTeamLevel?.id === undefined || selectedTeamLevel?.id.length === 0) {
      updateValue.age_group = ageGroup?.id;
      updateValue.sortOrder = teamLevels.length;
    }
    await operation({
      success: 'The team level was successfully updated',
      error: 'There was an error updating the team level',
      operation: () =>
        upsertTeamLevel({
          variables: {
            upsert: updateValue,
          },
        }),
      after: () => setModified(new Date()),
    });
  };

  const add = () => {
    const newItem = {
      id: '',
      name: '',
      sortOrder: teamLevels.length,
    } as UpsertTeamLevelInput;
    setSelectedTeamLevel(newItem as unknown as TeamLevel);
  };

  const remove = async (ageGroup: TeamLevel) => {
    await confirmedOperation({
      confirmation: 'Are you sure you would like to remove this team level?',
      success: 'Team Level successfully deleted',
      error: 'There was an error deleting the team level',
      operation: () =>
        removeTeamLevel({
          variables: {
            id: ageGroup.id,
          },
        }),
      after: () => setModified(new Date()),
    });
  };

  const [sorting, setSorting] = useState(false);
  const toggleSort = () => {
    if (sorting) {
      saveSortOrder();
    }
    setSorting(!sorting);
  };

  const saveSortOrder = async () => {
    await operation({
      success: 'Sort Order Saved',
      error: 'An error occurred saving the sort order',
      operation: () =>
        upsertTeamLevels({
          variables: {
            update: teamLevels.map((tl, idx) => ({ id: tl.id, sortOrder: idx })),
          },
        }),
    });
  };

  const onSortedHandler = (action: SetStateAction<TeamLevel[]>) => {
    setTeamLevels(action);
  };
  return (
    <Grid container columnSpacing={'.8em'}>
      <Grid xs={12} md={4}>
        <Card>
          <CardContent>
            <AgeGroupSelector onChange={ageGroupChange} />
            <SortableList<TeamLevel>
              items={teamLevels}
              sorting={sorting}
              onSorted={onSortedHandler}
              ItemTemplate={(team_level) => (
                <SortableListItem
                  key={team_level.id}
                  secondaryAction={
                    <IconButton color="error" edge="end" aria-label="delete" onClick={() => remove(team_level)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  }>
                  <ListItemButton selected={selectedTeamLevel?.id === team_level.id} onClick={() => selectTeamLevel(team_level.id)}>
                    <ListItemText primary={team_level.name} />
                  </ListItemButton>
                </SortableListItem>
              )}
            />
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={add}>
              Add
            </Button>
            <Button variant="outlined" onClick={toggleSort}>
              {sorting ? 'Save Order' : 'Sort'}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      {selectedTeamLevel ? (
        <Grid xs={8}>
          <TeamLevelForm model={selectedTeamLevel} onSubmit={onSubmit} />
        </Grid>
      ) : null}
    </Grid>
  );
}
