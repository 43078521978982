import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { useSeasonContext } from '@local/context/league/season-context';
import { LoadSeasonDocument, Season, UpsertSeasonDocument, UpsertSeasonInput } from '@local/graphql/graphql';
import { Button, Card, CardActions, CardContent, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useOperations } from '@seasonticker/dls';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SeasonForm from './SeasonForm';

export default function SeasonSetup() {
  const { operation } = useOperations();
  const { leagueContext } = useLeagueContext();
  const { season } = useSeasonContext();
  const [selectedSeason, setSelectedSeason] = useState<Season>({ id: '' } as Season);
  const [modified, setModified] = useState(new Date());
  const [loadSeason] = useLazyQuery(LoadSeasonDocument);
  const [upsertSeason] = useMutation(UpsertSeasonDocument);
  const formMethods = useForm<UpsertSeasonInput>({
    defaultValues: {
      name: '',
      start: Date.now(),
      end: Date.now(),
      registrationOpen: Date.now(),
      registrationClose: Date.now(),
    },
  });

  useEffect(() => {
    if (season) {
      selectSeason(season.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season]);

  const selectSeason = (id: string) => {
    const foundSeason = leagueContext?.league?.seasons?.find((s) => s.id === id);
    if (foundSeason) {
      setSelectedSeason(foundSeason as Season);
      loadLatestSeasonValue(foundSeason.id);
    }
  };

  const loadLatestSeasonValue = async (id: string) => {
    const response = await loadSeason({
      variables: {
        id,
      },
    });
    if (response.data?.season) {
      formMethods.reset(response.data.season);
    }
  };

  const onSubmit = async (data: UpsertSeasonInput) => {
    const { start, end, registrationOpen, registrationClose, name } = data;
    const updateValue: UpsertSeasonInput = {
      start,
      end,
      name,
      registrationOpen,
      registrationClose,
      id: selectedSeason.id,
    };
    if (selectedSeason.id !== undefined && selectedSeason.id.length === 0) {
      updateValue.league = leagueContext?.league?.id;
    }
    await operation({
      success: 'The season was successfully updated',
      error: 'There was an error updating the season',
      operation: () =>
        upsertSeason({
          variables: {
            upsert: updateValue,
          },
        }),
      after: () => setModified(new Date()),
    });
  };

  const addNewSeason = () => {
    const newSeason = { id: '', name: '', start: Date.now(), end: Date.now() } as UpsertSeasonInput;
    setSelectedSeason(newSeason as unknown as Season);
    formMethods.reset(newSeason);
  };
  return (
    <Grid container columnSpacing={2}>
      <Grid xs={4}>
        <Card>
          <CardContent>
            <TextField select label="Selected Season" fullWidth value={selectedSeason?.id} onChange={(event) => selectSeason(event.target.value)}>
              {leagueContext?.league?.seasons?.map((season) => {
                return (
                  <MenuItem key={season.id} value={season.id}>
                    {season.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </CardContent>
          <CardActions>
            <Button variant="outlined" color="primary" onClick={addNewSeason}>
              Add
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid xs={8}>{selectedSeason ? <SeasonForm model={selectedSeason} onSubmit={onSubmit} /> : <></>}</Grid>
    </Grid>
  );
}
