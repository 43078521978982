import { useLazyQuery } from '@apollo/client';
import { useSeasonContext } from '@local/context';
import { Game, OfficialsReportDocument, OfficialsReportQuery } from '@local/graphql/graphql';
import { Box, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { parseJSON } from 'date-fns';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never;
type GameQueryType = ArrElement<OfficialsReportQuery['games']>;

const dateToString = (input: string | undefined) => {
  let date: Date | undefined = undefined;
  try {
    if (input) {
      date = parseJSON(input);
    }
  } catch (ex) {
    console.log(ex);
  }
  return date;
};

export function Officials() {
  const [games, setGames] = useState<GameQueryType[]>([]);
  const [gamesQuery] = useLazyQuery(OfficialsReportDocument, { fetchPolicy: 'no-cache' });
  const { season } = useSeasonContext();

  const gamesLoader = useCallback(async () => {
    if (season?.id) {
      const results = await gamesQuery({
        variables: {
          seasonId: season?.id,
        },
      });
      setGames(sortBy(results.data?.games as Game[], 'date.date').filter((g) => g.date));
    }
  }, [gamesQuery, season?.id]);

  useEffect(() => {
    gamesLoader();
  }, [gamesLoader]);

  const columns: GridColDef<GameQueryType>[] = [
    {
      field: 'id',
      filterable: false,
      sortable: false,
    },
    {
      field: 'home_team',
      valueGetter(params) {
        return params.row.home_team?.team?.name;
      },
      flex: 0.8,
      filterable: true,
      sortable: true,
    },
    {
      field: 'away_team',
      valueGetter(params) {
        return params.row.away_team?.team?.name;
      },
      flex: 0.8,
      filterable: true,
      sortable: true,
    },
    {
      field: 'date',
      valueGetter(params) {
        return dateToString(params.row.date?.datetime);
      },
      type: 'date',
      flex: 0.4,
      filterable: true,
      sortable: true,
    },
    {
      field: 'facility',
      valueGetter(params) {
        return params.row.date?.facility?.name;
      },
      type: 'string',
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: 'address',
      valueGetter(params) {
        const location = params.row.date?.facility?.location;
        return location ? `${location.street} ${location.city} ${location.state}` : '';
      },
      type: 'string',
      flex: 1,
      sortable: true,
      filterable: true,
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  return (
    <Box sx={{ flexGrow: 1, height: 'auto' }}>
      <Typography variant="h1">Officials Dashboard</Typography>
      <DataGridPro
        components={{ Toolbar: GridToolbar }}
        density="compact"
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        disableChildrenSorting
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100, 200]}
        autoHeight
        columns={columns}
        rows={games}
        paginationMode={'client'}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }],
          },
        }}
        getRowId={(row) => String(row.id)}
      />
    </Box>
  );
}
