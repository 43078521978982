import { useTeamContext } from '@local/context/league/team-context';
import { Card, CardContent, List, ListItem, ListItemText, Typography } from '@mui/material';

export function RosterWidget() {
  const { registration } = useTeamContext();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Roster</Typography>
        <List dense>
          {registration?.rosters?.map((roster) => {
            return (
              <ListItem key={roster.id}>
                <ListItemText primary={`${roster.number} ${roster.player?.lastName}`} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}

export default RosterWidget;
