//<Controller name="registrationOpen" control={control} rules={{ required: true }} render={({ field, fieldState: { error, isDirty }, formState: { isSubmitted } }) => <DatePicker label="Open Registration" renderInput={(params) => <TextField fullWidth helperText={error?.message} error={(isDirty || isSubmitted) && !!error} {...params} />} {...field} />} />

import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormAutoCompleteProps<
  T extends FieldValues = FieldValues,
  OptionsType = unknown,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = HookFormBaseProps<T> & {
  options: AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>['options'];
  multiple?: AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>['multiple'];
  disableCloseOnSelect?: AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>['disableCloseOnSelect'];
  isOptionEqualToValue?: AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>['isOptionEqualToValue'];
  getOptionLabel?: AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>['getOptionLabel'];
  renderOption?: AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>['renderOption'];
  AutocompleteProps?: Omit<
    AutocompleteProps<OptionsType, Multiple, DisableClearable, FreeSolo>,
    'label' | 'renderInput' | 'onChange' | 'value' | 'options' | 'multiple' | 'disableCloseOnSelect' | 'renderOption'
  >;
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'fullWidth' | 'error'>;
};
export function HookFormAutoComplete<
  T extends FieldValues = FieldValues,
  OptionsType = unknown,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: HookFormAutoCompleteProps<T, OptionsType, Multiple, DisableClearable, FreeSolo>) {
  const {
    name,
    label,
    multiple,
    disableCloseOnSelect,
    isOptionEqualToValue,
    getOptionLabel,
    renderOption,
    options,
    defaultValue,
    rules,
    control,
    fullWidth,
    tooltip,
    ControllerProps,
    TextFieldProps,
    AutocompleteProps,
  } = props;
  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...fieldProps }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => {
        return (
          <Autocomplete
            {...AutocompleteProps}
            options={options}
            multiple={multiple}
            disableCloseOnSelect={disableCloseOnSelect}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            value={value}
            fullWidth={fullWidth}
            renderOption={renderOption}
            onChange={(event, value, reason, details) => {
              onChange({
                target: {
                  value: value,
                },
              });
            }}
            renderInput={(params) => <TextField label={labelValue} {...TextFieldProps} helperText={error?.message} error={!!error} {...params} />}
            {...fieldProps}
          />
        );
      }}
    />
  );
}

export default HookFormAutoComplete;
