import { styled, Theme } from '@mui/material';
import { MUIStyledCommonProps, unstable_styleFunctionSx } from '@mui/system';

export type TeamLogoProps = { url?: string | null; maxWidth?: string; maxHeight?: string } & MUIStyledCommonProps<Theme>;
export function TeamLogoBase(props: TeamLogoProps) {
  const { url, maxWidth = '100%', maxHeight = '32px', sx } = props;
  const LogoImage = styled('img')(unstable_styleFunctionSx);
  return <LogoImage src={url || '/generic-team.png'} alt="logo" sx={{ objectFit: 'scale-down', maxWidth: maxWidth, maxHeight: maxHeight, ...sx }} />;
}
export const TeamLogo = TeamLogoBase;
export default TeamLogo;
