import { Box, FormControl, FormLabel, Tooltip } from '@mui/material';
import { PropsWithChildren, ReactElement, ReactNode, forwardRef, useEffect } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { UploadIcon } from '../../icons';
import { FileMeta } from '../../models/FileMeta';

export type DropZoneProps = DropzoneOptions &
  PropsWithChildren & {
    onChange: (file: File | File[]) => void;
    preview: ReactElement;
    label?: ReactNode;
  };
type ExtendedFile = File & { url?: string };

function addUrlToFile<T extends ExtendedFile = ExtendedFile>(acceptedFiles: T[]) {
  acceptedFiles.map((file) => {
    file.url = URL.createObjectURL(file);
    return file;
  });
}

export const DropZone = forwardRef<HTMLInputElement, DropZoneProps>((props, ref) => {
  const { onChange, multiple = false, children, label, preview, ...dropZoneProps } = props;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple,
    onDrop: addUrlToFile,
    ...dropZoneProps,
  });
  const inputProps = getInputProps();
  const rootProps = getRootProps({ className: 'dropzone' });

  useEffect(() => {
    onChange(multiple && acceptedFiles.length ? acceptedFiles : acceptedFiles[0]);
  }, [acceptedFiles, multiple, onChange]);
  return (
    <Tooltip title="Click or drag an image here to upload or change the image file">
      <FormControl
        variant="outlined"
        {...rootProps}
        sx={({ palette, shape }) => ({
          minWidth: '24px',
          minHeight: '24px',
          border: 'dashed',
          borderColor: palette.grey[400],
          borderRadius: `${shape.borderRadius}px`,
          backgroundColor: palette.background.paper,
          boxSizing: 'border-box',
          overflow: 'visible',
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '.5em',
        })}>
        <FormLabel sx={{ position: 'absolute', top: 0, left: 0, transform: 'translate(10px, -14px) scale(0.75);' }}>{label}</FormLabel>
        <input ref={ref} {...inputProps} />
        <Box sx={{ flexGrow: 1 }}>{preview}</Box>

        <UploadIcon sx={({ palette }) => ({ flexGrow: 0, color: palette.grey[400] })} />
      </FormControl>
    </Tooltip>
  );
});

export type HookFormDropZoneProps<T extends FieldValues = FieldValues, V = FileMeta> = {
  name: ControllerProps<T>['name'];
  rules?: ControllerProps<T>['rules'];
  control?: ControllerProps<T>['control'];
  label: ReactNode;
  multiple?: DropZoneProps['multiple'];
  accept: DropZoneProps['accept'];
  preview?: (value: unknown) => ReactElement;
  valueGetter?: (value: V) => V;
  valueSetter?: (value: V, priorValue: V) => V;
  ControllerProps?: Omit<ControllerProps<T>, 'render' | 'name' | 'rules' | 'control'>;
  DropZoneProps?: Omit<DropZoneProps, 'multiple' | 'accept'>;
};

export function HookFormDropZone<T extends FieldValues = FieldValues, V = FileMeta>(props: HookFormDropZoneProps<T, V>) {
  const {
    name,
    label,
    multiple,
    accept,
    rules,
    control,
    preview = () => <></>,
    valueGetter = (value: V) => value,
    valueSetter = (value: V, priorValue: V) => value,
    ControllerProps,
  } = props;
  return (
    <Controller
      {...ControllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, ref, value }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <DropZone label={label} preview={preview(valueGetter(value))} multiple={multiple} accept={accept} onChange={onChange} ref={ref} />
      )}
    />
  );
}
