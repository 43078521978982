import { SortOptionType } from '@local/components';

export const sortSettingsToRules = (setting: string) => {
  let rules: SortOptionType[];
  try {
    rules = JSON.parse(setting) as SortOptionType[];
  } catch {
    rules = [];
  }
  const sortRuleSettings = rules.map((r) => r.id);
  const sortRules: string[] = [];
  const sortDirections: Array<'asc' | 'desc'> = [];
  sortRuleSettings.forEach((r) => {
    const [rule, direction] = r.split('|');
    sortRules.push(rule);
    sortDirections.push((direction as 'asc' | 'desc') || 'desc');
  });
  return {
    sortRules,
    sortDirections,
  };
};
