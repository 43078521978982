import { SiteSetting } from '@local/graphql/graphql';
import { Dispatch, SetStateAction } from 'react';

export class Settings {
  public setting: SiteSetting;
  public updater: Dispatch<SetStateAction<SiteSetting>>;
  constructor(public module: string, public key: string, public defaultValue: string, state: [SiteSetting, Dispatch<SetStateAction<SiteSetting>>]) {
    this.setting = state[0];
    this.updater = state[1];
  }
}

export function getSetting(settings: SiteSetting[], defaultValue: SiteSetting) {
  return settings?.find((setting) => setting.module === defaultValue.module && setting.key === defaultValue.key) || defaultValue;
}

export const settingsValueProps = {
  valueGetter: (setting: SiteSetting) => setting.value,
  valueSetter: (newValue: string, priorValue: SiteSetting) => ({ ...priorValue, value: newValue }),
};
