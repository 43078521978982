import { useCallback, useRef } from 'react';

export function useDocumentTitle(prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const setDocumentTitle = useCallback(
    (title: string) => {
      if (prevailOnUnmount) {
        document.title = defaultTitle.current;
      } else {
        document.title = title;
      }
    },
    [prevailOnUnmount]
  );

  return {
    setDocumentTitle,
  };
}

export default useDocumentTitle;
