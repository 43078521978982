import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useSeasonContext } from '@local/context';
import { AgeGroup, Division, TeamLevel } from '@local/graphql/graphql';
import { Box, Button, Checkbox } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormAutoComplete, HookFormSelect } from '@seasonticker/dls';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ScheduleSteps, ScheduleWizardStepProps } from './ScheduleTypes';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export function ScheduleScope(props: ScheduleWizardStepProps) {
  const context = useFormContext();
  const { goToStep } = props;
  const { season } = useSeasonContext();
  const ageGroupChange = useWatch({ name: 'age_group' });
  const teamLevelChange = useWatch({ name: 'team_level' });
  const [ageGroups, setAgeGroups] = useState<AgeGroup[]>([]);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [teamLevels, setTeamLevels] = useState<TeamLevel[]>([]);
  useEffect(() => {
    setAgeGroups(season?.age_groups || []);
  }, [season?.age_groups]);
  useEffect(() => {
    const ag = ageGroups?.find((ag) => {
      return ag.id === ageGroupChange || ag.id === (ageGroupChange as AgeGroup)?.id;
    });
    setTeamLevels(ag?.team_levels || []);
  }, [ageGroupChange, ageGroups]);
  useEffect(() => {
    const tl = teamLevels?.find((tl) => {
      return tl.id === teamLevelChange || tl.id === (teamLevelChange as TeamLevel)?.id;
    });
    context.setValue('divisions', tl?.divisions || []);
    setDivisions(tl?.divisions || []);
  }, [context, teamLevelChange, teamLevels]);
  return (
    <Box>
      <Grid container rowSpacing={'.8em'} columnSpacing={'.8em'}>
        <Grid xs={12} md={6}>
          <HookFormSelect
            label="Age Group"
            name="age_group"
            rules={{
              required: 'Age group is required',
            }}
            options={ageGroups}
            getLabel={(item) => item?.name || ''}
            getValue={(item) => item?.id || ''}
            findValue={(id: string) => ageGroups?.find((item) => item.id === id)}
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormSelect
            label="Team Level"
            name="team_level"
            rules={{
              required: 'Team level is required',
            }}
            options={teamLevels}
            getLabel={(item) => item?.name || ''}
            getValue={(item) => item?.id || ''}
            findValue={(id: string) => teamLevels?.find((item) => item.id === id)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <HookFormAutoComplete
            fullWidth
            multiple
            disableCloseOnSelect
            label="Divisions"
            tooltip="Select a number of divisions"
            rules={{
              validate: (value: Division[]) => {
                if (!((value?.length || 0) > 0)) {
                  return 'You must select at least one divisions';
                }
              },
            }}
            name="divisions"
            getOptionLabel={(item) => item?.name || ''}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            options={divisions}
          />
        </Grid>
        <Grid xs={12}>
          <Button onClick={() => goToStep(ScheduleSteps.Settings)} variant="contained" color="primary">
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ScheduleScope;
