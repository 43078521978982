import { DeleteIcon, EditIcon } from '../../icons';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { IconButton, ListItem, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemProps, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { IfInRole } from '../../auth';
import { EditableListItemType } from './EditableListItemType';

export type EditableListItemProps<ModelType extends EditableListItemType> = ListItemProps & {
  /**
   * The item to be sorted
   */
  item: ModelType;

  /**
   * whether sorting is enabled
   */
  sorting?: boolean;

  /**
   * Permission function to determine if the user can edit
   */
  canEdit: IfInRole;

  /**
   * Controls whether the list item button visual indicator is selected
   */
  selected?: boolean;

  /**
   * determines whether the list items can be selected
   */
  selectable: boolean | ((item: ModelType) => boolean);
  /**
   * Determines whether this list is editable
   */
  editable: boolean | ((item: ModelType) => boolean);

  /**
   * Determines whether the list is deletable
   */
  deletable: boolean | ((item: ModelType) => boolean);

  /**
   * Callback for when an item is selected
   */
  onSelectItem: (item: ModelType) => void;
  /**
   * Callback function when an item is edited
   */
  onEdit: (item: ModelType) => void;

  /**
   * Callback function when an item is deleted
   */
  onDelete: (item: ModelType) => void;

  /**
   * Additional Props to pass to the list item button
   */
  ButtonProps?: ListItemButtonProps;
};

/**
 *
 * @param props Each individual list item template
 * @returns
 */
export function EditableListItem<ModelType extends EditableListItemType>(props: EditableListItemProps<ModelType>) {
  const { canEdit, selectable, editable, deletable, item, sorting, selected, children, onEdit, onDelete, onSelectItem, ButtonProps = {}, ...listItemProps } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: String(item.id) });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const resolveBoolean = useCallback((boolOrFunction: boolean | ((item: ModelType) => boolean)) => {
    if (typeof boolOrFunction === 'boolean') {
      return boolOrFunction;
    } else if (typeof boolOrFunction === 'function') {
      return boolOrFunction(item);
    }
    return false;
  }, [item]);
  
  type DefaultEditableActionsProps = {
    onEdit: () => void;
    onDelete: () => void;
  };
  function DefaultEditableAction({ onEdit, onDelete }: DefaultEditableActionsProps) {
    return (
      <>
        {resolveBoolean(deletable) ? (
          <Tooltip title="Delete this item">
            <IconButton color="error" onClick={() => onDelete()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {resolveBoolean(editable) && resolveBoolean(selectable) ? (
          <Tooltip title="Edit this item">
            <IconButton onClick={() => onEdit()}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </>
    );
  }

  const selectClick = useCallback(
    (item: ModelType) => {
      if (resolveBoolean(selectable)) {
        onSelectItem(item);
      } else if (resolveBoolean(editable)) {
        onEdit(item);
      }
    },
    [editable, onEdit, onSelectItem, selectable, resolveBoolean]
  );



  return (
    <ListItem
      secondaryAction={canEdit(
        <DefaultEditableAction onEdit={() => onEdit(item)} onDelete={() => onDelete(item)} />,
        <></>
      )}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listItemProps}>
      {sorting ? (
        <ListItemIcon>
          <IconButton color="info" edge="end" aria-label="drag" {...listeners}>
            <DragHandleIcon />
          </IconButton>
        </ListItemIcon>
      ) : (
        <></>
      )}
      <ListItemButton selected={selected} onClick={() => selectClick(item)} {...ButtonProps}>
        {children}
      </ListItemButton>
    </ListItem>
  );
}

export default EditableListItem;
