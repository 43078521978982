import { useSettings } from '@local/hooks';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormTextField, VisibleIf } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback } from 'react';
import { RegisterForm, RegistrationStepProps, Steps } from './Steps';

const TeamForm = (props: RegistrationStepProps<RegisterForm>) => {
  const { settingValue } = useSettings();
  const { goToStep, formProps } = props;
  const { trigger } = formProps;

  const handleTeamClick = useCallback(async () => {
    const success = await trigger();
    if (success) {
      goToStep(Steps.ManagerInformation);
    }
  }, [goToStep, trigger]);
  return (
    <Grid container columnSpacing={'.8em'} rowSpacing={'.8em'}>
      <Grid md={6}>
        <Grid container rowSpacing={'.8em'}>
          <Grid xs={12}>
            <HookFormTextField
              rules={{
                required: 'Please enter a team name',
              }}
              name="team.name"
              label="Team Name"
              fullWidth
            />
          </Grid>
        </Grid>
        <VisibleIf cond={settingValue('registration.show_prior_year')}>
          <Grid xs={12}>
            <HookFormTextField name="leagueLastYear" label="What league(s) did you play last year" fullWidth />
          </Grid>
        </VisibleIf>
      </Grid>
      <Grid xs={12}>
        <Button variant="contained" color="primary" onClick={handleTeamClick}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
};
export default TeamForm;
