import { SiteSetting } from '@local/graphql/graphql';
import { set } from 'lodash';

// For convenience
type Primitive = string | number | bigint | boolean | undefined | symbol;

// To infinity and beyond >:D
export type StringPath<T, StopAt, Prefix = ''> = {
  [K in keyof T]: T[K] extends Primitive | Array<any>
    ? `${string & Prefix}${string & K}`
    : T[K] extends StopAt
    ? `${string & Prefix}${string & K}`
    : `${string & Prefix}${string & K}` | StringPath<T[K], StopAt, `${string & Prefix}${string & K}.`>;
}[keyof T];

type KeyValueArray<T> = [StringPath<T, SiteSetting>, string];
function buildDefaults<SettingType extends Record<string, any>>(module: string, ...defaults: KeyValueArray<SettingType>[]) {
  const defaultSettings = {} as SettingType;
  defaults.forEach(([key, value]) => {
    set(defaultSettings, key, { module, key, value } as SiteSetting);
  });
  return defaultSettings;
}

export const NAVIGATION_MODULE = 'navigation';
export type NavigationModuleSettingsType = {
  league_link: SiteSetting;
  navigation_items: SiteSetting;
};

export const ROSTER_MODULE = 'roster';
export type RosterSettingsType = {
  min_roster_size: SiteSetting;
  max_roster_size: SiteSetting;
  require_player_number: SiteSetting;
  emergency_contacts: SiteSetting;
  membership_id: SiteSetting;
  membership_label: SiteSetting;
  membershipId: SiteSetting;
  number: SiteSetting;
  position: SiteSetting;
  show_years_of_experience: SiteSetting;
  require_years_of_experience: SiteSetting;
  label_years_of_experience: SiteSetting;
  show_off_season_player: SiteSetting;
  require_off_season_player: SiteSetting;
  label_off_season_player: SiteSetting;
  player: {
    firstName: SiteSetting;
    birthday: SiteSetting;
    lastName: SiteSetting;
    grade: SiteSetting;
    guardianemail: SiteSetting;
    guardian2email: SiteSetting;
    phoneNumber: SiteSetting;
    phoneNumber2: SiteSetting;
    phoneNumber3: SiteSetting;
  };
  emergencyContact: {
    cellPhone: SiteSetting;
    email: SiteSetting;
    firstName: SiteSetting;
    lastName: SiteSetting;
    relationToPlayer: SiteSetting;
  };
};

export const THEME_MODULE = 'theme';

export type ThemeSettingsType = {
  color: {
    primary: SiteSetting;
    secondary: SiteSetting;
    mode: SiteSetting;
  };
  font: {
    size: SiteSetting;
  };
  carousel: {
    aspectRatio: SiteSetting;
  };
  shape: {
    borderRadius: SiteSetting;
  };
};

export const REGISTRATION_MODULE = 'registration';
export type RegistrationSettingsType = {
  registration_widget_title: SiteSetting;
  show_prior_year: SiteSetting;
  show_elite_justification: SiteSetting;
  enable_payments: SiteSetting;
  require_registration_terms: SiteSetting;
  registration_terms: SiteSetting;
  registration_instructions: SiteSetting;
  include_division: SiteSetting;
  require_division: SiteSetting;
};

export const STANDINGS_MODULE = 'standings';

export type StandingsSettingsType = {
  logo: SiteSetting;
  team: SiteSetting;
  wins: SiteSetting;
  losses: SiteSetting;
  ties: SiteSetting;
  win_loss_percent: SiteSetting;
  weighted_winning_percent: SiteSetting;
  opponents_winning_percent: SiteSetting;
  opponents_opponents_winning_percent: SiteSetting;
  ratings_percentage_index: SiteSetting;
  power_ranking: SiteSetting;
  points_for: SiteSetting;
  points_allowed: SiteSetting;
  sort_rule: SiteSetting;
  game_outcome: {
    win: SiteSetting;
    loss: SiteSetting;
    tie: SiteSetting;
  };
  standing_weight: {
    home_away: SiteSetting;
    winning_percent: SiteSetting;
    opponents_winning_percent: SiteSetting;
    opponents_opponents_winning_percent: SiteSetting;
  };
};

export const SettingDefaults = {
  [NAVIGATION_MODULE]: buildDefaults<NavigationModuleSettingsType>(NAVIGATION_MODULE, ['league_link', ''], ['navigation_items', JSON.stringify([])]),
  [ROSTER_MODULE]: buildDefaults<RosterSettingsType>(
    ROSTER_MODULE,
    ['min_roster_size', ''],
    ['min_roster_size', ''],
    ['require_player_number', 'false'],
    ['emergency_contacts', 'false'],
    ['membership_id', 'false'],
    ['membership_label', 'Membership Id'],
    ['membershipId', 'false'],
    ['number', 'false'],
    ['position', 'false'],
    ['player.firstName', 'false'],
    ['player.birthday', 'false'],
    ['player.birthday', 'false'],
    ['player.lastName', 'false'],
    ['player.grade', 'false'],
    ['player.guardianemail', 'false'],
    ['player.guardian2email', 'false'],
    ['player.phoneNumber', 'false'],
    ['player.phoneNumber2', 'false'],
    ['player.phoneNumber3', 'false'],
    ['emergencyContact.cellPhone', 'false'],
    ['emergencyContact.email', 'false'],
    ['emergencyContact.firstName', 'false'],
    ['emergencyContact.lastName', 'false'],
    ['emergencyContact.relationToPlayer', 'false'],
    ['show_off_season_player', 'false'],
    ['require_off_season_player', 'false'],
    ['label_off_season_player', 'Off season player'],
    ['show_years_of_experience', 'false'],
    ['require_years_of_experience', 'false'],
    ['label_years_of_experience', '']
  ),
  [THEME_MODULE]: buildDefaults<ThemeSettingsType>(
    THEME_MODULE,
    ['color.primary', '#000000'],
    ['color.secondary', '#000000'],
    ['color.mode', 'light'],
    ['font.size', '16'],
    ['carousel.aspectRatio', '19/9'],
    ['shape.borderRadius', '8']
  ),
  [REGISTRATION_MODULE]: buildDefaults<RegistrationSettingsType>(
    REGISTRATION_MODULE,
    ['registration_widget_title', 'Upcoming Season Registration'],
    ['require_registration_terms', 'false'],
    ['registration_instructions', ''],
    ['show_elite_justification', 'false'],
    ['show_prior_year', 'false'],
    ['enable_payments', 'false'],
    ['registration_terms', ''],
    ['include_division', 'false'],
    ['require_division', 'false']
  ),
  [STANDINGS_MODULE]: buildDefaults<StandingsSettingsType>(
    STANDINGS_MODULE,
    ['logo', 'true'],
    ['team', 'true'],
    ['losses', 'true'],
    ['wins', 'true'],
    ['ties', 'true'],
    ['win_loss_percent', 'true'],
    ['weighted_winning_percent', 'false'],
    ['opponents_winning_percent', 'false'],
    ['opponents_opponents_winning_percent', 'false'],
    ['ratings_percentage_index', 'false'],
    ['power_ranking', 'true'],
    ['points_for', 'true'],
    ['points_allowed', 'true'],
    ['sort_rule', JSON.stringify([{ label: 'Power Ranking', id: 'standings.power_ranking|desc' }])],
    ['game_outcome.win', '1'],
    ['game_outcome.loss', '0'],
    ['game_outcome.tie', '0.5'],
    ['standing_weight.home_away', '1'],
    ['standing_weight.winning_percent', '0.35'],
    ['standing_weight.opponents_winning_percent', '0.35'],
    ['standing_weight.opponents_opponents_winning_percent', '0.30']
  ),
};

export type Settings = StringPath<typeof SettingDefaults, SiteSetting>;
