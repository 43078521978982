import { Box, FormControl, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import { Controller, FieldValues } from 'react-hook-form';
import { Editor as TinyEditor } from 'tinymce';
import { useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormRichEditorProps<T extends FieldValues = FieldValues> = HookFormBaseProps<T> & {
  height?: number | string;
};
export function HookFormRichEditor<T extends FieldValues = FieldValues>(props: HookFormRichEditorProps<T>) {
  const globalStyles = [...document.querySelectorAll('style[data-emotion="css-global"]')];
  const styles = globalStyles.reduce((accumulation, next) => {
    return accumulation + next.innerHTML + '\n';
  }, '');
  const {
    palette: { mode },
  } = useTheme();
  const { name, label, rules, control, tooltip, height = 200, ControllerProps } = props;
  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      name={name}
      rules={rules}
      control={control}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <FormControl fullWidth variant="filled" error={!!error}>
          <Box>
            <Typography variant="body2" fontWeight={500}>
              {labelValue}
            </Typography>
          </Box>
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            value={value}
            onEditorChange={(updatedValue: string, editor: TinyEditor) => {
              onChange({
                target: {
                  value: updatedValue,
                },
              });
            }}
            init={{
              toolbar_persist: true,
              toolbar_sticky: true,
              inline: false,
              menubar: 'edit insert format table',
              branding: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'preview',
                'help',
              ],
              toolbar: [
                'undo redo | ' +
                  'bold italic | format | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | image | media | help',
              ],
              //toolbar: 'undo redo | styles | formatting | alignment | table | media',
              toolbar_groups: {
                formatting: {
                  icon: 'bold',
                  tooltip: 'Formatting',
                  items: 'bold italic underline | superscript subscript',
                },
                alignment: {
                  icon: 'center',
                  tooltip: 'Alignment',
                  items: 'alignleft aligncenter alignright',
                },
              },
              content_style: styles,
              promotion: false,
              skin: mode === 'dark' ? 'oxide-dark' : 'oxide',
              content_css: mode === 'dark' ? 'dark' : 'default',
              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype === 'image') {
                  var input = document.createElement('input', {});
                  input.type = 'file';
                  input.click();
                  input.onchange = function () {
                    var file = input.files?.item(0);
                    if (file) {
                      var reader = new FileReader();
                      reader.onload = function (e) {
                        if (e?.target?.result) {
                          callback(e.target.result as string, {
                            alt: file?.name,
                          });
                        }
                      };
                      reader.readAsDataURL(file);
                    }
                  };
                }
              },
            }}
          />
        </FormControl>
      )}></Controller>
  );
}

export default HookFormRichEditor;
