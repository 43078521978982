import { AgeGroup, Division, TeamLevel } from '@local/graphql/graphql';
import { useSettings } from '@local/hooks';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { HookFormSelect, HookFormTextField, VisibleIf } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback } from 'react';
import { RegisterForm, RegistrationStepProps, Steps } from './Steps';

const CompetitionLevels = (props: RegistrationStepProps<RegisterForm>) => {
  const { settingValue } = useSettings();
  const { ageGroups, teamLevels, divisions, goToStep, formProps } = props;
  const { trigger } = formProps;

  const handleLevelsClick = useCallback(async () => {
    const success = await trigger();
    if (success) {
      goToStep(Steps.RegistrationConfirmation);
    }
  }, [goToStep, trigger]);
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item md={6}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <HookFormSelect
              rules={{
                required: true,
                validate: (ag: AgeGroup) => {
                  if (!ag?.id) {
                    return 'Please choose an age group';
                  }
                },
              }}
              name="age_group"
              label="Age Group"
              fullWidth
              options={ageGroups}
              getLabel={(item) => item?.name || ''}
              getValue={(item) => item?.id || ''}
              findValue={(id: string) => ageGroups?.find((item) => item.id === id)}
            />
          </Grid>
          <Grid item xs={12}>
            <HookFormSelect
              rules={{
                required: true,
                validate: (tl: TeamLevel) => {
                  if (!tl?.id) {
                    return 'Please choose an competition level';
                  }
                },
              }}
              name="team_level"
              label="Competition Level"
              fullWidth
              options={teamLevels}
              getLabel={(item) => item?.name || ''}
              getValue={(item) => item?.id || ''}
              findValue={(id: string) => teamLevels?.find((item) => item.id === id)}
            />
          </Grid>
          <VisibleIf cond={boolean(settingValue('registration.include_division'))}>
            <Grid item xs={12}>
              <HookFormSelect
                rules={{
                  required: boolean(settingValue('registration.require_division')),
                  validate: (d: Division) => {
                    if (boolean(settingValue('registration.require_division'))) {
                      if (!d?.id) {
                        return 'Please choose a division';
                      }
                    }
                  },
                }}
                name="division"
                label="Division"
                fullWidth
                options={divisions}
                getLabel={(item) => item?.name || ''}
                getValue={(item) => item?.id || ''}
                findValue={(id: string) => divisions?.find((item) => item.id === id)}
              />
            </Grid>
          </VisibleIf>
        </Grid>
        <VisibleIf cond={boolean(settingValue('registration.show_elite_justification'))}>
          <Grid item xs={12}>
            <HookFormTextField name="eliteJustification" label="If you requested to play elite, tell us why your team should be considered" fullWidth />
          </Grid>
        </VisibleIf>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="caption">
          <div dangerouslySetInnerHTML={{ __html: settingValue('registration.registration_instructions') }}></div>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleLevelsClick}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
};
export default CompetitionLevels;
