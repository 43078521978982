/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  geocoding?: Maybe<Scalars['JSON']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  geocoding?: InputMaybe<Scalars['JSON']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AgeGroup = {
  __typename?: 'AgeGroup';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** League Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** whether invitation scheduling is enabled for this age group */
  inviteSchedulingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Determines if rosters are locked for this age group */
  lockRoster?: Maybe<Scalars['Boolean']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Age group name */
  name: Scalars['String']['output'];
  /** Whether open scheduling is enabled for this age group */
  openSchedulingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** SKU for determining the related purchasable product */
  registrationProduct?: Maybe<Scalars['String']['output']>;
  registrations?: Maybe<Array<Registration>>;
  /** Controls whether scheduling is open for this age group */
  schedulingOpen?: Maybe<Scalars['Boolean']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  season: Season;
  /** sort order for a list of levels */
  sortOrder?: Maybe<Scalars['Float']['output']>;
  team_levels?: Maybe<Array<TeamLevel>>;
};

export type AgeGroupRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

export type AgeGroupTeam_LevelsArgs = {
  filter?: InputMaybe<QueryTeamLevelInput>;
};

/** Messages List */
export type Announcement = {
  __typename?: 'Announcement';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  /** The body of the message */
  message?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** The body of the message */
  sortOrder?: Maybe<Scalars['Float']['output']>;
  /** The title of the announcement */
  title?: Maybe<Scalars['String']['output']>;
};

export type AuthZDirectiveCompositeRulesInput = {
  and?: InputMaybe<Array<InputMaybe<AuthZRules>>>;
  not?: InputMaybe<AuthZRules>;
  or?: InputMaybe<Array<InputMaybe<AuthZRules>>>;
};

export type AuthZDirectiveDeepCompositeRulesInput = {
  and?: InputMaybe<Array<InputMaybe<AuthZDirectiveDeepCompositeRulesInput>>>;
  id?: InputMaybe<AuthZRules>;
  not?: InputMaybe<AuthZDirectiveDeepCompositeRulesInput>;
  or?: InputMaybe<Array<InputMaybe<AuthZDirectiveDeepCompositeRulesInput>>>;
};

export enum AuthZRules {
  IsAdmin = 'IsAdmin',
  IsAuthenticated = 'IsAuthenticated',
  IsLeagueAdmin = 'IsLeagueAdmin',
  IsManager = 'IsManager',
  IsUser = 'IsUser',
  IsUserOwner = 'IsUserOwner',
}

export type BooleanQueryFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  like?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<Scalars['Boolean']['input']>;
  regex?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Carousel = {
  __typename?: 'Carousel';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  image?: Maybe<FileMeta>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  link?: Maybe<Scalars['String']['output']>;
  linktext?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CreateAgeGroupInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** whether invitation scheduling is enabled for this age group */
  inviteSchedulingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines if rosters are locked for this age group */
  lockRoster?: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Age group name */
  name: Scalars['String']['input'];
  /** Whether open scheduling is enabled for this age group */
  openSchedulingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** SKU for determining the related purchasable product */
  registrationProduct?: InputMaybe<Scalars['String']['input']>;
  /** Controls whether scheduling is open for this age group */
  schedulingOpen?: InputMaybe<Scalars['Boolean']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateAnnouncementInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** The body of the message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The body of the message */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** The title of the announcement */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCarouselInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  linktext?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDivisionInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name: Scalars['String']['input'];
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEmailLogInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  response?: InputMaybe<Scalars['JSON']['input']>;
  responseCode?: InputMaybe<Scalars['String']['input']>;
  rosterId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFacilityInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  fieldDescription?: InputMaybe<Scalars['String']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AddressInput>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFileMetaInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGameDateTimeInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  datetime?: InputMaybe<Scalars['DateTime']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  taken?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGameInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Indicator if the away team forfeited */
  awayTeamForfeit?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * A value used to inform the home team that the away team has offered
   * to use their facility to host the game
   */
  awayTeamOffersToHost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Score for the away team */
  awayTeamScore?: InputMaybe<Scalars['Float']['input']>;
  away_team?: InputMaybe<Scalars['String']['input']>;
  /** Indicator to show the date is confirmed */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the confirmation happened */
  confirmedDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  /** The type of match */
  gameType?: InputMaybe<Scalars['String']['input']>;
  /** If this game appears in a sequence, the order number in that sequence */
  game_number?: InputMaybe<Scalars['Float']['input']>;
  /** Indicator if the home team forfeited */
  homeTeamForfeit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Score for the home team */
  homeTeamScore?: InputMaybe<Scalars['Float']['input']>;
  home_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has an outcome */
  isPlayed: Scalars['Boolean']['input'];
  /** Virtual field to determine if the game has a tied outcome */
  isTied: Scalars['Boolean']['input'];
  /** Should not be used */
  isTournamentMatch?: InputMaybe<Scalars['Boolean']['input']>;
  losing_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  losing_team_id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tracker to see if an official was assigned */
  officialAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** A message to indicate why the game is postponed */
  postponeReason?: InputMaybe<Scalars['String']['input']>;
  /** A flag to indicate tha game is postponed */
  postponed?: InputMaybe<Scalars['Boolean']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  winning_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  winning_team_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLeagueInput = {
  /** League Acronym */
  acronym?: InputMaybe<Scalars['String']['input']>;
  /** Role name for age group directors */
  adgRole?: InputMaybe<Scalars['String']['input']>;
  /** Role name for admins */
  adminRole?: InputMaybe<Scalars['String']['input']>;
  /** A tagline to display for a league */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** League Primary Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A list of domains */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Url for this league */
  leagueUrl?: InputMaybe<Scalars['String']['input']>;
  leaguesettings?: InputMaybe<LeagueSettingInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Name */
  name: Scalars['String']['input'];
  /** A legacy value for determining which league is by a url part */
  urlKey?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLeagueRoleInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLeagueWaiverInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRegistrationInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  /** The preferred division preference */
  divisionPreference?: InputMaybe<Scalars['String']['input']>;
  /** Elite justification */
  eliteJustification?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** League last year response */
  leagueLastYear?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  standings?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  /** Whether the waiver is signed */
  waiver?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the waiver was signed */
  waiver_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateRoleInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRoleMappingInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  principalId: Scalars['String']['input'];
  principalType?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRosterEntryInput = {
  /** Is this player an all star nominee */
  allstarNomination?: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Emergency contact information for the player */
  emergencyContact?: InputMaybe<EmergencyContactInput>;
  membershipId?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The player number */
  number?: InputMaybe<Scalars['Float']['input']>;
  offSeasonPlayer?: InputMaybe<Scalars['String']['input']>;
  player?: InputMaybe<PlayerInput>;
  /** The player position */
  position?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  /** Whether this roster spot waiver has been signed */
  waiver?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date this waiver was signed */
  waiverdate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The waiver signer */
  waiversignature?: InputMaybe<Scalars['String']['input']>;
  yearsOfExperience?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateSeasonInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Acronym */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Name */
  name: Scalars['String']['input'];
  /** The date where the registration closes */
  registrationClose?: InputMaybe<Scalars['DateTime']['input']>;
  /** The date where the registration opens */
  registrationOpen?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateSiteSettingInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** The property key */
  key: Scalars['String']['input'];
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The base module grouping of the setting */
  module: Scalars['String']['input'];
  season?: InputMaybe<Scalars['String']['input']>;
  /** The property value */
  value: Scalars['String']['input'];
};

export type CreateSnsMessageInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  message_type?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  subscription_arn?: InputMaybe<Scalars['String']['input']>;
  topic_arn?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSponsorInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStandingsInput = {
  away_games_played?: InputMaybe<Scalars['Float']['input']>;
  away_losses?: InputMaybe<Scalars['Float']['input']>;
  away_wins?: InputMaybe<Scalars['Float']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  games_back?: InputMaybe<Scalars['Float']['input']>;
  games_played?: InputMaybe<Scalars['Float']['input']>;
  head_to_head?: InputMaybe<Scalars['Float']['input']>;
  head_to_head_two_only?: InputMaybe<Scalars['Float']['input']>;
  home_games_played?: InputMaybe<Scalars['Float']['input']>;
  home_losses?: InputMaybe<Scalars['Float']['input']>;
  home_wins?: InputMaybe<Scalars['Float']['input']>;
  losses?: InputMaybe<Scalars['Float']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  opponents_opponents_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  opponents_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  points?: InputMaybe<Scalars['Float']['input']>;
  points_allowed?: InputMaybe<Scalars['Float']['input']>;
  points_allowed_average?: InputMaybe<Scalars['Float']['input']>;
  points_for?: InputMaybe<Scalars['Float']['input']>;
  points_for_average?: InputMaybe<Scalars['Float']['input']>;
  power_ranking?: InputMaybe<Scalars['Float']['input']>;
  ratings_percentage_index?: InputMaybe<Scalars['Float']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  ties?: InputMaybe<Scalars['Float']['input']>;
  weighted_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  win_loss_percent?: InputMaybe<Scalars['Float']['input']>;
  wins?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateTeamInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  membershipExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  membershipLevel?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  urlKey?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTeamLevelInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name: Scalars['String']['input'];
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateTeamPositionInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  positionType?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  birthdate?: InputMaybe<Scalars['DateTime']['input']>;
  birthdateValidated?: InputMaybe<Scalars['Boolean']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AddressInput>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  userPhotoId?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateVanityUrlInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  domain: Scalars['String']['input'];
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  type: VanityUrlTypeInput;
};

export type CreateWaiverInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWaiverSignatureInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  signature_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type DateQueryFilter = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<Scalars['DateTime']['input']>;
  regex?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Division = {
  __typename?: 'Division';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Team Level Name */
  name: Scalars['String']['output'];
  registrations?: Maybe<Array<Registration>>;
  /** sort order for a list of levels */
  sortOrder?: Maybe<Scalars['Float']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team_level: TeamLevel;
};

export type DivisionRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

export type EmailLog = {
  __typename?: 'EmailLog';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['JSON']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  response?: Maybe<Scalars['JSON']['output']>;
  responseCode?: Maybe<Scalars['String']['output']>;
  rosterId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  cellPhone?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  relationToPlayer?: Maybe<Scalars['String']['output']>;
};

export type EmergencyContactInput = {
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  relationToPlayer?: InputMaybe<Scalars['String']['input']>;
};

export type Facility = {
  __typename?: 'Facility';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  fieldDescription?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  location?: Maybe<Address>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Float']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team: Team;
};

/** Primary League Object */
export type FileMeta = {
  __typename?: 'FileMeta';
  container?: Maybe<Scalars['String']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Game = {
  __typename?: 'Game';
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  age_group?: Maybe<AgeGroup>;
  /** Indicator if the away team forfeited */
  awayTeamForfeit?: Maybe<Scalars['Boolean']['output']>;
  /**
   * A value used to inform the home team that the away team has offered
   * to use their facility to host the game
   */
  awayTeamOffersToHost?: Maybe<Scalars['Boolean']['output']>;
  /** Score for the away team */
  awayTeamScore?: Maybe<Scalars['Float']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  away_team?: Maybe<Registration>;
  /** Indicator to show the date is confirmed */
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  /** The date the confirmation happened */
  confirmedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  date?: Maybe<GameDateTime>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  division?: Maybe<Division>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  facility?: Maybe<Facility>;
  /** The type of match */
  gameType?: Maybe<Scalars['String']['output']>;
  /** If this game appears in a sequence, the order number in that sequence */
  game_number?: Maybe<Scalars['Float']['output']>;
  /** Indicator if the home team forfeited */
  homeTeamForfeit?: Maybe<Scalars['Boolean']['output']>;
  /** Score for the home team */
  homeTeamScore?: Maybe<Scalars['Float']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  home_team?: Maybe<Registration>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Virtual field to determine if the game has an outcome */
  isPlayed: Scalars['Boolean']['output'];
  /** Virtual field to determine if the game has a tied outcome */
  isTied: Scalars['Boolean']['output'];
  /** Should not be used */
  isTournamentMatch?: Maybe<Scalars['Boolean']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  losing_team?: Maybe<Registration>;
  /** Virtual field to determine if the game has a tied outcome */
  losing_team_id?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Tracker to see if an official was assigned */
  officialAssigned?: Maybe<Scalars['Boolean']['output']>;
  /** A message to indicate why the game is postponed */
  postponeReason?: Maybe<Scalars['String']['output']>;
  /** A flag to indicate tha game is postponed */
  postponed?: Maybe<Scalars['Boolean']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  season?: Maybe<Season>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team_level?: Maybe<TeamLevel>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  winning_team?: Maybe<Registration>;
  /** Virtual field to determine if the game has a tied outcome */
  winning_team_id?: Maybe<Scalars['String']['output']>;
};

export type GameDateTime = {
  __typename?: 'GameDateTime';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  datetime?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  facility?: Maybe<Facility>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  registration?: Maybe<Registration>;
  taken?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<Scalars['String']['output']>;
};

export type League = {
  __typename?: 'League';
  /** League Acronym */
  acronym?: Maybe<Scalars['String']['output']>;
  /** Role name for age group directors */
  adgRole?: Maybe<Scalars['String']['output']>;
  /** Role name for admins */
  adminRole?: Maybe<Scalars['String']['output']>;
  announcements?: Maybe<Array<Announcement>>;
  /** A tagline to display for a league */
  caption?: Maybe<Scalars['String']['output']>;
  carousels?: Maybe<Array<Carousel>>;
  /**
   * League Primary Color
   * @deprecated deprecated
   */
  color?: Maybe<Scalars['String']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** League Description */
  description?: Maybe<Scalars['String']['output']>;
  /** A list of domains */
  domains?: Maybe<Array<Scalars['String']['output']>>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Url for this league */
  leagueUrl?: Maybe<Scalars['String']['output']>;
  leaguesettings?: Maybe<LeagueSetting>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  logo?: Maybe<FileMeta>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** League Name */
  name: Scalars['String']['output'];
  registrations?: Maybe<Array<Registration>>;
  roles?: Maybe<Array<LeagueRole>>;
  seasons?: Maybe<Array<Season>>;
  settings?: Maybe<Array<SiteSetting>>;
  sponsors?: Maybe<Array<Sponsor>>;
  /** A legacy value for determining which league is by a url part */
  urlKey?: Maybe<Scalars['String']['output']>;
  waivers?: Maybe<Array<LeagueWaiver>>;
};

export type LeagueAnnouncementsArgs = {
  filter?: InputMaybe<QueryAnnouncementInput>;
};

export type LeagueCarouselsArgs = {
  filter?: InputMaybe<QueryCarouselInput>;
};

export type LeagueRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

export type LeagueRolesArgs = {
  filter?: InputMaybe<QueryLeagueRoleInput>;
};

export type LeagueSeasonsArgs = {
  filter?: InputMaybe<QuerySeasonInput>;
};

export type LeagueSettingsArgs = {
  filter?: InputMaybe<QuerySiteSettingInput>;
};

export type LeagueSponsorsArgs = {
  filter?: InputMaybe<QuerySponsorInput>;
};

export type LeagueWaiversArgs = {
  filter?: InputMaybe<QueryLeagueWaiverInput>;
};

/** Primary League Object */
export type LeagueRole = {
  __typename?: 'LeagueRole';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  role?: Maybe<Role>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  user?: Maybe<User>;
};

export type LeagueSetting = {
  __typename?: 'LeagueSetting';
  disableAwayTeamReschedule?: Maybe<Scalars['Boolean']['output']>;
  disableRegistration?: Maybe<Scalars['Boolean']['output']>;
  divisionPreferences?: Maybe<Array<Scalars['String']['output']>>;
  enableAdvancedExport?: Maybe<Scalars['Boolean']['output']>;
  enableAllStar?: Maybe<Scalars['Boolean']['output']>;
  enableDivisionPreference?: Maybe<Scalars['Boolean']['output']>;
  enableOfferToHost?: Maybe<Scalars['Boolean']['output']>;
  enableOpenGameDeletion?: Maybe<Scalars['Boolean']['output']>;
  enableRosterMembershipId?: Maybe<Scalars['Boolean']['output']>;
  enableRoundRobin?: Maybe<Scalars['Boolean']['output']>;
  enabledEmergencyContacts?: Maybe<Scalars['Boolean']['output']>;
  fieldDescriptionHelp?: Maybe<Scalars['String']['output']>;
  fieldDescriptionTitle?: Maybe<Scalars['String']['output']>;
  helpContent?: Maybe<Scalars['String']['output']>;
  hideElite?: Maybe<Scalars['Boolean']['output']>;
  hideHomeDates?: Maybe<Scalars['Boolean']['output']>;
  hideLastYearRegistration?: Maybe<Scalars['Boolean']['output']>;
  hideOutOfDivisionInformation?: Maybe<Scalars['Boolean']['output']>;
  hideRosters?: Maybe<Scalars['Boolean']['output']>;
  hideSchedule?: Maybe<Scalars['Boolean']['output']>;
  hideTournament?: Maybe<Scalars['Boolean']['output']>;
  lockRoster?: Maybe<Scalars['Boolean']['output']>;
  minimumHomeDates?: Maybe<Scalars['Float']['output']>;
  minimumRoster?: Maybe<Scalars['Float']['output']>;
  openSchedulingMode?: Maybe<Scalars['Boolean']['output']>;
  registrationForm?: Maybe<Scalars['String']['output']>;
  registrationInstructions?: Maybe<Scalars['String']['output']>;
  registrationLegal?: Maybe<Scalars['String']['output']>;
  registrationPayments?: Maybe<Scalars['Boolean']['output']>;
  requireRegistrationWaiver?: Maybe<Scalars['Boolean']['output']>;
  restrictScheduling?: Maybe<Scalars['Boolean']['output']>;
  rosterGrade?: Maybe<Scalars['Boolean']['output']>;
  rosterMemberIdLabel?: Maybe<Scalars['String']['output']>;
  showOfficialsArea?: Maybe<Scalars['Boolean']['output']>;
  showRegistrationForm?: Maybe<Scalars['Boolean']['output']>;
  tournamentName?: Maybe<Scalars['String']['output']>;
  waiverText?: Maybe<Scalars['String']['output']>;
};

export type LeagueSettingInput = {
  disableAwayTeamReschedule?: InputMaybe<Scalars['Boolean']['input']>;
  disableRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  divisionPreferences?: InputMaybe<Array<Scalars['String']['input']>>;
  enableAdvancedExport?: InputMaybe<Scalars['Boolean']['input']>;
  enableAllStar?: InputMaybe<Scalars['Boolean']['input']>;
  enableDivisionPreference?: InputMaybe<Scalars['Boolean']['input']>;
  enableOfferToHost?: InputMaybe<Scalars['Boolean']['input']>;
  enableOpenGameDeletion?: InputMaybe<Scalars['Boolean']['input']>;
  enableRosterMembershipId?: InputMaybe<Scalars['Boolean']['input']>;
  enableRoundRobin?: InputMaybe<Scalars['Boolean']['input']>;
  enabledEmergencyContacts?: InputMaybe<Scalars['Boolean']['input']>;
  fieldDescriptionHelp?: InputMaybe<Scalars['String']['input']>;
  fieldDescriptionTitle?: InputMaybe<Scalars['String']['input']>;
  helpContent?: InputMaybe<Scalars['String']['input']>;
  hideElite?: InputMaybe<Scalars['Boolean']['input']>;
  hideHomeDates?: InputMaybe<Scalars['Boolean']['input']>;
  hideLastYearRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  hideOutOfDivisionInformation?: InputMaybe<Scalars['Boolean']['input']>;
  hideRosters?: InputMaybe<Scalars['Boolean']['input']>;
  hideSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  hideTournament?: InputMaybe<Scalars['Boolean']['input']>;
  lockRoster?: InputMaybe<Scalars['Boolean']['input']>;
  minimumHomeDates?: InputMaybe<Scalars['Float']['input']>;
  minimumRoster?: InputMaybe<Scalars['Float']['input']>;
  openSchedulingMode?: InputMaybe<Scalars['Boolean']['input']>;
  registrationForm?: InputMaybe<Scalars['String']['input']>;
  registrationInstructions?: InputMaybe<Scalars['String']['input']>;
  registrationLegal?: InputMaybe<Scalars['String']['input']>;
  registrationPayments?: InputMaybe<Scalars['Boolean']['input']>;
  requireRegistrationWaiver?: InputMaybe<Scalars['Boolean']['input']>;
  restrictScheduling?: InputMaybe<Scalars['Boolean']['input']>;
  rosterGrade?: InputMaybe<Scalars['Boolean']['input']>;
  rosterMemberIdLabel?: InputMaybe<Scalars['String']['input']>;
  showOfficialsArea?: InputMaybe<Scalars['Boolean']['input']>;
  showRegistrationForm?: InputMaybe<Scalars['Boolean']['input']>;
  tournamentName?: InputMaybe<Scalars['String']['input']>;
  waiverText?: InputMaybe<Scalars['String']['input']>;
};

/** Primary League Object */
export type LeagueWaiver = {
  __typename?: 'LeagueWaiver';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  waiver?: Maybe<Waiver>;
};

export type Mutation = {
  __typename?: 'Mutation';
  add_age_group_to_season: AgeGroup;
  add_announcement_to_league: Announcement;
  add_carousel_to_league: Carousel;
  add_division_to_team_level: Division;
  add_facility_to_team: Facility;
  add_game_date_time_to_registration: GameDateTime;
  add_game_to_registration: Game;
  add_game_to_team_level: Game;
  add_league_role_to_league: LeagueRole;
  add_league_role_to_user: LeagueRole;
  add_league_waiver_to_league: LeagueWaiver;
  add_registration_to_age_group: Registration;
  add_registration_to_division: Registration;
  add_registration_to_league: Registration;
  add_registration_to_season: Registration;
  add_registration_to_team: Registration;
  add_registration_to_team_level: Registration;
  add_role_mapping_to_role: RoleMapping;
  add_role_mapping_to_user: RoleMapping;
  add_roster_entry_to_registration: RosterEntry;
  add_season_to_league: Season;
  add_site_setting_to_league: SiteSetting;
  add_site_setting_to_season: SiteSetting;
  add_sponsor_to_league: Sponsor;
  add_team_level_to_age_group: TeamLevel;
  add_team_position_to_team: TeamPosition;
  add_team_position_to_user: TeamPosition;
  add_team_to_user: Team;
  add_waiver_signature_to_user: WaiverSignature;
  create_age_group: AgeGroup;
  create_announcement: Announcement;
  create_carousel: Carousel;
  create_division: Division;
  create_email_log: EmailLog;
  create_facility: Facility;
  create_file_meta: FileMeta;
  create_game: Game;
  create_game_date_time: GameDateTime;
  create_league: League;
  create_league_role: LeagueRole;
  create_league_waiver: LeagueWaiver;
  create_registration: Registration;
  create_role: Role;
  create_role_mapping: RoleMapping;
  create_roster_entry: RosterEntry;
  create_season: Season;
  create_season_registration?: Maybe<Registration>;
  create_site_setting: SiteSetting;
  create_sns_message: SnsMessage;
  create_sponsor: Sponsor;
  create_standings: Standings;
  create_team: Team;
  create_team_level: TeamLevel;
  create_team_position: TeamPosition;
  create_user: User;
  create_vanity_url: VanityUrl;
  create_waiver: Waiver;
  create_waiver_signature: WaiverSignature;
  remove_age_group: AgeGroup;
  remove_age_group_from_season: AgeGroup;
  remove_announcement: Announcement;
  remove_announcement_from_league: Announcement;
  remove_carousel: Carousel;
  remove_carousel_from_league: Carousel;
  remove_division: Division;
  remove_division_from_team_level: Division;
  remove_email_log: EmailLog;
  remove_facility: Facility;
  remove_facility_from_team: Facility;
  remove_file_meta: FileMeta;
  remove_game: Game;
  remove_game_date_time: GameDateTime;
  remove_game_date_time_from_registration: GameDateTime;
  remove_game_from_registration: Game;
  remove_game_from_team_level: Game;
  remove_league: League;
  remove_league_role: LeagueRole;
  remove_league_role_from_league: LeagueRole;
  remove_league_role_from_user: LeagueRole;
  remove_league_waiver: LeagueWaiver;
  remove_league_waiver_from_league: LeagueWaiver;
  remove_registration: Registration;
  remove_registration_from_age_group: Registration;
  remove_registration_from_division: Registration;
  remove_registration_from_league: Registration;
  remove_registration_from_season: Registration;
  remove_registration_from_team: Registration;
  remove_registration_from_team_level: Registration;
  remove_role: Role;
  remove_role_mapping: RoleMapping;
  remove_role_mapping_from_role: RoleMapping;
  remove_role_mapping_from_user: RoleMapping;
  remove_roster_entry: RosterEntry;
  remove_roster_entry_from_registration: RosterEntry;
  remove_season: Season;
  remove_season_from_league: Season;
  remove_site_setting: SiteSetting;
  remove_site_setting_from_league: SiteSetting;
  remove_site_setting_from_season: SiteSetting;
  remove_sns_message: SnsMessage;
  remove_sponsor: Sponsor;
  remove_sponsor_from_league: Sponsor;
  remove_standings: Standings;
  remove_team: Team;
  remove_team_from_user: Team;
  remove_team_level: TeamLevel;
  remove_team_level_from_age_group: TeamLevel;
  remove_team_position: TeamPosition;
  remove_team_position_from_team: TeamPosition;
  remove_team_position_from_user: TeamPosition;
  remove_user: User;
  remove_vanity_url: VanityUrl;
  remove_waiver: Waiver;
  remove_waiver_signature: WaiverSignature;
  remove_waiver_signature_from_user: WaiverSignature;
  sign_waiver?: Maybe<RosterEntry>;
  update_age_group: AgeGroup;
  update_announcement: Announcement;
  update_carousel: Carousel;
  update_division: Division;
  update_email_log: EmailLog;
  update_facility: Facility;
  update_file_meta: FileMeta;
  update_game: Game;
  update_game_date_time: GameDateTime;
  update_league: League;
  update_league_role: LeagueRole;
  update_league_waiver: LeagueWaiver;
  update_many_age_group: Array<AgeGroup>;
  update_many_announcement: Array<Announcement>;
  update_many_carousel: Array<Carousel>;
  update_many_division: Array<Division>;
  update_many_email_log: Array<EmailLog>;
  update_many_facility: Array<Facility>;
  update_many_file_meta: Array<FileMeta>;
  update_many_game: Array<Game>;
  update_many_game_date_time: Array<GameDateTime>;
  update_many_league: Array<League>;
  update_many_league_role: Array<LeagueRole>;
  update_many_league_waiver: Array<LeagueWaiver>;
  update_many_registration: Array<Registration>;
  update_many_role: Array<Role>;
  update_many_role_mapping: Array<RoleMapping>;
  update_many_roster_entry: Array<RosterEntry>;
  update_many_season: Array<Season>;
  update_many_site_setting: Array<SiteSetting>;
  update_many_sns_message: Array<SnsMessage>;
  update_many_sponsor: Array<Sponsor>;
  update_many_standings: Array<Standings>;
  update_many_team: Array<Team>;
  update_many_team_level: Array<TeamLevel>;
  update_many_team_position: Array<TeamPosition>;
  update_many_user: Array<User>;
  update_many_vanity_url: Array<VanityUrl>;
  update_many_waiver: Array<Waiver>;
  update_many_waiver_signature: Array<WaiverSignature>;
  update_registration: Registration;
  update_role: Role;
  update_role_mapping: RoleMapping;
  update_roster_entry: RosterEntry;
  update_season: Season;
  update_site_setting: SiteSetting;
  update_sns_message: SnsMessage;
  update_sponsor: Sponsor;
  update_standings: Standings;
  update_team: Team;
  update_team_level: TeamLevel;
  update_team_position: TeamPosition;
  update_user: User;
  update_vanity_url: VanityUrl;
  update_waiver: Waiver;
  update_waiver_signature: WaiverSignature;
  upsert_age_group: AgeGroup;
  upsert_age_group_to_season: AgeGroup;
  upsert_announcement: Announcement;
  upsert_announcement_to_league: Announcement;
  upsert_carousel: Carousel;
  upsert_carousel_to_league: Carousel;
  upsert_division: Division;
  upsert_division_to_team_level: Division;
  upsert_email_log: EmailLog;
  upsert_facility: Facility;
  upsert_facility_to_team: Facility;
  upsert_file_meta: FileMeta;
  upsert_game: Game;
  upsert_game_date_time: GameDateTime;
  upsert_game_date_time_to_registration: GameDateTime;
  upsert_game_to_registration: Game;
  upsert_game_to_team_level: Game;
  upsert_league: League;
  upsert_league_role: LeagueRole;
  upsert_league_role_to_league: LeagueRole;
  upsert_league_role_to_user: LeagueRole;
  upsert_league_waiver: LeagueWaiver;
  upsert_league_waiver_to_league: LeagueWaiver;
  upsert_many_age_group_to_season: Array<AgeGroup>;
  upsert_many_announcement_to_league: Array<Announcement>;
  upsert_many_carousel_to_league: Array<Carousel>;
  upsert_many_division_to_team_level: Array<Division>;
  upsert_many_facility_to_team: Array<Facility>;
  upsert_many_game_date_time_to_registration: Array<GameDateTime>;
  upsert_many_game_to_registration: Array<Game>;
  upsert_many_game_to_team_level: Array<Game>;
  upsert_many_league_role_to_league: Array<LeagueRole>;
  upsert_many_league_role_to_user: Array<LeagueRole>;
  upsert_many_league_waiver_to_league: Array<LeagueWaiver>;
  upsert_many_registration_to_age_group: Array<Registration>;
  upsert_many_registration_to_division: Array<Registration>;
  upsert_many_registration_to_league: Array<Registration>;
  upsert_many_registration_to_season: Array<Registration>;
  upsert_many_registration_to_team: Array<Registration>;
  upsert_many_registration_to_team_level: Array<Registration>;
  upsert_many_role_mapping_to_role: Array<RoleMapping>;
  upsert_many_role_mapping_to_user: Array<RoleMapping>;
  upsert_many_roster_entry_to_registration: Array<RosterEntry>;
  upsert_many_season_to_league: Array<Season>;
  upsert_many_site_setting_to_league: Array<SiteSetting>;
  upsert_many_site_setting_to_season: Array<SiteSetting>;
  upsert_many_sponsor_to_league: Array<Sponsor>;
  upsert_many_team_level_to_age_group: Array<TeamLevel>;
  upsert_many_team_position_to_team: Array<TeamPosition>;
  upsert_many_team_position_to_user: Array<TeamPosition>;
  upsert_many_team_to_user: Array<Team>;
  upsert_many_waiver_signature_to_user: Array<WaiverSignature>;
  upsert_registration: Registration;
  upsert_registration_to_age_group: Registration;
  upsert_registration_to_division: Registration;
  upsert_registration_to_league: Registration;
  upsert_registration_to_season: Registration;
  upsert_registration_to_team: Registration;
  upsert_registration_to_team_level: Registration;
  upsert_role: Role;
  upsert_role_mapping: RoleMapping;
  upsert_role_mapping_to_role: RoleMapping;
  upsert_role_mapping_to_user: RoleMapping;
  upsert_roster_entry: RosterEntry;
  upsert_roster_entry_to_registration: RosterEntry;
  upsert_season: Season;
  upsert_season_to_league: Season;
  upsert_site_setting: SiteSetting;
  upsert_site_setting_to_league: SiteSetting;
  upsert_site_setting_to_season: SiteSetting;
  upsert_sns_message: SnsMessage;
  upsert_sponsor: Sponsor;
  upsert_sponsor_to_league: Sponsor;
  upsert_standings: Standings;
  upsert_team: Team;
  upsert_team_level: TeamLevel;
  upsert_team_level_to_age_group: TeamLevel;
  upsert_team_position: TeamPosition;
  upsert_team_position_to_team: TeamPosition;
  upsert_team_position_to_user: TeamPosition;
  upsert_team_to_user: Team;
  upsert_user: User;
  upsert_vanity_url: VanityUrl;
  upsert_waiver: Waiver;
  upsert_waiver_signature: WaiverSignature;
  upsert_waiver_signature_to_user: WaiverSignature;
};

export type MutationAdd_Age_Group_To_SeasonArgs = {
  age_group: CreateAgeGroupInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Announcement_To_LeagueArgs = {
  announcement: CreateAnnouncementInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Carousel_To_LeagueArgs = {
  carousel: CreateCarouselInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Division_To_Team_LevelArgs = {
  division: CreateDivisionInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Facility_To_TeamArgs = {
  facility: CreateFacilityInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Game_Date_Time_To_RegistrationArgs = {
  game_date_time: CreateGameDateTimeInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Game_To_RegistrationArgs = {
  game: CreateGameInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_Game_To_Team_LevelArgs = {
  game: CreateGameInput;
  id: Scalars['String']['input'];
};

export type MutationAdd_League_Role_To_LeagueArgs = {
  id: Scalars['String']['input'];
  league_role: CreateLeagueRoleInput;
};

export type MutationAdd_League_Role_To_UserArgs = {
  id: Scalars['String']['input'];
  league_role: CreateLeagueRoleInput;
};

export type MutationAdd_League_Waiver_To_LeagueArgs = {
  id: Scalars['String']['input'];
  league_waiver: CreateLeagueWaiverInput;
};

export type MutationAdd_Registration_To_Age_GroupArgs = {
  id: Scalars['String']['input'];
  registration: CreateRegistrationInput;
};

export type MutationAdd_Registration_To_DivisionArgs = {
  id: Scalars['String']['input'];
  registration: CreateRegistrationInput;
};

export type MutationAdd_Registration_To_LeagueArgs = {
  id: Scalars['String']['input'];
  registration: CreateRegistrationInput;
};

export type MutationAdd_Registration_To_SeasonArgs = {
  id: Scalars['String']['input'];
  registration: CreateRegistrationInput;
};

export type MutationAdd_Registration_To_TeamArgs = {
  id: Scalars['String']['input'];
  registration: CreateRegistrationInput;
};

export type MutationAdd_Registration_To_Team_LevelArgs = {
  id: Scalars['String']['input'];
  registration: CreateRegistrationInput;
};

export type MutationAdd_Role_Mapping_To_RoleArgs = {
  id: Scalars['String']['input'];
  role_mapping: CreateRoleMappingInput;
};

export type MutationAdd_Role_Mapping_To_UserArgs = {
  id: Scalars['String']['input'];
  role_mapping: CreateRoleMappingInput;
};

export type MutationAdd_Roster_Entry_To_RegistrationArgs = {
  id: Scalars['String']['input'];
  roster_entry: CreateRosterEntryInput;
};

export type MutationAdd_Season_To_LeagueArgs = {
  id: Scalars['String']['input'];
  season: CreateSeasonInput;
};

export type MutationAdd_Site_Setting_To_LeagueArgs = {
  id: Scalars['String']['input'];
  site_setting: CreateSiteSettingInput;
};

export type MutationAdd_Site_Setting_To_SeasonArgs = {
  id: Scalars['String']['input'];
  site_setting: CreateSiteSettingInput;
};

export type MutationAdd_Sponsor_To_LeagueArgs = {
  id: Scalars['String']['input'];
  sponsor: CreateSponsorInput;
};

export type MutationAdd_Team_Level_To_Age_GroupArgs = {
  id: Scalars['String']['input'];
  team_level: CreateTeamLevelInput;
};

export type MutationAdd_Team_Position_To_TeamArgs = {
  id: Scalars['String']['input'];
  team_position: CreateTeamPositionInput;
};

export type MutationAdd_Team_Position_To_UserArgs = {
  id: Scalars['String']['input'];
  team_position: CreateTeamPositionInput;
};

export type MutationAdd_Team_To_UserArgs = {
  id: Scalars['String']['input'];
  team: CreateTeamInput;
};

export type MutationAdd_Waiver_Signature_To_UserArgs = {
  id: Scalars['String']['input'];
  waiver_signature: CreateWaiverSignatureInput;
};

export type MutationCreate_Age_GroupArgs = {
  createInput: CreateAgeGroupInput;
};

export type MutationCreate_AnnouncementArgs = {
  createInput: CreateAnnouncementInput;
};

export type MutationCreate_CarouselArgs = {
  createInput: CreateCarouselInput;
};

export type MutationCreate_DivisionArgs = {
  createInput: CreateDivisionInput;
};

export type MutationCreate_Email_LogArgs = {
  createInput: CreateEmailLogInput;
};

export type MutationCreate_FacilityArgs = {
  createInput: CreateFacilityInput;
};

export type MutationCreate_File_MetaArgs = {
  createInput: CreateFileMetaInput;
};

export type MutationCreate_GameArgs = {
  createInput: CreateGameInput;
};

export type MutationCreate_Game_Date_TimeArgs = {
  createInput: CreateGameDateTimeInput;
};

export type MutationCreate_LeagueArgs = {
  createInput: CreateLeagueInput;
};

export type MutationCreate_League_RoleArgs = {
  createInput: CreateLeagueRoleInput;
};

export type MutationCreate_League_WaiverArgs = {
  createInput: CreateLeagueWaiverInput;
};

export type MutationCreate_RegistrationArgs = {
  createInput: CreateRegistrationInput;
};

export type MutationCreate_RoleArgs = {
  createInput: CreateRoleInput;
};

export type MutationCreate_Role_MappingArgs = {
  createInput: CreateRoleMappingInput;
};

export type MutationCreate_Roster_EntryArgs = {
  createInput: CreateRosterEntryInput;
};

export type MutationCreate_SeasonArgs = {
  createInput: CreateSeasonInput;
};

export type MutationCreate_Season_RegistrationArgs = {
  administrator?: InputMaybe<UpsertUserInput>;
  manager: UpsertUserInput;
  registration: CreateRegistrationInput;
  team: UpsertTeamInput;
};

export type MutationCreate_Site_SettingArgs = {
  createInput: CreateSiteSettingInput;
};

export type MutationCreate_Sns_MessageArgs = {
  createInput: CreateSnsMessageInput;
};

export type MutationCreate_SponsorArgs = {
  createInput: CreateSponsorInput;
};

export type MutationCreate_StandingsArgs = {
  createInput: CreateStandingsInput;
};

export type MutationCreate_TeamArgs = {
  createInput: CreateTeamInput;
};

export type MutationCreate_Team_LevelArgs = {
  createInput: CreateTeamLevelInput;
};

export type MutationCreate_Team_PositionArgs = {
  createInput: CreateTeamPositionInput;
};

export type MutationCreate_UserArgs = {
  createInput: CreateUserInput;
};

export type MutationCreate_Vanity_UrlArgs = {
  createInput: CreateVanityUrlInput;
};

export type MutationCreate_WaiverArgs = {
  createInput: CreateWaiverInput;
};

export type MutationCreate_Waiver_SignatureArgs = {
  createInput: CreateWaiverSignatureInput;
};

export type MutationRemove_Age_GroupArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Age_Group_From_SeasonArgs = {
  age_group: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_AnnouncementArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Announcement_From_LeagueArgs = {
  announcement: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_CarouselArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Carousel_From_LeagueArgs = {
  carousel: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_DivisionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Division_From_Team_LevelArgs = {
  division: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_Email_LogArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_FacilityArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Facility_From_TeamArgs = {
  facility: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_File_MetaArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_GameArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Game_Date_TimeArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Game_Date_Time_From_RegistrationArgs = {
  game_date_time: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_Game_From_RegistrationArgs = {
  game: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_Game_From_Team_LevelArgs = {
  game: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationRemove_LeagueArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_League_RoleArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_League_Role_From_LeagueArgs = {
  id: Scalars['String']['input'];
  league_role: Scalars['String']['input'];
};

export type MutationRemove_League_Role_From_UserArgs = {
  id: Scalars['String']['input'];
  league_role: Scalars['String']['input'];
};

export type MutationRemove_League_WaiverArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_League_Waiver_From_LeagueArgs = {
  id: Scalars['String']['input'];
  league_waiver: Scalars['String']['input'];
};

export type MutationRemove_RegistrationArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Registration_From_Age_GroupArgs = {
  id: Scalars['String']['input'];
  registration: Scalars['String']['input'];
};

export type MutationRemove_Registration_From_DivisionArgs = {
  id: Scalars['String']['input'];
  registration: Scalars['String']['input'];
};

export type MutationRemove_Registration_From_LeagueArgs = {
  id: Scalars['String']['input'];
  registration: Scalars['String']['input'];
};

export type MutationRemove_Registration_From_SeasonArgs = {
  id: Scalars['String']['input'];
  registration: Scalars['String']['input'];
};

export type MutationRemove_Registration_From_TeamArgs = {
  id: Scalars['String']['input'];
  registration: Scalars['String']['input'];
};

export type MutationRemove_Registration_From_Team_LevelArgs = {
  id: Scalars['String']['input'];
  registration: Scalars['String']['input'];
};

export type MutationRemove_RoleArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Role_MappingArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Role_Mapping_From_RoleArgs = {
  id: Scalars['String']['input'];
  role_mapping: Scalars['String']['input'];
};

export type MutationRemove_Role_Mapping_From_UserArgs = {
  id: Scalars['String']['input'];
  role_mapping: Scalars['String']['input'];
};

export type MutationRemove_Roster_EntryArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Roster_Entry_From_RegistrationArgs = {
  id: Scalars['String']['input'];
  roster_entry: Scalars['String']['input'];
};

export type MutationRemove_SeasonArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Season_From_LeagueArgs = {
  id: Scalars['String']['input'];
  season: Scalars['String']['input'];
};

export type MutationRemove_Site_SettingArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Site_Setting_From_LeagueArgs = {
  id: Scalars['String']['input'];
  site_setting: Scalars['String']['input'];
};

export type MutationRemove_Site_Setting_From_SeasonArgs = {
  id: Scalars['String']['input'];
  site_setting: Scalars['String']['input'];
};

export type MutationRemove_Sns_MessageArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_SponsorArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Sponsor_From_LeagueArgs = {
  id: Scalars['String']['input'];
  sponsor: Scalars['String']['input'];
};

export type MutationRemove_StandingsArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_TeamArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Team_From_UserArgs = {
  id: Scalars['String']['input'];
  team: Scalars['String']['input'];
};

export type MutationRemove_Team_LevelArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Team_Level_From_Age_GroupArgs = {
  id: Scalars['String']['input'];
  team_level: Scalars['String']['input'];
};

export type MutationRemove_Team_PositionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Team_Position_From_TeamArgs = {
  id: Scalars['String']['input'];
  team_position: Scalars['String']['input'];
};

export type MutationRemove_Team_Position_From_UserArgs = {
  id: Scalars['String']['input'];
  team_position: Scalars['String']['input'];
};

export type MutationRemove_UserArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Vanity_UrlArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_WaiverArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Waiver_SignatureArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_Waiver_Signature_From_UserArgs = {
  id: Scalars['String']['input'];
  waiver_signature: Scalars['String']['input'];
};

export type MutationSign_WaiverArgs = {
  roster: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type MutationUpdate_Age_GroupArgs = {
  id: Scalars['String']['input'];
  update: UpdateAgeGroupInput;
};

export type MutationUpdate_AnnouncementArgs = {
  id: Scalars['String']['input'];
  update: UpdateAnnouncementInput;
};

export type MutationUpdate_CarouselArgs = {
  id: Scalars['String']['input'];
  update: UpdateCarouselInput;
};

export type MutationUpdate_DivisionArgs = {
  id: Scalars['String']['input'];
  update: UpdateDivisionInput;
};

export type MutationUpdate_Email_LogArgs = {
  id: Scalars['String']['input'];
  update: UpdateEmailLogInput;
};

export type MutationUpdate_FacilityArgs = {
  id: Scalars['String']['input'];
  update: UpdateFacilityInput;
};

export type MutationUpdate_File_MetaArgs = {
  id: Scalars['String']['input'];
  update: UpdateFileMetaInput;
};

export type MutationUpdate_GameArgs = {
  id: Scalars['String']['input'];
  update: UpdateGameInput;
};

export type MutationUpdate_Game_Date_TimeArgs = {
  id: Scalars['String']['input'];
  update: UpdateGameDateTimeInput;
};

export type MutationUpdate_LeagueArgs = {
  id: Scalars['String']['input'];
  update: UpdateLeagueInput;
};

export type MutationUpdate_League_RoleArgs = {
  id: Scalars['String']['input'];
  update: UpdateLeagueRoleInput;
};

export type MutationUpdate_League_WaiverArgs = {
  id: Scalars['String']['input'];
  update: UpdateLeagueWaiverInput;
};

export type MutationUpdate_Many_Age_GroupArgs = {
  update: Array<UpsertAgeGroupInput>;
};

export type MutationUpdate_Many_AnnouncementArgs = {
  update: Array<UpsertAnnouncementInput>;
};

export type MutationUpdate_Many_CarouselArgs = {
  update: Array<UpsertCarouselInput>;
};

export type MutationUpdate_Many_DivisionArgs = {
  update: Array<UpsertDivisionInput>;
};

export type MutationUpdate_Many_Email_LogArgs = {
  update: Array<UpsertEmailLogInput>;
};

export type MutationUpdate_Many_FacilityArgs = {
  update: Array<UpsertFacilityInput>;
};

export type MutationUpdate_Many_File_MetaArgs = {
  update: Array<UpsertFileMetaInput>;
};

export type MutationUpdate_Many_GameArgs = {
  update: Array<UpsertGameInput>;
};

export type MutationUpdate_Many_Game_Date_TimeArgs = {
  update: Array<UpsertGameDateTimeInput>;
};

export type MutationUpdate_Many_LeagueArgs = {
  update: Array<UpsertLeagueInput>;
};

export type MutationUpdate_Many_League_RoleArgs = {
  update: Array<UpsertLeagueRoleInput>;
};

export type MutationUpdate_Many_League_WaiverArgs = {
  update: Array<UpsertLeagueWaiverInput>;
};

export type MutationUpdate_Many_RegistrationArgs = {
  update: Array<UpsertRegistrationInput>;
};

export type MutationUpdate_Many_RoleArgs = {
  update: Array<UpsertRoleInput>;
};

export type MutationUpdate_Many_Role_MappingArgs = {
  update: Array<UpsertRoleMappingInput>;
};

export type MutationUpdate_Many_Roster_EntryArgs = {
  update: Array<UpsertRosterEntryInput>;
};

export type MutationUpdate_Many_SeasonArgs = {
  update: Array<UpsertSeasonInput>;
};

export type MutationUpdate_Many_Site_SettingArgs = {
  update: Array<UpsertSiteSettingInput>;
};

export type MutationUpdate_Many_Sns_MessageArgs = {
  update: Array<UpsertSnsMessageInput>;
};

export type MutationUpdate_Many_SponsorArgs = {
  update: Array<UpsertSponsorInput>;
};

export type MutationUpdate_Many_StandingsArgs = {
  update: Array<UpsertStandingsInput>;
};

export type MutationUpdate_Many_TeamArgs = {
  update: Array<UpsertTeamInput>;
};

export type MutationUpdate_Many_Team_LevelArgs = {
  update: Array<UpsertTeamLevelInput>;
};

export type MutationUpdate_Many_Team_PositionArgs = {
  update: Array<UpsertTeamPositionInput>;
};

export type MutationUpdate_Many_UserArgs = {
  update: Array<UpsertUserInput>;
};

export type MutationUpdate_Many_Vanity_UrlArgs = {
  update: Array<UpsertVanityUrlInput>;
};

export type MutationUpdate_Many_WaiverArgs = {
  update: Array<UpsertWaiverInput>;
};

export type MutationUpdate_Many_Waiver_SignatureArgs = {
  update: Array<UpsertWaiverSignatureInput>;
};

export type MutationUpdate_RegistrationArgs = {
  id: Scalars['String']['input'];
  update: UpdateRegistrationInput;
};

export type MutationUpdate_RoleArgs = {
  id: Scalars['String']['input'];
  update: UpdateRoleInput;
};

export type MutationUpdate_Role_MappingArgs = {
  id: Scalars['String']['input'];
  update: UpdateRoleMappingInput;
};

export type MutationUpdate_Roster_EntryArgs = {
  id: Scalars['String']['input'];
  update: UpdateRosterEntryInput;
};

export type MutationUpdate_SeasonArgs = {
  id: Scalars['String']['input'];
  update: UpdateSeasonInput;
};

export type MutationUpdate_Site_SettingArgs = {
  id: Scalars['String']['input'];
  update: UpdateSiteSettingInput;
};

export type MutationUpdate_Sns_MessageArgs = {
  id: Scalars['String']['input'];
  update: UpdateSnsMessageInput;
};

export type MutationUpdate_SponsorArgs = {
  id: Scalars['String']['input'];
  update: UpdateSponsorInput;
};

export type MutationUpdate_StandingsArgs = {
  id: Scalars['String']['input'];
  update: UpdateStandingsInput;
};

export type MutationUpdate_TeamArgs = {
  id: Scalars['String']['input'];
  update: UpdateTeamInput;
};

export type MutationUpdate_Team_LevelArgs = {
  id: Scalars['String']['input'];
  update: UpdateTeamLevelInput;
};

export type MutationUpdate_Team_PositionArgs = {
  id: Scalars['String']['input'];
  update: UpdateTeamPositionInput;
};

export type MutationUpdate_UserArgs = {
  id: Scalars['String']['input'];
  update: UpdateUserInput;
};

export type MutationUpdate_Vanity_UrlArgs = {
  id: Scalars['String']['input'];
  update: UpdateVanityUrlInput;
};

export type MutationUpdate_WaiverArgs = {
  id: Scalars['String']['input'];
  update: UpdateWaiverInput;
};

export type MutationUpdate_Waiver_SignatureArgs = {
  id: Scalars['String']['input'];
  update: UpdateWaiverSignatureInput;
};

export type MutationUpsert_Age_GroupArgs = {
  upsert: UpsertAgeGroupInput;
};

export type MutationUpsert_Age_Group_To_SeasonArgs = {
  age_group: UpsertAgeGroupInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_AnnouncementArgs = {
  upsert: UpsertAnnouncementInput;
};

export type MutationUpsert_Announcement_To_LeagueArgs = {
  announcement: UpsertAnnouncementInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_CarouselArgs = {
  upsert: UpsertCarouselInput;
};

export type MutationUpsert_Carousel_To_LeagueArgs = {
  carousel: UpsertCarouselInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_DivisionArgs = {
  upsert: UpsertDivisionInput;
};

export type MutationUpsert_Division_To_Team_LevelArgs = {
  division: UpsertDivisionInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Email_LogArgs = {
  upsert: UpsertEmailLogInput;
};

export type MutationUpsert_FacilityArgs = {
  upsert: UpsertFacilityInput;
};

export type MutationUpsert_Facility_To_TeamArgs = {
  facility: UpsertFacilityInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_File_MetaArgs = {
  upsert: UpsertFileMetaInput;
};

export type MutationUpsert_GameArgs = {
  upsert: UpsertGameInput;
};

export type MutationUpsert_Game_Date_TimeArgs = {
  upsert: UpsertGameDateTimeInput;
};

export type MutationUpsert_Game_Date_Time_To_RegistrationArgs = {
  game_date_time: UpsertGameDateTimeInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Game_To_RegistrationArgs = {
  game: UpsertGameInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Game_To_Team_LevelArgs = {
  game: UpsertGameInput;
  id: Scalars['String']['input'];
};

export type MutationUpsert_LeagueArgs = {
  upsert: UpsertLeagueInput;
};

export type MutationUpsert_League_RoleArgs = {
  upsert: UpsertLeagueRoleInput;
};

export type MutationUpsert_League_Role_To_LeagueArgs = {
  id: Scalars['String']['input'];
  league_role: UpsertLeagueRoleInput;
};

export type MutationUpsert_League_Role_To_UserArgs = {
  id: Scalars['String']['input'];
  league_role: UpsertLeagueRoleInput;
};

export type MutationUpsert_League_WaiverArgs = {
  upsert: UpsertLeagueWaiverInput;
};

export type MutationUpsert_League_Waiver_To_LeagueArgs = {
  id: Scalars['String']['input'];
  league_waiver: UpsertLeagueWaiverInput;
};

export type MutationUpsert_Many_Age_Group_To_SeasonArgs = {
  age_group: Array<UpsertAgeGroupInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Announcement_To_LeagueArgs = {
  announcement: Array<UpsertAnnouncementInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Carousel_To_LeagueArgs = {
  carousel: Array<UpsertCarouselInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Division_To_Team_LevelArgs = {
  division: Array<UpsertDivisionInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Facility_To_TeamArgs = {
  facility: Array<UpsertFacilityInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Game_Date_Time_To_RegistrationArgs = {
  game_date_time: Array<UpsertGameDateTimeInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Game_To_RegistrationArgs = {
  game: Array<UpsertGameInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_Game_To_Team_LevelArgs = {
  game: Array<UpsertGameInput>;
  id: Scalars['String']['input'];
};

export type MutationUpsert_Many_League_Role_To_LeagueArgs = {
  id: Scalars['String']['input'];
  league_role: Array<UpsertLeagueRoleInput>;
};

export type MutationUpsert_Many_League_Role_To_UserArgs = {
  id: Scalars['String']['input'];
  league_role: Array<UpsertLeagueRoleInput>;
};

export type MutationUpsert_Many_League_Waiver_To_LeagueArgs = {
  id: Scalars['String']['input'];
  league_waiver: Array<UpsertLeagueWaiverInput>;
};

export type MutationUpsert_Many_Registration_To_Age_GroupArgs = {
  id: Scalars['String']['input'];
  registration: Array<UpsertRegistrationInput>;
};

export type MutationUpsert_Many_Registration_To_DivisionArgs = {
  id: Scalars['String']['input'];
  registration: Array<UpsertRegistrationInput>;
};

export type MutationUpsert_Many_Registration_To_LeagueArgs = {
  id: Scalars['String']['input'];
  registration: Array<UpsertRegistrationInput>;
};

export type MutationUpsert_Many_Registration_To_SeasonArgs = {
  id: Scalars['String']['input'];
  registration: Array<UpsertRegistrationInput>;
};

export type MutationUpsert_Many_Registration_To_TeamArgs = {
  id: Scalars['String']['input'];
  registration: Array<UpsertRegistrationInput>;
};

export type MutationUpsert_Many_Registration_To_Team_LevelArgs = {
  id: Scalars['String']['input'];
  registration: Array<UpsertRegistrationInput>;
};

export type MutationUpsert_Many_Role_Mapping_To_RoleArgs = {
  id: Scalars['String']['input'];
  role_mapping: Array<UpsertRoleMappingInput>;
};

export type MutationUpsert_Many_Role_Mapping_To_UserArgs = {
  id: Scalars['String']['input'];
  role_mapping: Array<UpsertRoleMappingInput>;
};

export type MutationUpsert_Many_Roster_Entry_To_RegistrationArgs = {
  id: Scalars['String']['input'];
  roster_entry: Array<UpsertRosterEntryInput>;
};

export type MutationUpsert_Many_Season_To_LeagueArgs = {
  id: Scalars['String']['input'];
  season: Array<UpsertSeasonInput>;
};

export type MutationUpsert_Many_Site_Setting_To_LeagueArgs = {
  id: Scalars['String']['input'];
  site_setting: Array<UpsertSiteSettingInput>;
};

export type MutationUpsert_Many_Site_Setting_To_SeasonArgs = {
  id: Scalars['String']['input'];
  site_setting: Array<UpsertSiteSettingInput>;
};

export type MutationUpsert_Many_Sponsor_To_LeagueArgs = {
  id: Scalars['String']['input'];
  sponsor: Array<UpsertSponsorInput>;
};

export type MutationUpsert_Many_Team_Level_To_Age_GroupArgs = {
  id: Scalars['String']['input'];
  team_level: Array<UpsertTeamLevelInput>;
};

export type MutationUpsert_Many_Team_Position_To_TeamArgs = {
  id: Scalars['String']['input'];
  team_position: Array<UpsertTeamPositionInput>;
};

export type MutationUpsert_Many_Team_Position_To_UserArgs = {
  id: Scalars['String']['input'];
  team_position: Array<UpsertTeamPositionInput>;
};

export type MutationUpsert_Many_Team_To_UserArgs = {
  id: Scalars['String']['input'];
  team: Array<UpsertTeamInput>;
};

export type MutationUpsert_Many_Waiver_Signature_To_UserArgs = {
  id: Scalars['String']['input'];
  waiver_signature: Array<UpsertWaiverSignatureInput>;
};

export type MutationUpsert_RegistrationArgs = {
  upsert: UpsertRegistrationInput;
};

export type MutationUpsert_Registration_To_Age_GroupArgs = {
  id: Scalars['String']['input'];
  registration: UpsertRegistrationInput;
};

export type MutationUpsert_Registration_To_DivisionArgs = {
  id: Scalars['String']['input'];
  registration: UpsertRegistrationInput;
};

export type MutationUpsert_Registration_To_LeagueArgs = {
  id: Scalars['String']['input'];
  registration: UpsertRegistrationInput;
};

export type MutationUpsert_Registration_To_SeasonArgs = {
  id: Scalars['String']['input'];
  registration: UpsertRegistrationInput;
};

export type MutationUpsert_Registration_To_TeamArgs = {
  id: Scalars['String']['input'];
  registration: UpsertRegistrationInput;
};

export type MutationUpsert_Registration_To_Team_LevelArgs = {
  id: Scalars['String']['input'];
  registration: UpsertRegistrationInput;
};

export type MutationUpsert_RoleArgs = {
  upsert: UpsertRoleInput;
};

export type MutationUpsert_Role_MappingArgs = {
  upsert: UpsertRoleMappingInput;
};

export type MutationUpsert_Role_Mapping_To_RoleArgs = {
  id: Scalars['String']['input'];
  role_mapping: UpsertRoleMappingInput;
};

export type MutationUpsert_Role_Mapping_To_UserArgs = {
  id: Scalars['String']['input'];
  role_mapping: UpsertRoleMappingInput;
};

export type MutationUpsert_Roster_EntryArgs = {
  upsert: UpsertRosterEntryInput;
};

export type MutationUpsert_Roster_Entry_To_RegistrationArgs = {
  id: Scalars['String']['input'];
  roster_entry: UpsertRosterEntryInput;
};

export type MutationUpsert_SeasonArgs = {
  upsert: UpsertSeasonInput;
};

export type MutationUpsert_Season_To_LeagueArgs = {
  id: Scalars['String']['input'];
  season: UpsertSeasonInput;
};

export type MutationUpsert_Site_SettingArgs = {
  upsert: UpsertSiteSettingInput;
};

export type MutationUpsert_Site_Setting_To_LeagueArgs = {
  id: Scalars['String']['input'];
  site_setting: UpsertSiteSettingInput;
};

export type MutationUpsert_Site_Setting_To_SeasonArgs = {
  id: Scalars['String']['input'];
  site_setting: UpsertSiteSettingInput;
};

export type MutationUpsert_Sns_MessageArgs = {
  upsert: UpsertSnsMessageInput;
};

export type MutationUpsert_SponsorArgs = {
  upsert: UpsertSponsorInput;
};

export type MutationUpsert_Sponsor_To_LeagueArgs = {
  id: Scalars['String']['input'];
  sponsor: UpsertSponsorInput;
};

export type MutationUpsert_StandingsArgs = {
  upsert: UpsertStandingsInput;
};

export type MutationUpsert_TeamArgs = {
  upsert: UpsertTeamInput;
};

export type MutationUpsert_Team_LevelArgs = {
  upsert: UpsertTeamLevelInput;
};

export type MutationUpsert_Team_Level_To_Age_GroupArgs = {
  id: Scalars['String']['input'];
  team_level: UpsertTeamLevelInput;
};

export type MutationUpsert_Team_PositionArgs = {
  upsert: UpsertTeamPositionInput;
};

export type MutationUpsert_Team_Position_To_TeamArgs = {
  id: Scalars['String']['input'];
  team_position: UpsertTeamPositionInput;
};

export type MutationUpsert_Team_Position_To_UserArgs = {
  id: Scalars['String']['input'];
  team_position: UpsertTeamPositionInput;
};

export type MutationUpsert_Team_To_UserArgs = {
  id: Scalars['String']['input'];
  team: UpsertTeamInput;
};

export type MutationUpsert_UserArgs = {
  upsert: UpsertUserInput;
};

export type MutationUpsert_Vanity_UrlArgs = {
  upsert: UpsertVanityUrlInput;
};

export type MutationUpsert_WaiverArgs = {
  upsert: UpsertWaiverInput;
};

export type MutationUpsert_Waiver_SignatureArgs = {
  upsert: UpsertWaiverSignatureInput;
};

export type MutationUpsert_Waiver_Signature_To_UserArgs = {
  id: Scalars['String']['input'];
  waiver_signature: UpsertWaiverSignatureInput;
};

export type Player = {
  __typename?: 'Player';
  birthday?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['String']['output']>;
  guardian2email?: Maybe<Scalars['String']['output']>;
  guardianemail?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumber2?: Maybe<Scalars['String']['output']>;
  phoneNumber3?: Maybe<Scalars['String']['output']>;
};

export type PlayerInput = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  grade?: InputMaybe<Scalars['String']['input']>;
  guardian2email?: InputMaybe<Scalars['String']['input']>;
  guardianemail?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber2?: InputMaybe<Scalars['String']['input']>;
  phoneNumber3?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  age_group: AgeGroup;
  age_groups: Array<AgeGroup>;
  announcement: Announcement;
  announcements: Array<Announcement>;
  carousel: Carousel;
  carousels: Array<Carousel>;
  division: Division;
  divisions: Array<Division>;
  email_log: EmailLog;
  email_logs: Array<EmailLog>;
  facilities: Array<Facility>;
  facility: Facility;
  file_meta: FileMeta;
  file_metas: Array<FileMeta>;
  game: Game;
  game_date_time: GameDateTime;
  game_date_times: Array<GameDateTime>;
  games: Array<Game>;
  league: League;
  league_role: LeagueRole;
  league_roles: Array<LeagueRole>;
  league_waiver: LeagueWaiver;
  league_waivers: Array<LeagueWaiver>;
  leagues: Array<League>;
  registration: Registration;
  registrations: Array<Registration>;
  role: Role;
  role_mapping: RoleMapping;
  role_mappings: Array<RoleMapping>;
  roles: Array<Role>;
  roster_entries: Array<RosterEntry>;
  roster_entry: RosterEntry;
  season: Season;
  seasons: Array<Season>;
  site_setting: SiteSetting;
  site_settings: Array<SiteSetting>;
  sns_message: SnsMessage;
  sns_messages: Array<SnsMessage>;
  sponsor: Sponsor;
  sponsors: Array<Sponsor>;
  standings: Standings;
  team: Team;
  team_level: TeamLevel;
  team_levels: Array<TeamLevel>;
  team_position: TeamPosition;
  team_positions: Array<TeamPosition>;
  team_registration?: Maybe<Registration>;
  teams: Array<Team>;
  user: User;
  users: Array<User>;
  vanity_url: VanityUrl;
  vanity_url_resolve?: Maybe<VanityUrl>;
  vanity_urls: Array<VanityUrl>;
  waiver: Waiver;
  waiver_signature: WaiverSignature;
  waiver_signatures: Array<WaiverSignature>;
  waivers: Array<Waiver>;
};

export type QueryAge_GroupArgs = {
  id: Scalars['String']['input'];
};

export type QueryAge_GroupsArgs = {
  filter?: InputMaybe<QueryAgeGroupInput>;
};

export type QueryAnnouncementArgs = {
  id: Scalars['String']['input'];
};

export type QueryAnnouncementsArgs = {
  filter?: InputMaybe<QueryAnnouncementInput>;
};

export type QueryCarouselArgs = {
  id: Scalars['String']['input'];
};

export type QueryCarouselsArgs = {
  filter?: InputMaybe<QueryCarouselInput>;
};

export type QueryDivisionArgs = {
  id: Scalars['String']['input'];
};

export type QueryDivisionsArgs = {
  filter?: InputMaybe<QueryDivisionInput>;
};

export type QueryEmail_LogArgs = {
  id: Scalars['String']['input'];
};

export type QueryEmail_LogsArgs = {
  filter?: InputMaybe<QueryEmailLogInput>;
};

export type QueryFacilitiesArgs = {
  filter?: InputMaybe<QueryFacilityInput>;
};

export type QueryFacilityArgs = {
  id: Scalars['String']['input'];
};

export type QueryFile_MetaArgs = {
  id: Scalars['String']['input'];
};

export type QueryFile_MetasArgs = {
  filter?: InputMaybe<QueryFileMetaInput>;
};

export type QueryGameArgs = {
  id: Scalars['String']['input'];
};

export type QueryGame_Date_TimeArgs = {
  id: Scalars['String']['input'];
};

export type QueryGame_Date_TimesArgs = {
  filter?: InputMaybe<QueryGameDateTimeInput>;
};

export type QueryGamesArgs = {
  filter?: InputMaybe<QueryGameInput>;
};

export type QueryLeagueArgs = {
  id: Scalars['String']['input'];
};

export type QueryLeague_RoleArgs = {
  id: Scalars['String']['input'];
};

export type QueryLeague_RolesArgs = {
  filter?: InputMaybe<QueryLeagueRoleInput>;
};

export type QueryLeague_WaiverArgs = {
  id: Scalars['String']['input'];
};

export type QueryLeague_WaiversArgs = {
  filter?: InputMaybe<QueryLeagueWaiverInput>;
};

export type QueryLeaguesArgs = {
  filter?: InputMaybe<QueryLeagueInput>;
};

export type QueryRegistrationArgs = {
  id: Scalars['String']['input'];
};

export type QueryRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

export type QueryRoleArgs = {
  id: Scalars['String']['input'];
};

export type QueryRole_MappingArgs = {
  id: Scalars['String']['input'];
};

export type QueryRole_MappingsArgs = {
  filter?: InputMaybe<QueryRoleMappingInput>;
};

export type QueryRolesArgs = {
  filter?: InputMaybe<QueryRoleInput>;
};

export type QueryRoster_EntriesArgs = {
  filter?: InputMaybe<QueryRosterEntryInput>;
};

export type QueryRoster_EntryArgs = {
  id: Scalars['String']['input'];
};

export type QuerySeasonArgs = {
  id: Scalars['String']['input'];
};

export type QuerySeasonsArgs = {
  filter?: InputMaybe<QuerySeasonInput>;
};

export type QuerySite_SettingArgs = {
  id: Scalars['String']['input'];
};

export type QuerySite_SettingsArgs = {
  filter?: InputMaybe<QuerySiteSettingInput>;
};

export type QuerySns_MessageArgs = {
  id: Scalars['String']['input'];
};

export type QuerySns_MessagesArgs = {
  filter?: InputMaybe<QuerySnsMessageInput>;
};

export type QuerySponsorArgs = {
  id: Scalars['String']['input'];
};

export type QuerySponsorsArgs = {
  filter?: InputMaybe<QuerySponsorInput>;
};

export type QueryStandingsArgs = {
  id: Scalars['String']['input'];
};

export type QueryTeamArgs = {
  id: Scalars['String']['input'];
};

export type QueryTeam_LevelArgs = {
  id: Scalars['String']['input'];
};

export type QueryTeam_LevelsArgs = {
  filter?: InputMaybe<QueryTeamLevelInput>;
};

export type QueryTeam_PositionArgs = {
  id: Scalars['String']['input'];
};

export type QueryTeam_PositionsArgs = {
  filter?: InputMaybe<QueryTeamPositionInput>;
};

export type QueryTeam_RegistrationArgs = {
  seasonId: Scalars['String']['input'];
  urlKey: Scalars['String']['input'];
};

export type QueryTeamsArgs = {
  filter?: InputMaybe<QueryTeamInput>;
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<QueryUserInput>;
};

export type QueryVanity_UrlArgs = {
  id: Scalars['String']['input'];
};

export type QueryVanity_Url_ResolveArgs = {
  domain: Scalars['String']['input'];
};

export type QueryVanity_UrlsArgs = {
  filter?: InputMaybe<QueryVanityUrlInput>;
};

export type QueryWaiverArgs = {
  id: Scalars['String']['input'];
};

export type QueryWaiver_SignatureArgs = {
  id: Scalars['String']['input'];
};

export type QueryWaiver_SignaturesArgs = {
  filter?: InputMaybe<QueryWaiverSignatureInput>;
};

export type QueryWaiversArgs = {
  filter?: InputMaybe<QueryWaiverInput>;
};

export type QueryAgeGroupInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  season?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAnnouncementInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryCarouselInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryDivisionInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  team_level?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEmailLogInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  messageId?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  rosterId?: InputMaybe<StringQueryFilter>;
};

export type QueryFacilityInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  team?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFileMetaInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryGameDateTimeInput = {
  created?: InputMaybe<DateQueryFilter>;
  date?: InputMaybe<StringQueryFilter>;
  datetime?: InputMaybe<DateQueryFilter>;
  facility?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  registration?: InputMaybe<Scalars['String']['input']>;
  taken?: InputMaybe<BooleanQueryFilter>;
  time?: InputMaybe<StringQueryFilter>;
};

export type QueryGameInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  away_team?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<DateQueryFilter>;
  date?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  home_team?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<StringQueryFilter>;
  isTournamentMatch?: InputMaybe<BooleanQueryFilter>;
  losing_team?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  season?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  winning_team?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLeagueInput = {
  acronym?: InputMaybe<StringQueryFilter>;
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  logo?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  name?: InputMaybe<StringQueryFilter>;
  urlKey?: InputMaybe<StringQueryFilter>;
};

export type QueryLeagueRoleInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLeagueWaiverInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRegistrationInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<DateQueryFilter>;
  division?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  season?: InputMaybe<Scalars['String']['input']>;
  standings?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRoleInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  name?: InputMaybe<StringQueryFilter>;
};

export type QueryRoleMappingInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRosterEntryInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  registration?: InputMaybe<Scalars['String']['input']>;
  waiver?: InputMaybe<BooleanQueryFilter>;
};

export type QuerySeasonInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  name?: InputMaybe<StringQueryFilter>;
};

export type QuerySiteSettingInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  season?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySnsMessageInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QuerySponsorInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryTeamInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  logo?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  urlKey?: InputMaybe<StringQueryFilter>;
};

export type QueryTeamLevelInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryTeamPositionInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  team?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserInput = {
  created?: InputMaybe<DateQueryFilter>;
  email?: InputMaybe<StringQueryFilter>;
  firstName?: InputMaybe<StringQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  lastName?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryVanityUrlInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  league?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<DateQueryFilter>;
  team?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWaiverInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
};

export type QueryWaiverSignatureInput = {
  created?: InputMaybe<DateQueryFilter>;
  id?: InputMaybe<StringQueryFilter>;
  modified?: InputMaybe<DateQueryFilter>;
  user?: InputMaybe<Scalars['String']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type Registration = {
  __typename?: 'Registration';
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  age_group?: Maybe<AgeGroup>;
  away_games?: Maybe<Array<Game>>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  division?: Maybe<Division>;
  /** The preferred division preference */
  divisionPreference?: Maybe<Scalars['String']['output']>;
  /** Elite justification */
  eliteJustification?: Maybe<Scalars['String']['output']>;
  home_dates?: Maybe<Array<GameDateTime>>;
  home_games?: Maybe<Array<Game>>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  /** League last year response */
  leagueLastYear?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  rosters?: Maybe<Array<RosterEntry>>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  season: Season;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  standings?: Maybe<Standings>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team?: Maybe<Team>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team_level?: Maybe<TeamLevel>;
  /** Whether the waiver is signed */
  waiver?: Maybe<Scalars['Boolean']['output']>;
  /** The date the waiver was signed */
  waiver_date?: Maybe<Scalars['DateTime']['output']>;
};

export type RegistrationAway_GamesArgs = {
  filter?: InputMaybe<QueryGameInput>;
};

export type RegistrationHome_DatesArgs = {
  filter?: InputMaybe<QueryGameDateTimeInput>;
};

export type RegistrationHome_GamesArgs = {
  filter?: InputMaybe<QueryGameInput>;
};

export type RegistrationRostersArgs = {
  filter?: InputMaybe<QueryRosterEntryInput>;
};

/** Primary League Object */
export type Role = {
  __typename?: 'Role';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role_mapping?: Maybe<Array<RoleMapping>>;
};

/** Primary League Object */
export type RoleRole_MappingArgs = {
  filter?: InputMaybe<QueryRoleMappingInput>;
};

/** Primary League Object */
export type RoleMapping = {
  __typename?: 'RoleMapping';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  principalId: Scalars['String']['output'];
  principalType?: Maybe<Scalars['String']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  role?: Maybe<Role>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  user?: Maybe<User>;
};

/** A rostered player on a team */
export type RosterEntry = {
  __typename?: 'RosterEntry';
  /** Is this player an all star nominee */
  allstarNomination?: Maybe<Scalars['Boolean']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Emergency contact information for the player */
  emergencyContact?: Maybe<EmergencyContact>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  membershipId?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** The player number */
  number?: Maybe<Scalars['Float']['output']>;
  offSeasonPlayer?: Maybe<Scalars['String']['output']>;
  player?: Maybe<Player>;
  /** The player position */
  position?: Maybe<Scalars['String']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  registration?: Maybe<Registration>;
  /** Whether this roster spot waiver has been signed */
  waiver?: Maybe<Scalars['Boolean']['output']>;
  /** The date this waiver was signed */
  waiverdate?: Maybe<Scalars['DateTime']['output']>;
  /** The waiver signer */
  waiversignature?: Maybe<Scalars['String']['output']>;
  yearsOfExperience?: Maybe<Scalars['Float']['output']>;
};

export type Season = {
  __typename?: 'Season';
  age_groups?: Maybe<Array<AgeGroup>>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** League Acronym */
  end?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league: League;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** League Name */
  name: Scalars['String']['output'];
  /** The date where the registration closes */
  registrationClose?: Maybe<Scalars['DateTime']['output']>;
  /** The date where the registration opens */
  registrationOpen?: Maybe<Scalars['DateTime']['output']>;
  registrations?: Maybe<Array<Registration>>;
  settings?: Maybe<Array<SiteSetting>>;
  /** League Description */
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type SeasonAge_GroupsArgs = {
  filter?: InputMaybe<QueryAgeGroupInput>;
};

export type SeasonRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

export type SeasonSettingsArgs = {
  filter?: InputMaybe<QuerySiteSettingInput>;
};

export type SiteSetting = {
  __typename?: 'SiteSetting';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** The property key */
  key: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league: League;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** The base module grouping of the setting */
  module: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  season?: Maybe<Season>;
  /** The property value */
  value: Scalars['String']['output'];
};

export type SnsMessage = {
  __typename?: 'SnsMessage';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['JSON']['output']>;
  message_id?: Maybe<Scalars['String']['output']>;
  message_type?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  subscription_arn?: Maybe<Scalars['String']['output']>;
  topic_arn?: Maybe<Scalars['String']['output']>;
};

export type Sponsor = {
  __typename?: 'Sponsor';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  image?: Maybe<FileMeta>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Main operational object that represents the current state standings */
export type Standings = {
  __typename?: 'Standings';
  away_games_played?: Maybe<Scalars['Float']['output']>;
  away_losses?: Maybe<Scalars['Float']['output']>;
  away_wins?: Maybe<Scalars['Float']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  games_back?: Maybe<Scalars['Float']['output']>;
  games_played?: Maybe<Scalars['Float']['output']>;
  head_to_head?: Maybe<Scalars['Float']['output']>;
  head_to_head_two_only?: Maybe<Scalars['Float']['output']>;
  home_games_played?: Maybe<Scalars['Float']['output']>;
  home_losses?: Maybe<Scalars['Float']['output']>;
  home_wins?: Maybe<Scalars['Float']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  losses?: Maybe<Scalars['Float']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  opponents_opponents_winning_percent?: Maybe<Scalars['Float']['output']>;
  opponents_winning_percent?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  points_allowed?: Maybe<Scalars['Float']['output']>;
  points_allowed_average?: Maybe<Scalars['Float']['output']>;
  points_for?: Maybe<Scalars['Float']['output']>;
  points_for_average?: Maybe<Scalars['Float']['output']>;
  power_ranking?: Maybe<Scalars['Float']['output']>;
  ratings_percentage_index?: Maybe<Scalars['Float']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  registration?: Maybe<Registration>;
  ties?: Maybe<Scalars['Float']['output']>;
  weighted_winning_percent?: Maybe<Scalars['Float']['output']>;
  win_loss_percent?: Maybe<Scalars['Float']['output']>;
  wins?: Maybe<Scalars['Float']['output']>;
};

export type StringQueryFilter = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<Scalars['String']['input']>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type Team = {
  __typename?: 'Team';
  /** @deprecated deprecated */
  color?: Maybe<Scalars['String']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated deprecated */
  description?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Array<Facility>>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  logo?: Maybe<FileMeta>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  manager?: Maybe<User>;
  /** @deprecated deprecated */
  membershipExpiration?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated deprecated */
  membershipLevel?: Maybe<Scalars['String']['output']>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Team Level Name */
  name?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<TeamPosition>>;
  registrations?: Maybe<Array<Registration>>;
  /** @deprecated deprecated */
  urlKey?: Maybe<Scalars['String']['output']>;
};

export type TeamFacilitiesArgs = {
  filter?: InputMaybe<QueryFacilityInput>;
};

export type TeamPositionsArgs = {
  filter?: InputMaybe<QueryTeamPositionInput>;
};

export type TeamRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

export type TeamLevel = {
  __typename?: 'TeamLevel';
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  age_group: AgeGroup;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  divisions?: Maybe<Array<Division>>;
  games?: Maybe<Array<Game>>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Team Level Name */
  name: Scalars['String']['output'];
  registrations?: Maybe<Array<Registration>>;
  /** sort order for a list of levels */
  sortOrder?: Maybe<Scalars['Float']['output']>;
};

export type TeamLevelDivisionsArgs = {
  filter?: InputMaybe<QueryDivisionInput>;
};

export type TeamLevelGamesArgs = {
  filter?: InputMaybe<QueryGameInput>;
};

export type TeamLevelRegistrationsArgs = {
  filter?: InputMaybe<QueryRegistrationInput>;
};

/** Primary League Object */
export type TeamPosition = {
  __typename?: 'TeamPosition';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  positionType?: Maybe<Scalars['String']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team?: Maybe<Team>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  user?: Maybe<User>;
};

export type UpdateAgeGroupInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** whether invitation scheduling is enabled for this age group */
  inviteSchedulingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines if rosters are locked for this age group */
  lockRoster?: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Age group name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether open scheduling is enabled for this age group */
  openSchedulingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** SKU for determining the related purchasable product */
  registrationProduct?: InputMaybe<Scalars['String']['input']>;
  /** Controls whether scheduling is open for this age group */
  schedulingOpen?: InputMaybe<Scalars['Boolean']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateAnnouncementInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** The body of the message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The body of the message */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** The title of the announcement */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCarouselInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  linktext?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDivisionInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailLogInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  response?: InputMaybe<Scalars['JSON']['input']>;
  responseCode?: InputMaybe<Scalars['String']['input']>;
  rosterId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFacilityInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  fieldDescription?: InputMaybe<Scalars['String']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AddressInput>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFileMetaInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGameDateTimeInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  datetime?: InputMaybe<Scalars['DateTime']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  taken?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGameInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Indicator if the away team forfeited */
  awayTeamForfeit?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * A value used to inform the home team that the away team has offered
   * to use their facility to host the game
   */
  awayTeamOffersToHost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Score for the away team */
  awayTeamScore?: InputMaybe<Scalars['Float']['input']>;
  away_team?: InputMaybe<Scalars['String']['input']>;
  /** Indicator to show the date is confirmed */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the confirmation happened */
  confirmedDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  /** The type of match */
  gameType?: InputMaybe<Scalars['String']['input']>;
  /** If this game appears in a sequence, the order number in that sequence */
  game_number?: InputMaybe<Scalars['Float']['input']>;
  /** Indicator if the home team forfeited */
  homeTeamForfeit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Score for the home team */
  homeTeamScore?: InputMaybe<Scalars['Float']['input']>;
  home_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has an outcome */
  isPlayed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  isTied?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should not be used */
  isTournamentMatch?: InputMaybe<Scalars['Boolean']['input']>;
  losing_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  losing_team_id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tracker to see if an official was assigned */
  officialAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** A message to indicate why the game is postponed */
  postponeReason?: InputMaybe<Scalars['String']['input']>;
  /** A flag to indicate tha game is postponed */
  postponed?: InputMaybe<Scalars['Boolean']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  winning_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  winning_team_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeagueInput = {
  /** League Acronym */
  acronym?: InputMaybe<Scalars['String']['input']>;
  /** Role name for age group directors */
  adgRole?: InputMaybe<Scalars['String']['input']>;
  /** Role name for admins */
  adminRole?: InputMaybe<Scalars['String']['input']>;
  /** A tagline to display for a league */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** League Primary Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A list of domains */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Url for this league */
  leagueUrl?: InputMaybe<Scalars['String']['input']>;
  leaguesettings?: InputMaybe<LeagueSettingInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A legacy value for determining which league is by a url part */
  urlKey?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeagueRoleInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLeagueWaiverInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRegistrationInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  /** The preferred division preference */
  divisionPreference?: InputMaybe<Scalars['String']['input']>;
  /** Elite justification */
  eliteJustification?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** League last year response */
  leagueLastYear?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  standings?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  /** Whether the waiver is signed */
  waiver?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the waiver was signed */
  waiver_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateRoleInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleMappingInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  principalId?: InputMaybe<Scalars['String']['input']>;
  principalType?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRosterEntryInput = {
  /** Is this player an all star nominee */
  allstarNomination?: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Emergency contact information for the player */
  emergencyContact?: InputMaybe<EmergencyContactInput>;
  membershipId?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The player number */
  number?: InputMaybe<Scalars['Float']['input']>;
  offSeasonPlayer?: InputMaybe<Scalars['String']['input']>;
  player?: InputMaybe<PlayerInput>;
  /** The player position */
  position?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  /** Whether this roster spot waiver has been signed */
  waiver?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date this waiver was signed */
  waiverdate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The waiver signer */
  waiversignature?: InputMaybe<Scalars['String']['input']>;
  yearsOfExperience?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSeasonInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Acronym */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The date where the registration closes */
  registrationClose?: InputMaybe<Scalars['DateTime']['input']>;
  /** The date where the registration opens */
  registrationOpen?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateSiteSettingInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** The property key */
  key?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The base module grouping of the setting */
  module?: InputMaybe<Scalars['String']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  /** The property value */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSnsMessageInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  message_type?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  subscription_arn?: InputMaybe<Scalars['String']['input']>;
  topic_arn?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSponsorInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStandingsInput = {
  away_games_played?: InputMaybe<Scalars['Float']['input']>;
  away_losses?: InputMaybe<Scalars['Float']['input']>;
  away_wins?: InputMaybe<Scalars['Float']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  games_back?: InputMaybe<Scalars['Float']['input']>;
  games_played?: InputMaybe<Scalars['Float']['input']>;
  head_to_head?: InputMaybe<Scalars['Float']['input']>;
  head_to_head_two_only?: InputMaybe<Scalars['Float']['input']>;
  home_games_played?: InputMaybe<Scalars['Float']['input']>;
  home_losses?: InputMaybe<Scalars['Float']['input']>;
  home_wins?: InputMaybe<Scalars['Float']['input']>;
  losses?: InputMaybe<Scalars['Float']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  opponents_opponents_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  opponents_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  points?: InputMaybe<Scalars['Float']['input']>;
  points_allowed?: InputMaybe<Scalars['Float']['input']>;
  points_allowed_average?: InputMaybe<Scalars['Float']['input']>;
  points_for?: InputMaybe<Scalars['Float']['input']>;
  points_for_average?: InputMaybe<Scalars['Float']['input']>;
  power_ranking?: InputMaybe<Scalars['Float']['input']>;
  ratings_percentage_index?: InputMaybe<Scalars['Float']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  ties?: InputMaybe<Scalars['Float']['input']>;
  weighted_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  win_loss_percent?: InputMaybe<Scalars['Float']['input']>;
  wins?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTeamInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  membershipExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  membershipLevel?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  urlKey?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamLevelInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTeamPositionInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  positionType?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  birthdate?: InputMaybe<Scalars['DateTime']['input']>;
  birthdateValidated?: InputMaybe<Scalars['Boolean']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AddressInput>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  userPhotoId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVanityUrlInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VanityUrlTypeInput>;
};

export type UpdateWaiverInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWaiverSignatureInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  signature_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertAgeGroupInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** whether invitation scheduling is enabled for this age group */
  inviteSchedulingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines if rosters are locked for this age group */
  lockRoster?: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Age group name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether open scheduling is enabled for this age group */
  openSchedulingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** SKU for determining the related purchasable product */
  registrationProduct?: InputMaybe<Scalars['String']['input']>;
  /** Controls whether scheduling is open for this age group */
  schedulingOpen?: InputMaybe<Scalars['Boolean']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type UpsertAnnouncementInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** The body of the message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The body of the message */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  /** The title of the announcement */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertCarouselInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  linktext?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertDivisionInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertEmailLogInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  response?: InputMaybe<Scalars['JSON']['input']>;
  responseCode?: InputMaybe<Scalars['String']['input']>;
  rosterId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertFacilityInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  fieldDescription?: InputMaybe<Scalars['String']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AddressInput>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertFileMetaInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertGameDateTimeInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  datetime?: InputMaybe<Scalars['DateTime']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  taken?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertGameInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Indicator if the away team forfeited */
  awayTeamForfeit?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * A value used to inform the home team that the away team has offered
   * to use their facility to host the game
   */
  awayTeamOffersToHost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Score for the away team */
  awayTeamScore?: InputMaybe<Scalars['Float']['input']>;
  away_team?: InputMaybe<Scalars['String']['input']>;
  /** Indicator to show the date is confirmed */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the confirmation happened */
  confirmedDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  /** The type of match */
  gameType?: InputMaybe<Scalars['String']['input']>;
  /** If this game appears in a sequence, the order number in that sequence */
  game_number?: InputMaybe<Scalars['Float']['input']>;
  /** Indicator if the home team forfeited */
  homeTeamForfeit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Score for the home team */
  homeTeamScore?: InputMaybe<Scalars['Float']['input']>;
  home_team?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has an outcome */
  isPlayed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  isTied?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should not be used */
  isTournamentMatch?: InputMaybe<Scalars['Boolean']['input']>;
  losing_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  losing_team_id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Tracker to see if an official was assigned */
  officialAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** A message to indicate why the game is postponed */
  postponeReason?: InputMaybe<Scalars['String']['input']>;
  /** A flag to indicate tha game is postponed */
  postponed?: InputMaybe<Scalars['Boolean']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  winning_team?: InputMaybe<Scalars['String']['input']>;
  /** Virtual field to determine if the game has a tied outcome */
  winning_team_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertLeagueInput = {
  /** League Acronym */
  acronym?: InputMaybe<Scalars['String']['input']>;
  /** Role name for age group directors */
  adgRole?: InputMaybe<Scalars['String']['input']>;
  /** Role name for admins */
  adminRole?: InputMaybe<Scalars['String']['input']>;
  /** A tagline to display for a league */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** League Primary Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A list of domains */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Url for this league */
  leagueUrl?: InputMaybe<Scalars['String']['input']>;
  leaguesettings?: InputMaybe<LeagueSettingInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** A legacy value for determining which league is by a url part */
  urlKey?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertLeagueRoleInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertLeagueWaiverInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertRegistrationInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  division?: InputMaybe<Scalars['String']['input']>;
  /** The preferred division preference */
  divisionPreference?: InputMaybe<Scalars['String']['input']>;
  /** Elite justification */
  eliteJustification?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** League last year response */
  leagueLastYear?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  standings?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  team_level?: InputMaybe<Scalars['String']['input']>;
  /** Whether the waiver is signed */
  waiver?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the waiver was signed */
  waiver_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpsertRoleInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertRoleMappingInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  principalId?: InputMaybe<Scalars['String']['input']>;
  principalType?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertRosterEntryInput = {
  /** Is this player an all star nominee */
  allstarNomination?: InputMaybe<Scalars['Boolean']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Emergency contact information for the player */
  emergencyContact?: InputMaybe<EmergencyContactInput>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The player number */
  number?: InputMaybe<Scalars['Float']['input']>;
  offSeasonPlayer?: InputMaybe<Scalars['String']['input']>;
  player?: InputMaybe<PlayerInput>;
  /** The player position */
  position?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  /** Whether this roster spot waiver has been signed */
  waiver?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date this waiver was signed */
  waiverdate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The waiver signer */
  waiversignature?: InputMaybe<Scalars['String']['input']>;
  yearsOfExperience?: InputMaybe<Scalars['Float']['input']>;
};

export type UpsertSeasonInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Acronym */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The date where the registration closes */
  registrationClose?: InputMaybe<Scalars['DateTime']['input']>;
  /** The date where the registration opens */
  registrationOpen?: InputMaybe<Scalars['DateTime']['input']>;
  /** League Description */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpsertSiteSettingInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The property key */
  key?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** The base module grouping of the setting */
  module?: InputMaybe<Scalars['String']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  /** The property value */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSnsMessageInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  message_type?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  subscription_arn?: InputMaybe<Scalars['String']['input']>;
  topic_arn?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSponsorInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertStandingsInput = {
  away_games_played?: InputMaybe<Scalars['Float']['input']>;
  away_losses?: InputMaybe<Scalars['Float']['input']>;
  away_wins?: InputMaybe<Scalars['Float']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  games_back?: InputMaybe<Scalars['Float']['input']>;
  games_played?: InputMaybe<Scalars['Float']['input']>;
  head_to_head?: InputMaybe<Scalars['Float']['input']>;
  head_to_head_two_only?: InputMaybe<Scalars['Float']['input']>;
  home_games_played?: InputMaybe<Scalars['Float']['input']>;
  home_losses?: InputMaybe<Scalars['Float']['input']>;
  home_wins?: InputMaybe<Scalars['Float']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  losses?: InputMaybe<Scalars['Float']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  opponents_opponents_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  opponents_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  points?: InputMaybe<Scalars['Float']['input']>;
  points_allowed?: InputMaybe<Scalars['Float']['input']>;
  points_allowed_average?: InputMaybe<Scalars['Float']['input']>;
  points_for?: InputMaybe<Scalars['Float']['input']>;
  points_for_average?: InputMaybe<Scalars['Float']['input']>;
  power_ranking?: InputMaybe<Scalars['Float']['input']>;
  ratings_percentage_index?: InputMaybe<Scalars['Float']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  ties?: InputMaybe<Scalars['Float']['input']>;
  weighted_winning_percent?: InputMaybe<Scalars['Float']['input']>;
  win_loss_percent?: InputMaybe<Scalars['Float']['input']>;
  wins?: InputMaybe<Scalars['Float']['input']>;
};

export type UpsertTeamInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  membershipExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  membershipLevel?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  urlKey?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertTeamLevelInput = {
  age_group?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  /** Team Level Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** sort order for a list of levels */
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type UpsertTeamPositionInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  positionType?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertUserInput = {
  birthdate?: InputMaybe<Scalars['DateTime']['input']>;
  birthdateValidated?: InputMaybe<Scalars['Boolean']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AddressInput>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  userPhotoId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertVanityUrlInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  league?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VanityUrlTypeInput>;
};

export type UpsertWaiverInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertWaiverSignatureInput = {
  /** Standard created timestamp */
  created?: InputMaybe<Scalars['DateTime']['input']>;
  /** Primary document identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Standard modified timestamp */
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  signature_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
  waiver?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  birthdate?: Maybe<Scalars['DateTime']['output']>;
  birthdateValidated?: Maybe<Scalars['Boolean']['output']>;
  cellPhone?: Maybe<Scalars['String']['output']>;
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  league_roles?: Maybe<Array<LeagueRole>>;
  location?: Maybe<Address>;
  managed_teams?: Maybe<Array<Team>>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<TeamPosition>>;
  role_mappings?: Maybe<Array<RoleMapping>>;
  stripeId?: Maybe<Scalars['String']['output']>;
  userPhotoId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
  waiver_signatures?: Maybe<Array<WaiverSignature>>;
};

export type UserLeague_RolesArgs = {
  filter?: InputMaybe<QueryLeagueRoleInput>;
};

export type UserManaged_TeamsArgs = {
  filter?: InputMaybe<QueryTeamInput>;
};

export type UserPositionsArgs = {
  filter?: InputMaybe<QueryTeamPositionInput>;
};

export type UserRole_MappingsArgs = {
  filter?: InputMaybe<QueryRoleMappingInput>;
};

export type UserWaiver_SignaturesArgs = {
  filter?: InputMaybe<QueryWaiverSignatureInput>;
};

/** Primary League Object */
export type VanityUrl = {
  __typename?: 'VanityUrl';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  league?: Maybe<League>;
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  team?: Maybe<Team>;
  type: VanityUrlType;
};

export type VanityUrlType = {
  __typename?: 'VanityUrlType';
  id: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type VanityUrlTypeInput = {
  id: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

/** Primary League Object */
export type Waiver = {
  __typename?: 'Waiver';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** Primary League Object */
export type WaiverSignature = {
  __typename?: 'WaiverSignature';
  /** Standard created timestamp */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Primary document identifier */
  id: Scalars['String']['output'];
  /** Standard modified timestamp */
  modified?: Maybe<Scalars['DateTime']['output']>;
  signature_date?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  user?: Maybe<User>;
  /** Resolver for a one to many relationship.  This utilizes a data loader to minimize */
  waiver?: Maybe<Waiver>;
};

export type StandingsQueryVariables = Exact<{
  age_group: Scalars['String']['input'];
}>;

export type StandingsQuery = {
  __typename?: 'Query';
  age_group: {
    __typename?: 'AgeGroup';
    team_levels?: Array<{
      __typename?: 'TeamLevel';
      id: string;
      name: string;
      sortOrder?: number | null;
      divisions?: Array<{
        __typename?: 'Division';
        id: string;
        name: string;
        sortOrder?: number | null;
        registrations?: Array<{
          __typename?: 'Registration';
          id: string;
          team?: { __typename?: 'Team'; name?: string | null; urlKey?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
          standings?: {
            __typename?: 'Standings';
            opponents_winning_percent?: number | null;
            opponents_opponents_winning_percent?: number | null;
            ratings_percentage_index?: number | null;
            power_ranking?: number | null;
            wins?: number | null;
            losses?: number | null;
            games_played?: number | null;
            win_loss_percent?: number | null;
            weighted_winning_percent?: number | null;
            points?: number | null;
            points_for?: number | null;
            points_for_average?: number | null;
            points_allowed?: number | null;
            points_allowed_average?: number | null;
            home_wins?: number | null;
            home_losses?: number | null;
            home_games_played?: number | null;
            away_wins?: number | null;
            away_losses?: number | null;
            away_games_played?: number | null;
            ties?: number | null;
          } | null;
        }> | null;
      }> | null;
    }> | null;
  };
};

export type LoadWaiverTextQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
}>;

export type LoadWaiverTextQuery = {
  __typename?: 'Query';
  league: { __typename?: 'League'; leaguesettings?: { __typename?: 'LeagueSetting'; waiverText?: string | null } | null };
};

export type LoadRosterWaiverStateQueryVariables = Exact<{
  rosterId: Scalars['String']['input'];
}>;

export type LoadRosterWaiverStateQuery = { __typename?: 'Query'; roster_entry: { __typename?: 'RosterEntry'; id: string; waiver?: boolean | null } };

export type SignRosterWaiverMutationVariables = Exact<{
  rosterId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
}>;

export type SignRosterWaiverMutation = {
  __typename?: 'Mutation';
  sign_waiver?: { __typename?: 'RosterEntry'; id: string; waiver?: boolean | null; waiverdate?: any | null; waiversignature?: string | null } | null;
};

export type LoadUserWaiversQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type LoadUserWaiversQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; waiver_signatures?: Array<{ __typename?: 'WaiverSignature'; waiver?: { __typename?: 'Waiver'; id: string } | null }> | null };
};

export type SignWaiverMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  upsert: UpsertWaiverSignatureInput;
}>;

export type SignWaiverMutation = { __typename?: 'Mutation'; upsert_waiver_signature_to_user: { __typename?: 'WaiverSignature'; id: string } };

export type LoadAnnouncementsQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
}>;

export type LoadAnnouncementsQuery = {
  __typename?: 'Query';
  announcements: Array<{
    __typename?: 'Announcement';
    id: string;
    title?: string | null;
    message?: string | null;
    created?: any | null;
    modified?: any | null;
    sortOrder?: number | null;
  }>;
};

export type UpsertMessageMutationVariables = Exact<{
  upsert: UpsertAnnouncementInput;
}>;

export type UpsertMessageMutation = {
  __typename?: 'Mutation';
  upsert_announcement: { __typename?: 'Announcement'; id: string; title?: string | null; message?: string | null; created?: any | null; modified?: any | null };
};

export type UpsertManyMessagesMutationVariables = Exact<{
  update: Array<UpsertAnnouncementInput> | UpsertAnnouncementInput;
}>;

export type UpsertManyMessagesMutation = {
  __typename?: 'Mutation';
  update_many_announcement: Array<{
    __typename?: 'Announcement';
    id: string;
    title?: string | null;
    message?: string | null;
    created?: any | null;
    modified?: any | null;
  }>;
};

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteMessageMutation = { __typename?: 'Mutation'; remove_announcement: { __typename?: 'Announcement'; id: string } };

export type UpsertFileMutationVariables = Exact<{
  fileMeta: UpsertFileMetaInput;
}>;

export type UpsertFileMutation = {
  __typename?: 'Mutation';
  upsert_file_meta: { __typename?: 'FileMeta'; id: string; name?: string | null; url?: string | null };
};

export type LoadSponsorsQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
}>;

export type LoadSponsorsQuery = {
  __typename?: 'Query';
  sponsors: Array<{
    __typename?: 'Sponsor';
    id: string;
    url?: string | null;
    name?: string | null;
    sortOrder?: number | null;
    image?: { __typename?: 'FileMeta'; url?: string | null } | null;
  }>;
};

export type DeleteSponsorMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteSponsorMutation = { __typename?: 'Mutation'; remove_sponsor: { __typename?: 'Sponsor'; id: string } };

export type UpsertSponsorMutationVariables = Exact<{
  upsert: UpsertSponsorInput;
}>;

export type UpsertSponsorMutation = {
  __typename?: 'Mutation';
  upsert_sponsor: {
    __typename?: 'Sponsor';
    id: string;
    url?: string | null;
    name?: string | null;
    sortOrder?: number | null;
    image?: { __typename?: 'FileMeta'; url?: string | null } | null;
  };
};

export type UpsertSponsorsMutationVariables = Exact<{
  sponsors: Array<UpsertSponsorInput> | UpsertSponsorInput;
}>;

export type UpsertSponsorsMutation = {
  __typename?: 'Mutation';
  update_many_sponsor: Array<{
    __typename?: 'Sponsor';
    id: string;
    url?: string | null;
    name?: string | null;
    sortOrder?: number | null;
    image?: { __typename?: 'FileMeta'; url?: string | null } | null;
  }>;
};

export type LoadTeamLevelDivisionsQueryVariables = Exact<{
  teamLevelId: Scalars['String']['input'];
}>;

export type LoadTeamLevelDivisionsQuery = {
  __typename?: 'Query';
  divisions: Array<{ __typename?: 'Division'; id: string; name: string; sortOrder?: number | null }>;
};

export type LoadTeamLevelRegistrationsQueryVariables = Exact<{
  teamLevelId: Scalars['String']['input'];
}>;

export type LoadTeamLevelRegistrationsQuery = {
  __typename?: 'Query';
  registrations: Array<{
    __typename?: 'Registration';
    id: string;
    team?: { __typename?: 'Team'; id: string; name?: string | null } | null;
    division?: { __typename?: 'Division'; id: string; name: string } | null;
  }>;
};

export type AssignRegistrationDivisionMutationVariables = Exact<{
  registrationId: Scalars['String']['input'];
  update: UpdateRegistrationInput;
}>;

export type AssignRegistrationDivisionMutation = {
  __typename?: 'Mutation';
  update_registration: { __typename?: 'Registration'; id: string; division?: { __typename?: 'Division'; id: string; name: string } | null };
};

export type LoadAgeGroupGamesQueryVariables = Exact<{
  ageGroupId: Scalars['String']['input'];
}>;

export type LoadAgeGroupGamesQuery = {
  __typename?: 'Query';
  age_group: {
    __typename?: 'AgeGroup';
    id: string;
    registrations?: Array<{
      __typename?: 'Registration';
      id: string;
      team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
      home_dates?: Array<{
        __typename?: 'GameDateTime';
        id: string;
        date?: string | null;
        datetime?: any | null;
        facility?: { __typename?: 'Facility'; id: string; name: string } | null;
      }> | null;
      home_games?: Array<{
        __typename?: 'Game';
        id: string;
        date?: {
          __typename?: 'GameDateTime';
          id: string;
          date?: string | null;
          datetime?: any | null;
          facility?: { __typename?: 'Facility'; id: string; name: string } | null;
        } | null;
        home_team?: {
          __typename?: 'Registration';
          id: string;
          team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
        } | null;
        away_team?: {
          __typename?: 'Registration';
          id: string;
          team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type LoadDivisionGamesQueryVariables = Exact<{
  divisionId: Scalars['String']['input'];
}>;

export type LoadDivisionGamesQuery = {
  __typename?: 'Query';
  division: {
    __typename?: 'Division';
    id: string;
    registrations?: Array<{
      __typename?: 'Registration';
      id: string;
      team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
      home_dates?: Array<{
        __typename?: 'GameDateTime';
        id: string;
        date?: string | null;
        datetime?: any | null;
        facility?: { __typename?: 'Facility'; id: string; name: string } | null;
      }> | null;
      home_games?: Array<{
        __typename?: 'Game';
        id: string;
        date?: {
          __typename?: 'GameDateTime';
          id: string;
          date?: string | null;
          datetime?: any | null;
          facility?: { __typename?: 'Facility'; id: string; name: string } | null;
        } | null;
        home_team?: {
          __typename?: 'Registration';
          id: string;
          team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
        } | null;
        away_team?: {
          __typename?: 'Registration';
          id: string;
          team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type LoadRegistrationsQueryVariables = Exact<{
  ageGroupId: Scalars['String']['input'];
  teamLevelId: Scalars['String']['input'];
}>;

export type LoadRegistrationsQuery = {
  __typename?: 'Query';
  registrations: Array<{
    __typename?: 'Registration';
    id: string;
    team?: {
      __typename?: 'Team';
      id: string;
      name?: string | null;
      urlKey?: string | null;
      logo?: { __typename?: 'FileMeta'; id: string; url?: string | null } | null;
    } | null;
    age_group?: { __typename?: 'AgeGroup'; id: string; name: string } | null;
    team_level?: { __typename?: 'TeamLevel'; id: string; name: string } | null;
    division?: { __typename?: 'Division'; id: string; name: string } | null;
  }>;
};

export type LoadRegistrationQueryVariables = Exact<{
  registrationId: Scalars['String']['input'];
}>;

export type LoadRegistrationQuery = {
  __typename?: 'Query';
  registration: {
    __typename?: 'Registration';
    id: string;
    waiver?: boolean | null;
    waiver_date?: any | null;
    team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; id: string; url?: string | null } | null } | null;
    age_group?: { __typename?: 'AgeGroup'; id: string; name: string } | null;
    team_level?: { __typename?: 'TeamLevel'; id: string; name: string } | null;
    division?: { __typename?: 'Division'; id: string; name: string } | null;
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type UpsertRegistrationMutationVariables = Exact<{
  upsert: UpsertRegistrationInput;
}>;

export type UpsertRegistrationMutation = {
  __typename?: 'Mutation';
  upsert_registration: {
    __typename?: 'Registration';
    id: string;
    age_group?: { __typename?: 'AgeGroup'; id: string; name: string } | null;
    team_level?: { __typename?: 'TeamLevel'; id: string; name: string } | null;
    division?: { __typename?: 'Division'; id: string; name: string } | null;
  };
};

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['String']['input'];
  update: UpdateTeamInput;
}>;

export type UpdateTeamMutation = { __typename?: 'Mutation'; update_team: { __typename?: 'Team'; id: string; name?: string | null } };

export type RemoveRegistrationMutationVariables = Exact<{
  registrationId: Scalars['String']['input'];
}>;

export type RemoveRegistrationMutation = { __typename?: 'Mutation'; remove_registration: { __typename?: 'Registration'; id: string } };

export type LoadTeamsForSchedulingQueryVariables = Exact<{
  ageGroupId: Scalars['String']['input'];
  teamLevelId: Scalars['String']['input'];
}>;

export type LoadTeamsForSchedulingQuery = {
  __typename?: 'Query';
  registrations: Array<{
    __typename?: 'Registration';
    id: string;
    team?: { __typename?: 'Team'; id: string; name?: string | null } | null;
    division?: { __typename?: 'Division'; id: string; name: string } | null;
    age_group?: { __typename?: 'AgeGroup'; id: string; name: string } | null;
    team_level?: { __typename?: 'TeamLevel'; id: string; name: string } | null;
    away_games?: Array<{
      __typename?: 'Game';
      id: string;
      home_team?: { __typename?: 'Registration'; id: string } | null;
      away_team?: { __typename?: 'Registration'; id: string } | null;
    }> | null;
    home_games?: Array<{
      __typename?: 'Game';
      home_team?: { __typename?: 'Registration'; id: string } | null;
      away_team?: { __typename?: 'Registration'; id: string } | null;
    }> | null;
  }>;
};

export type LoadAgeGroupSchedulingQueryVariables = Exact<{
  ageGroupId: Scalars['String']['input'];
}>;

export type LoadAgeGroupSchedulingQuery = {
  __typename?: 'Query';
  age_group: {
    __typename?: 'AgeGroup';
    id: string;
    schedulingOpen?: boolean | null;
    inviteSchedulingEnabled?: boolean | null;
    openSchedulingEnabled?: boolean | null;
  };
};

export type UpsertAgeGroupSchedulingMutationVariables = Exact<{
  upsertAgeGroup: UpsertAgeGroupInput;
}>;

export type UpsertAgeGroupSchedulingMutation = {
  __typename?: 'Mutation';
  upsert_age_group: {
    __typename?: 'AgeGroup';
    id: string;
    schedulingOpen?: boolean | null;
    inviteSchedulingEnabled?: boolean | null;
    openSchedulingEnabled?: boolean | null;
  };
};

export type OfficialsReportQueryVariables = Exact<{
  seasonId: Scalars['String']['input'];
}>;

export type OfficialsReportQuery = {
  __typename?: 'Query';
  games: Array<{
    __typename?: 'Game';
    id: string;
    home_team?: { __typename?: 'Registration'; id: string; team?: { __typename?: 'Team'; name?: string | null } | null } | null;
    away_team?: { __typename?: 'Registration'; team?: { __typename?: 'Team'; name?: string | null } | null } | null;
    date?: {
      __typename?: 'GameDateTime';
      date?: string | null;
      datetime?: any | null;
      facility?: {
        __typename?: 'Facility';
        name: string;
        location?: {
          __typename?: 'Address';
          city?: string | null;
          geocoding?: any | null;
          state?: string | null;
          street?: string | null;
          zip?: string | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type GetRolesQueryVariables = Exact<{
  roles: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetRolesQuery = { __typename?: 'Query'; roles: Array<{ __typename?: 'Role'; id: string; name?: string | null }> };

export type CreateRoleMutationVariables = Exact<{
  role: CreateRoleInput;
}>;

export type CreateRoleMutation = { __typename?: 'Mutation'; create_role: { __typename?: 'Role'; id: string } };

export type GetLeagueUsersQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
}>;

export type GetLeagueUsersQuery = {
  __typename?: 'Query';
  league_roles: Array<{
    __typename?: 'LeagueRole';
    id: string;
    user?: { __typename?: 'User'; id: string; email: string; firstName?: string | null; lastName?: string | null } | null;
  }>;
};

export type FindUserQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type FindUserQuery = {
  __typename?: 'Query';
  users: Array<{ __typename?: 'User'; id: string; email: string; firstName?: string | null; lastName?: string | null }>;
};

export type AddLeagueUserRoleMutationVariables = Exact<{
  leagueId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type AddLeagueUserRoleMutation = { __typename?: 'Mutation'; add_league_role_to_league: { __typename?: 'LeagueRole'; id: string } };

export type RemoveLeagueUserRoleMutationVariables = Exact<{
  leagueId: Scalars['String']['input'];
  leagueRoleId: Scalars['String']['input'];
}>;

export type RemoveLeagueUserRoleMutation = { __typename?: 'Mutation'; remove_league_role_from_league: { __typename?: 'LeagueRole'; id: string } };

export type MyTeamsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type MyTeamsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    managed_teams?: Array<{
      __typename?: 'Team';
      id: string;
      name?: string | null;
      logo?: { __typename?: 'FileMeta'; url?: string | null } | null;
      registrations?: Array<{ __typename?: 'Registration'; id: string; season: { __typename?: 'Season'; id: string; name: string } }> | null;
    }> | null;
    positions?: Array<{
      __typename?: 'TeamPosition';
      positionType?: string | null;
      team?: {
        __typename?: 'Team';
        id: string;
        name?: string | null;
        logo?: { __typename?: 'FileMeta'; url?: string | null } | null;
        registrations?: Array<{ __typename?: 'Registration'; id: string; season: { __typename?: 'Season'; id: string; name: string } }> | null;
      } | null;
    }> | null;
  };
};

export type CreateRegistrationMutationVariables = Exact<{
  registration: CreateRegistrationInput;
  manager: UpsertUserInput;
  team: UpsertTeamInput;
  admin?: InputMaybe<UpsertUserInput>;
}>;

export type CreateRegistrationMutation = {
  __typename?: 'Mutation';
  create_season_registration?: { __typename?: 'Registration'; id: string; team?: { __typename?: 'Team'; id: string; urlKey?: string | null } | null } | null;
};

export type RegistrationDashboardQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RegistrationDashboardQuery = {
  __typename?: 'Query';
  age_group: {
    __typename?: 'AgeGroup';
    id: string;
    team_levels?: Array<{
      __typename?: 'TeamLevel';
      id: string;
      name: string;
      registrations?: Array<{
        __typename?: 'Registration';
        id: string;
        team?: { __typename?: 'Team'; id: string; name?: string | null; urlKey?: string | null } | null;
        home_dates?: Array<{ __typename?: 'GameDateTime'; id: string }> | null;
        away_games?: Array<{ __typename?: 'Game'; id: string; isPlayed: boolean; date?: { __typename?: 'GameDateTime'; id: string } | null }> | null;
        home_games?: Array<{ __typename?: 'Game'; id: string; isPlayed: boolean; date?: { __typename?: 'GameDateTime'; id: string } | null }> | null;
        rosters?: Array<{ __typename?: 'RosterEntry'; id: string; waiver?: boolean | null }> | null;
      }> | null;
    }> | null;
  };
};

export type LoadCarouselsQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
}>;

export type LoadCarouselsQuery = {
  __typename?: 'Query';
  carousels: Array<{
    __typename?: 'Carousel';
    id: string;
    link?: string | null;
    linktext?: string | null;
    sortOrder?: number | null;
    text?: string | null;
    title?: string | null;
    image?: { __typename?: 'FileMeta'; id: string; url?: string | null; name?: string | null } | null;
    league?: { __typename?: 'League'; id: string } | null;
  }>;
};

export type UpsertCarouselMutationVariables = Exact<{
  upsert: UpsertCarouselInput;
  leagueId: Scalars['String']['input'];
}>;

export type UpsertCarouselMutation = {
  __typename?: 'Mutation';
  upsert_carousel_to_league: {
    __typename?: 'Carousel';
    id: string;
    text?: string | null;
    title?: string | null;
    sortOrder?: number | null;
    image?: { __typename?: 'FileMeta'; id: string; url?: string | null } | null;
  };
};

export type UpsertManyCarouselsMutationVariables = Exact<{
  update: Array<UpsertCarouselInput> | UpsertCarouselInput;
  leagueId: Scalars['String']['input'];
}>;

export type UpsertManyCarouselsMutation = {
  __typename?: 'Mutation';
  upsert_many_carousel_to_league: Array<{ __typename?: 'Carousel'; id: string; sortOrder?: number | null }>;
};

export type DeleteCarouselMutationVariables = Exact<{
  id: Scalars['String']['input'];
  leagueId: Scalars['String']['input'];
}>;

export type DeleteCarouselMutation = { __typename?: 'Mutation'; remove_carousel_from_league: { __typename?: 'Carousel'; id: string } };

export type LoadWaiversQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
}>;

export type LoadWaiversQuery = {
  __typename?: 'Query';
  league_waivers: Array<{
    __typename?: 'LeagueWaiver';
    id: string;
    waiver?: { __typename?: 'Waiver'; id: string; name?: string | null; text?: string | null; required?: boolean | null } | null;
  }>;
};

export type UpsertWaiverMutationVariables = Exact<{
  upsert: UpsertWaiverInput;
}>;

export type UpsertWaiverMutation = {
  __typename?: 'Mutation';
  upsert_waiver: { __typename?: 'Waiver'; id: string; name?: string | null; text?: string | null; required?: boolean | null };
};

export type UpsertLeagueWaiverMutationVariables = Exact<{
  upsert: UpsertLeagueWaiverInput;
  leagueId: Scalars['String']['input'];
}>;

export type UpsertLeagueWaiverMutation = { __typename?: 'Mutation'; upsert_league_waiver_to_league: { __typename?: 'LeagueWaiver'; id: string } };

export type DeleteLeagueWaiverMutationVariables = Exact<{
  id: Scalars['String']['input'];
  leagueId: Scalars['String']['input'];
}>;

export type DeleteLeagueWaiverMutation = { __typename?: 'Mutation'; remove_league_waiver_from_league: { __typename?: 'LeagueWaiver'; id: string } };

export type UpsertLeagueMutationVariables = Exact<{
  update: UpsertLeagueInput;
}>;

export type UpsertLeagueMutation = { __typename?: 'Mutation'; upsert_league: { __typename?: 'League'; id: string; name: string; description?: string | null } };

export type UpdateSettingMutationVariables = Exact<{
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type UpdateSettingMutation = { __typename?: 'Mutation'; update_site_setting: { __typename?: 'SiteSetting'; id: string; key: string; value: string } };

export type CreateSettingMutationVariables = Exact<{
  leagueId: Scalars['String']['input'];
  module: Scalars['String']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type CreateSettingMutation = {
  __typename?: 'Mutation';
  add_site_setting_to_league: { __typename?: 'SiteSetting'; id: string; module: string; key: string; value: string };
};

export type UpdateManySettingsMutationVariables = Exact<{
  settings: Array<UpsertSiteSettingInput> | UpsertSiteSettingInput;
}>;

export type UpdateManySettingsMutation = {
  __typename?: 'Mutation';
  update_many_site_setting: Array<{
    __typename?: 'SiteSetting';
    id: string;
    module: string;
    key: string;
    value: string;
    league: { __typename?: 'League'; id: string };
    season?: { __typename?: 'Season'; id: string } | null;
  }>;
};

export type LoadAgeGroupsQueryVariables = Exact<{
  seasonId: Scalars['String']['input'];
}>;

export type LoadAgeGroupsQuery = {
  __typename?: 'Query';
  age_groups: Array<{
    __typename?: 'AgeGroup';
    id: string;
    name: string;
    description?: string | null;
    sortOrder?: number | null;
    created?: any | null;
    modified?: any | null;
    registrationProduct?: string | null;
  }>;
};

export type LoadAgeGroupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadAgeGroupQuery = {
  __typename?: 'Query';
  age_group: {
    __typename?: 'AgeGroup';
    id: string;
    name: string;
    description?: string | null;
    sortOrder?: number | null;
    created?: any | null;
    modified?: any | null;
    registrationProduct?: string | null;
  };
};

export type UpsertAgeGroupMutationVariables = Exact<{
  upsert: UpsertAgeGroupInput;
}>;

export type UpsertAgeGroupMutation = {
  __typename?: 'Mutation';
  upsert_age_group: {
    __typename?: 'AgeGroup';
    id: string;
    name: string;
    description?: string | null;
    sortOrder?: number | null;
    created?: any | null;
    modified?: any | null;
    registrationProduct?: string | null;
  };
};

export type UpsertManyAgeGroupsMutationVariables = Exact<{
  update: Array<UpsertAgeGroupInput> | UpsertAgeGroupInput;
}>;

export type UpsertManyAgeGroupsMutation = {
  __typename?: 'Mutation';
  update_many_age_group: Array<{ __typename?: 'AgeGroup'; id: string; sortOrder?: number | null }>;
};

export type DeleteAgeGroupMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteAgeGroupMutation = {
  __typename?: 'Mutation';
  remove_age_group: {
    __typename?: 'AgeGroup';
    id: string;
    name: string;
    description?: string | null;
    sortOrder?: number | null;
    created?: any | null;
    modified?: any | null;
    registrationProduct?: string | null;
  };
};

export type DivisionTreeQueryVariables = Exact<{
  seasonId: Scalars['String']['input'];
}>;

export type DivisionTreeQuery = {
  __typename?: 'Query';
  season: {
    __typename?: 'Season';
    age_groups?: Array<{
      __typename?: 'AgeGroup';
      id: string;
      name: string;
      sortOrder?: number | null;
      team_levels?: Array<{
        __typename?: 'TeamLevel';
        id: string;
        name: string;
        sortOrder?: number | null;
        divisions?: Array<{ __typename?: 'Division'; id: string; name: string; sortOrder?: number | null }> | null;
      }> | null;
    }> | null;
  };
};

export type LoadDivisionsQueryVariables = Exact<{
  teamLevelId: Scalars['String']['input'];
}>;

export type LoadDivisionsQuery = {
  __typename?: 'Query';
  divisions: Array<{ __typename?: 'Division'; id: string; name: string; created?: any | null; modified?: any | null; sortOrder?: number | null }>;
};

export type UpsertDivisionMutationVariables = Exact<{
  upsert: UpsertDivisionInput;
}>;

export type UpsertDivisionMutation = {
  __typename?: 'Mutation';
  upsert_division: { __typename?: 'Division'; id: string; name: string; sortOrder?: number | null; created?: any | null; modified?: any | null };
};

export type UpsertManyDivisionsMutationVariables = Exact<{
  update: Array<UpsertDivisionInput> | UpsertDivisionInput;
}>;

export type UpsertManyDivisionsMutation = {
  __typename?: 'Mutation';
  update_many_division: Array<{ __typename?: 'Division'; id: string; sortOrder?: number | null }>;
};

export type DeleteDivisionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteDivisionMutation = {
  __typename?: 'Mutation';
  remove_division: { __typename?: 'Division'; id: string; name: string; sortOrder?: number | null; created?: any | null; modified?: any | null };
};

export type LoadSeasonQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadSeasonQuery = {
  __typename?: 'Query';
  season: {
    __typename?: 'Season';
    id: string;
    name: string;
    start?: any | null;
    end?: any | null;
    registrationOpen?: any | null;
    registrationClose?: any | null;
    created?: any | null;
    modified?: any | null;
  };
};

export type UpsertSeasonMutationVariables = Exact<{
  upsert: UpsertSeasonInput;
}>;

export type UpsertSeasonMutation = {
  __typename?: 'Mutation';
  upsert_season: { __typename?: 'Season'; id: string; name: string; start?: any | null; end?: any | null; created?: any | null; modified?: any | null };
};

export type LoadTeamLevelsQueryVariables = Exact<{
  ageGroupId: Scalars['String']['input'];
}>;

export type LoadTeamLevelsQuery = {
  __typename?: 'Query';
  team_levels: Array<{ __typename?: 'TeamLevel'; id: string; name: string; sortOrder?: number | null }>;
};

export type LoadTeamLevelQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadTeamLevelQuery = {
  __typename?: 'Query';
  team_level: { __typename?: 'TeamLevel'; id: string; name: string; sortOrder?: number | null; created?: any | null; modified?: any | null };
};

export type UpsertTeamLevelMutationVariables = Exact<{
  upsert: UpsertTeamLevelInput;
}>;

export type UpsertTeamLevelMutation = { __typename?: 'Mutation'; upsert_team_level: { __typename?: 'TeamLevel'; id: string; name: string } };

export type UpsertManyTeamLevelsMutationVariables = Exact<{
  update: Array<UpsertTeamLevelInput> | UpsertTeamLevelInput;
}>;

export type UpsertManyTeamLevelsMutation = {
  __typename?: 'Mutation';
  update_many_team_level: Array<{ __typename?: 'TeamLevel'; id: string; sortOrder?: number | null }>;
};

export type DeleteTeamLevelMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteTeamLevelMutation = { __typename?: 'Mutation'; remove_team_level: { __typename?: 'TeamLevel'; id: string; name: string } };

export type AgeGroupTeamsByTeamLevelQueryVariables = Exact<{
  age_group: Scalars['String']['input'];
}>;

export type AgeGroupTeamsByTeamLevelQuery = {
  __typename?: 'Query';
  age_group: {
    __typename?: 'AgeGroup';
    id: string;
    name: string;
    team_levels?: Array<{
      __typename?: 'TeamLevel';
      id: string;
      name: string;
      registrations?: Array<{
        __typename?: 'Registration';
        id: string;
        team?: {
          __typename?: 'Team';
          id: string;
          name?: string | null;
          urlKey?: string | null;
          logo?: { __typename?: 'FileMeta'; url?: string | null } | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type ResolveUrlQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;

export type ResolveUrlQuery = {
  __typename?: 'Query';
  vanity_url_resolve?: {
    __typename?: 'VanityUrl';
    league?: {
      __typename?: 'League';
      id: string;
      color?: string | null;
      name: string;
      description?: string | null;
      acronym?: string | null;
      logo?: { __typename?: 'FileMeta'; url?: string | null; id: string } | null;
      announcements?: Array<{ __typename?: 'Announcement'; id: string; title?: string | null; message?: string | null; sortOrder?: number | null }> | null;
      carousels?: Array<{
        __typename?: 'Carousel';
        id: string;
        link?: string | null;
        linktext?: string | null;
        text?: string | null;
        title?: string | null;
        image?: { __typename?: 'FileMeta'; url?: string | null } | null;
      }> | null;
      sponsors?: Array<{
        __typename?: 'Sponsor';
        id: string;
        sortOrder?: number | null;
        name?: string | null;
        url?: string | null;
        image?: { __typename?: 'FileMeta'; url?: string | null } | null;
      }> | null;
      seasons?: Array<{
        __typename?: 'Season';
        id: string;
        name: string;
        start?: any | null;
        end?: any | null;
        registrationOpen?: any | null;
        registrationClose?: any | null;
        age_groups?: Array<{
          __typename?: 'AgeGroup';
          id: string;
          name: string;
          sortOrder?: number | null;
          team_levels?: Array<{
            __typename?: 'TeamLevel';
            id: string;
            name: string;
            sortOrder?: number | null;
            divisions?: Array<{ __typename?: 'Division'; id: string; name: string; sortOrder?: number | null }> | null;
          }> | null;
        }> | null;
        settings?: Array<{
          __typename?: 'SiteSetting';
          id: string;
          module: string;
          key: string;
          value: string;
          season?: { __typename?: 'Season'; id: string } | null;
        }> | null;
      }> | null;
      settings?: Array<{
        __typename?: 'SiteSetting';
        id: string;
        module: string;
        key: string;
        value: string;
        league: { __typename?: 'League'; id: string };
      }> | null;
      waivers?: Array<{
        __typename?: 'LeagueWaiver';
        id: string;
        waiver?: { __typename?: 'Waiver'; id: string; required?: boolean | null; name?: string | null; text?: string | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type RefreshFacilitiesQueryVariables = Exact<{
  teamId: Scalars['String']['input'];
}>;

export type RefreshFacilitiesQuery = {
  __typename?: 'Query';
  facilities: Array<{
    __typename?: 'Facility';
    id: string;
    fieldDescription?: string | null;
    fieldName?: string | null;
    name: string;
    sortOrder?: number | null;
    location?: {
      __typename?: 'Address';
      city?: string | null;
      country?: string | null;
      state?: string | null;
      street?: string | null;
      zip?: string | null;
      geocoding?: any | null;
    } | null;
  }>;
};

export type DeleteFacilityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  facility: Scalars['String']['input'];
}>;

export type DeleteFacilityMutation = { __typename?: 'Mutation'; remove_facility_from_team: { __typename?: 'Facility'; id: string } };

export type UpsertFacilityMutationVariables = Exact<{
  upsert: UpsertFacilityInput;
  team_id: Scalars['String']['input'];
}>;

export type UpsertFacilityMutation = { __typename?: 'Mutation'; upsert_facility_to_team: { __typename?: 'Facility'; id: string; sortOrder?: number | null } };

export type UpsertManyFacilitiesMutationVariables = Exact<{
  upsert: Array<UpsertFacilityInput> | UpsertFacilityInput;
  team_id: Scalars['String']['input'];
}>;

export type UpsertManyFacilitiesMutation = {
  __typename?: 'Mutation';
  upsert_many_facility_to_team: Array<{ __typename?: 'Facility'; id: string; sortOrder?: number | null }>;
};

export type RefreshGameQueryVariables = Exact<{
  gameId: Scalars['String']['input'];
}>;

export type RefreshGameQuery = {
  __typename?: 'Query';
  game: {
    __typename?: 'Game';
    id: string;
    winning_team_id?: string | null;
    losing_team_id?: string | null;
    awayTeamForfeit?: boolean | null;
    awayTeamScore?: number | null;
    awayTeamOffersToHost?: boolean | null;
    homeTeamForfeit?: boolean | null;
    homeTeamScore?: number | null;
    gameType?: string | null;
    game_number?: number | null;
    date?: {
      __typename?: 'GameDateTime';
      id: string;
      date?: string | null;
      datetime?: any | null;
      facility?: {
        __typename?: 'Facility';
        id: string;
        fieldName?: string | null;
        fieldDescription?: string | null;
        name: string;
        location?: {
          __typename?: 'Address';
          city?: string | null;
          country?: string | null;
          geocoding?: any | null;
          state?: string | null;
          street?: string | null;
          zip?: string | null;
        } | null;
      } | null;
    } | null;
    home_team?: {
      __typename?: 'Registration';
      id: string;
      team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
    } | null;
    away_team?: {
      __typename?: 'Registration';
      id: string;
      team?: { __typename?: 'Team'; id: string; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
    } | null;
  };
};

export type UpsertGameMutationVariables = Exact<{
  upsert: UpsertGameInput;
}>;

export type UpsertGameMutation = { __typename?: 'Mutation'; upsert_game: { __typename?: 'Game'; id: string } };

export type RefreshHomeDatesQueryVariables = Exact<{
  registrationId: Scalars['String']['input'];
}>;

export type RefreshHomeDatesQuery = {
  __typename?: 'Query';
  game_date_times: Array<{
    __typename?: 'GameDateTime';
    id: string;
    date?: string | null;
    time?: string | null;
    datetime?: any | null;
    taken?: boolean | null;
    facility?: { __typename?: 'Facility'; id: string; fieldName?: string | null; name: string } | null;
  }>;
};

export type DeleteHomeDateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  item: Scalars['String']['input'];
}>;

export type DeleteHomeDateMutation = { __typename?: 'Mutation'; remove_game_date_time_from_registration: { __typename?: 'GameDateTime'; id: string } };

export type UpsertHomeDateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  upsert: UpsertGameDateTimeInput;
}>;

export type UpsertHomeDateMutation = { __typename?: 'Mutation'; upsert_game_date_time_to_registration: { __typename?: 'GameDateTime'; id: string } };

export type UpsertManyHomeDatesMutationVariables = Exact<{
  id: Scalars['String']['input'];
  upsert: Array<UpsertGameDateTimeInput> | UpsertGameDateTimeInput;
}>;

export type UpsertManyHomeDatesMutation = {
  __typename?: 'Mutation';
  upsert_many_game_date_time_to_registration: Array<{ __typename?: 'GameDateTime'; id: string }>;
};

export type RefreshRostersQueryVariables = Exact<{
  registrationId: Scalars['String']['input'];
}>;

export type RefreshRostersQuery = {
  __typename?: 'Query';
  roster_entries: Array<{
    __typename?: 'RosterEntry';
    id: string;
    allstarNomination?: boolean | null;
    waiverdate?: any | null;
    waiversignature?: string | null;
    waiver?: boolean | null;
    number?: number | null;
    position?: string | null;
    membershipId?: string | null;
    yearsOfExperience?: number | null;
    offSeasonPlayer?: string | null;
    emergencyContact?: {
      __typename?: 'EmergencyContact';
      cellPhone?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      relationToPlayer?: string | null;
    } | null;
    player?: {
      __typename?: 'Player';
      birthday?: string | null;
      firstName?: string | null;
      grade?: string | null;
      guardianemail?: string | null;
      guardian2email?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      phoneNumber2?: string | null;
      phoneNumber3?: string | null;
    } | null;
  }>;
};

export type DeleteRosterMutationVariables = Exact<{
  id: Scalars['String']['input'];
  roster_entry: Scalars['String']['input'];
}>;

export type DeleteRosterMutation = { __typename?: 'Mutation'; remove_roster_entry_from_registration: { __typename?: 'RosterEntry'; id: string } };

export type UpsertRosterMutationVariables = Exact<{
  id: Scalars['String']['input'];
  upsert: UpsertRosterEntryInput;
}>;

export type UpsertRosterMutation = { __typename?: 'Mutation'; upsert_roster_entry_to_registration: { __typename?: 'RosterEntry'; id: string } };

export type UpsertManyRostersMutationVariables = Exact<{
  id: Scalars['String']['input'];
  upsert: Array<UpsertRosterEntryInput> | UpsertRosterEntryInput;
}>;

export type UpsertManyRostersMutation = {
  __typename?: 'Mutation';
  upsert_many_roster_entry_to_registration: Array<{ __typename?: 'RosterEntry'; id: string }>;
};

export type RefreshTeamPositionQueryVariables = Exact<{
  team_id: Scalars['String']['input'];
}>;

export type RefreshTeamPositionQuery = {
  __typename?: 'Query';
  team_positions: Array<{
    __typename?: 'TeamPosition';
    id: string;
    positionType?: string | null;
    user?: { __typename?: 'User'; id: string; firstName?: string | null; lastName?: string | null; email: string; cellPhone?: string | null } | null;
  }>;
};

export type DeleteTeamPositionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  team_position: Scalars['String']['input'];
}>;

export type DeleteTeamPositionMutation = { __typename?: 'Mutation'; remove_team_position_from_team: { __typename?: 'TeamPosition'; id: string } };

export type UpsertTeamPositionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  team_position: UpsertTeamPositionInput;
}>;

export type UpsertTeamPositionMutation = { __typename?: 'Mutation'; upsert_team_position_to_team: { __typename?: 'TeamPosition'; id: string } };

export type UpsertManyTeamPositionsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  team_position: Array<UpsertTeamPositionInput> | UpsertTeamPositionInput;
}>;

export type UpsertManyTeamPositionsMutation = {
  __typename?: 'Mutation';
  upsert_many_team_position_to_team: Array<{ __typename?: 'TeamPosition'; id: string }>;
};

export type TeamHomeQueryQueryVariables = Exact<{
  urlKey: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type TeamHomeQueryQuery = {
  __typename?: 'Query';
  team_registration?: {
    __typename?: 'Registration';
    id: string;
    rosters?: Array<{
      __typename?: 'RosterEntry';
      waiver?: boolean | null;
      waiversignature?: string | null;
      id: string;
      number?: number | null;
      position?: string | null;
      player?: { __typename?: 'Player'; firstName?: string | null; lastName?: string | null } | null;
    }> | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name?: string | null;
      color?: string | null;
      urlKey?: string | null;
      manager?: { __typename?: 'User'; firstName?: string | null; lastName?: string | null; email: string; cellPhone?: string | null } | null;
      facilities?: Array<{
        __typename?: 'Facility';
        id: string;
        name: string;
        fieldDescription?: string | null;
        location?: { __typename?: 'Address'; state?: string | null; city?: string | null; zip?: string | null; geocoding?: any | null } | null;
      }> | null;
      logo?: { __typename?: 'FileMeta'; url?: string | null } | null;
    } | null;
    season: { __typename?: 'Season'; name: string };
    home_games?: Array<{
      __typename?: 'Game';
      id: string;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      homeTeamForfeit?: boolean | null;
      awayTeamForfeit?: boolean | null;
      winning_team_id?: string | null;
      losing_team_id?: string | null;
      home_team?: {
        __typename?: 'Registration';
        id: string;
        team?: { __typename?: 'Team'; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
      } | null;
      away_team?: {
        __typename?: 'Registration';
        id: string;
        team?: { __typename?: 'Team'; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
      } | null;
      date?: { __typename?: 'GameDateTime'; datetime?: any | null } | null;
    }> | null;
    away_games?: Array<{
      __typename?: 'Game';
      id: string;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      homeTeamForfeit?: boolean | null;
      awayTeamForfeit?: boolean | null;
      winning_team_id?: string | null;
      losing_team_id?: string | null;
      home_team?: {
        __typename?: 'Registration';
        id: string;
        team?: { __typename?: 'Team'; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
      } | null;
      away_team?: {
        __typename?: 'Registration';
        id: string;
        team?: { __typename?: 'Team'; name?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
      } | null;
      date?: { __typename?: 'GameDateTime'; datetime?: any | null } | null;
    }> | null;
    division?: {
      __typename?: 'Division';
      id: string;
      name: string;
      registrations?: Array<{
        __typename?: 'Registration';
        id: string;
        team?: { __typename?: 'Team'; name?: string | null; urlKey?: string | null; logo?: { __typename?: 'FileMeta'; url?: string | null } | null } | null;
        standings?: { __typename?: 'Standings'; wins?: number | null; losses?: number | null; ties?: number | null; power_ranking?: number | null } | null;
      }> | null;
    } | null;
    age_group?: { __typename?: 'AgeGroup'; id: string; name: string } | null;
    team_level?: { __typename?: 'TeamLevel'; id: string; name: string } | null;
    home_dates?: Array<{
      __typename?: 'GameDateTime';
      id: string;
      datetime?: any | null;
      facility?: { __typename?: 'Facility'; fieldDescription?: string | null; name: string; id: string } | null;
    }> | null;
  } | null;
};

export type GetMeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetMeQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    homePhone?: string | null;
    cellPhone?: string | null;
    location?: {
      __typename?: 'Address';
      street?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
    } | null;
  };
};

export type UpdateMeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  update: UpdateUserInput;
}>;

export type UpdateMeMutation = { __typename?: 'Mutation'; update_user: { __typename?: 'User'; id: string } };

export const StandingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Standings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'age_group' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'age_group' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_levels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'divisions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'registrations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'standings' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'opponents_winning_percent' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'opponents_opponents_winning_percent' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ratings_percentage_index' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'power_ranking' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'wins' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'losses' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'games_played' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'win_loss_percent' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'weighted_winning_percent' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'points' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'points_for' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'points_for_average' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'points_allowed' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'points_allowed_average' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'games_played' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'home_wins' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'home_losses' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'home_games_played' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'away_wins' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'away_losses' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'away_games_played' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ties' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'home_wins' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'away_wins' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StandingsQuery, StandingsQueryVariables>;
export const LoadWaiverTextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadWaiverText' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'league' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaguesettings' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'waiverText' } }] },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadWaiverTextQuery, LoadWaiverTextQueryVariables>;
export const LoadRosterWaiverStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadRosterWaiverState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rosterId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roster_entry' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'rosterId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadRosterWaiverStateQuery, LoadRosterWaiverStateQueryVariables>;
export const SignRosterWaiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignRosterWaiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rosterId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signature' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sign_waiver' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'roster' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'rosterId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'signature' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'signature' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiverdate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiversignature' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignRosterWaiverMutation, SignRosterWaiverMutationVariables>;
export const LoadUserWaiversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadUserWaivers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waiver_signatures' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waiver' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadUserWaiversQuery, LoadUserWaiversQueryVariables>;
export const SignWaiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignWaiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertWaiverSignatureInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_waiver_signature_to_user' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'waiver_signature' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignWaiverMutation, SignWaiverMutationVariables>;
export const LoadAnnouncementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadAnnouncements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'announcements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'league' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadAnnouncementsQuery, LoadAnnouncementsQueryVariables>;
export const UpsertMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertAnnouncementInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_announcement' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertMessageMutation, UpsertMessageMutationVariables>;
export const UpsertManyMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertAnnouncementInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_many_announcement' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyMessagesMutation, UpsertManyMessagesMutationVariables>;
export const DeleteMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_announcement' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const UpsertFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileMeta' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertFileMetaInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_file_meta' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'fileMeta' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertFileMutation, UpsertFileMutationVariables>;
export const LoadSponsorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadSponsors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'league' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadSponsorsQuery, LoadSponsorsQueryVariables>;
export const DeleteSponsorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSponsor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_sponsor' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSponsorMutation, DeleteSponsorMutationVariables>;
export const UpsertSponsorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertSponsor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertSponsorInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_sponsor' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertSponsorMutation, UpsertSponsorMutationVariables>;
export const UpsertSponsorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertSponsors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sponsors' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertSponsorInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_many_sponsor' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sponsors' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertSponsorsMutation, UpsertSponsorsMutationVariables>;
export const LoadTeamLevelDivisionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadTeamLevelDivisions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'divisions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_level' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadTeamLevelDivisionsQuery, LoadTeamLevelDivisionsQueryVariables>;
export const LoadTeamLevelRegistrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadTeamLevelRegistrations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_level' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadTeamLevelRegistrationsQuery, LoadTeamLevelRegistrationsQueryVariables>;
export const AssignRegistrationDivisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssignRegistrationDivision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRegistrationInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignRegistrationDivisionMutation, AssignRegistrationDivisionMutationVariables>;
export const LoadAgeGroupGamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadAgeGroupGames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registrations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_dates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'facility' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_games' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'facility' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'home_team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'away_team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadAgeGroupGamesQuery, LoadAgeGroupGamesQueryVariables>;
export const LoadDivisionGamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadDivisionGames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'divisionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'division' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'divisionId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registrations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_dates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'facility' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_games' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'facility' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'home_team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'away_team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadDivisionGamesQuery, LoadDivisionGamesQueryVariables>;
export const LoadRegistrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadRegistrations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'age_group' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_level' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'age_group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_level' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadRegistrationsQuery, LoadRegistrationsQueryVariables>;
export const LoadRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'age_group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_level' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'season' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiver_date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadRegistrationQuery, LoadRegistrationQueryVariables>;
export const UpsertRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertRegistrationInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_registration' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'age_group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_level' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertRegistrationMutation, UpsertRegistrationMutationVariables>;
export const UpdateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTeamInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const RemoveRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveRegistrationMutation, RemoveRegistrationMutationVariables>;
export const LoadTeamsForSchedulingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadTeamsForScheduling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'age_group' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_level' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'age_group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_level' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'away_games' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_team' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_team' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home_games' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_team' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_team' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadTeamsForSchedulingQuery, LoadTeamsForSchedulingQueryVariables>;
export const LoadAgeGroupSchedulingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadAgeGroupScheduling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schedulingOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inviteSchedulingEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openSchedulingEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadAgeGroupSchedulingQuery, LoadAgeGroupSchedulingQueryVariables>;
export const UpsertAgeGroupSchedulingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAgeGroupScheduling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsertAgeGroup' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertAgeGroupInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_age_group' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsertAgeGroup' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schedulingOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inviteSchedulingEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openSchedulingEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertAgeGroupSchedulingMutation, UpsertAgeGroupSchedulingMutationVariables>;
export const OfficialsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfficialsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'games' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'season' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home_team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }] },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'away_team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }] },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'date' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'geocoding' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfficialsReportQuery, OfficialsReportQueryVariables>;
export const GetRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          { kind: 'ObjectField', name: { kind: 'Name', value: 'in' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRolesQuery, GetRolesQueryVariables>;
export const CreateRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRoleInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_role' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'createInput' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } } }],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRoleMutation, CreateRoleMutationVariables>;
export const GetLeagueUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLeagueUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'league_roles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'league' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'role' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLeagueUsersQuery, GetLeagueUsersQueryVariables>;
export const FindUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          { kind: 'ObjectField', name: { kind: 'Name', value: 'eq' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindUserQuery, FindUserQueryVariables>;
export const AddLeagueUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddLeagueUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'add_league_role_to_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'league_role' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'role' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } } },
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'user' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } },
                  ],
                },
              },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddLeagueUserRoleMutation, AddLeagueUserRoleMutationVariables>;
export const RemoveLeagueUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveLeagueUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueRoleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_league_role_from_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'league_role' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueRoleId' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveLeagueUserRoleMutation, RemoveLeagueUserRoleMutationVariables>;
export const MyTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managed_teams' },
                  arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'filter' }, value: { kind: 'ObjectValue', fields: [] } }],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'season' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'positions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'positionType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'registrations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'season' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyTeamsQuery, MyTeamsQueryVariables>;
export const CreateRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registration' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRegistrationInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'manager' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertUserInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertTeamInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertUserInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_season_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'manager' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'manager' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'registration' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'registration' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'team' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'administrator' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'admin' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRegistrationMutation, CreateRegistrationMutationVariables>;
export const RegistrationDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegistrationDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_levels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'home_dates' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'away_games' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isPlayed' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'home_games' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isPlayed' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rosters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegistrationDashboardQuery, RegistrationDashboardQueryVariables>;
export const LoadCarouselsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadCarousels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carousels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'league' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'league' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linktext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadCarouselsQuery, LoadCarouselsQueryVariables>;
export const UpsertCarouselDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCarousel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertCarouselInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_carousel_to_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'carousel' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertCarouselMutation, UpsertCarouselMutationVariables>;
export const UpsertManyCarouselsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyCarousels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertCarouselInput' } } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_many_carousel_to_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'carousel' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyCarouselsMutation, UpsertManyCarouselsMutationVariables>;
export const DeleteCarouselDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCarousel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_carousel_from_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'carousel' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCarouselMutation, DeleteCarouselMutationVariables>;
export const LoadWaiversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadWaivers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'league_waivers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'league' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'waiver' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadWaiversQuery, LoadWaiversQueryVariables>;
export const UpsertWaiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertWaiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertWaiverInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_waiver' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertWaiverMutation, UpsertWaiverMutationVariables>;
export const UpsertLeagueWaiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertLeagueWaiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertLeagueWaiverInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_league_waiver_to_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'league_waiver' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertLeagueWaiverMutation, UpsertLeagueWaiverMutationVariables>;
export const DeleteLeagueWaiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLeagueWaiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_league_waiver_from_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'league_waiver' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLeagueWaiverMutation, DeleteLeagueWaiverMutationVariables>;
export const UpsertLeagueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertLeague' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertLeagueInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_league' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertLeagueMutation, UpsertLeagueMutationVariables>;
export const UpdateSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_site_setting' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'update' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'value' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSettingMutation, UpdateSettingMutationVariables>;
export const CreateSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'module' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'add_site_setting_to_league' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'leagueId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'site_setting' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'key' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } } },
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'value' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } } },
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'module' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'module' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'module' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSettingMutation, CreateSettingMutationVariables>;
export const UpdateManySettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateManySettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertSiteSettingInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_many_site_setting' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'module' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'league' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'season' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateManySettingsMutation, UpdateManySettingsMutationVariables>;
export const LoadAgeGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadAgeGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_groups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'season' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationProduct' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadAgeGroupsQuery, LoadAgeGroupsQueryVariables>;
export const LoadAgeGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadAgeGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationProduct' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadAgeGroupQuery, LoadAgeGroupQueryVariables>;
export const UpsertAgeGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAgeGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertAgeGroupInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationProduct' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertAgeGroupMutation, UpsertAgeGroupMutationVariables>;
export const UpsertManyAgeGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyAgeGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertAgeGroupInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_many_age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyAgeGroupsMutation, UpsertManyAgeGroupsMutationVariables>;
export const DeleteAgeGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAgeGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationProduct' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAgeGroupMutation, DeleteAgeGroupMutationVariables>;
export const DivisionTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DivisionTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'season' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'age_groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team_levels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'divisions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DivisionTreeQuery, DivisionTreeQueryVariables>;
export const LoadDivisionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadDivisions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'divisions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_level' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teamLevelId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadDivisionsQuery, LoadDivisionsQueryVariables>;
export const UpsertDivisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertDivision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertDivisionInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_division' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertDivisionMutation, UpsertDivisionMutationVariables>;
export const UpsertManyDivisionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyDivisions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertDivisionInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_many_division' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyDivisionsMutation, UpsertManyDivisionsMutationVariables>;
export const DeleteDivisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDivision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_division' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDivisionMutation, DeleteDivisionMutationVariables>;
export const LoadSeasonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadSeason' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'season' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationClose' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadSeasonQuery, LoadSeasonQueryVariables>;
export const UpsertSeasonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertSeason' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertSeasonInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_season' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertSeasonMutation, UpsertSeasonMutationVariables>;
export const LoadTeamLevelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadTeamLevels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team_levels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'age_group' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ageGroupId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadTeamLevelsQuery, LoadTeamLevelsQueryVariables>;
export const LoadTeamLevelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoadTeamLevel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team_level' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoadTeamLevelQuery, LoadTeamLevelQueryVariables>;
export const UpsertTeamLevelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTeamLevel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertTeamLevelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_team_level' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertTeamLevelMutation, UpsertTeamLevelMutationVariables>;
export const UpsertManyTeamLevelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyTeamLevels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertTeamLevelInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_many_team_level' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyTeamLevelsMutation, UpsertManyTeamLevelsMutationVariables>;
export const DeleteTeamLevelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTeamLevel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_team_level' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTeamLevelMutation, DeleteTeamLevelMutationVariables>;
export const AgeGroupTeamsByTeamLevelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgeGroupTeamsByTeamLevel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'age_group' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'age_group' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'age_group' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_levels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgeGroupTeamsByTeamLevelQuery, AgeGroupTeamsByTeamLevelQueryVariables>;
export const ResolveUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResolveUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vanity_url_resolve' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'domain' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'league' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acronym' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'announcements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carousels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linktext' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sponsors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seasons' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'registrationOpen' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'registrationClose' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'age_groups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team_levels' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'divisions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'settings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'module' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'season' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'module' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'league' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'waivers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'waiver' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResolveUrlQuery, ResolveUrlQueryVariables>;
export const RefreshFacilitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshFacilities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'facilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'team' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geocoding' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshFacilitiesQuery, RefreshFacilitiesQueryVariables>;
export const DeleteFacilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFacility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'facility' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_facility_from_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'facility' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'facility' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFacilityMutation, DeleteFacilityMutationVariables>;
export const UpsertFacilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertFacility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertFacilityInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_facility_to_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'facility' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team_id' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertFacilityMutation, UpsertFacilityMutationVariables>;
export const UpsertManyFacilitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyFacilities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertFacilityInput' } } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_many_facility_to_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'facility' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team_id' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyFacilitiesMutation, UpsertManyFacilitiesMutationVariables>;
export const RefreshGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gameId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'game' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'gameId' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'winning_team_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'losing_team_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awayTeamForfeit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awayTeamScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'awayTeamOffersToHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeTeamForfeit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeTeamScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gameType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'game_number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'date' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'geocoding' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home_team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'away_team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshGameQuery, RefreshGameQueryVariables>;
export const UpsertGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertGameInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_game' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'upsert' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } }],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertGameMutation, UpsertGameMutationVariables>;
export const RefreshHomeDatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshHomeDates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'game_date_times' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'registration' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'facility' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshHomeDatesQuery, RefreshHomeDatesQueryVariables>;
export const DeleteHomeDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteHomeDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'item' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_game_date_time_from_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'game_date_time' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'item' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteHomeDateMutation, DeleteHomeDateMutationVariables>;
export const UpsertHomeDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertHomeDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertGameDateTimeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_game_date_time_to_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'game_date_time' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertHomeDateMutation, UpsertHomeDateMutationVariables>;
export const UpsertManyHomeDatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyHomeDates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertGameDateTimeInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_many_game_date_time_to_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'game_date_time' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyHomeDatesMutation, UpsertManyHomeDatesMutationVariables>;
export const RefreshRostersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshRosters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roster_entries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'registration' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'registrationId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allstarNomination' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiverdate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiversignature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emergencyContact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relationToPlayer' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'player' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grade' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guardianemail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guardian2email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber3' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'membershipId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yearsOfExperience' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offSeasonPlayer' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshRostersQuery, RefreshRostersQueryVariables>;
export const DeleteRosterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRoster' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roster_entry' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_roster_entry_from_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'roster_entry' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'roster_entry' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRosterMutation, DeleteRosterMutationVariables>;
export const UpsertRosterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertRoster' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertRosterEntryInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_roster_entry_to_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'roster_entry' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertRosterMutation, UpsertRosterMutationVariables>;
export const UpsertManyRostersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyRosters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertRosterEntryInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_many_roster_entry_to_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'roster_entry' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'upsert' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyRostersMutation, UpsertManyRostersMutationVariables>;
export const RefreshTeamPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshTeamPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team_positions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    { kind: 'ObjectField', name: { kind: 'Name', value: 'team' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team_id' } } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'positionType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTeamPositionQuery, RefreshTeamPositionQueryVariables>;
export const DeleteTeamPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTeamPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team_position' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_team_position_from_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'team_position' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team_position' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTeamPositionMutation, DeleteTeamPositionMutationVariables>;
export const UpsertTeamPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTeamPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team_position' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertTeamPositionInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_team_position_to_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'team_position' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team_position' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertTeamPositionMutation, UpsertTeamPositionMutationVariables>;
export const UpsertManyTeamPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertManyTeamPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'team_position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertTeamPositionInput' } } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_many_team_position_to_team' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'team_position' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'team_position' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertManyTeamPositionsMutation, UpsertManyTeamPositionsMutationVariables>;
export const TeamHomeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TeamHomeQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urlKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team_registration' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'urlKey' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'urlKey' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'seasonId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'seasonId' } } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rosters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'waiver' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'waiversignature' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'player' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manager' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldDescription' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'geocoding' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'season' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }] },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home_games' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'homeTeamScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awayTeamScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'homeTeamForfeit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awayTeamForfeit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'winning_team_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'losing_team_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'date' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'datetime' } }] },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'away_games' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'homeTeamScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awayTeamScore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'homeTeamForfeit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'awayTeamForfeit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'winning_team_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'losing_team_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'date' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'datetime' } }] },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'division' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'standings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'wins' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'losses' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ties' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'power_ranking' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'age_group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_level' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'home_dates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facility' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamHomeQueryQuery, TeamHomeQueryQueryVariables>;
export const GetMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'update' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_user' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'update' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'update' } } },
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>;
