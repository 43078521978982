import { TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormTextFieldProps<T extends FieldValues = FieldValues> = HookFormBaseProps<T> & {
  type?: TextFieldProps['type'];
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'fullWidth' | 'helperText' | 'error' | 'type'>;
};
export function HookFormTextField<T extends FieldValues = FieldValues>(props: HookFormTextFieldProps<T>) {
  const { name, label, rules, fullWidth, control, ControllerProps, TextFieldProps, tooltip, type = 'text', children, sx } = props;

  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, ...otherFieldProps }, fieldState: { error, isDirty, isTouched }, formState: { isSubmitted } }) => (
        <TextField
          sx={sx}
          {...TextFieldProps}
          label={labelValue}
          type={type}
          value={value === undefined || value === null ? '' : value}
          fullWidth={fullWidth}
          helperText={error?.message}
          error={!!error}
          {...otherFieldProps}>
          {children}
        </TextField>
      )}
    />
  );
}

export default HookFormTextField;
