import { Division, UpsertDivisionInput } from '@local/graphql/graphql';
import { HookFormTextField, ModalForm, ModalFormImplementationProps } from '@seasonticker/dls';

export function DivisionForm(props: ModalFormImplementationProps<Division, UpsertDivisionInput>) {
  const FormFields = () => {
    return <HookFormTextField name="name" label="Name" fullWidth rules={{ required: 'Division name is required' }} />;
  };
  return <ModalForm {...props} FormFields={FormFields} defaultValues={{ name: '' }} />;
}

export default DivisionForm;
