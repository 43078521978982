import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import {
  DeleteSponsorDocument,
  FileMeta,
  LoadSponsorsDocument,
  Sponsor,
  UpsertSponsorDocument,
  UpsertSponsorInput,
  UpsertSponsorsDocument,
} from '@local/graphql/graphql';
import { useFileUpload } from '@local/hooks';
import { Link, LinkProps, ListItemAvatar, ListItemButtonProps, ListItemText, Tooltip, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, HookFormDropZone, HookFormTextField, Roles, VisibleIf, useAuthz } from '@seasonticker/dls';
import { useCallback } from 'react';

const LogoImage = styled('img')({ maxHeight: '60px' });

export function Sponsors() {
  const [refreshList] = useLazyQuery(LoadSponsorsDocument, { fetchPolicy: 'no-cache' });
  const [upsertMutation] = useMutation(UpsertSponsorDocument);
  const [upsertManyMutation] = useMutation(UpsertSponsorsDocument);
  const [deleteMutation] = useMutation(DeleteSponsorDocument);
  const { leagueContext } = useLeagueContext();
  const { ifInRole, isInRole } = useAuthz();
  const permissionFunction = ifInRole({ context: leagueContext?.league?.id, roles: [Roles.admin, Roles.leagueadmin] });

  const upsertManyOperation = (list: UpsertSponsorInput[]) => () => upsertManyMutation({ variables: { sponsors: list } });
  const { uploadFile } = useFileUpload();
  const emptyTemplate = {
    name: '',
    url: '',
  } as Sponsor;
  const deleteItem = (item: Sponsor) => () => deleteMutation({ variables: { id: item.id } });

  const refresh = useCallback(async () => {
    const response = await refreshList({ variables: { leagueId: String(leagueContext?.league?.id) } });
    return response.data?.sponsors as Sponsor[];
  }, [leagueContext?.league?.id, refreshList]);

  const prepareUpsert = async (item: Sponsor) => {
    let newFile: FileMeta | null = null;
    if (item.image) {
      newFile = (await uploadFile(item.image, { folder: `uploads/league/${leagueContext?.league?.id}/sponsors` })) as FileMeta;
    }
    const update: UpsertSponsorInput = {
      id: item.id,
      name: item.name,
      url: item.url,
    };
    if (newFile?.id) {
      update.image = newFile?.id;
    }
    if (item.id === undefined && leagueContext?.league?.id) {
      update.league = leagueContext.league.id;
    }
    return update;
  };
  const upsertItem = (update: UpsertSponsorInput) => () => {
    return upsertMutation({
      variables: {
        upsert: update,
      },
    });
  };

  const sponsorForm = (
    <Grid container rowSpacing={'.8em'}>
      <Grid xs={12}>
        <HookFormTextField label="Name" name="name" fullWidth />
      </Grid>
      <Grid xs={12}>
        <HookFormTextField label="Url" name="url" fullWidth />
      </Grid>
      <Grid xs={12}>
        <HookFormDropZone
          label="Image"
          name="image"
          accept={{
            'image/*': [],
          }}
          preview={(value) => (value ? <LogoImage src={String((value as FileMeta)?.url || '')} /> : <></>)}
          valueGetter={(value: FileMeta) => value}
          valueSetter={(value: FileMeta, priorValue: FileMeta) => value}
        />
      </Grid>
    </Grid>
  );

  const itemTemplate = (item: Sponsor) => {
    return item.image?.url ? (
      <Tooltip title={item.name}>
        <ListItemAvatar>
          <LogoImage src={String(item.image?.url)} />
        </ListItemAvatar>
      </Tooltip>
    ) : (
      <ListItemText primary={item.name} />
    );
  };

  return (
    <VisibleIf
      cond={(leagueContext?.league?.sponsors?.length || 0) > 0 || isInRole({ context: leagueContext?.league?.id, roles: [Roles.admin, Roles.leagueadmin] })}>
      <EditableList<Sponsor, UpsertSponsorInput, ReturnType<typeof upsertMutation>, ReturnType<typeof upsertManyMutation>, ReturnType<typeof deleteMutation>>
        model="Sponsors"
        refresh={refresh}
        refreshList={refresh}
        deleteMutation={deleteItem}
        prepareUpsert={prepareUpsert}
        upsertMutation={upsertItem}
        upsertOperation={upsertManyOperation}
        canEdit={permissionFunction}
        listItemTemplate={itemTemplate}
        editElement={sponsorForm}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        ButtonProps={(item) => ({ component: Link, href: String(item.url), target: '_blank' } as ListItemButtonProps<'div', LinkProps & ListItemButtonProps>)}
        inline
        editable
        sortable
        selectable
        deletable
      />
    </VisibleIf>
  );
}

export default Sponsors;
