import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AgeGroup,
  AssignRegistrationDivisionDocument,
  Division,
  LoadAgeGroupGamesDocument,
  LoadDivisionGamesDocument,
  LoadDivisionGamesQuery,
  LoadTeamLevelDivisionsDocument,
  LoadTeamLevelRegistrationsDocument,
  Registration,
  TeamLevel,
  UpsertDivisionDocument,
  UpsertDivisionInput,
} from '@local/graphql/graphql';
import { DivisionTree } from '@local/league/components/DivisionTree';
import { Box, Card, CardContent, CardHeader, List, ListItem, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams, GridToolbar } from '@mui/x-data-grid-pro';
import { DeleteIcon, EditIcon, useOperations } from '@seasonticker/dls';
import { format, parseJSON } from 'date-fns';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSorting from '../../../hooks/useSorting/useSorting';

type DivisionGamesData = LoadDivisionGamesQuery['division'];
type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type RegistrationType = ArrayElement<NonNullable<DivisionGamesData['registrations']>>;
type GameType = ArrayElement<NonNullable<RegistrationType['home_games']>>;

const dateToString = (input: any) => {
  try {
    if (input) {
      const date = parseJSON(input);
      return format(date, 'EEE MMM dd h:mm bbb');
    }
  } catch (ex) {
    console.log(ex);
  }
  return input;
};

export function GameManager() {
  const { operation } = useOperations();
  const [draggingRegistration, setDraggingRegistration] = useState<Registration>();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup>();
  const [selectedTeamLevel, setSelectedTeamLevel] = useState<TeamLevel>();
  const [selectedDivision, setSelectedDivision] = useState<Division>();
  const [divisionGames, setDivisionGames] = useState<GameType[]>([]);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [loadAgeGroupGamesQuery] = useLazyQuery(LoadAgeGroupGamesDocument);
  const [loadDivisionGamesQuery] = useLazyQuery(LoadDivisionGamesDocument);
  const [loadDivisionsQuery] = useLazyQuery(LoadTeamLevelDivisionsDocument, { fetchPolicy: 'no-cache' });
  const [loadRegistrationsQuery] = useLazyQuery(LoadTeamLevelRegistrationsDocument);
  const [assignDivisionMutation] = useMutation(AssignRegistrationDivisionDocument);
  const [upsertDivisionMutation] = useMutation(UpsertDivisionDocument);
  const nodeClick = useCallback((ag: AgeGroup, tl: TeamLevel, d?: Division) => {
    setSelectedAgeGroup(ag);
    setSelectedTeamLevel(tl);
    setSelectedDivision(d);
  }, []);
  const [divisionDialogOpen, setDivisionDialogOpen] = useState(false);
  const handleCloseDivisionDialog = () => {
    setDivisionDialogOpen(false);
  };
  const divisionStats = useMemo(() => {
    const values: { id: string; count: number }[] = divisions?.map((d) => ({
      id: d.id,
      count: registrations.filter((r) => r.division?.id === d.id)?.length || 0,
    }));
    const map: Record<string, number> = {};
    values.forEach((v) => (map[v.id] = v.count));
    return map;
  }, [divisions, registrations]);

  const sort = useSorting();

  const loadAgeGroupGames = useCallback(async () => {
    if (selectedAgeGroup?.id && !selectedDivision?.id) {
      const results = await loadAgeGroupGamesQuery({
        variables: {
          ageGroupId: selectedAgeGroup.id,
        },
      });
      if (results.data?.age_group) {
        const map = results.data.age_group.registrations?.flatMap((r) => r.home_games) as GameType[];
        setDivisionGames(map);
      }
    }
  }, [loadAgeGroupGamesQuery, selectedAgeGroup?.id, selectedDivision?.id]);

  const loadDivisionGames = useCallback(async () => {
    if (selectedDivision?.id) {
      const results = await loadDivisionGamesQuery({
        variables: {
          divisionId: selectedDivision.id,
        },
      });
      if (results.data?.division) {
        const map = results.data.division.registrations?.flatMap((r) => r.home_games) as GameType[];
        setDivisionGames(map);
      }
    }
  }, [loadDivisionGamesQuery, selectedDivision?.id]);

  const assignDivision = useCallback(
    async (registration: Registration) => {
      await operation({
        success: 'Division Assigned',
        error: 'There was an error assigning the division',
        operation: () =>
          assignDivisionMutation({
            variables: {
              registrationId: registration.id,
              update: {
                division: registration.division?.id,
              },
            },
          }),
      });
    },
    [assignDivisionMutation, operation]
  );

  const onSubmit = async (data: UpsertDivisionInput) => {
    const { name } = data;
    const updateValue: UpsertDivisionInput = {
      name,
      id: data?.id,
    };
    if (data?.id === undefined || data?.id?.length === 0) {
      updateValue.team_level = selectedTeamLevel?.id;
      updateValue.sortOrder = divisions.length;
    }
    await operation({
      success: 'The division was successfully added',
      error: 'There was an error adding the division',
      operation: async () => {
        const results = await upsertDivisionMutation({
          variables: {
            upsert: updateValue,
          },
        });
        return (results.data?.upsert_division || {}) as Division;
      },
      after: (division: Division) => {
        setDivisions([...divisions, division]);
        handleCloseDivisionDialog();
      },
    });
  };

  useEffect(() => {
    loadAgeGroupGames();
    loadDivisionGames();
  }, [loadAgeGroupGames, loadDivisionGames]);

  /**
   * Draggable Item
   * @TODO make this more generic draggable thing
   * @param props
   * @returns
   */
  const DraggableRegistration = (props: { registration: Registration }) => {
    const { registration } = props;
    const { setNodeRef, isDragging, attributes, listeners } = useDraggable({
      id: registration.id,
      data: registration,
    });
    return (
      <ListItem ref={setNodeRef} {...attributes} {...listeners} sx={{ visibility: isDragging ? 'hidden' : 'visible' }}>
        <ListItemText primary={registration.team?.name} />
      </ListItem>
    );
  };

  const onDrag = (reg: DragStartEvent) => {
    setDraggingRegistration(reg.active.data.current as Registration);
  };

  const onDrop = (dropEvent: DragEndEvent) => {
    if (dropEvent.over) {
      setRegistrations(
        registrations?.map((r) => {
          if (r.id === dropEvent.active.id) {
            const updatedReg = {
              ...r,
              division: divisions.find((d) => d.id === dropEvent.over?.id),
            };
            assignDivision(updatedReg);
            return updatedReg;
          }
          return r;
        })
      );
      setDraggingRegistration(undefined);
    }
  };

  const DroppableDivision = (props: { division: Division }) => {
    const { division } = props;
    const { setNodeRef, isOver } = useDroppable({
      id: division.id,
      data: division,
    });
    return (
      <Grid xs={12} md={6} xl={4}>
        <Card key={division.id} ref={setNodeRef} sx={(theme) => ({ backgroundColor: isOver ? theme.palette.action.hover : theme.palette.background.default })}>
          <CardHeader title={`${division.name} (${divisionStats[division.id] || 0})`} />
          <CardContent>
            <List
              dense
              sx={{
                height: {
                  xs: `calc(70vh / ${Math.ceil((divisions?.length || 1) / 1)})`,
                  md: `calc(70vh / ${Math.ceil((divisions?.length || 1) / 2)})`,
                  xl: `calc(70vh / ${Math.ceil((divisions?.length || 1) / 3)})`,
                },
                overflowY: 'auto',
              }}>
              {sortBy(
                registrations?.filter((r) => r.division?.id === division.id),
                'team.name'
              ).map((r) => (
                <DraggableRegistration key={r.id} registration={r} />
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const deleteClick = (row: GameType) => {};

  const editClick = (row: GameType) => {};

  const columns: GridColDef<GameType>[] = [
    {
      field: 'id',
      flex: 1,
    },
    {
      field: 'home team',
      valueGetter(params) {
        return params.row.home_team?.team?.name;
      },
      flex: 1,
    },
    {
      field: 'away team',
      valueGetter(params) {
        return params.row.away_team?.team?.name;
      },
      flex: 1,
    },
    {
      field: 'date',
      valueGetter(params) {
        return dateToString(params.row.date?.datetime);
      },
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem color="primary" icon={<EditIcon />} onClick={() => editClick(params.row)} label="Edit" />,
        <GridActionsCellItem color="error" icon={<DeleteIcon />} onClick={() => deleteClick(params.row)} label="Delete" />,
      ],
    },
  ];
  return (
    <DndContext onDragEnd={onDrop} sensors={sensors} onDragStart={onDrag} collisionDetection={pointerWithin}>
      <DragOverlay>
        <Typography>{draggingRegistration?.team?.name}</Typography>
      </DragOverlay>
      <Grid container>
        <Grid xs={12} md={2}>
          <DivisionTree onClick={nodeClick} />
        </Grid>
        <Grid xs={12} md={10}>
          <Box sx={{ flexGrow: 1, height: 'auto' }}>
            <DataGridPro
              density="compact"
              disableColumnMenu
              disableColumnResize
              disableColumnFilter
              disableRowSelectionOnClick
              disableChildrenSorting
              hideFooter
              autoHeight
              columns={columns}
              rows={divisionGames || []}
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'home team', sort: 'asc' }],
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    'home team': true,
                    'away team': true,
                    date: true,
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </DndContext>
  );
}

export default GameManager;
