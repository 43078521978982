import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting } from '@local/graphql/graphql';
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useSeasonContext } from './season-context';
export type SettingState = {
  settings: SiteSetting[];
  setSettings: (settings: SiteSetting[]) => void;
};

/**
 * Note, this is ONLY to be used in the root app, to get access to the values
 * use the usePortalContext hook
 * @returns
 */
function useSettingState(): SettingState {
  const [settings, setSettingsState] = useState<SiteSetting[]>([] as SiteSetting[]);
  const setSettings = useCallback(
    (updatedSettings: SiteSetting[]) => {
      const settingsUpdate = [...settings];
      /**
       * loop through each updated setting, if it exists, update it, otherwise add it
       */
      updatedSettings?.forEach((setting) => {
        const idx = settingsUpdate.findIndex((s) => s.id === setting.id);
        if (idx > -1) {
          settingsUpdate[idx] = setting;
        } else {
          settingsUpdate.push(setting);
        }
      });
      setSettingsState(settingsUpdate);
    },
    [settings]
  );
  return {
    settings,
    setSettings,
  };
}

export const SettingContext = createContext<SettingState>({} as SettingState);
export const SettingContextProvider = ({ children }: { children: ReactElement }) => {
  const settingState = useSettingState();
  const { leagueContext } = useLeagueContext();
  const { season } = useSeasonContext();
  useEffect(() => {
    if (leagueContext?.league?.settings && season?.settings) {
      settingState.setSettings([...(leagueContext.league?.settings as SiteSetting[]), ...season?.settings]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leagueContext?.league?.settings, season?.settings]);
  return <SettingContext.Provider value={settingState}>{children}</SettingContext.Provider>;
};
export const SettingContextConsumer = SettingContext.Consumer;

/**
 * Returns the current portal state.  There are a few global values stored in the state to control things like header
 * and page titles
 * @returns The Current PortalState
 */
export function useSettingContext(): SettingState {
  return useContext(SettingContext);
}
