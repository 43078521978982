import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AgeGroupSetup from './AgeGroups';
import DivisionSetup from './Divisions';
import SeasonSetup from './Seasons';
import TeamLevelSetup from './TeamLevel';

export default function SetupRouter() {
  const [currentTab, setCurrentTab] = useState('season');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const parts = pathname?.split('/');
    if (parts?.length > 2) {
      setCurrentTab(pathname?.split('/').pop() || 'season');
    } else {
      navigate('./season');
    }
  }, [navigate, pathname]);
  const handleChange = (event: SyntheticEvent, value: string) => {
    setCurrentTab(value);
  };
  return (
    <TabContext value={currentTab}>
      <TabList onChange={handleChange} aria-label="lab API tabs example">
        <Tab label="Season" value="season" component={Link} to="./season" />
        <Tab label="Age Groups" value="agegroup" component={Link} to="./agegroup" />
        <Tab label="Team Levels" value="teamlevel" component={Link} to="./teamlevel" />
        <Tab label="Divisions" value="division" component={Link} to="./division" />
      </TabList>
      <Routes>
        <Route path="/season" element={<SeasonSetup />} />
        <Route path="/agegroup" element={<AgeGroupSetup />} />
        <Route path="/teamlevel" element={<TeamLevelSetup />} />
        <Route path="/division" element={<DivisionSetup />} />
      </Routes>
      <Outlet />
    </TabContext>
  );
}
