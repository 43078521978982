import { Roles, useAuthz } from '@seasonticker/dls';
import { ReactElement } from 'react';
import NoAccess from './NoAccess';

export type ProtectedRouteProps = {
  roles: Roles[];
  children: ReactElement;
  context?: string;
  subContext?: string;
};
export function ProtectedRoute(props: ProtectedRouteProps) {
  const { isInRole } = useAuthz();
  const { roles, children, context = '', subContext = '' } = props;
  return isInRole({ context, subContext, roles }) ? children : <NoAccess />;
}
