import { Season } from '@local/graphql/graphql';
import { createContext, ReactElement, useCallback, useContext, useState } from 'react';
export type SeasonState = {
  season: Season;
  setSeasonContext: (season: Season) => void;
};

/**
 * Note, this is ONLY to be used in the root app, to get access to the values
 * use the usePortalContext hook
 * @returns
 */
function useSeasonState(): SeasonState {
  const [season, setSeasonState] = useState<Season>({} as Season);
  const setSeasonContext = useCallback((newSeason: Season) => {
    sessionStorage.setItem('seasonId', newSeason?.id);
    setSeasonState(newSeason);
  }, []);
  return {
    season,
    setSeasonContext,
  };
}

export const SeasonContext = createContext<SeasonState>({} as SeasonState);
export const SeasonContextProvider = ({ children }: { children: ReactElement }) => {
  const leagueState = useSeasonState();
  return <SeasonContext.Provider value={leagueState}>{children}</SeasonContext.Provider>;
};
export const SeasonContextConsumer = SeasonContext.Consumer;

/**
 * Returns the current portal state.  There are a few global values stored in the state to control things like header
 * and page titles
 * @returns The Current PortalState
 */
export function useSeasonContext(): SeasonState {
  return useContext(SeasonContext);
}
