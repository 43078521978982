import { useQuery } from '@apollo/client';
import { useSeasonContext } from '@local/context/league/season-context';
import { AgeGroup, AgeGroupTeamsByTeamLevelDocument } from '@local/graphql/graphql';
import { Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LoadingIndicator } from '@seasonticker/dls';
import { sortBy } from 'lodash';
import { useState } from 'react';
// import { Link } from 'react-router-dom';
import { TeamLogo } from '@local/components';
import { Link } from '@mui/material';
import { AgeGroupSelector } from './components/AgeGroupSelector';

export default function Teams() {
  const { season } = useSeasonContext();

  const [ageGroup, setAgeGroup] = useState<AgeGroup>({} as AgeGroup);
  const { data, loading, error } = useQuery(AgeGroupTeamsByTeamLevelDocument, {
    variables: {
      age_group: ageGroup?.id,
    },
    skip: !ageGroup?.id,
  });
  return (
    <Box>
      <AgeGroupSelector onChange={setAgeGroup} />
      <LoadingIndicator loading={loading}>
        <Paper>
          <Typography sx={{ mt: 1, ml: 1 }} variant="h3">
            {ageGroup?.name}
          </Typography>
          {data?.age_group?.team_levels?.map((tl) => {
            return (
              <Card key={tl.id} sx={{ mb: 1 }}>
                <CardContent>
                  <Typography variant="h4">{tl.name}</Typography>
                  {sortBy(tl.registrations, 'team.name').map((reg) => {
                    return (
                      <List key={reg.id} dense>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar variant="rounded" sx={{ backgroundColor: 'transparent' }}>
                              <TeamLogo url={reg.team?.logo?.url} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemButton component={Link} href={`/league/team/${reg.team?.urlKey}?seasonOverride=${season.id}`}>
                            <ListItemText primary={reg.team?.name} />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    );
                  })}
                </CardContent>
              </Card>
            );
          })}
        </Paper>
      </LoadingIndicator>
    </Box>
  );
}
