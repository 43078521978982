import { TeamLogo } from '@local/components';
import { Game } from '@local/graphql/graphql';
import WinningIcon from '@mui/icons-material/ArrowRight';
import { Box, styled, Typography } from '@mui/material';
import { format, parseJSON } from 'date-fns';

export type GameDisplayProps = {};
const LogoImage = styled('img')({ objectFit: 'scale-down', maxWidth: '16px', maxHeight: '100%', marginRight: '.8em' });

const dateToString = (input: any) => {
  try {
    if (input) {
      const date = parseJSON(input);
      return format(date, 'EEE MMM dd h:mm bbb');
    }
  } catch (ex) {
    console.log(ex);
  }
  return input;
};

export type GameTeamDisplayProps = { game?: Game };
export function GameTeamDisplay(props: GameTeamDisplayProps) {
  const { game } = props;

  const WinningTeamIcon = (props: GameTeamDisplayProps & { mode: 'home' | 'away' }) => {
    const { game, mode } = props;
    if (mode === 'away' && game?.winning_team_id === game?.away_team?.id) {
      return <WinningIcon sx={{ position: 'absolute', marginRight: '.5em', bottom: '-2px' }} />;
    }
    if (mode === 'home' && game?.winning_team_id === game?.home_team?.id) {
      return <WinningIcon sx={{ position: 'absolute', marginRight: '.5em', bottom: '-2px' }} />;
    }
    return <></>;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', py: '.8em' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ flexGrow: 1 }} variant="body2">
            <TeamLogo url={game?.away_team?.team?.logo?.url} maxWidth="16px" maxHeight="100%" sx={{ marginRight: '.8em' }} />
            {game?.away_team?.team?.name}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', position: 'relative', justifyContent: 'end' }}>
            <Typography variant="body2">{game?.awayTeamScore}</Typography>
            <WinningTeamIcon game={game} mode="away" />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ flexGrow: 1 }} variant="body2">
            <TeamLogo url={game?.home_team?.team?.logo?.url} maxWidth="16px" maxHeight="100%" sx={{ marginRight: '.8em' }} />
            {game?.home_team?.team?.name}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', position: 'relative', justifyContent: 'end' }}>
            <Typography variant="body2">{game?.homeTeamScore}</Typography>
            <WinningTeamIcon game={game} mode="home" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ maxWidth: '100px', flexFlow: 'wrap', marginLeft: '.8em' }}>
        <Typography variant="body2">{dateToString(game?.date?.datetime)}</Typography>
      </Box>
    </Box>
  );
}

export default GameTeamDisplay;
