import { createContext, Fragment, useCallback, useContext, useState } from 'react';
import ConfirmationDialog, { ConfirmDialogProps } from './ConfirmationDialog';

interface ConfirmProviderProps {
  children: React.ReactNode;
  defaultOptions?: ConfirmDialogProps;
}

type ConfirmContextType = (options?: ConfirmDialogProps) => Promise<boolean>;

const ConfirmContext = createContext<ConfirmContextType>(() => Promise.resolve(true));

type ConfirmCallback = (value: boolean | PromiseLike<boolean>) => void;
type ConfirmRejection = (reason: any) => void;
type Promises = {
  resolve?: ConfirmCallback;
  reject?: ConfirmRejection;
};
export const ConfirmProvider: React.ComponentType<ConfirmProviderProps> = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({ ...defaultOptions });
  const [resolveReject, setResolveReject] = useState<Promises>({});
  const { resolve } = resolveReject;

  const confirm = useCallback((confirmOptions = {}) => {
    return new Promise<boolean>((resolve, reject) => {
      setOptions({ ...options, ...confirmOptions });
      setResolveReject({ resolve, reject });
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject({});
  }, []);

  const handleCancel = useCallback(() => {
    if (resolve) {
      resolve(false);
      handleClose();
    }
  }, [resolve, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve(true);
      handleClose();
    }
  }, [resolve, handleClose]);
  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog open={resolve !== undefined} onClose={handleClose} onCancel={handleCancel} onConfirm={handleConfirm} {...options} />
    </Fragment>
  );
};

/**
 * Creates a better looking confirm dialog.
 * @example
 * ```typescript
 * import { useConfirm } from '@components';
 * import { Button } from '@mui/material';
 *
 * export function ConfirmExample() {
 *     const confirm = useConfirm();
 *     const snackBar = useSnackbar();
 *
 *     const openConfirm = async () => {
 *         if (await confirm({description: 'Are you sure you want to do this?'})) {
 *             snackBar.enqueueSnackbar('User clicked yes', { variant: 'success'});
 *         } else {
 *             snackBar.enqueueSnackbar('User clicked no', { variant: 'success'});
 *         }
 *     }
 *
 *     return (
 *         <Button onClick={openConfirm}>Open Confirm</Button>
 *     );
 * }
 * ```
 * @returns
 */
export const useConfirm: () => (options?: ConfirmDialogProps) => Promise<boolean> = () => {
  const confirm = useContext(ConfirmContext);
  return confirm;
};

export default ConfirmProvider;
