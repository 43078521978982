import { GameDateTime } from '@local/graphql/graphql';
import { Box } from '@mui/material';

export type HomeDatesDisplayProps = {
  dates: GameDateTime;
};

export function HomeDatesDisplay(props: HomeDatesDisplayProps) {
  return <Box>Home Dates</Box>;
}

export default HomeDatesDisplay;
