import { TeamLevel, UpsertTeamLevelInput } from '@local/graphql/graphql';
import { HookFormTextField, ModalForm, ModalFormImplementationProps } from '@seasonticker/dls';

export function TeamLevelForm(props: ModalFormImplementationProps<TeamLevel, UpsertTeamLevelInput>) {
  const FormFields = () => {
    return <HookFormTextField name="name" label="Name" fullWidth rules={{ required: 'Team Level name is required' }} />;
  };
  return <ModalForm {...props} FormFields={FormFields} defaultValues={{ name: '' }} />;
}

export default TeamLevelForm;
