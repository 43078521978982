import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ScheduleGenerate } from './ScheduleGenerate';
import { ScheduleScope } from './ScheduleScope';
import { ScheduleSettings } from './ScheduleSettings';
import { ScheduleSteps, ScheduleWizardForm, ScheduleWizardStepProps, StepDefinition } from './ScheduleTypes';

export const steps: StepDefinition<ScheduleWizardStepProps>[] = [
  { id: ScheduleSteps.Scope, name: 'Teams', Component: ScheduleScope },
  { id: ScheduleSteps.Settings, name: 'Settings', Component: ScheduleSettings },
  { id: ScheduleSteps.Generate, name: 'Create Games', Component: ScheduleGenerate },
];

export function ScheduleWizard() {
  const [activeStep, setActiveStep] = useState<StepDefinition<ScheduleWizardStepProps>>(steps[0]);
  const formProps = useForm<ScheduleWizardForm>({
    defaultValues: {
      max_home_games_each: 1,
      total_games_each: 18,
      use_out_of_division: false,
      divisions: [],
      divisionGames: [],
      teams: [],
    },
  });
  const goToStep = (id: number) => {
    setActiveStep(steps[id]);
  };
  const onSubmit = useCallback((values: ScheduleWizardForm) => {
    console.log(values);
  }, []);
  return (
    <Box>
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box>
            <Stepper activeStep={activeStep.id} sx={{ marginBottom: '1em' }}>
              {steps.map((step, index) => {
                return (
                  <Step key={step.id} completed={step.completed} disabled={true}>
                    <StepLabel onClick={() => goToStep(step.id)}>{step.name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <activeStep.Component goToStep={goToStep} />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}

export default ScheduleWizard;
