import { Registration } from '@local/graphql/graphql';
import { Settings, useSettings } from '@local/hooks';
import { sortSettingsToRules } from '@local/lib';
import { Button } from '@mui/material';
import { DataGridPro, GridColDef, GridColumnVisibilityModel, GridSortingInitialState, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { boolean } from 'boolean';
import { orderBy, round } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
//import { Link } from 'react-router-dom';
import { useSeasonContext } from '@local/context/league/season-context';
import { Link } from '@mui/material';
import TeamLogo from '../TeamLogo';

export type StandingsGridProps = {
  fields?: string[];
  exportable?: boolean;
  registrations: Registration[];
};
export function StandingsGrid(props: StandingsGridProps) {
  const { season } = useSeasonContext();
  const { settingValue } = useSettings();
  const { fields, registrations, exportable } = props;
  const toNumber = (input?: any) => {
    const num = Number(input);
    if (Number.isNaN(num)) {
      return 0;
    }
    return num;
  };
  const columns: GridColDef<Registration>[] = useMemo(() => {
    return [
      {
        flex: 0.1,
        hideable: false,
        sortable: false,
        headerName: ' ',
        type: 'string',
        field: 'standings.logo',
        renderCell: (params) => {
          return <TeamLogo url={params.row.team?.logo?.url as string} maxHeight="16px" />;
        },
      },
      {
        headerName: 'Team',
        hideable: false,
        sortable: false,
        flex: 1,
        type: 'string',
        field: 'standings.team',
        valueGetter: (params) => params.row.team?.name,
        renderCell: (params) => {
          return (
            <Button
              sx={{ textTransform: 'none', fontWeight: 400, padding: '1px', justifyContent: 'flex-start', overflow: 'hidden' }}
              variant="text"
              component={Link}
              href={`/league/team/${params.row.team?.urlKey}?seasonOverride=${season.id}`} /*to={`/team/${params.row.team?.urlKey}`}*/
            >
              {params.row.team?.name}
            </Button>
          );
        },
      },
      {
        headerName: 'W',
        hideable: false,
        sortable: false,
        width: 10,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.wins',
        valueGetter: (params) => toNumber(params.row.standings?.wins),
      },
      {
        headerName: 'L',
        hideable: false,
        sortable: false,
        width: 20,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.losses',
        valueGetter: (params) => toNumber(params.row.standings?.losses),
      },
      {
        headerName: 'T',
        hideable: true,
        sortable: false,
        width: 20,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.ties',
        valueGetter: (params) => toNumber(params.row.standings?.ties),
      },
      {
        headerName: 'W%',
        hideable: true,
        sortable: false,
        flex: 0.2,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.win_loss_percent',
        valueGetter: (params) => round(toNumber(params.row.standings?.win_loss_percent), 3),
      },
      {
        headerName: 'WW%',
        hideable: true,
        sortable: false,
        flex: 0.2,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.weighted_winning_percent',
        valueGetter: (params) => round(toNumber(params.row.standings?.weighted_winning_percent), 3),
      },
      {
        headerName: 'OWP',
        hideable: true,
        sortable: false,
        flex: 0.2,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.opponents_winning_percent',
        valueGetter: (params) => round(toNumber(params.row.standings?.opponents_winning_percent), 3),
      },
      {
        headerName: 'OOWP',
        hideable: true,
        sortable: false,
        flex: 0.2,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.opponents_opponents_winning_percent',
        valueGetter: (params) => round(toNumber(params.row.standings?.opponents_opponents_winning_percent), 3),
      },
      {
        headerName: 'RPI',
        hideable: true,
        sortable: false,
        flex: 0.2,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.ratings_percentage_index',
        valueGetter: (params) => round(toNumber(params.row.standings?.ratings_percentage_index), 3),
      },
      {
        headerName: 'PWR',
        hideable: true,
        sortable: false,
        flex: 0.2,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.power_ranking',
        valueGetter: (params) => round(toNumber(params.row.standings?.power_ranking), 3),
      },
      {
        headerName: 'PF',
        hideable: true,
        sortable: false,
        flex: 0.1,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.points_for',
        valueGetter: (params) => toNumber(params.row.standings?.points_for),
      },
      {
        headerName: 'PA',
        hideable: true,
        sortable: false,
        flex: 0.1,
        align: 'right',
        headerAlign: 'right',
        field: 'standings.points_allowed',
        valueGetter: (params) => toNumber(params.row.standings?.points_allowed),
      },
    ];
  }, []);

  const filterColumns = useCallback(() => {
    if (fields) {
      return columns.filter((cd) => fields.findIndex((f) => cd.field === f) > -1);
    }
    return columns;
  }, [columns, fields]);

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const model = columns.reduce((a, c) => {
      a[c.field] = boolean(settingValue(c.field as Settings));
      return a;
    }, {} as GridColumnVisibilityModel);
    return model;
  }, [columns, settingValue]);

  const sorting: GridSortingInitialState = useMemo(() => {
    const { sortRules, sortDirections } = sortSettingsToRules(settingValue('standings.sort_rule'));
    return {
      sortModel: sortRules.map((r, idx) => ({ field: r, sort: sortDirections[idx] })),
    };
  }, [settingValue]);
  const [sortedRegistrations, setSortedRegistrations] = useState<Registration[]>([]);
  useEffect(() => {
    if (registrations) {
      const { sortRules, sortDirections } = sortSettingsToRules(settingValue('standings.sort_rule'));
      const sortedData = orderBy(registrations, sortRules, sortDirections);
      setSortedRegistrations(sortedData);
    }
  }, [registrations, settingValue]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <DataGridPro
      density="compact"
      disableColumnMenu
      disableColumnResize
      disableColumnFilter
      disableRowSelectionOnClick
      disableChildrenSorting
      hideFooter
      autoHeight
      columns={filterColumns()}
      rows={sortedRegistrations}
      slots={{
        toolbar: exportable ? CustomToolbar : undefined,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: columnVisibilityModel,
        },
        sorting: sorting,
      }}
      getRowId={(row) => String(row.id)}
    />
  );
}
