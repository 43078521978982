import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting, UpdateManySettingsDocument, UpsertSiteSettingInput } from '@local/graphql/graphql';
import { useSettings } from '@local/hooks';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormSlider, useOperations } from '@seasonticker/dls';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SettingDefaults, StandingsSettingsType } from '../../hooks/useSetting/SettingDefaults';
import { settingsValueProps } from './Settings';

const DefaultSettings = SettingDefaults.standings;

export default function StandingSettings() {
  const { operation } = useOperations();
  const { setting, setSettings } = useSettings();
  const { leagueContext } = useLeagueContext();
  const formMethods = useForm<StandingsSettingsType>({ defaultValues: DefaultSettings });

  useEffect(() => {
    formMethods.reset({
      game_outcome: {
        win: setting('standings.game_outcome.win'),
        loss: setting('standings.game_outcome.loss'),
        tie: setting('standings.game_outcome.tie'),
      },
      standing_weight: {
        home_away: setting('standings.standing_weight.home_away'),
        winning_percent: setting('standings.standing_weight.winning_percent'),
        opponents_winning_percent: setting('standings.standing_weight.opponents_winning_percent'),
        opponents_opponents_winning_percent: setting('standings.standing_weight.opponents_opponents_winning_percent'),
      },
    });
  }, [formMethods, setting]);

  const [updateManySettingsDocuments] = useMutation(UpdateManySettingsDocument);
  const onSubmit = async (settings: StandingsSettingsType) => {
    const getSettingSaveValue = (setting: SiteSetting): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        return { id, value: String(value) };
      }
      return { module, key, value: String(value), league: leagueContext?.league?.id };
    };
    await operation({
      success: 'Settings were successfully updated',
      error: 'An error occurred updating the settings',
      operation: () =>
        updateManySettingsDocuments({
          variables: {
            settings: [
              getSettingSaveValue(settings.game_outcome.win),
              getSettingSaveValue(settings.game_outcome.loss),
              getSettingSaveValue(settings.game_outcome.tie),
              getSettingSaveValue(settings.standing_weight.home_away),
              getSettingSaveValue(settings.standing_weight.winning_percent),
              getSettingSaveValue(settings.standing_weight.opponents_winning_percent),
              getSettingSaveValue(settings.standing_weight.opponents_opponents_winning_percent),
            ],
          },
        }),
      after: (response) => setSettings(response.data?.update_many_site_setting as SiteSetting[]),
    });
  };

  const percentValue = (value: number) => `${value * 100}%`;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Typography variant="body1">
          Control how the standings are calculated. Here you can adjust how many points are given for a win, loss or tie outcome of a game. You can also control
          the percentages used in the RPI and power ranking calculations, the defaults are based on the standard values.
        </Typography>
        <Typography variant="caption">
          ** Standings are calculated periodically or when a game is scored, so an adjustment here will not be visible immediately.
        </Typography>
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid xs={12}>
            <HookFormSlider
              fullWidth
              label="Win Value"
              name="game_outcome.win.value"
              max={2}
              step={0.5}
              {...settingsValueProps}
              tooltip="The amount to value a win from a game. The default value is 1, but
          in scenarios where you want to value games played more than games
          lost, you would want to set this to 2."
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSlider
              fullWidth
              label="Loss Value"
              name="game_outcome.loss.value"
              max={2}
              step={0.5}
              {...settingsValueProps}
              tooltip="The amount to value a loss from a game. The default value is 0,
          but in scenarios where you want to value games played more than
          games lost, you would want to set this to something higher than a
          0."
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSlider
              fullWidth
              label="Tie Value"
              name="game_outcome.tie.value"
              max={2}
              step={0.5}
              {...settingsValueProps}
              tooltip="The amount to value a tie from a game. The default value is 0.5,
          but in scenarios where you want to value games played more than
          games lost, you would want to set this to a higher value than the
          loss value if ties are more meaningful than losses."
            />
          </Grid>
          <Grid xs={12}>
            <HookFormSlider
              fullWidth
              label="Home/Away Weight"
              name="standing_weight.home_away.value"
              marks={[
                { value: 0, label: 'Away' },
                { value: 1, label: 'Equal' },
                { value: 2, label: 'Home' },
              ]}
              min={0}
              max={2}
              step={0.1}
              {...settingsValueProps}
              tooltip="The home and away game weights are used to calculate a weighted
          winning percent. You can use this slider to value home game wins
          and ties vs away wins and ties."
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="caption">
              The following three weights are used in the RPI calculation. These are based on the standard RPI calculation, but can be adjusted if needed. They
              need to total to 100%
            </Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <HookFormSlider
              fullWidth
              label="Winning Percent Weight"
              name="standing_weight.winning_percent.value"
              valuetext={percentValue}
              {...settingsValueProps}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <HookFormSlider
              fullWidth
              label="Opponent Winning Percent Weight"
              name="standing_weight.opponents_winning_percent.value"
              valuetext={percentValue}
              {...settingsValueProps}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <HookFormSlider
              fullWidth
              label="Opponent Opponent Winning Percent Weight"
              name="standing_weight.opponents_opponents_winning_percent.value"
              valuetext={percentValue}
              {...settingsValueProps}
            />
          </Grid>
          <Grid xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
