import { Facility } from '@local/graphql/graphql';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { MapDisplay } from '@seasonticker/dls';

export function FacilityDisplay({ facility }: { facility: Facility }) {
  return (
    <Card>
      <CardHeader title={facility.name} />
      <CardContent>
        <Typography variant="body1">{facility.fieldDescription}</Typography>
        <MapDisplay zoom={15} address={facility?.location} />
      </CardContent>
    </Card>
  );
}

export default FacilityDisplay;
