import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { sortBy } from 'lodash';
import { SetStateAction, useCallback, useState } from 'react';
import { OperationFunction, useOperations } from './useOperations';

export type SortableItem = {
  id?: string | null;
  sortOrder?: number | null;
};
export type UseSortingOptions<UpsertType extends SortableItem, UpdateResponse> = {
  upsertOperation?: (update: UpsertType[]) => OperationFunction<UpdateResponse>;
};
export function useSortableList<ModelType extends SortableItem, UpsertType extends SortableItem, UpdateResponse>(
  options: UseSortingOptions<UpsertType, UpdateResponse>
) {
  const {
    upsertOperation = (update: UpsertType[]) => {
      return undefined as unknown as OperationFunction<UpdateResponse>;
    },
  } = options;
  const { operation } = useOperations();
  const [sorting, setSorting] = useState(false);
  const [list, setListState] = useState([] as ModelType[]);

  const setList = useCallback((action: SetStateAction<ModelType[]>) => {
    setListState(sortBy(action, 'sortOrder'));
  }, []);

  const onSorted = useCallback((sortAction: SetStateAction<ModelType[]>) => {
    setListState(sortAction);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        const sortFunction = (items: ModelType[]) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        };
        onSorted(sortFunction);
      }
    },
    [onSorted]
  );

  const saveSorting = useCallback(async () => {
    const itemUpdate = list.map((item, idx) => ({ id: item.id, sortOrder: idx } as UpsertType));
    await operation({
      success: 'Sort order saved',
      error: 'There was an error saving the sort order',
      operation: upsertOperation(itemUpdate),
    });
  }, [list, operation, upsertOperation]);

  const toggleSorting = useCallback(() => {
    if (sorting) {
      saveSorting();
    }
    setSorting(!sorting);
  }, [saveSorting, sorting]);

  return {
    sorting,
    setSorting,
    list,
    setList,
    onSorted,
    saveSorting,
    toggleSorting,
    handleDragEnd,
  };
}

export default useSortableList;
