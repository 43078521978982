import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import {
  AddLeagueUserRoleDocument,
  FindUserDocument,
  GetLeagueUsersDocument,
  GetRolesDocument,
  LeagueRole,
  RemoveLeagueUserRoleDocument,
  Role,
  User,
} from '@local/graphql/graphql';
import { IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddIcon, DeleteIcon, HookFormTextField, useOperations } from '@seasonticker/dls';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type UserSearchForm = {
  email: string;
};

export function RolesEditor() {
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [modified, setModified] = useState(Date.now());
  const { leagueContext } = useLeagueContext();
  const [roles, setRoles] = useState<Role[]>([]);
  const [leagueRoles, setLeagueRoles] = useState<LeagueRole[]>([]);
  const [rolesLoader] = useLazyQuery(GetRolesDocument, { fetchPolicy: 'no-cache' });
  const [findUsersLoader] = useLazyQuery(FindUserDocument, { fetchPolicy: 'no-cache' });
  const [addLeagueRole] = useMutation(AddLeagueUserRoleDocument);
  const [searchedUsers, setSearchedUsers] = useState<User[]>([]);
  const [removeUserMutation] = useMutation(RemoveLeagueUserRoleDocument);

  const addLeagueRoleForm = useForm<UserSearchForm>();
  const [usersLoader] = useLazyQuery(GetLeagueUsersDocument, { fetchPolicy: 'no-cache' });

  const roleClick = useCallback((role: Role) => {
    setSelectedRole(role);
  }, []);

  const { operation, confirmedOperation } = useOperations();

  const findUsers = useCallback(
    async (search: UserSearchForm) => {
      const result = await findUsersLoader({ variables: search });
      if (result.data?.users) {
        setSearchedUsers(result.data.users as User[]);
      }
    },
    [findUsersLoader]
  );

  const addUser = useCallback(
    async (userId: string) => {
      if (userId && leagueContext?.league?.id && selectedRole?.id) {
        const result = await addLeagueRole({
          variables: {
            leagueId: leagueContext.league.id,
            roleId: selectedRole.id,
            userId: userId,
          },
        });
        setModified(Date.now());
      }
    },
    [addLeagueRole, leagueContext?.league?.id, selectedRole?.id]
  );

  const loadUsers = useCallback(
    async (roleId: string) => {
      const result = await usersLoader({
        variables: {
          leagueId: String(leagueContext?.league?.id),
          roleId: roleId,
        },
      });
      if (result.data?.league_roles) {
        setLeagueRoles(result.data.league_roles as LeagueRole[]);
      }
    },
    [usersLoader, leagueContext?.league?.id]
  );

  useEffect(() => {
    if (selectedRole) {
      loadUsers(selectedRole.id);
    }
  }, [loadUsers, selectedRole]);

  useEffect(() => {
    if (modified && selectedRole) {
      loadUsers(selectedRole.id);
    }
  }, [loadUsers, modified, selectedRole]);

  const removeUserFromRole = useCallback(
    async (leagueRoleId: string) => {
      if (leagueContext?.league?.id) {
        await confirmedOperation({
          confirmation: 'Are you sure you want to remove this user',
          success: 'User successfully removed',
          after: () => setModified(Date.now()),
          operation: () =>
            removeUserMutation({
              variables: {
                leagueId: String(leagueContext.league?.id),
                leagueRoleId: leagueRoleId,
              },
            }),
        });
      }
    },
    [confirmedOperation, leagueContext?.league?.id, removeUserMutation]
  );
  const loadRoles = useCallback(async () => {
    const result = await rolesLoader({
      variables: {
        roles: ['agegroup_director', 'leagueadmin', 'official'],
      },
    });
    if (result.data) {
      setRoles(result.data.roles as Role[]);
    }
  }, [rolesLoader]);
  useEffect(() => {
    loadRoles();
  }, [loadRoles]);
  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <List>
          {roles?.map((r) => {
            return (
              <ListItem key={r.id}>
                <ListItemButton onClick={() => roleClick(r)}>
                  <ListItemText primary={r.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid xs={12} md={6}>
        <Typography variant="h6">{selectedRole?.name}</Typography>
        <FormProvider {...addLeagueRoleForm}>
          <form onSubmit={addLeagueRoleForm.handleSubmit(findUsers)}>
            <HookFormTextField name="email" label="email" />
          </form>
        </FormProvider>

        <List>
          {searchedUsers?.map((u) => {
            return (
              <ListItem key={u.id}>
                <ListItemText secondary={`${u?.firstName} ${u?.lastName}`} primary={u?.email} />
                <ListItemSecondaryAction>
                  <IconButton color="primary" onClick={() => addUser(u.id)}>
                    <AddIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>

        <List>
          {leagueRoles?.map((r) => {
            return (
              <ListItem key={r.id}>
                <ListItemText secondary={`${r.user?.firstName} ${r.user?.lastName}`} primary={r.user?.email} />
                <ListItemSecondaryAction>
                  <IconButton color="error" onClick={() => removeUserFromRole(r.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
}

export default RolesEditor;
