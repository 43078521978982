import { LeagueContext } from '@local/context/league/league-context';
import { Page, useNavigationContext } from '@local/context/league/navigation-context';
import { useSettings } from '@local/hooks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Link, LinkProps, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { VisibleIf, useAuthz } from '@seasonticker/dls';
import PopupState, { InjectedProps, bindMenu, bindTrigger } from 'material-ui-popup-state';
import { forwardRef, useContext, useMemo, useState } from 'react';
import { NavLink, NavLinkProps, Outlet } from 'react-router-dom';
import UserNavMenu from '../users/UserNavMenu';
import SeasonSelection from './SeasonSelection';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export function MenuPopupState({ page }: { page: Page }) {
  const { name, icon, children } = page;
  const { leagueContext } = useContext(LeagueContext);
  const { isInRole } = useAuthz();

  const subMenuRoleFilter = (page: Page) => {
    if (page.roles) {
      if (isInRole({ context: leagueContext?.league?.id, roles: page.roles })) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <Tooltip title={name} placement="right-end">
            <ListItemButton {...bindTrigger(popupState)}>
              <ListItemIcon sx={{ '.Mui-selected > &': { color: (theme) => theme.palette.primary.main } }}>{icon}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </Tooltip>
          <Menu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            {...bindMenu(popupState)}>
            {children?.filter(subMenuRoleFilter).map((child) => (
              <DrawerButtonItem key={child.name} page={child} popupState={popupState} />
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
}

const DrawerButtonItem = ({ page, popupState }: { page: Page } & { popupState?: InjectedProps }) => {
  const { name, link, icon, end = false, url } = page;
  type MyNavLinkProps = Omit<NavLinkProps, 'to'>;
  type MyLinkProps = Omit<LinkProps, 'href'>;
  const onClick = () => {
    if (popupState) {
      popupState.close();
    }
  };
  const InnerNavLink = useMemo(() => {
    return url
      ? forwardRef<HTMLAnchorElement, MyLinkProps>((linkProps, ref) => <Link {...linkProps} ref={ref} href={url} />)
      : forwardRef<HTMLAnchorElement, MyNavLinkProps>((navLinkProps, ref) => <NavLink {...navLinkProps} end={end} ref={ref} to={String(link)} />);
  }, [end, link, url]);
  return (
    <Tooltip title={name} placement="right-end">
      <ListItemButton onClick={onClick} component={InnerNavLink}>
        <VisibleIf cond={icon !== null && icon !== undefined}>
          <ListItemIcon sx={{ '.Mui-selected > &': { color: (theme) => theme.palette.primary.main } }}>{icon}</ListItemIcon>
        </VisibleIf>
        <ListItemText primary={name} />
      </ListItemButton>
    </Tooltip>
  );
};

const DrawerNavItem = (props: Page) => {
  const { children } = props;

  return <>{children && children.length ? <MenuPopupState page={props} /> : <DrawerButtonItem page={props} />}</>;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const LogoImage = styled('img')({ maxHeight: '45px', mr: '.8em' });

export default function Layout() {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { leagueContext } = useContext(LeagueContext);
  const { isInRole } = useAuthz();
  const { pages, secondaryPages } = useNavigationContext();
  const { settingValue } = useSettings();

  const pageRoleFilter = (page: Page) => {
    if (page.roles) {
      if (isInRole({ context: leagueContext?.league?.id, roles: page.roles })) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const leagueLink = useMemo(() => {
    return settingValue('navigation.league_link');
  }, [settingValue]);

  const navigationItems = useMemo(() => {
    try {
      return JSON.parse(settingValue('navigation.navigation_items')) as { label: string; link: string }[];
    } catch (ex) {
      return [];
    }
  }, [settingValue]);

  const HeaderNavigation = () => {
    if (Number(leagueLink?.length) > 0) {
      return (
        <Typography
          variant="h6"
          noWrap
          component="a"
          target="_blank"
          href={leagueLink}
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            color: 'inherit',
            lineHeight: '2em',
            textDecoration: 'none',
          }}>
          <VisibleIf cond={Number(leagueContext?.league?.logo?.url?.length) > 0}>
            <LogoImage src={String(leagueContext?.league?.logo?.url)} alt="logo" />
          </VisibleIf>

          {leagueContext?.league?.name}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h6"
          noWrap
          component={NavLink}
          to="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            color: 'inherit',
            lineHeight: '2em',
            textDecoration: 'none',
          }}>
          <VisibleIf cond={Number(leagueContext?.league?.logo?.url?.length) > 0}>
            <LogoImage src={String(leagueContext?.league?.logo?.url)} alt="logo" />
          </VisibleIf>

          {leagueContext?.league?.name}
        </Typography>
      );
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute" open={open} enableColorOnDark>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <HeaderNavigation />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {navigationItems.map((page) => (
              <Button key={page.label} sx={{ my: 2, color: 'white', display: 'block' }} component="a" href={page.link} target="_blank">
                {page.label}
              </Button>
            ))}
          </Box>
          <SeasonSelection />
          <UserNavMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {pages.filter(pageRoleFilter).map((page) => (
            <DrawerNavItem key={page.name} {...page} />
          ))}
          <Divider sx={{ my: 1 }} />
          {secondaryPages.filter(pageRoleFilter).map((page) => (
            <DrawerNavItem key={page.name} {...page} />
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}>
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{ p: '1em' }}>
            <Outlet />
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}
