import { CancelIcon, DeleteIcon, EditIcon } from '../../icons';

import { Card, CardContent, CardHeader, CardProps, IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { IfInRole } from '../../auth';

type EditableCardProps = CardProps & {
  title: ReactNode;
  viewDisplay: ReactElement;
  editDisplay: ReactElement;
  canDelete: boolean;
  canEdit: IfInRole;
  editMode: boolean;
  onEditClick: (editing: boolean) => void;
  onDeleteClick: () => void;
};
export function EditableCard(props: EditableCardProps) {
  const { canDelete, title, canEdit, viewDisplay, editDisplay, editMode, onEditClick, onDeleteClick } = props;

  return (
    <Card>
      <CardHeader
        title={title}
        action={canEdit(
          <>
            <IconButton
              hidden={canDelete}
              color="error"
              onClick={() => {
                onDeleteClick();
              }}>
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                onEditClick(!editMode);
              }}>
              {editMode ? <CancelIcon /> : <EditIcon />}
            </IconButton>
          </>
        )}
      />
      <CardContent>{editMode ? editDisplay : viewDisplay}</CardContent>
    </Card>
  );
}

export default EditableCard;
