import { Season, UpsertSeasonInput } from '@local/graphql/graphql';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormDatePicker, HookFormTextField, ModalForm, ModalFormImplementationProps } from '@seasonticker/dls';

export function SeasonForm(props: ModalFormImplementationProps<Season, UpsertSeasonInput>) {
  const FormFields = () => {
    return (
      <Grid container rowSpacing={'1em'}>
        <Grid xs={12} md={6}>
          <HookFormTextField name="name" rules={{ required: 'Name is required' }} label="Name" fullWidth />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormDatePicker name="start" rules={{ required: 'Season open date is required' }} label="Season Open" fullWidth />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormDatePicker name="end" rules={{ required: 'Season close date is required' }} label="Season Close" fullWidth />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormDatePicker name="registrationOpen" rules={{ required: 'Open registration date is required' }} label="Open Registration" fullWidth />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormDatePicker name="registrationClose" rules={{ required: 'Close registration date is required' }} label="Close Registration" fullWidth />
        </Grid>
      </Grid>
    );
  };
  return <ModalForm {...props} FormFields={FormFields} defaultValues={{ name: '' }} />;
}

export default SeasonForm;
