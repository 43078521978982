import { useMutation } from '@apollo/client';
import { SortOptionType } from '@local/components';
import { useLeagueContext } from '@local/context/league/league-context';
import { useSeasonContext } from '@local/context/league/season-context';
import { SiteSetting, UpdateManySettingsDocument, UpsertSiteSettingInput } from '@local/graphql/graphql';
import { STANDINGS_MODULE, SettingDefaults, StandingsSettingsType, useSettings } from '@local/hooks';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button, Checkbox, MenuItem, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { FieldSet, HookFormAutoComplete, HookFormSwitch, Legend, useOperations } from '@seasonticker/dls';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const options: SortOptionType[] = [
  { label: 'Wins', id: 'standings.wins|desc' },
  { label: 'Losses', id: 'standings.losses|desc' },
  { label: 'Ties', id: 'standings.ties|desc' },
  { label: 'Win %', id: 'standings.win_loss_percent|desc' },
  { label: 'Weighted Win %', id: 'standings.weighted_win_loss_percent|desc' },
  { label: 'RPI', id: 'standings.rating_percent_index|des' },
  { label: 'Power Ranking', id: 'standings.power_ranking|desc' },
  { label: 'Points For', id: 'standings.points_for|desc' },
  { label: 'Points Allowed', id: 'standings.points_allowed|asc' },
];
type SettingMode = 'season' | 'league';
type ListSetting<ListType> = Omit<SiteSetting, 'value'> & {
  value: ListType[];
};
type SettingForm = Omit<StandingsSettingsType, 'sort_rule'> & {
  sort_rule: ListSetting<SortOptionType>;
};

export function StandingsDisplaySettings() {
  const [settingsMode, setSettingsMode] = useState<SettingMode>('league');
  const { operation } = useOperations();
  const { setting, setSettings } = useSettings();
  const { season } = useSeasonContext();
  const { leagueContext } = useLeagueContext();
  const formMethods = useForm<SettingForm>({
    defaultValues: { ...SettingDefaults[STANDINGS_MODULE], sort_rule: { ...SettingDefaults[STANDINGS_MODULE].sort_rule, value: [options[6]] } },
  });

  useEffect(() => {
    const sortSetting = setting('standings.sort_rule', settingsMode, season?.id);
    let sortValues;
    try {
      sortValues = JSON.parse(sortSetting.value) as SortOptionType[];
    } catch (ex) {
      sortValues = [options[6]];
    }
    const sortRules: ListSetting<SortOptionType> = {
      ...sortSetting,
      value: sortValues,
    };
    formMethods.reset({
      losses: setting('standings.losses', settingsMode, season?.id),
      wins: setting('standings.wins', settingsMode, season?.id),
      ties: setting('standings.ties', settingsMode, season?.id),
      win_loss_percent: setting('standings.win_loss_percent', settingsMode, season?.id),
      weighted_winning_percent: setting('standings.weighted_winning_percent', settingsMode, season?.id),
      opponents_winning_percent: setting('standings.opponents_winning_percent', settingsMode, season?.id),
      opponents_opponents_winning_percent: setting('standings.opponents_opponents_winning_percent', settingsMode, season?.id),
      ratings_percentage_index: setting('standings.ratings_percentage_index', settingsMode, season?.id),
      power_ranking: setting('standings.power_ranking', settingsMode, season?.id),
      points_for: setting('standings.points_for', settingsMode, season?.id),
      points_allowed: setting('standings.points_allowed', settingsMode, season?.id),
      sort_rule: sortRules,
    });
  }, [formMethods, season?.id, setting, settingsMode]);

  const settingModeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const mode = event.target.value as SettingMode;
    setSettingsMode(mode);
  }, []);
  const [updateManySettingsDocuments] = useMutation(UpdateManySettingsDocument);
  const onSubmit = async (settings: SettingForm) => {
    const getSettingSaveValue = (setting: SiteSetting): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        if ((settingsMode === 'league' && setting.league?.id) || (settingsMode === 'season' && setting.season?.id)) {
          return { id, value: String(value) };
        }
      }
      if (settingsMode === 'league') {
        return { module, key, value, league: leagueContext?.league?.id };
      } else {
        return { module, key, value, season: season?.id, league: leagueContext?.league?.id };
      }
    };
    const getListSettingSaveValue = (setting: ListSetting<SortOptionType>): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        if ((settingsMode === 'league' && setting.league?.id) || (settingsMode === 'season' && setting.season?.id)) {
          return { id, value: JSON.stringify(value) };
        }
      }
      if (settingsMode === 'league') {
        return { module, key, value: JSON.stringify(value), league: leagueContext?.league?.id };
      } else {
        return { module, key, value: JSON.stringify(value), season: season?.id, league: leagueContext?.league?.id };
      }
    };
    await operation({
      success: 'Settings were successfully updated',
      error: 'An error occurred updating the settings',
      operation: () =>
        updateManySettingsDocuments({
          variables: {
            settings: [
              getSettingSaveValue(settings.wins),
              getSettingSaveValue(settings.losses),
              getSettingSaveValue(settings.ties),
              getSettingSaveValue(settings.win_loss_percent),
              getSettingSaveValue(settings.weighted_winning_percent),
              getSettingSaveValue(settings.opponents_winning_percent),
              getSettingSaveValue(settings.opponents_opponents_winning_percent),
              getSettingSaveValue(settings.ratings_percentage_index),
              getSettingSaveValue(settings.power_ranking),
              getSettingSaveValue(settings.points_allowed),
              getSettingSaveValue(settings.points_for),
              getListSettingSaveValue(settings.sort_rule),
            ],
          },
        }),
      after: (response) => setSettings(response.data?.update_many_site_setting as SiteSetting[]),
    });
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FieldSet>
          <Legend>Standings Sorting</Legend>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12}>
              <TextField select label="Apply Settings to:" fullWidth value={settingsMode} onChange={settingModeChange}>
                <MenuItem value="league">League</MenuItem>
                <MenuItem value="season"> Season: {season?.name}</MenuItem>
              </TextField>
            </Grid>
            <Grid xs={12}>
              <HookFormAutoComplete
                fullWidth
                multiple
                disableCloseOnSelect
                label="Sort Rules"
                tooltip="Select the fields that are used to calculate the standing sorting.  Here the order matters, the first in this list if the first criteria, then subsequent rules follow"
                name="sort_rule.value"
                getOptionLabel={(option: SortOptionType) => {
                  return option.label;
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.label}
                  </li>
                )}
                options={options}
              />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Standings Columns</Legend>
          <Typography variant="body2">Choose the columns to show for the standings</Typography>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="Wins"
                name="wins.value"
                tooltip="Wins Column, this is the raw number and not affected by the win value per game"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="Losses"
                name="losses.value"
                tooltip="Loss Column, this is the raw count and not affected by the loss value per game"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="Ties"
                name="ties.value"
                tooltip="Ties Column, this is the raw value and not affected by the ties value per game"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="Win %"
                name="win_loss_percent.value"
                tooltip="Win Loss Percent, this is affected by the win, loss and tie values.  The basic formula is (w * winValue) + (t * tieValue) + (l * lossValue) / gamePlayed"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="Weighted Win %"
                name="weighted_winning_percent.value"
                tooltip="Weighted win value.  This is similar to the Win Loss Percent except home and away games can be calculated with different weights"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="OWP"
                name="opponents_winning_percent.value"
                tooltip="Opponents winning percent.  This is calculated for all opponent matches except where they played the base team where the OWP is being calculated"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="OOWP"
                name="opponents_opponents_winning_percent.value"
                tooltip="Opponents Opponents winning percent.  This is calculated for all of the opponents of the opponents except for games where the base team or the first level opponent is involved.  It's the opponents opponents winning percent in all other games not related to the base team or the first level opponent."
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="RPI"
                name="ratings_percentage_index.value"
                tooltip="Rating Percent Index. This is a calculation of aggregating the Win Loss Percent, OWP and OOWP to create a strength of schedule factor to the Win Loss Percent"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch
                fullWidth
                label="Power Ranking"
                name="power_ranking.value"
                tooltip="Power Ranking is a Season Ticker specific algorithm that further refines the RPI based on smaller schedules and shorter seasons"
              />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch fullWidth label="Points For" name="points_for.value" tooltip="Cumulative points scored for the season" />
            </Grid>
            <Grid xs={12} sm={4} md={3}>
              <HookFormSwitch fullWidth label="Points Allowed" name="points_allowed.value" tooltip="Cumulative points allowed for the season" />
            </Grid>
          </Grid>
        </FieldSet>

        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default StandingsDisplaySettings;
