import { lowerCase } from 'lodash';
import { useCallback, useState } from 'react';
import { OperationFunction, useOperations } from './useOperations';

export type EditableItem = {
  id?: string | null;
};

export type EditableOptions<ModelType extends EditableItem, UpsertType, UpsertResponse, DeleteResponse> = {
  model: string;
  emptyTemplate: ModelType;
  prepareUpsert: (item: ModelType) => Promise<UpsertType> | UpsertType;
  refresh: () => void;
  upsertMutation: (update: UpsertType) => OperationFunction<UpsertResponse>;
  deleteMutation: (item: ModelType) => OperationFunction<DeleteResponse>;
};

export function useEditable<ModelType extends EditableItem, UpsertType, UpsertResponse, DeleteResponse>(
  options: EditableOptions<ModelType, UpsertType, UpsertResponse, DeleteResponse>
) {
  const { model, emptyTemplate, prepareUpsert: prepareUpdate, refresh, upsertMutation, deleteMutation } = options;
  const [editMode, setEditMode] = useState(false);
  const [editingItem, setEditingItem] = useState<ModelType>();
  const { confirmedOperation, operation } = useOperations();

  const onEdit = useCallback((item: ModelType) => {
    setEditMode(true);
    setEditingItem(item);
  }, []);

  const onAdd = useCallback(
    (callback?: (item: ModelType) => void) => {
      setEditMode(true);
      setEditingItem(emptyTemplate);
      if (callback) {
        callback(emptyTemplate);
      }
    },
    [emptyTemplate]
  );

  const onDelete = useCallback(
    async (item: ModelType) => {
      await confirmedOperation({
        success: `${model} successfully deleted`,
        confirmation: `Are you sure you want to remove this ${lowerCase(model)}`,
        operation: deleteMutation(item),
        after: () => refresh(),
      });
    },
    [confirmedOperation, deleteMutation, model, refresh]
  );

  const onSave = useCallback(
    async (item: ModelType) => {
      const update = await prepareUpdate(item);
      await operation({
        success: `${model} updated`,
        operation: upsertMutation(update),
        after: () => {
          setEditingItem(undefined);
          setEditMode(false);
          refresh();
        },
      });
    },
    [model, operation, prepareUpdate, refresh, upsertMutation]
  );

  return {
    editMode,
    setEditMode,
    editingItem,
    setEditingItem,
    onAdd,
    onEdit,
    onDelete,
    onSave,
  };
}

export default useEditable;
