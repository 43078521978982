import { useLazyQuery } from '@apollo/client';
import { TeamLogo } from '@local/components';
import { MyTeamsDocument, Team } from '@local/graphql/graphql';
import { useSettings } from '@local/hooks';
import { Box, Button, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { VisibleIf, useAuth } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RegisterForm, RegistrationStepProps } from './Steps';

export function RegistrationConfirmation(props: RegistrationStepProps<RegisterForm>) {
  const { formProps } = props;
  const { getValues } = formProps;
  const { settingValue } = useSettings();
  const values = getValues();
  const { seasonId } = useParams();
  const { user } = useAuth();
  const [myTeams, setMyTeams] = useState<Team[]>([]);
  const [loadMyTeams] = useLazyQuery(MyTeamsDocument, { fetchPolicy: 'no-cache' });
  const teamLoader = useCallback(async () => {
    if (user?.profile?.sub) {
      const response = await loadMyTeams({ variables: { id: user.profile.sub } });
      const teams: Team[] = [
        ...(response.data?.user.managed_teams || []).filter((t) => {
          if (t.registrations?.find((r) => r.season?.id === seasonId)) {
            return true;
          }
          return false;
        }),
        ...(response.data?.user.positions?.filter((t) => t.positionType === 'team administrator')?.map((t) => t.team) || []).filter((t) => {
          if (t?.registrations?.find((r) => r.season?.id === seasonId)) {
            return true;
          }
          return false;
        }),
      ] as Team[];
      setMyTeams(uniqBy(teams, 'id'));
    }
  }, [loadMyTeams, seasonId, user?.profile?.sub]);

  useEffect(() => {
    teamLoader();
  }, [teamLoader]);
  return (
    <Box>
      <Grid container>
        <Grid xs={12} md={6}>
          <Typography variant="h4">Review</Typography>
          <Typography variant="h6">
            Team Name{': '}
            <Typography variant="body1" component="span">
              {values.team?.name}
            </Typography>
          </Typography>

          <Typography variant="h6">
            Age Group{': '}
            <Typography variant="body1" component="span">
              {values.age_group?.name}
            </Typography>
          </Typography>

          <Typography variant="h6">
            Competition Level{': '}
            <Typography variant="body1" component="span">
              {values.team_level?.name}
            </Typography>
          </Typography>

          <VisibleIf cond={boolean(settingValue('registration.include_division'))}>
            <>
              <Typography variant="h6">
                Division{': '}
                <Typography variant="body1" component="span">
                  {values.division?.name}
                </Typography>
              </Typography>
            </>
          </VisibleIf>
          <Typography variant="h6">Manager</Typography>
          <VisibleIf cond={values.manager?.id !== null && values.manager?.id !== ''}>
            <Typography variant="body1">You</Typography>
          </VisibleIf>
          <VisibleIf cond={values.manager?.id === null || values.manager?.id === ''}>
            <>
              <Typography variant="body1">
                {values.manager?.firstName} {values.manager?.lastName}
              </Typography>
              <Typography variant="body1">{values.manager?.email}</Typography>
              <Typography variant="body1">{values.manager?.cellPhone}</Typography>
            </>
          </VisibleIf>
          <VisibleIf cond={values.administrator?.id !== undefined && values.administrator?.id !== null && values.administrator?.id !== ''}>
            <>
              <Typography variant="h6">Team Administrator</Typography>
              <Typography variant="body1">You</Typography>
            </>
          </VisibleIf>

          <Button variant="contained" color="primary" type="submit" sx={{ mt: '.8em' }}>
            Register
          </Button>
        </Grid>
        <Grid xs={12} md={6}>
          <VisibleIf cond={myTeams?.length > 0}>
            <>
              <Typography variant="body1">These are the teams that you already have registered for this season</Typography>
              <List>
                {myTeams?.map((team) => {
                  return (
                    <ListItem>
                      <ListItemAvatar>
                        <TeamLogo url={team.logo?.url} />
                      </ListItemAvatar>
                      <ListItemText primary={team?.name} />
                    </ListItem>
                  );
                })}
              </List>
            </>
          </VisibleIf>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RegistrationConfirmation;
