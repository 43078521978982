import { Card, CardContent } from '@mui/material';
import StandingsComponent from './Standings';

export default function StandingsWidgetComponent() {
  return (
    <Card>
      <CardContent>
        <StandingsComponent fields={['standings.team', 'standings.logo', 'standings.wins', 'standings.losses']} />
      </CardContent>
    </Card>
  );
}
