import { useLeagueContext } from '@local/context/league/league-context';
import { useSettings } from '@local/hooks';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { Card, CardContent, CardHeader, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { VisibleIf } from '@seasonticker/dls';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export function RegistrationWidget() {
  const { settingValue } = useSettings();
  const { leagueContext } = useLeagueContext();
  const openRegistrationSeasons = useMemo(() => {
    return leagueContext?.league?.seasons?.filter((season) => {
      const now = new Date().toJSON();
      return now > season.registrationOpen && season.registrationClose > now;
    });
  }, [leagueContext?.league?.seasons]);
  return (
    <VisibleIf cond={(openRegistrationSeasons?.length || 0) > 0}>
      <Card>
        <CardHeader title={settingValue('registration.registration_widget_title')} avatar={<AnnouncementIcon color="secondary" />} />
        <CardContent>
          <Typography variant="body1">Start the process to register for an upcoming season below.</Typography>
          <List>
            {openRegistrationSeasons?.map((season) => {
              return (
                <ListItem key={season.id}>
                  <ListItemButton component={Link} to={`/register/${season.id}`}>
                    {season.name}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </VisibleIf>
  );
}

export default RegistrationWidget;
