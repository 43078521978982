import { ProtectedRoute, ProtectedTab } from '@local/auth';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Roles } from '@seasonticker/dls';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLeagueContext } from '../../context/league/league-context';
import { AssignDivisions } from './AssignDivisions';
import { GameManager } from './games/GameManager';
import { GenerateStandings } from './GenerateStandings';
import Registrations from './Registration';
import ScheduleWizard from './schedule/ScheduleWizard';
import { Scheduling } from './scheduling/Scheduling';

export default function ManageRoutes() {
  const { leagueContext } = useLeagueContext();
  const [currentTab, setCurrentTab] = useState('divisions');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const parts = pathname?.split('/');
    if (parts?.length > 2) {
      setCurrentTab(pathname?.split('/').pop() || 'registrations');
    } else {
      navigate('./registrations');
    }
  }, [navigate, pathname]);
  const handleChange = (event: SyntheticEvent, value: string) => {
    setCurrentTab(value);
  };
  return (
    <TabContext value={currentTab}>
      <TabList onChange={handleChange} aria-label="Season Management Tabs">
        <ProtectedTab
          context={leagueContext?.league?.id}
          roles={[Roles.admin, Roles.leagueadmin]}
          label="Registrations"
          value="registrations"
          to="./registrations"
        />
        <ProtectedTab
          context={leagueContext?.league?.id}
          roles={[Roles.leagueadmin, Roles.admin]}
          label="Assign Divisions"
          value="divisions"
          to="./divisions"
        />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin]} label="Generate Schedule" value="schedule" to="./schedule" />
        <ProtectedTab
          context={leagueContext?.league?.id}
          roles={[Roles.admin, Roles.leagueadmin]}
          label="Manage Scheduling"
          value="scheduling"
          to="./scheduling"
        />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin]} label="Manage Games" value="games" to="./games" />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin]} label="Generate Standings" value="standings" to="./standings" />
      </TabList>
      <Routes>
        <Route
          path="/divisions"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.leagueadmin, Roles.admin]}>
              <AssignDivisions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/standings"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
              <GenerateStandings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registrations"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <Registrations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
              <ScheduleWizard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/scheduling"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <Scheduling />
            </ProtectedRoute>
          }
        />
        <Route
          path="/games"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
              <GameManager />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Outlet />
    </TabContext>
  );
}
