import { ProtectedRoute, ProtectedTab } from '@local/auth';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { Roles } from '@seasonticker/dls';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLeagueContext } from '../../context/league/league-context';
import Carousels from './Carousels';
import Design from './Design';
import LeagueSettings from './LeagueSettings';
import { NavigationSettings } from './NavigationSettings';
import RegistrationSettings from './RegistrationSettings';
import RosterSettings from './RosterSettings';
import StandingsDisplaySettings from './StandingDisplaySettings';
import StandingSettings from './StandingSettings';
import Waivers from './Waivers';

export default function SettingsPanel() {
  const { leagueContext } = useLeagueContext();
  const [currentTab, setCurrentTab] = useState('league');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const parts = pathname?.split('/');
    if (parts?.length > 2) {
      setCurrentTab(pathname?.split('/').pop() || 'league');
    } else {
      navigate('./league');
    }
  }, [navigate, pathname]);
  const handleChange = (event: SyntheticEvent, value: string) => {
    setCurrentTab(value);
  };
  return (
    <TabContext value={currentTab}>
      <TabList onChange={handleChange} aria-label="lab API tabs example">
        <Tab label="League" value="league" component={Link} to="./league" />
        <Tab label="Registration" value="registration" component={Link} to="./registration" />
        <Tab label="Roster" value="roster" component={Link} to="./roster" />
        <Tab label="Design" value="design" component={Link} to="./design" />
        <Tab label="Carousels" value="carousels" component={Link} to="./carousels" />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin]} label="Navigation" value="navbar" to="./navbar" />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin]} label="Standings" value="standings_display" to="./standings_display" />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin]} label="Standing Calcs" value="standings" to="./standings" />
        <ProtectedTab context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]} label="Waivers" value="waivers" to="./waivers" />
      </TabList>
      <Routes>
        <Route path="/league" element={<LeagueSettings />} />
        <Route path="/registration" element={<RegistrationSettings />} />
        <Route path="/roster" element={<RosterSettings />} />
        <Route path="/design" element={<Design />} />
        <Route path="/carousels" element={<Carousels />} />
        <Route
          path="/navbar"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
              <NavigationSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/standings"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
              <StandingSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/standings_display"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
              <StandingsDisplaySettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/waivers"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin, Roles.leagueadmin]}>
              <Waivers />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Outlet />
    </TabContext>
  );
}
