import { useDroppable } from '@dnd-kit/core';
import { useSeasonContext } from '@local/context';
import { AgeGroup, Division, TeamLevel } from '@local/graphql/graphql';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import useSorting from '../../hooks/useSorting/useSorting';

export type DivisionTreeProps = {
  onClick: (ageGroup: AgeGroup, teamLevel: TeamLevel, division?: Division) => void;
};

export type DivisionTreeDropData = {
  age_group: AgeGroup;
  team_level: TeamLevel;
  division: Division;
};

export function DivisionTree(props: DivisionTreeProps) {
  const { season } = useSeasonContext();
  const sort = useSorting();
  const { onClick } = props;

  const DroppableTeamLevelTreeItem = (props: { age_group: AgeGroup; team_level: TeamLevel; division: Division } & TreeItemProps) => {
    const { age_group, team_level, division, ...rest } = props;
    const { setNodeRef, isOver } = useDroppable({
      id: team_level.id,
      data: {
        age_group: age_group,
        team_level: team_level,
        division: division,
      } as DivisionTreeDropData,
    });
    return (
      <TreeItem {...rest} ref={setNodeRef} sx={(theme) => ({ backgroundColor: isOver ? theme.palette.action.hover : theme.palette.background.default })} />
    );
  };
  return (
    <TreeView aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {sort(season?.age_groups)?.map((ag) => {
        return (
          <TreeItem key={ag.id} nodeId={ag.id} label={ag.name}>
            {sort(ag?.team_levels)?.map((tl) => {
              return (
                <TreeItem key={tl.id} nodeId={tl.id} label={tl.name} onClick={() => onClick(ag, tl)}>
                  {sort(tl?.divisions)?.map((d) => {
                    return (
                      <DroppableTeamLevelTreeItem
                        age_group={ag}
                        team_level={tl}
                        division={d}
                        key={d.id}
                        nodeId={d.id}
                        label={d.name}
                        onClick={() => onClick(ag, tl, d)}
                      />
                    );
                  })}
                </TreeItem>
              );
            })}
          </TreeItem>
        );
      })}
    </TreeView>
  );
}
