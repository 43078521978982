import { parseJSON } from 'date-fns';

const safeParseJson = (input: string | undefined) => {
  let date: Date | undefined = undefined;
  try {
    if (input) {
      date = parseJSON(input);
    }
  } catch (ex) {
    console.log(ex);
  }
  return date;
};

export const useDateFns = () => ({
  safeParseJson: safeParseJson,
});
