import { Division, Game, Registration } from '@local/graphql/graphql';
import { clone, each, filter, map, min, reduce, remove, shuffle } from 'lodash';

export type ScheduleConfig = {
  attempts: number;
  maxAttempts: number;
  gamesEach: number;
  maxGames: number;
  maxOutOfDivisionGames: number;
  useDivisionTeams: boolean;
  useOutOfDivisionTeams: boolean;
};

export class SchedulingService {
  divisionGames(team: Registration) {
    return filter(team.home_games, function (value) {
      return team.division?.id === value.away_team?.division?.id;
    }).concat(
      filter(team.away_games, function (value) {
        return team.division?.id === value.home_team?.division?.id;
      })
    );
  }

  outOfDivisionGames(team: Registration) {
    return filter(team.home_games, function (value) {
      return team.division?.id !== value.away_team?.division?.id;
    }).concat(
      filter(team.away_games, function (value) {
        return team.division?.id !== value.home_team?.division?.id;
      })
    );
  }

  totalGamesTeam(team: Registration) {
    return (team.home_games ? team.home_games.length : 0) + (team.away_games ? team.away_games.length : 0);
  }

  totalGamesAll(allTeams: Registration[]) {
    let away = reduce(
      map(allTeams, function (item) {
        return item.away_games ? item.away_games.length : 0;
      }),
      function (sum: number, n: number) {
        return sum + n;
      },
      0
    );
    let home = reduce(
      map(allTeams, function (item) {
        return item.home_games ? item.home_games.length : 0;
      }),
      function (sum: number, n: number) {
        return sum + n;
      },
      0
    );
    return away + home;
  }

  generateSchedule(divisions: Division[], config: ScheduleConfig): void {
    debugger;
    if (config.attempts >= config.maxAttempts) config.attempts = 0;
    config.attempts++;
    console.info('building schedule ', config.attempts);
    each(divisions, (division) => {
      each(division.registrations, (registration) => {
        remove(registration.away_games || [], (game) => {
          return game.id == null;
        });
        remove(registration.home_games || [], (game) => {
          return game.id == null;
        });
      });
    });

    each(divisions, (division: Division) => {
      let teams = clone(division.registrations) || [];
      if (teams.length <= 0) return;
      teams = shuffle(teams);
      this.buildDivisionSchedule(teams, config);
    });
    if (config.useOutOfDivisionTeams) {
      this.buildOutOfDivisionSchedule(divisions, config);
      if (config.useDivisionTeams) {
        divisions.forEach((division) => {
          this.fillSeasonWithDivisionGames(division.registrations || [], { ...config, maxGames: 1 });
        });
      }
      divisions?.forEach((division) => {
        division.registrations?.forEach((team) => {
          if ((team.home_games?.length || 0) + (team.away_games?.length || 0) < config.maxGames) {
            if (config.attempts < 500) {
              this.generateSchedule(divisions, config);
            }
          }
        });
      });
    }
  }

  buildOutOfDivisionSchedule(divisions: Division[], config: ScheduleConfig): void {
    let gamesEach = config.maxGames / 2;
    let teams: Registration[] = [];
    each(divisions, (division) => {
      teams.push(...(division.registrations || []));
    });

    let matchMatrix = new Array(teams.length).fill(0).map(() => new Array(teams.length).fill(0));

    teams = shuffle(teams);
    let rounds = config.maxOutOfDivisionGames + teams.length + 1;
    for (let x = 0; x < rounds; x++) {
      for (let j = 0; j < teams.length; j++) {
        for (let i = 0; i < teams.length; i++) {
          let homeTeam = i;
          let awayTeam = (i + j) % teams.length;
          if (homeTeam === awayTeam) {
            continue;
          }
          let ht = teams[homeTeam];
          let at = teams[awayTeam];
          if (ht.division?.id === at.division?.id) {
            continue;
          }
          const ht_games = ht.home_games?.length || 0;
          const at_games = at.away_games?.length || 0;
          if (ht_games >= gamesEach || at_games >= gamesEach) {
            continue;
          }
          if (matchMatrix[homeTeam][awayTeam] + matchMatrix[awayTeam][homeTeam] >= config.maxOutOfDivisionGames) {
            //console.log("skipping", ht.team.name, at.team.name, matchMatrix[homeTeam][awayTeam], matchMatrix[awayTeam][homeTeam]);
            continue;
          }
          matchMatrix[homeTeam][awayTeam] += 1;
          let gm = {
            home_team: { id: teams[homeTeam].id, team: teams[homeTeam].team },
            away_team: { id: teams[awayTeam].id, team: teams[awayTeam].team },
          } as Game;
          teams[homeTeam]?.home_games?.push(gm);
          teams[awayTeam]?.away_games?.push(gm);
        }
      }
    }

    //console.log(matchMatrix);
  }

  fillSeasonWithDivisionGames(teams: Registration[], config: ScheduleConfig) {
    const totalGamesArray = map(teams, (team) => (team.away_games?.length || 0) + (team.home_games?.length || 0));
    const minGames = min(totalGamesArray) || config.maxGames;
    console.log('the minimum number of games', minGames, totalGamesArray);
    for (let i = minGames; i < config.maxGames; i++) {
      const teamsToSchedule = filter(teams, (team) => {
        return (team.away_games?.length || 0) + (team.home_games?.length || 0) == i;
      });
      console.log('the teams to schedule with remaining games are: ', teamsToSchedule);
      this.buildDivisionSchedule(teamsToSchedule, {
        ...config,
        maxGames: 1,
      });
    }
  }

  buildDivisionSchedule(teams: Registration[], config: ScheduleConfig) {
    let matchMatrix = new Array(teams.length).fill(0).map(() => new Array(teams.length).fill(0));
    let gamesEachRule = (teams.length - 1) * config.gamesEach;
    let maxGamesRule = Math.ceil(config.maxGames / 2);
    let homeGames = config.gamesEach > 0 ? Math.min(gamesEachRule, maxGamesRule) : maxGamesRule;
    let unmatchFactor = teams.length / (teams.length - 1);
    let rounds = Math.ceil(homeGames * unmatchFactor); // Math.max(1, Math.floor(config.maxGames / (teams.length - 1)));
    for (let j = 1; j < rounds; j++) {
      for (let i = 0; i < teams.length; i++) {
        let homeTeam = i;
        let awayTeam = (i + j) % teams.length;
        if (homeTeam === awayTeam) {
          continue;
        }
        matchMatrix[homeTeam][awayTeam] += 1;
        let gm = {
          home_team: { id: teams[homeTeam].id, team: teams[homeTeam].team },
          away_team: { id: teams[awayTeam].id, team: teams[awayTeam].team },
        } as Game;
        teams[homeTeam].home_games?.push(gm);
        teams[awayTeam].away_games?.push(gm);
      }
    }
  }
}
