import { useSeasonContext } from '@local/context/league/season-context';
import { AgeGroup, LoadRegistrationQuery, TeamLevel, UpsertRegistrationInput } from '@local/graphql/graphql';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormSelect, HookFormTextField, ModalForm, ModalFormImplementationProps } from '@seasonticker/dls';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export type RegistrationFormModel = LoadRegistrationQuery['registration'];

export function RegistrationEditorForm(props: ModalFormImplementationProps<RegistrationFormModel, UpsertRegistrationInput>) {
  const { model } = props;
  const FormFields = () => {
    const { season } = useSeasonContext();
    const ageGroupChange = useWatch({ name: 'age_group' });
    const teamLevelChange = useWatch({ name: 'team_level' });
    const ageGroups = useMemo(() => {
      return season?.age_groups || [];
    }, [season?.age_groups]);
    const teamLevels = useMemo(() => {
      const ag = ageGroups.find((ag) => {
        return ag.id === ageGroupChange || ag.id === (ageGroupChange as AgeGroup)?.id;
      });
      return ag?.team_levels || [];
    }, [ageGroupChange, ageGroups]);
    const divisions = useMemo(() => {
      const tl = teamLevels.find((tl) => {
        return tl.id === teamLevelChange || tl.id === (teamLevelChange as TeamLevel)?.id;
      });
      return tl?.divisions || [];
    }, [teamLevelChange, teamLevels]);

    return (
      <Grid container rowSpacing={'.8em'}>
        <Grid xs={12}>
          <HookFormTextField name="team.name" label="Team Name" rules={{ required: 'Team Name is required' }} fullWidth />
        </Grid>
        <Grid xs={12}>
          <HookFormSelect
            label="Age Group"
            name="age_group"
            rules={{
              required: 'Age group is required',
            }}
            options={ageGroups}
            getLabel={(item) => item?.name || ''}
            getValue={(item) => item?.id || ''}
            findValue={(id: string) => ageGroups?.find((item) => item.id === id)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <HookFormSelect
            label="Team Level"
            name="team_level"
            rules={{
              required: 'Team level is required',
            }}
            options={teamLevels}
            getLabel={(item) => item?.name || ''}
            getValue={(item) => item?.id || ''}
            findValue={(id: string) => teamLevels?.find((item) => item.id === id)}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <HookFormSelect
            label="Division"
            name="division"
            options={divisions}
            getLabel={(item) => item?.name || ''}
            getValue={(item) => item?.id || ''}
            findValue={(id: string) => divisions?.find((item) => item.id === id)}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <ModalForm
      model={props.model}
      open={props.open}
      defaultValues={{}}
      onSubmit={props.onSubmit}
      handleClose={props.handleClose}
      mode={props.mode}
      title={props.title}
      FormFields={FormFields}
    />
  );
}

export default RegistrationEditorForm;
