import { styled } from '@mui/material';

export const Legend = styled('legend')(({ theme }) => ({
  display: 'block',
  width: '100%',
  marginBottom: '.5em',
  borderBottomColor: theme.palette.grey[400],
  borderBottomStyle: 'solid',
}));

export default Legend;
