import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useSeasonContext } from '@local/context/league/season-context';
import {
  AgeGroup,
  DeleteDivisionDocument,
  Division,
  DivisionTreeDocument,
  LoadDivisionsDocument,
  LoadTeamLevelsDocument,
  TeamLevel,
  UpsertDivisionDocument,
  UpsertDivisionInput,
  UpsertManyDivisionsDocument,
} from '@local/graphql/graphql';
import { useSorting } from '@local/hooks';
import { AgeGroupSelector } from '@local/league/components/AgeGroupSelector';
import { Box, Button, Card, CardActions, CardContent, IconButton, ListItemButton, ListItemText, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingIndicator, SortableList, SortableListItem, useOperations } from '@seasonticker/dls';
import { MouseEvent, SetStateAction, useCallback, useEffect, useState } from 'react';
import DivisionForm from './DivisionForm';

export default function DivisionSetup() {
  const { operation, confirmedOperation } = useOperations();
  const { season } = useSeasonContext();
  const sort = useSorting();
  const [modified, setModified] = useState(new Date());
  const [sorting, setSorting] = useState(false);
  const [ageGroups, setAgeGroups] = useState<AgeGroup[]>([] as AgeGroup[]);
  const [teamLevels, setTeamLevels] = useState([] as TeamLevel[]);
  const [divisions, setDivisions] = useState([] as Division[]);
  const [selectedDivision, setSelectedDivision] = useState<Division>();
  const [teamLevel, setTeamLevel] = useState({} as TeamLevel);
  const [divisionTreeQuery] = useLazyQuery(DivisionTreeDocument, {
    fetchPolicy: 'no-cache',
  });
  const [loadTeamLevels] = useLazyQuery(LoadTeamLevelsDocument, {
    fetchPolicy: 'no-cache',
  });
  const [loadDivisions, { loading: divisionLoading }] = useLazyQuery(LoadDivisionsDocument, {
    fetchPolicy: 'no-cache',
  });
  const [upsertDivision] = useMutation(UpsertDivisionDocument);
  const [upsertDivisions] = useMutation(UpsertManyDivisionsDocument);
  const [removeDivision] = useMutation(DeleteDivisionDocument);

  const teamLevelLoader = useCallback(
    async (ageGroup: AgeGroup) => {
      const response = await loadTeamLevels({
        variables: {
          ageGroupId: ageGroup.id,
        },
      });
      setTeamLevels(response.data?.team_levels as TeamLevel[]);
    },
    [loadTeamLevels]
  );

  const ageGroupChange = useCallback(
    (ageGroup: AgeGroup) => {
      if (ageGroup?.id) {
        teamLevelLoader(ageGroup);
      }
    },
    [teamLevelLoader]
  );

  const teamLevelChange = useCallback(
    (event: MouseEvent, id: string) => {
      const foundTeamLevel = teamLevels?.find((tl) => tl.id === id);
      if (foundTeamLevel) {
        setTeamLevel(foundTeamLevel);
        setSelectedDivision(undefined);
      }
    },
    [teamLevels]
  );

  const selectDivision = (id: string | undefined) => {
    const foundDivision = divisions?.find((d) => d.id === id);
    if (foundDivision) {
      setSelectedDivision(foundDivision);
    }
  };

  useEffect(() => {
    const loader = async () => {
      const response = await loadDivisions({
        variables: {
          teamLevelId: teamLevel.id,
        },
      });
      setDivisions(sort(response.data?.divisions as Division[]));
      setSelectedDivision(undefined);
    };
    if (modified && teamLevel?.id) {
      loader();
    }
  }, [loadDivisions, modified, sort, teamLevel]);

  useEffect(() => {
    const loader = async () => {
      const response = await divisionTreeQuery({
        variables: {
          seasonId: season.id,
        },
      });
      setAgeGroups(response.data?.season.age_groups as AgeGroup[]);
    };

    if (modified && season?.id) {
      loader();
    }
  }, [divisionTreeQuery, modified, season]);

  const onSubmit = async (data: UpsertDivisionInput) => {
    const { name } = data;
    const updateValue: UpsertDivisionInput = {
      name,
      id: selectedDivision?.id,
    };
    if (selectedDivision?.id === undefined || selectedDivision?.id.length === 0) {
      updateValue.team_level = teamLevel?.id;
      updateValue.sortOrder = divisions.length;
    }
    await operation({
      success: 'The division was successfully updated',
      error: 'There was an error updating the division',
      operation: () =>
        upsertDivision({
          variables: {
            upsert: updateValue,
          },
        }),
      after: () => setModified(new Date()),
    });
  };

  const add = () => {
    const newItem = { id: '', name: '', sortOrder: ageGroups.length } as UpsertDivisionInput;
    setSelectedDivision(newItem as unknown as Division);
  };

  const remove = async (division: Division) => {
    await confirmedOperation({
      confirmation: 'Are you sure you would like to remove this division?',
      success: 'Division was successfully deleted',
      error: 'An error occurred',
      operation: () =>
        removeDivision({
          variables: {
            id: division.id,
          },
        }),
      after: () => setModified(new Date()),
    });
  };

  const toggleSort = () => {
    if (sorting) {
      saveSortOrder();
    }
    setSorting(!sorting);
  };

  const saveSortOrder = async () => {
    await operation({
      success: 'Sort order saved',
      error: 'An error occurred saving the sort order',
      operation: () =>
        upsertDivisions({
          variables: {
            update: divisions.map((ag, idx) => ({ id: ag.id, sortOrder: idx })),
          },
        }),
    });
  };

  const onSortedHandler = (action: SetStateAction<Division[]>) => {
    setDivisions(action);
  };
  return (
    <Grid container columnSpacing={'.8em'}>
      <Grid xs={4}>
        <Card>
          <CardContent>
            <AgeGroupSelector sx={{ marginBottom: '1em' }} onChange={ageGroupChange} />
            <Box>
              <ToggleButtonGroup exclusive value={teamLevel.id} onChange={teamLevelChange} aria-label="Select Age Group">
                {sort(teamLevels).map((tl) => {
                  return (
                    <ToggleButton value={tl.id} key={tl.id}>
                      {tl.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Box>
            <LoadingIndicator loading={divisionLoading}>
              <SortableList<Division>
                items={divisions}
                sorting={sorting}
                onSorted={onSortedHandler}
                ItemTemplate={(d) => (
                  <SortableListItem
                    key={d.id}
                    secondaryAction={
                      <IconButton color="error" edge="end" aria-label="delete" onClick={() => remove(d)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    }>
                    <ListItemButton selected={selectedDivision?.id === d.id} onClick={() => selectDivision(d?.id)}>
                      <ListItemText primary={d.name} />
                    </ListItemButton>
                  </SortableListItem>
                )}
              />
            </LoadingIndicator>
            <CardActions>
              <Button variant="outlined" onClick={add}>
                Add
              </Button>
              <Button variant="outlined" onClick={toggleSort}>
                {sorting ? 'Save Order' : 'Sort'}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
      {selectedDivision ? (
        <Grid xs={8}>
          <DivisionForm onSubmit={onSubmit} model={selectedDivision} />
        </Grid>
      ) : null}
    </Grid>
  );
}
