import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { boolean } from 'boolean';
import { Controller, FieldValues } from 'react-hook-form';
import { useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormSwitchProps<T extends FieldValues = FieldValues, V = string> = HookFormBaseProps<T>;
export function HookFormSwitch<T extends FieldValues = FieldValues, V = string>(props: HookFormSwitchProps<T, V>) {
  const { name, label, rules, fullWidth, control, ControllerProps, tooltip, sx } = props;

  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);

  return (
    <Controller
      {...ControllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...otherFieldValues }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <FormControl sx={sx} fullWidth={fullWidth} error={!!error}>
          <FormControlLabel
            label={labelValue}
            labelPlacement="end"
            control={
              <Switch
                checked={boolean(value)}
                {...otherFieldValues}
                onChange={(event, checked) => {
                  onChange({
                    target: {
                      value: checked,
                    },
                  });
                }}
                aria-labelledby={name}
              />
            }
          />
        </FormControl>
      )}
    />
  );
}
export default HookFormSwitch;
