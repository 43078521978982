import { AgeGroup, Division, Registration, Team, TeamLevel, User } from '@local/graphql/graphql';
import { JSXElementConstructor } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import CompetitionLevels from './CompetitionLevels';
import RegistrationConfirmation from './Confirmation';
import ManagerInformation from './ManagerInformation';
import ModeSelect from './ModeSelect';
import TeamForm from './TeamForm';

export type RegisterForm = Pick<Registration, 'eliteJustification' | 'leagueLastYear'> & {
  waiver: boolean;
  self_manager: boolean;
  administrator: Pick<User, 'id'>;
  manager: Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'cellPhone'>;
  age_group: Pick<AgeGroup, 'id' | 'name'>;
  team_level: Pick<TeamLevel, 'id' | 'name'>;
  division: Pick<Division, 'id' | 'name'>;
  team: Pick<Team, 'id' | 'name'>;
};

export type StepDefinition<T> = {
  id: number;
  name: string;
  Component: JSXElementConstructor<T>;
  completed?: boolean;
  optional?: boolean;
  finalStep?: boolean;
};

export type RegistrationStepProps<TFieldValues extends FieldValues> = {
  seasonId: string;
  formProps: UseFormReturn<TFieldValues, any>;
  goToStep: (id: number) => void;
  ageGroups: AgeGroup[];
  teamLevels: TeamLevel[];
  divisions: Division[];
};

export enum Steps {
  ExistingTeam = 0,
  NewTeam = 1,
  ManagerInformation = 2,
  CompetitionLevels = 3,
  RegistrationConfirmation = 4,
}

export const steps: StepDefinition<RegistrationStepProps<RegisterForm>>[] = [
  { id: Steps.ExistingTeam, name: 'Registration Mode', Component: ModeSelect },
  { id: Steps.NewTeam, name: 'Team Information', Component: TeamForm },
  { id: Steps.ManagerInformation, name: 'Manager Information', Component: ManagerInformation },
  { id: Steps.CompetitionLevels, name: 'Competition Levels', Component: CompetitionLevels },
  { id: Steps.RegistrationConfirmation, name: 'Confirmation', Component: RegistrationConfirmation },
];
