import { CheckIcon, HookFormSelect } from '@seasonticker/dls';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { useTeamContext } from '@local/context/league/team-context';
import {
  DeleteRosterDocument,
  RefreshRostersDocument,
  RosterEntry,
  UpsertManyRostersDocument,
  UpsertRosterDocument,
  UpsertRosterEntryInput,
} from '@local/graphql/graphql';
import { Settings, useSettings } from '@local/hooks';
import { ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, FieldSet, HookFormDatePicker, HookFormTextField, Legend, Roles, useAuthz } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback } from 'react';
import RosterDisplay from './RosterDisplay';

export function Roster() {
  const { registration } = useTeamContext();
  const { leagueContext } = useLeagueContext();
  const { settings } = leagueContext?.league || {};
  const { setting } = useSettings();
  const [upsertMutation] = useMutation(UpsertRosterDocument);
  const [upsertManyMutation] = useMutation(UpsertManyRostersDocument);
  const [deleteMutation] = useMutation(DeleteRosterDocument);
  const [refreshFacilities] = useLazyQuery(RefreshRostersDocument, { fetchPolicy: 'no-cache' });

  const upsertItems = useCallback(
    (update: UpsertRosterEntryInput[]) => () => upsertManyMutation({ variables: { id: String(registration?.id), upsert: update } }),
    [registration?.id, upsertManyMutation]
  );
  const { ifInRole } = useAuthz();
  const permissionFunction = ifInRole({
    context: leagueContext?.league?.id,
    subContext: registration?.team?.id,
    roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
  });

  const loadItems = useCallback(async () => {
    const response = await refreshFacilities({ variables: { registrationId: String(registration?.id) } });
    return response.data?.roster_entries as RosterEntry[];
  }, [refreshFacilities, registration?.id]);

  const prepareItem = useCallback(
    (item: RosterEntry) => {
      const { id, number, player, emergencyContact, yearsOfExperience, offSeasonPlayer, membershipId } = item;
      delete player?.__typename;
      delete emergencyContact?.__typename;
      return id !== undefined
        ? ({ id, number, yearsOfExperience, offSeasonPlayer, player, emergencyContact, membershipId } as UpsertRosterEntryInput)
        : ({ number, yearsOfExperience, offSeasonPlayer, membershipId, player, emergencyContact, registration: registration?.id } as UpsertRosterEntryInput);
    },
    [registration?.id]
  );

  const upsertItem = useCallback(
    (upsert: UpsertRosterEntryInput) => () => upsertMutation({ variables: { id: String(registration?.id), upsert: upsert } }),
    [registration?.id, upsertMutation]
  );

  const deleteItem = useCallback(
    (item: RosterEntry) => () => deleteMutation({ variables: { id: String(registration?.id), roster_entry: item.id } }),
    [deleteMutation, registration?.id]
  );

  const stringSetting = useCallback(
    (key: Settings) => {
      const foundSetting = setting(key);
      return foundSetting.value;
    },
    [setting]
  );

  const requiredSetting = useCallback(
    (key: Settings) => {
      return boolean(setting(key).value);
    },
    [setting]
  );
  const itemForm = (
    <>
      <FieldSet>
        <Legend>Player</Legend>
        <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.firstName') }}
              name="player.firstName"
              label="First Name"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.lastName') }}
              name="player.lastName"
              label="Last Name"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField defaultValue="" rules={{ required: requiredSetting('roster.position') }} name="position" label="Position" fullWidth />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField defaultValue="" type="number" rules={{ required: requiredSetting('roster.number') }} name="number" label="Number" fullWidth />
          </Grid>
          {requiredSetting('roster.membership_id') ? (
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                rules={{ required: requiredSetting('roster.membershipId') }}
                name="membershipId"
                label={stringSetting('roster.membership_label')}
                fullWidth
              />
            </Grid>
          ) : undefined}
          <Grid xs={12} md={6}>
            <HookFormTextField defaultValue="" rules={{ required: requiredSetting('roster.player.grade') }} name="player.grade" label="Grade" fullWidth />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormDatePicker
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.birthday') }}
              name="player.birthday"
              label="Birthday"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.guardianemail') }}
              name="player.guardianemail"
              label="Guardian 1 email"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.guardian2email') }}
              name="player.guardian2email"
              label="Guardian 2 email"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.phoneNumber') }}
              name="player.phoneNumber"
              label="Phone Number"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.phoneNumber2') }}
              name="player.phoneNumber2"
              label="Phone Number 2"
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField
              defaultValue=""
              rules={{ required: requiredSetting('roster.player.phoneNumber3') }}
              name="player.phoneNumber3"
              label="Phone Number 3"
              fullWidth
            />
          </Grid>
          {requiredSetting('roster.show_off_season_player') ? (
            <Grid xs={12} md={6}>
              <HookFormSelect
                defaultValue=""
                rules={{ required: requiredSetting('roster.require_off_season_player') }}
                name="offSeasonPlayer"
                label={stringSetting('roster.label_off_season_player')}
                fullWidth
                options={['Yes', 'No']}
              />
            </Grid>
          ) : undefined}
          {requiredSetting('roster.show_years_of_experience') ? (
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                type="number"
                rules={{ required: requiredSetting('roster.require_years_of_experience') }}
                name="yearsOfExperience"
                label={stringSetting('roster.label_years_of_experience')}
                fullWidth
              />
            </Grid>
          ) : undefined}
        </Grid>
      </FieldSet>
      {requiredSetting('roster.emergency_contacts') ? (
        <FieldSet>
          <Legend>Emergency Contact</Legend>
          <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                rules={{ required: requiredSetting('roster.emergencyContact.firstName') }}
                name="emergencyContact.firstName"
                label="First Name"
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                rules={{ required: requiredSetting('roster.emergencyContact.lastName') }}
                name="emergencyContact.lastName"
                label="Last Name"
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                rules={{ required: requiredSetting('roster.emergencyContact.email') }}
                name="emergencyContact.email"
                label="Email"
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                rules={{ required: requiredSetting('roster.emergencyContact.cellPhone') }}
                name="emergencyContact.cellPhone"
                label="Phone"
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField
                defaultValue=""
                rules={{ required: requiredSetting('roster.emergencyContact.relationToPlayer') }}
                name="emergencyContact.relationToPlayer"
                label="Relation"
                fullWidth
              />
            </Grid>
          </Grid>
        </FieldSet>
      ) : (
        <></>
      )}
    </>
  );

  const facilityDisplay = (item: RosterEntry) => <RosterDisplay roster={item} />;
  const emptyTemplate = {
    allstarNomination: false,
    emergencyContact: {
      cellPhone: '',
      email: '',
      firstName: '',
      lastName: '',
      relationToPlayer: '',
    },
    number: 0,
    position: '',
    player: {
      birthday: null,
      firstName: '',
      lastName: '',
      grade: '',
      guardianemail: '',
      guardian2email: '',
      phoneNumber: '',
      phoneNumber2: '',
      phoneNumber3: '',
    },
    yearsOfExperience: null,
    offSeasonPlayer: '',
  } as RosterEntry;

  return (
    <EditableList<
      RosterEntry,
      UpsertRosterEntryInput,
      ReturnType<typeof upsertMutation>,
      ReturnType<typeof upsertManyMutation>,
      ReturnType<typeof deleteMutation>
    >
      dense
      model="Roster"
      refresh={loadItems}
      refreshList={loadItems}
      deleteMutation={deleteItem}
      prepareUpsert={prepareItem}
      upsertMutation={upsertItem}
      upsertOperation={upsertItems}
      canEdit={permissionFunction}
      listItemTemplate={(item) => (
        <ListItemText
          primary={
            <>
              {permissionFunction(<CheckIcon color="success" sx={{ display: item.waiver ? 'inline' : 'none' }} />)}
              {`${item?.player?.firstName} ${item?.player?.lastName}`}
            </>
          }
        />
      )}
      editElement={itemForm}
      itemTemplate={facilityDisplay}
      emptyTemplate={emptyTemplate}
      editable
      deletable
    />
  );
}

export default Roster;
