import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { useSeasonContext } from '@local/context/league/season-context';
import { AgeGroup, CreateRegistrationDocument, Division, Season, TeamLevel } from '@local/graphql/graphql';
import { useSettings } from '@local/hooks';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { HookFormSwitch, VisibleIf, useOperations } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RegisterForm, RegistrationStepProps, StepDefinition, steps } from './Steps';

export function RegisterForSeason() {
  const [createRegistration] = useMutation(CreateRegistrationDocument);
  const { setSeasonContext } = useSeasonContext();
  const [activeStep, setActiveStep] = useState<StepDefinition<RegistrationStepProps<RegisterForm>>>(steps[0]);
  const formProps = useForm<RegisterForm>({
    defaultValues: {
      administrator: {},
      waiver: false,
      self_manager: false,
      manager: { email: '', firstName: '', lastName: '', cellPhone: '' },
      eliteJustification: '',
      team: { name: '' },
      age_group: {},
      team_level: {},
    },
  });
  const { watch, setValue } = formProps;
  const navigate = useNavigate();
  const [showWaiver, setShowWaiver] = useState<boolean>();
  const [showForm, setShowForm] = useState(false);
  const [ageGroupChange, waiverChange, teamLevelChange] = watch(['age_group', 'waiver', 'team_level']);
  const { seasonId } = useParams();
  const [ageGroups, setAgeGroups] = useState<AgeGroup[]>([]);
  const [teamLevels, setTeamLevels] = useState<TeamLevel[]>([]);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [registeringSeason, setRegisteringSeason] = useState<Season>();
  const { leagueContext } = useLeagueContext();
  const { settingValue } = useSettings();

  useEffect(() => {
    if (showWaiver !== undefined && showWaiver && boolean(waiverChange)) {
      setShowForm(true);
    } else if (showWaiver !== undefined) {
      setShowForm(!boolean(showWaiver));
    }
  }, [showWaiver, waiverChange]);

  useEffect(() => {
    if (boolean(settingValue('registration.require_registration_terms'))) {
      setShowWaiver(true);
    } else {
      setShowWaiver(false);
      setShowForm(true);
    }
  }, [settingValue]);

  useEffect(() => {
    const newTeamLevels = ageGroups?.find((ag) => ag.id === ageGroupChange.id)?.team_levels;
    if (newTeamLevels) {
      setTeamLevels(newTeamLevels);
      setValue('team_level', {} as Pick<TeamLevel, 'id' | 'name'>);
    }
  }, [ageGroupChange, ageGroups, setValue]);

  useEffect(() => {
    const newDivisions = teamLevels.find((tl) => tl.id === teamLevelChange.id)?.divisions;
    if (newDivisions) {
      setDivisions(newDivisions);
      setValue('division', {} as Pick<Division, 'id' | 'name'>);
    }
  }, [setValue, teamLevelChange, teamLevels]);

  useEffect(() => {
    const season = leagueContext?.league?.seasons?.find((s) => s.id === seasonId);
    if (season) {
      setRegisteringSeason(season as Season);
    }
    if (season?.age_groups) {
      setAgeGroups(season.age_groups as AgeGroup[]);
    }
  }, [leagueContext?.league?.seasons, seasonId]);

  const goToStep = (id: number) => {
    setActiveStep(steps[id]);
  };

  const { operation } = useOperations();

  const onSubmit = useCallback(
    async (values: RegisterForm) => {
      await operation({
        success: 'The registration was successfully created',
        after: (response) => {
          setSeasonContext(registeringSeason as Season);
          window.location.href = `/league/team/${response.data?.create_season_registration?.team?.urlKey}?seasonOverride=${registeringSeason?.id}`;
          //navigate(`/team/${response.data?.create_season_registration?.team?.urlKey}/?seasonOverride=${registeringSeason?.id}`, { replace: true });
        },
        operation: () => {
          return createRegistration({
            variables: {
              admin: {
                id: values.administrator?.id,
              },
              manager: {
                id: values.manager.id,
                firstName: values.manager.firstName,
                lastName: values.manager.lastName,
                email: values.manager.email,
                cellPhone: values.manager.cellPhone,
              },
              team: {
                id: values.team.id,
                name: values.team.name,
              },
              registration: {
                waiver: values.waiver,
                waiver_date: values.waiver ? new Date() : undefined,
                season: seasonId,
                age_group: values.age_group.id,
                team_level: values.team_level.id,
                division: values.division?.id,
              },
            },
          });
        },
      });
    },
    [createRegistration, operation, registeringSeason, seasonId, setSeasonContext]
  );

  return (
    <Box>
      <Typography variant="h1">Register for {registeringSeason?.name}</Typography>

      <Box sx={{ width: '100%' }}>
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(onSubmit)}>
            <VisibleIf cond={boolean(showWaiver)}>
              <HookFormSwitch
                name="waiver"
                label={<div dangerouslySetInnerHTML={{ __html: settingValue('registration.registration_terms') }}></div>}></HookFormSwitch>
            </VisibleIf>
            <VisibleIf cond={boolean(showForm)}>
              <Box>
                <Stepper activeStep={activeStep.id} sx={{ marginBottom: '1em' }}>
                  {steps.map((step, index) => {
                    return (
                      <Step key={step.id} completed={step.completed} disabled={true}>
                        <StepLabel>{step.name}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <activeStep.Component
                  seasonId={String(seasonId)}
                  ageGroups={ageGroups}
                  teamLevels={teamLevels}
                  divisions={divisions}
                  goToStep={goToStep}
                  formProps={formProps}
                />
              </Box>
            </VisibleIf>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
}

export default RegisterForSeason;
