import { SiteSetting } from '@local/graphql/graphql';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useSeasonContext } from '../../context/league/season-context';
import { useSettingContext } from '../../context/league/settings-context';
import { SettingDefaults, Settings } from './SettingDefaults';

export type SettingMode = 'season' | 'league';

export function useSettings() {
  const { settings, setSettings } = useSettingContext();
  const { season } = useSeasonContext();

  const setting = useCallback(
    (setting: Settings, mode?: SettingMode, subKey?: string): SiteSetting => {
      const [module, ...rest] = setting.split('.');
      const key = rest.join('.');
      const possibleValues = settings.filter((setting) => setting.module === module && setting.key === key);
      let settingValue: SiteSetting | undefined;
      if (mode === 'league') {
        settingValue = possibleValues.find((v) => v.season === undefined || v.season === null);
      } else if (mode === 'season') {
        settingValue = possibleValues.find((v) => v.season?.id === subKey);
      } else {
        settingValue = possibleValues.find((v) => v.season?.id === season?.id) || possibleValues.find((v) => v.season === undefined || v.season === null);
      }
      return settingValue || get(SettingDefaults, String(setting));
    },
    [season?.id, settings]
  );

  const settingValue = useCallback(
    (settingKey: Settings, mode?: SettingMode, subKey?: string) => {
      return setting(settingKey, mode, subKey).value;
    },
    [setting]
  );
  return {
    settingValue,
    setSettings,
    setting,
  };
}
