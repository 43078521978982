import { StandingsGrid } from '@local/components/standings/StandingsGrid';
import { useTeamContext } from '@local/context/league/team-context';
import { Registration } from '@local/graphql/graphql';
import { Card, CardContent, Typography } from '@mui/material';

export function StandingsWidget() {
  const { registration } = useTeamContext();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{registration?.division?.name}</Typography>
        <StandingsGrid
          fields={['standings.logo', 'standings.team', 'standings.wins', 'standings.losses']}
          registrations={(registration?.division?.registrations || []) as Registration[]}
        />
      </CardContent>
    </Card>
  );
}

export default StandingsWidget;
