import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { LicenseInfo } from '@mui/x-license-pro';

import { setContext } from '@apollo/client/link/context';
import { Typography } from '@mui/material';
import { AuthProvider, AuthProviderProps, User } from '@seasonticker/dls';
import { WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, useCallback } from 'react';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import League from './league/League';
LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_KEY));
type AuthenticatedApplicationProps = {
  test?: string;
};
function AuthenticatedApplication(props: PropsWithChildren<AuthenticatedApplicationProps>) {
  const { children } = props;
  const navigate = useNavigate();
  const onSignIn = useCallback(
    (userData: User | void) => {
      if (userData?.id_token) {
        localStorage.setItem('id_token', userData?.id_token);
        window.location.replace('#');
        // slice off the remaining '#' in HTML5:
        if (typeof window.history.replaceState == 'function') {
          window.history.replaceState({}, '', window.location.href.slice(0, -1));
        }
        const returningLocation = localStorage.getItem('seasonticker_login_redirect');
        try {
          if (returningLocation) {
            const parsedLocation = JSON.parse(returningLocation);
            if (parsedLocation) {
              if (parsedLocation.legacy_path) {
                window.location = parsedLocation.legacy_path;
              } else {
                navigate(parsedLocation);
              }
            }
            localStorage.removeItem('seasonticker_login_redirect');
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    [navigate]
  );

  const authProps: AuthProviderProps = {
    authority: String(process.env.REACT_APP_SSO_AUTHORITY),
    userStore: new WebStorageStateStore({ store: localStorage }),
    client_id: 'seasonticker',
    loadUserInfo: true,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}`,
    scope: 'openid email profile',
    automaticSilentRenew: true,
    response_type: 'code',
    response_mode: 'fragment',
    onSigninCallback: onSignIn,
  };
  return <AuthProvider {...authProps}>{children}</AuthProvider>;
}
const httpLink = createHttpLink({
  uri: '/graphql',
});
const authLink = setContext((_, { headers }) => {
  const id_token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: id_token ? `Bearer ${id_token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function MainRouter() {
  const router = createBrowserRouter([
    {
      path: '/*',
      element: (
        <AuthenticatedApplication>
          <ApolloProvider client={client}>
            <League />
          </ApolloProvider>
        </AuthenticatedApplication>
      ),
      errorElement: <Typography>Error</Typography>,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default function App() {
  return <MainRouter />;
}
