import { useMutation } from '@apollo/client';
import { FileMeta, UpsertFileDocument } from '@local/graphql/graphql';
import { useOperations } from '@seasonticker/dls';
import axios from 'axios';
import { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';

export type UploadFileOptions = {
  folder?: string;
};
/**
 * Creates a file upload implementation that allows you
 * to pass in a File object and get back a FileMeta object representing
 * the uploaded file
 * @returns FileMeta
 */
export function useFileUpload() {
  const { silentOperation } = useOperations();
  const [upsertFile] = useMutation(UpsertFileDocument);

  /**
   * Operation to upload a file
   */
  const uploadFile = useCallback(
    async (file: File | FileMeta, options: UploadFileOptions = {}) => {
      const { folder = 'uploads' } = options;
      if (file instanceof File) {
        const newFile = file;
        const parts = newFile.name.split('.');
        const ext = parts.pop();
        const oldName = parts.join('');
        const newName = `${oldName}_${uuidV4()}.${ext}`;
        const signedUrl = await axios.get<string>(`/rest/signedurl?fileType?${newFile.type}&fileName=${folder}/${newName}`);
        await axios.put(signedUrl.data, newFile, {
          headers: {
            'Content-Type': newFile.type,
            'x-amz-acl': 'public-read',
          },
          transformRequest: (data, headers) => {
            headers?.common?.clear();
            return data;
          },
        });
        return await silentOperation({
          operation: () =>
            upsertFile({
              variables: {
                fileMeta: {
                  name: newName,
                  url: `https://content.seasonticker.com/${folder}/${newName}`,
                },
              },
            }),
          after: (response) => response.data?.upsert_file_meta as FileMeta,
        });
      }
    },
    [silentOperation, upsertFile]
  );

  return {
    uploadFile,
  };
}

export default useFileUpload;
