import { useQuery } from '@apollo/client';
import { useLeagueContext } from '@local/context';
import { AgeGroup, Registration, StandingsDocument, TeamLevel } from '@local/graphql/graphql';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { LoadingIndicator, Roles, VisibleIf, useAuthz } from '@seasonticker/dls';
import { orderBy } from 'lodash';
import { MouseEvent, useCallback, useState } from 'react';
import { AgeGroupSelector } from '../../league/components/AgeGroupSelector';
import { StandingsGrid } from './StandingsGrid';

export type StandingsComponentProps = {
  fields?: string[];
};

export type DisplayMode = 'byDivision' | 'byAgeGroup';
export default function StandingsComponent(props: StandingsComponentProps) {
  const { fields } = props;
  const { isInRole } = useAuthz();
  const { leagueContext } = useLeagueContext();
  const [mode, setMode] = useState<DisplayMode>('byDivision');
  const [ageGroup, setAgeGroup] = useState<AgeGroup>({} as AgeGroup);
  const { data, loading } = useQuery(StandingsDocument, {
    variables: {
      age_group: ageGroup?.id,
    },
    skip: !ageGroup?.id,
  });

  const changeMode = useCallback((event: MouseEvent, value: DisplayMode) => {
    setMode(value);
  }, []);

  const ByDivision = (props: { team_level: TeamLevel }) => {
    const { team_level } = props;
    return (
      <VisibleIf cond={(team_level.divisions?.length || 0) > 0} key={team_level.id}>
        <Box key={team_level.id} sx={{ mb: '1em' }}>
          <Typography sx={{ my: '.8em' }} variant="h4">
            {team_level.name}
          </Typography>
          {orderBy(team_level.divisions, ['sortOrder', 'name']).map((division) => {
            const standings = orderBy((division.registrations || []) as Registration[], ['team.name']);
            return (
              <VisibleIf cond={standings.length > 0} key={division.id}>
                <Box sx={{ mb: '1em', display: 'flex', flexDirection: 'column', height: 'auto' }} key={division.id}>
                  <Typography variant="h5">{division.name}</Typography>
                  <Box sx={{ flexGrow: 1, height: 'auto' }}>
                    <StandingsGrid fields={fields} registrations={standings} />
                  </Box>
                </Box>
              </VisibleIf>
            );
          })}
        </Box>
      </VisibleIf>
    );
  };

  const ByAgeGroup = (props: { team_level: TeamLevel }) => {
    const { team_level } = props;
    const standings = orderBy((team_level.divisions?.flatMap((division) => division.registrations) || []) as Registration[], ['team.name']);
    return (
      <VisibleIf cond={standings.length > 0} key={team_level.id}>
        <Box key={team_level.id} sx={{ mb: '1em' }}>
          <Typography sx={{ my: '.8em' }} variant="h4">
            {team_level.name}
          </Typography>
          <StandingsGrid
            fields={fields}
            registrations={standings}
            exportable={isInRole({ context: leagueContext?.league?.id, roles: [Roles.admin, Roles.leagueadmin] })}
          />
        </Box>
      </VisibleIf>
    );
  };

  return (
    <Box>
      <AgeGroupSelector onChange={setAgeGroup} />
      <Typography variant="body1" sx={{ mt: '.8em' }}>
        Group By
      </Typography>
      <ToggleButtonGroup exclusive size="small" value={mode} onChange={changeMode} aria-label="Select View Mode" orientation="horizontal">
        <ToggleButton value="byDivision">Division</ToggleButton>
        <ToggleButton value="byAgeGroup">Age Group</ToggleButton>
      </ToggleButtonGroup>
      <LoadingIndicator loading={loading}>
        {orderBy(data?.age_group?.team_levels, ['sortOrder', 'name']).map((team_level) =>
          mode === 'byDivision' ? <ByDivision team_level={team_level as TeamLevel} /> : <ByAgeGroup team_level={team_level as TeamLevel} />
        )}
      </LoadingIndicator>
    </Box>
  );
}
