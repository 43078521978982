import { FormControl, FormControlLabel, Slider, SliderProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormSliderProps<T extends FieldValues = FieldValues, V = string> = HookFormBaseProps<T> & {
  min?: SliderProps['min'];
  max?: SliderProps['max'];
  step?: SliderProps['step'];
  marks?: SliderProps['marks'];
  valuetext?: (value: number) => string;
};
export function HookFormSlider<T extends FieldValues = FieldValues, V = string>(props: HookFormSliderProps<T, V>) {
  const { name, step = 0.01, min = 0, max = 1, marks, label, rules, fullWidth, control, ControllerProps, tooltip, valuetext } = props;

  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...otherFieldValues }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <FormControl fullWidth={fullWidth} error={!!error}>
          <FormControlLabel
            label={labelValue}
            labelPlacement="top"
            control={
              <Slider
                min={min}
                max={max}
                step={step}
                marks={marks}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                valueLabelFormat={valuetext}
                value={+value || 0}
                {...otherFieldValues}
                onChange={(event, val, activeThumb) => {
                  onChange({
                    target: {
                      value: val,
                    },
                  });
                }}
                aria-labelledby={name}
              />
            }
          />
        </FormControl>
      )}
    />
  );
}

export default HookFormSlider;
