import { Box, Typography } from '@mui/material';
import { useAuth } from '@seasonticker/dls';
import { useEffect, useState } from 'react';

export function NoAccess() {
  const { user } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (user && !user.expired) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);
  return (
    <Box>
      <Typography variant="h4">Access Denied</Typography>
      {isLoggedIn ? (
        <Typography variant="body1">You are logged in but do not have permission to access this page.</Typography>
      ) : (
        <Typography variant="body1">You are trying to access a page that requires authentication, please login.</Typography>
      )}
    </Box>
  );
}

export default NoAccess;
