import { useAgeGroupContext } from '@local/context/league/age-group-context';
import { useSeasonContext } from '@local/context/league/season-context';
import { Box, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';

import { AgeGroup } from '@local/graphql/graphql';
import { useSorting } from '@local/hooks';
type AgeGroupSelectorProps = {
  onChange: (age_group: AgeGroup) => void;
};

export function UnstyledAgeGroupSelector(props: AgeGroupSelectorProps) {
  const { onChange } = props;
  const sort = useSorting();
  const { season } = useSeasonContext();
  const { ageGroup: ageGroupContext, setAgeGroupContext } = useAgeGroupContext();
  const [ageGroup, setAgeGroup] = useState({} as AgeGroup);

  useEffect(() => {
    if (ageGroupContext) {
      setAgeGroup(ageGroupContext);
      onChange(ageGroupContext);
    }
  }, [ageGroupContext, onChange]);

  const changeAgeGroup = useCallback(
    (event: MouseEvent, value: string) => {
      const foundAgeGroup = season?.age_groups?.find((ag) => ag.id === value);
      if (foundAgeGroup) {
        setAgeGroup(foundAgeGroup);
        if (foundAgeGroup.id !== ageGroupContext.id) {
          setAgeGroupContext(foundAgeGroup);
        }
      }
    },
    [ageGroupContext, season?.age_groups, setAgeGroupContext]
  );
  return (
    <Box>
      <ToggleButtonGroup exclusive size="small" value={ageGroup.id} onChange={changeAgeGroup} aria-label="Select Age Group" orientation="horizontal">
        {sort(season?.age_groups).map((ag) => {
          return (
            <ToggleButton value={ag.id} key={ag.id}>
              {ag.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
}

export const AgeGroupSelector = styled(UnstyledAgeGroupSelector, {})({});
