import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { SiteSetting, UpdateManySettingsDocument, UpsertSiteSettingInput } from '@local/graphql/graphql';
import { useSettings } from '@local/hooks';
import { NAVIGATION_MODULE, NavigationModuleSettingsType, SettingDefaults } from '@local/hooks/useSetting';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormTextField, useOperations } from '@seasonticker/dls';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export function NavigationSettings() {
  const { operation } = useOperations();
  const { leagueContext } = useLeagueContext();
  const { setting, setSettings } = useSettings();
  const [updateManySettingsDocuments] = useMutation(UpdateManySettingsDocument);
  const formMethods = useForm<NavigationModuleSettingsType>({
    defaultValues: SettingDefaults[NAVIGATION_MODULE],
  });

  useEffect(() => {
    formMethods.reset({
      league_link: setting('navigation.league_link'),
      navigation_items: setting('navigation.navigation_items'),
    });
  }, [formMethods, setting]);

  const onSubmit = async (settings: NavigationModuleSettingsType) => {
    const getSettingSaveValue = (setting: SiteSetting): UpsertSiteSettingInput => {
      const { id, module, key, value } = setting;
      if (id) {
        return { id, value };
      }
      return { module, key, value, league: leagueContext?.league?.id };
    };
    await operation({
      success: 'The settings were successfully updated',
      error: 'An error occurred while updating the settings',
      operation: () =>
        updateManySettingsDocuments({
          variables: {
            settings: [getSettingSaveValue(settings.league_link), getSettingSaveValue(settings.navigation_items)],
          },
        }),
      after: (response) => setSettings(response.data?.update_many_site_setting as SiteSetting[]),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid xs={12} sm={6}>
            <HookFormTextField
              name="league_link.value"
              label="Logo Url"
              fullWidth
              tooltip="This is the url where the logo in the top nav bar will redirect to, if blank it will be to the season ticker league home page"
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <HookFormTextField
              name="navigation_items.value"
              label="Navigation Items"
              fullWidth
              tooltip="These are additional links that will appear in the top nav bar"
            />
          </Grid>
          <Grid xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default NavigationSettings;
