import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapIcon from '@mui/icons-material/Map';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SportsIcon from '@mui/icons-material/Sports';

import { ProtectedRoute, ProtectedTab } from '@local/auth';
import { TeamLogo } from '@local/components';
import { useLeagueContext } from '@local/context/league/league-context';
import { useNavigationContext } from '@local/context/league/navigation-context';
import { useTeamContext } from '@local/context/league/team-context';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { Roles } from '@seasonticker/dls';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import { Facilities } from './Facilities/Facilities';
import { HomeDates } from './HomeDates';
import { Roster } from './Roster/Roster';
import { Staff } from './Staff/Staff';

export default function TeamTabs() {
  const [currentTab, setCurrentTab] = useState('dashboard');
  const { leagueContext } = useLeagueContext();
  const { registration } = useTeamContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const parts = pathname?.split('/');
    if (parts?.length > 3) {
      setCurrentTab(pathname?.split('/').pop() || 'dashboard');
    } else {
      navigate('./');
    }
  }, [navigate, pathname]);
  const handleChange = (event: SyntheticEvent, value: string) => {
    setCurrentTab(value);
  };
  const { setSecondaryPages } = useNavigationContext();
  useEffect(() => {
    setSecondaryPages([
      {
        name: `${registration?.team?.name} Home`,
        icon: <TeamLogo url={registration?.team?.logo?.url} />,
        link: `/team/${registration?.team?.urlKey}/`,
      },
      {
        name: `Facilities`,
        icon: <MapIcon />,
        link: `/team/${registration?.team?.urlKey}/facilities`,
      },
      {
        name: `Roster`,
        icon: <PeopleOutlineIcon />,
        link: `/team/${registration?.team?.urlKey}/roster`,
        roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
      },
      {
        name: `Staff`,
        icon: <SportsIcon />,
        link: `/team/${registration?.team?.urlKey}/staff`,
        roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
      },
      {
        name: `Home Dates`,
        icon: <CalendarMonthIcon />,
        link: `/team/${registration?.team?.urlKey}/homedates`,
        roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
      },
    ]);
  }, [registration?.team?.logo?.url, registration?.team?.name, registration?.team?.urlKey, setSecondaryPages]);
  return (
    <TabContext value={currentTab}>
      <TabList onChange={handleChange} aria-label="lab API tabs example">
        <Tab label="Home" value="dashboard" component={Link} to="./" />
        <Tab label="Facilities" value="facilities" component={Link} to="./facilities" />
        <ProtectedTab
          context={leagueContext?.league?.id}
          subContext={registration?.id}
          roles={[Roles.admin, Roles.leagueadmin, Roles.manager]}
          label="Roster"
          value="roster"
          to="./roster"
        />
        <ProtectedTab
          context={leagueContext?.league?.id}
          subContext={registration?.id}
          roles={[Roles.admin, Roles.leagueadmin, Roles.manager]}
          label="Staff"
          value="staff"
          to="./staff"
        />
        <ProtectedTab
          context={leagueContext?.league?.id}
          subContext={registration?.id}
          roles={[Roles.admin, Roles.leagueadmin, Roles.manager]}
          label="Home Dates"
          value="homedates"
          to="./homedates"
        />
      </TabList>
      <Routes>
        <Route path="" element={<Dashboard />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route
          path="/roster"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} subContext={registration?.id} roles={[Roles.admin, Roles.leagueadmin, Roles.manager]}>
              <Roster />
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} subContext={registration?.id} roles={[Roles.admin, Roles.leagueadmin, Roles.manager]}>
              <Staff />
            </ProtectedRoute>
          }
        />
        <Route
          path="/homedates"
          element={
            <ProtectedRoute context={leagueContext?.league?.id} subContext={registration?.id} roles={[Roles.admin, Roles.leagueadmin, Roles.manager]}>
              <HomeDates />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Outlet />
    </TabContext>
  );
}
