import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { IconButton, ListItem, ListItemIcon, ListItemProps } from '@mui/material';
import { SortableListItemType } from './SortableListItemType';

/**
 * Properties for a sortable list item
 */
export type SortableListItemProps<T extends SortableListItemType> = ListItemProps & {
  /**
   * The item to be sorted
   */
  item?: T;

  /**
   * The algorithm used to get the identifier
   */
  getIdentifier?: (item: T) => UniqueIdentifier;

  /**
   * whether sorting is enabled
   */
  sorting?: boolean;
};
export function SortableListItem<T extends SortableListItemType>(props: SortableListItemProps<T>) {
  const { item = {} as T, getIdentifier = (item) => item.id, sorting, children, ...listItemProps } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: getIdentifier(item) });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <ListItem ref={setNodeRef} style={style} {...attributes} {...listItemProps}>
      {sorting ? (
        <ListItemIcon>
          <IconButton color="info" edge="end" aria-label="drag" {...listeners}>
            <DragHandleIcon />
          </IconButton>
        </ListItemIcon>
      ) : (
        <></>
      )}
      {children}
    </ListItem>
  );
}
