import { useLazyQuery, useMutation } from '@apollo/client';
import { GetMeDocument, UpdateMeDocument, User } from '@local/graphql/graphql';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { FieldSet, HookFormTextField, Legend, useAuth, useOperations } from '@seasonticker/dls';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type UserForm = Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'cellPhone' | 'homePhone' | 'location'>;

export function Profile() {
  const { user } = useAuth();
  const [loadMyData] = useLazyQuery(GetMeDocument);
  const [updateMe] = useMutation(UpdateMeDocument);
  const userForm = useForm<UserForm>();
  const { operation } = useOperations();
  const loadMe = useCallback(async () => {
    if (user?.profile) {
      const result = await loadMyData({
        variables: {
          id: user.profile.sub,
        },
      });
      if (result.data?.user) {
        userForm.reset(result.data?.user);
      }
    }
  }, [loadMyData, user?.profile, userForm]);
  useEffect(() => {
    loadMe();
  }, [loadMe]);
  const submitForm = useCallback(
    async (values: UserForm) => {
      await operation({
        success: 'User successfully updates',
        operation: () =>
          updateMe({
            variables: {
              id: values.id,
              update: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                location: {
                  street: values.location?.street,
                  city: values.location?.city,
                  state: values.location?.state,
                  country: values.location?.country,
                  zip: values.location?.zip,
                },
                homePhone: values.homePhone,
                cellPhone: values.cellPhone,
              },
            },
          }),
      });
    },
    [operation, updateMe]
  );
  return (
    <FormProvider {...userForm}>
      <form onSubmit={userForm.handleSubmit(submitForm)}>
        <FieldSet>
          <Legend>User Information</Legend>
          <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
            <Grid xs={12} md={6}>
              <HookFormTextField rules={{ required: 'email is required' }} name="email" label="email" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField name="cellPhone" label="Cell Phone" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField rules={{ required: 'First Name is required' }} name="firstName" label="First Name" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField rules={{ required: 'Last Name is required' }} name="lastName" label="Last Name" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField name="homePhone" label="Home Phone" fullWidth />
            </Grid>
          </Grid>
        </FieldSet>
        <FieldSet>
          <Legend>Address</Legend>
          <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
            <Grid xs={12} md={6}>
              <HookFormTextField name="location.street" label="Street" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField name="location.city" label="City" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField name="location.state" label="State" fullWidth />
            </Grid>
            <Grid xs={12} md={6}>
              <HookFormTextField rules={{ required: 'Zip is required' }} name="location.zip" label="Zip" fullWidth />
            </Grid>
            <Grid xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Grid>
          </Grid>
        </FieldSet>
      </form>
    </FormProvider>
  );
}

export default Profile;
