import { closestCenter, DndContext, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Button, Card, CardActions, CardContent, CardHeader, IconButton, List, ListItemButtonProps, ListProps, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { IfInRole } from '../../auth';
import { EditableOptions, useEditable, useSortableList, UseSortingOptions } from '../../hooks';
import { AddIcon, SaveIcon, SortIcon } from '../../icons';
import EditableListItem from './EditableListItem';
import { EditableListItemType } from './EditableListItemType';

export type EditableListProps<
  ModelType extends EditableListItemType,
  UpsertType extends EditableListItemType,
  UpsertResponse,
  UpsertManyResponse,
  DeleteResponse
> = ListProps &
  EditableOptions<ModelType, UpsertType, UpsertResponse, DeleteResponse> &
  UseSortingOptions<UpsertType, UpsertManyResponse> & {
    listItemTemplate: (item: ModelType) => ReactElement;
    canEdit: IfInRole;
    /**
     * Controls whether the editor is inline or on the side
     */
    inline?: boolean;

    /**
     * A boolean or a function to control editable at the item level
     */
    editable?: boolean | ((item: ModelType) => boolean);

    /**
     * sortable
     */
    sortable?: boolean;

    /**
     * A boolean or a function to control selectable at the item level
     */
    selectable?: boolean | ((item: ModelType) => boolean);

    /**
     * A boolean or function to control deletable at the item level
     */
    deletable?: boolean | ((item: ModelType) => boolean);

    /**
     * Additional props to pass to the list button
     */
    ButtonProps?: (item: ModelType) => ListItemButtonProps;
    editElement: ReactNode | ((item?: ModelType) => ReactNode);
    itemTemplate: (item: ModelType) => ReactElement;
    emptyElement?: ReactNode;
    refreshList: () => Promise<ModelType[]>;
  };

export function EditableList<
  ModelType extends EditableListItemType,
  UpsertType extends EditableListItemType,
  UpsertResponse,
  UpsertManyResponse,
  DeleteResponse
>(props: EditableListProps<ModelType, UpsertType, UpsertResponse, UpsertManyResponse, DeleteResponse>) {
  const {
    canEdit,
    editable = false,
    deletable = false,
    sortable = false,
    selectable = false,
    inline = false,
    listItemTemplate,
    itemTemplate,
    editElement,
    emptyElement = <></>,
    deleteMutation,
    emptyTemplate,
    model,
    refresh,
    prepareUpsert,
    upsertMutation,
    upsertOperation,
    refreshList,
    ButtonProps = () => ({}),
    ...listProps
  } = props;
  const [selectedItem, setSelectedItem] = useState<ModelType>();
  const { editMode, editingItem, onAdd, onDelete, onEdit, onSave, setEditMode, setEditingItem } = useEditable({
    deleteMutation,
    model,
    emptyTemplate,
    prepareUpsert,
    refresh,
    upsertMutation,
  });

  const formProps = useForm<ModelType>({
    defaultValues: emptyTemplate as DeepPartial<ModelType>,
  });

  const { list, onSorted, saveSorting, setList, setSorting, sorting, toggleSorting, handleDragEnd } = useSortableList<
    ModelType,
    UpsertType,
    UpsertManyResponse
  >({
    upsertOperation,
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const resolveBoolean = useCallback((boolOrFunction: boolean | ((item: ModelType) => boolean), item: ModelType) => {
    if (typeof boolOrFunction === 'boolean') {
      return boolOrFunction;
    } else if (typeof boolOrFunction === 'function') {
      return boolOrFunction(item);
    }
    return false;
  }, []);

  const loadList = useCallback(async () => {
    const data = await refreshList();
    setList(data);
  }, [refreshList, setList]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const addClick = useCallback(() => {
    onAdd((item: ModelType) => {
      formProps.reset(emptyTemplate);
      formProps.reset(item);
    });
  }, [emptyTemplate, formProps, onAdd]);

  const editClick = useCallback(
    (item: ModelType) => {
      setSelectedItem(item);
      setEditMode(true);
      onEdit(item);
      formProps.reset(emptyTemplate);
      formProps.reset(item);
    },
    [emptyTemplate, formProps, onEdit]
  );

  const selectItemClick = useCallback((item: ModelType) => {
    setSelectedItem(item);
    setEditMode(false);
  }, []);

  const cancelClick = useCallback(() => {
    setEditMode(false);
    setEditingItem(undefined);
  }, []);

  const saveClick = useCallback(
    async (data: ModelType) => {
      await onSave(data);
      loadList();
    },
    [onSave, loadList]
  );

  const deleteClick = useCallback(
    async (data: ModelType) => {
      await onDelete(data);
      loadList();
    },
    [onDelete, loadList]
  );

  const actions = useMemo(
    () => (
      <>
        {editable ? (
          <Tooltip title="Add another facility">
            <IconButton onClick={() => addClick()}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {sortable ? (
          <IconButton onClick={toggleSorting} hidden={!sortable}>
            {sorting ? (
              <Tooltip title="Save the sort order">
                <SaveIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Enable the sort mode">
                <SortIcon />
              </Tooltip>
            )}
          </IconButton>
        ) : (
          <></>
        )}
      </>
    ),
    [addClick, editable, sortable, sorting, toggleSorting]
  );

  const editForm = (
    <form autoComplete="off" onSubmit={formProps.handleSubmit(saveClick)}>
      <Card>
        <CardContent>
          <FormProvider {...formProps}>{typeof editElement === 'function' ? editElement(editingItem) : editElement}</FormProvider>
        </CardContent>
        <CardActions>
          <Button sx={{ marginRight: '.3' }} variant="outlined" onClick={cancelClick}>
            Cancel
          </Button>{' '}
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </CardActions>
      </Card>
    </form>
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={list as unknown as { id: UniqueIdentifier }[]}>
        <Grid container columnSpacing={inline ? 0 : '1em'}>
          <Grid xs={12} md={inline ? 12 : 4}>
            <Card>
              <CardHeader title={model} action={canEdit(actions)} />
              <CardContent>
                {!editMode || !inline ? (
                  <List {...listProps}>
                    {list.map((item, idx) => (
                      <EditableListItem
                        sorting={sorting}
                        canEdit={canEdit}
                        key={item.id || idx}
                        selectable={selectable}
                        selected={item?.id === editingItem?.id || item?.id === selectedItem?.id}
                        editable={editable}
                        deletable={deletable}
                        onSelectItem={selectItemClick}
                        onDelete={deleteClick}
                        onEdit={editClick}
                        item={item}
                        ButtonProps={ButtonProps(item)}>
                        {listItemTemplate(item)}
                      </EditableListItem>
                    ))}
                  </List>
                ) : (
                  <></>
                )}
                {inline && editMode ? editForm : <></>}
              </CardContent>
            </Card>
          </Grid>
          {inline ? (
            <></>
          ) : (
            <Grid xs={12} md={8}>
              {editMode ? editForm : selectedItem ? itemTemplate(selectedItem) : emptyElement}
            </Grid>
          )}
        </Grid>
      </SortableContext>
    </DndContext>
  );
}
export default EditableList;
