import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { useTeamContext } from '@local/context/league/team-context';
import {
  DeleteTeamPositionDocument,
  RefreshTeamPositionDocument,
  SiteSetting,
  TeamPosition,
  UpsertManyTeamPositionsDocument,
  UpsertTeamPositionDocument,
  UpsertTeamPositionInput,
  User,
} from '@local/graphql/graphql';
import { getSetting } from '@local/league/settings/Settings';
import { ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, FieldSet, HookFormSelect, HookFormTextField, Legend, Roles, useAuthz } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback } from 'react';
import StaffDisplay from './StaffDisplay';

export function Staff() {
  const { registration } = useTeamContext();
  const { leagueContext } = useLeagueContext();
  const { settings } = leagueContext?.league || {};
  const [upsertMutation] = useMutation(UpsertTeamPositionDocument);
  const [upsertManyMutation] = useMutation(UpsertManyTeamPositionsDocument);
  const [deleteMutation] = useMutation(DeleteTeamPositionDocument);
  const [refreshFacilities] = useLazyQuery(RefreshTeamPositionDocument, { fetchPolicy: 'no-cache' });

  const upsertItems = useCallback(
    (update: UpsertTeamPositionInput[]) => () => upsertManyMutation({ variables: { id: String(registration?.team?.id), team_position: update } }),
    [registration?.team?.id, upsertManyMutation]
  );
  const { ifInRole } = useAuthz();
  const permissionFunction = ifInRole({
    context: leagueContext?.league?.id,
    subContext: registration?.team?.id,
    roles: [Roles.admin, Roles.leagueadmin, Roles.manager],
  });

  const loadItems = useCallback(async () => {
    const response = await refreshFacilities({ variables: { team_id: String(registration?.team?.id) } });
    return response.data?.team_positions as TeamPosition[];
  }, [refreshFacilities, registration?.team?.id]);

  const prepareItem = useCallback(
    (item: TeamPosition) => {
      const { id, positionType } = item;
      return id !== undefined
        ? ({ id, positionType } as UpsertTeamPositionInput)
        : ({ positionType, team: registration?.team?.id, email: item.email } as UpsertTeamPositionInput);
    },
    [registration?.team?.id]
  );

  const upsertItem = useCallback(
    (upsert: UpsertTeamPositionInput) => () => upsertMutation({ variables: { id: String(registration?.team?.id), team_position: upsert } }),
    [registration?.team?.id, upsertMutation]
  );

  const deleteItem = useCallback(
    (item: TeamPosition) => () => deleteMutation({ variables: { id: String(registration?.team?.id), team_position: item.id } }),
    [deleteMutation, registration?.team?.id]
  );

  const stringSetting = useCallback(
    (key: string) => {
      const setting = getSetting((settings || []) as SiteSetting[], { module: 'roster', key, value: 'false' } as SiteSetting);
      return setting.value;
    },
    [settings]
  );

  const requiredSetting = useCallback(
    (key: string) => {
      return boolean(stringSetting(key));
    },
    [stringSetting]
  );
  const itemForm = (item?: TeamPosition) => {
    return (
      <>
        <FieldSet>
          <Legend>Team Staff</Legend>
          <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
            {item?.id === undefined ? (
              <Grid xs={12}>
                <Typography variant="body2">
                  Enter the email and the team role below. If the email matches a user, that person will be linked to the team. If the email is not found, a
                  message will be sent to that person letting them know that they were added as a member of the team with instructions to complete their account
                  setup.
                </Typography>
              </Grid>
            ) : undefined}
            {item?.id === undefined ? (
              <Grid xs={12} md={6}>
                <HookFormTextField defaultValue="" rules={{ required: 'Email is required' }} name="email" label="Email" fullWidth />
              </Grid>
            ) : undefined}
            <Grid xs={12} md={6}>
              <HookFormSelect
                options={['team administrator', 'score keeper', 'coach']}
                defaultValue="coach"
                rules={{ required: 'Position is Required' }}
                name="positionType"
                label="Position Type"
                fullWidth
              />
            </Grid>
          </Grid>
        </FieldSet>
      </>
    );
  };

  const facilityDisplay = (item: TeamPosition) => <StaffDisplay staff={item} />;
  const emptyTemplate = {} as TeamPosition;
  const displayName = (user?: User | null) => {
    if (user && user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user?.email;
  };

  return (
    <EditableList<
      TeamPosition,
      UpsertTeamPositionInput,
      ReturnType<typeof upsertMutation>,
      ReturnType<typeof upsertManyMutation>,
      ReturnType<typeof deleteMutation>
    >
      dense
      model="Staff"
      refresh={loadItems}
      refreshList={loadItems}
      deleteMutation={deleteItem}
      prepareUpsert={prepareItem}
      upsertMutation={upsertItem}
      upsertOperation={upsertItems}
      canEdit={permissionFunction}
      listItemTemplate={(item) => <ListItemText primary={displayName(item?.user)} secondary={item?.positionType} />}
      editElement={itemForm}
      itemTemplate={facilityDisplay}
      emptyTemplate={emptyTemplate}
      editable
      deletable
    />
  );
}
