import { sortBy } from 'lodash';
import { useCallback } from 'react';
export type SortableItem = {
  sortOrder?: number | null;
};
export function useSorting() {
  const sortingFunction = useCallback(<T extends SortableItem>(inputArray?: T[] | null) => {
    return sortBy(inputArray || [], 'sortOrder');
  }, []);
  return sortingFunction;
}

export default useSorting;
