import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormSwitch, HookFormTextField, useAuth, VisibleIf } from '@seasonticker/dls';
import { boolean } from 'boolean';
import { useCallback, useEffect, useState } from 'react';
import { RegisterForm, RegistrationStepProps } from './Steps';

export function ManagerInformation(props: RegistrationStepProps<RegisterForm>) {
  const [isManager, setIsManager] = useState<boolean>(false);
  const { user } = useAuth();
  const { goToStep, formProps } = props;
  const {
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = formProps;
  const handleTeamClick = useCallback(async () => {
    const success = await trigger();
    if (success) {
      goToStep(3);
    } else {
      console.log(errors);
    }
  }, [errors, goToStep, trigger]);
  const [selfManagerWatch] = watch(['self_manager']);
  useEffect(() => {
    setIsManager(boolean(selfManagerWatch));
    if (boolean(selfManagerWatch)) {
      setValue('manager.id', String(user?.profile.sub));
      setValue('administrator.id', '');
    } else {
      setValue('manager.id', '');
      setValue('administrator.id', String(user?.profile.sub));
    }
  }, [selfManagerWatch, setValue, user?.profile.sub]);

  return (
    <Grid container columnSpacing={'.8em'} rowSpacing={'.8em'}>
      <Grid xs={12}>
        <HookFormSwitch name="self_manager" label="I am the Manager" />
      </Grid>
      <VisibleIf cond={!isManager}>
        <>
          <Grid xs={12} md={6}>
            <HookFormTextField name="manager.email" label="Email" rules={{ required: "Please enter the manager's email" }} fullWidth />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField name="manager.cellPhone" label="Cell Phone" fullWidth />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField name="manager.firstName" label="First Name" fullWidth />
          </Grid>
          <Grid xs={12} md={6}>
            <HookFormTextField name="manager.lastName" label="Last Name" fullWidth />
          </Grid>
        </>
      </VisibleIf>
      <Grid xs={12}>
        <Button variant="contained" color="primary" onClick={handleTeamClick}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
}

export default ManagerInformation;
