import { useLazyQuery, useMutation } from '@apollo/client';
import { DialogTransition } from '@local/components/DialogTransition';
import { useLeagueContext } from '@local/context';
import { LoadUserWaiversDocument, SignWaiverDocument, Waiver, WaiverSignature } from '@local/graphql/graphql';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { VisibleIf } from '@seasonticker/dls';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

export type WaiverDisplayProps = {};

export function WaiverDisplay(props: WaiverDisplayProps) {
  const [open, setOpen] = useState(false);
  const [activeWaiver, setActiveWaiver] = useState<Waiver>();
  const [missingWaivers, setMissingWaivers] = useState<Waiver[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAuthenticated } = useAuth();
  const { leagueContext } = useLeagueContext();
  const [loadWaivers] = useLazyQuery(LoadUserWaiversDocument, { fetchPolicy: 'no-cache' });
  const [signWaiver] = useMutation(SignWaiverDocument);
  const waiverLoader = useCallback(async () => {
    if (isAuthenticated) {
      const response = await loadWaivers({ variables: { userId: String(user?.profile.sub) } });
      const signedWaivers = (response.data?.user.waiver_signatures || []) as WaiverSignature[];
      const leagueRequiredWaivers = (leagueContext?.league?.waivers?.map((lw) => lw.waiver).filter((w) => w?.required) || []) as Waiver[];
      const notSignedWaivers = leagueRequiredWaivers.filter((w) => signedWaivers.find((sw) => sw.waiver?.id === w.id) === undefined);
      setMissingWaivers(notSignedWaivers);
      if (notSignedWaivers.length > 0) {
        enqueueSnackbar('You are missing some required waivers', { variant: 'warning' });
      }
    }
  }, [enqueueSnackbar, isAuthenticated, leagueContext?.league?.waivers, loadWaivers, user?.profile.sub]);
  useEffect(() => {
    waiverLoader();
  }, [waiverLoader]);

  const handleSignWaiver = async (waiver: Waiver) => {
    const response = await signWaiver({
      variables: {
        userId: String(user?.profile.sub),
        upsert: {
          signature_date: new Date(),
          user: user?.profile.sub,
          waiver: waiver.id,
        },
      },
    });
    if (response.data?.upsert_waiver_signature_to_user.id) {
      waiverLoader();
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const waiverClick = (waiver: Waiver) => {
    setActiveWaiver(waiver);
    setOpen(true);
  };
  return (
    <>
      <VisibleIf cond={missingWaivers.length > 0}>
        <Card>
          <CardHeader title="Outstanding Waivers" />
          <CardContent>
            <List>
              {missingWaivers.map((w) => (
                <ListItem key={w.id}>
                  <ListItemButton onClick={() => waiverClick(w)}>
                    <ListItemAvatar>
                      <WarningIcon />
                    </ListItemAvatar>
                    <ListItemText primary={w.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </VisibleIf>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={'lg'}>
        <DialogTitle>{activeWaiver?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div dangerouslySetInnerHTML={{ __html: String(activeWaiver?.text) }}></div>
          </DialogContentText>
          <div>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Disagree
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleSignWaiver(activeWaiver as Waiver)}>
              Agree
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
