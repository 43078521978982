import { AgeGroup, TeamLevel } from '@local/graphql/graphql';
import { AgeGroupTree } from '@local/league/components';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { VisibleIf, useOperations } from '@seasonticker/dls';
import axios from 'axios';
import { useCallback, useState } from 'react';

export function GenerateStandings() {
  const { operation } = useOperations();
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup>();
  const [selectedTeamLevel, setSelectedTeamLevel] = useState<TeamLevel>();
  const nodeClick = useCallback((ag: AgeGroup, tl: TeamLevel) => {
    setSelectedAgeGroup(ag);
    setSelectedTeamLevel(tl);
  }, []);
  const generateClick = useCallback(async () => {
    await operation({
      success: 'Standings Generated',
      operation: () => axios.put(`/rest/standings/${selectedTeamLevel?.id}`),
    });
  }, [operation, selectedTeamLevel?.id]);
  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <AgeGroupTree onClick={nodeClick} />
      </Grid>
      <Grid xs={12} md={6}>
        <VisibleIf cond={(selectedTeamLevel?.name?.length || 0) > 0}>
          <>
            <Typography variant="h6">
              {selectedAgeGroup?.name} {selectedTeamLevel?.name}
            </Typography>
            <Button variant="contained" color="primary" onClick={generateClick}>
              Generate Standings
            </Button>
          </>
        </VisibleIf>
      </Grid>
    </Grid>
  );
}

export default GenerateStandings;
