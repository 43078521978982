import { useTeamContext } from '@local/context/league/team-context';
import { Card, CardContent, List, ListItem, ListItemText } from '@mui/material';

export function StaffWidget() {
  const { registration } = useTeamContext();

  return (
    <Card>
      <CardContent>
        <List>
          <ListItem>
            <ListItemText primary="Manager" secondary={`${registration?.team?.manager?.firstName} ${registration?.team?.manager?.lastName}`}></ListItemText>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

export default StaffWidget;
