import { ProtectedRoute } from '@local/auth';
import { Roles } from '@seasonticker/dls';
import { Route, Routes } from 'react-router-dom';
import { useLeagueContext } from '../../context/league/league-context';
import { CreateRoles } from './CreateRoles';
import RolesEditor from './RolesEditor';

export function Permissions() {
  const { leagueContext } = useLeagueContext();
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
            <RolesEditor />
          </ProtectedRoute>
        }
      />
      <Route
        path="/roles"
        element={
          <ProtectedRoute context={leagueContext?.league?.id} roles={[Roles.admin]}>
            <CreateRoles />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default Permissions;
