import { Box, TextField, TextFieldProps } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { Controller, FieldValues } from 'react-hook-form';
import { useHelpLabel } from '../hooks/useHelpLabel';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormColorPickerProps<T extends FieldValues = FieldValues> = HookFormBaseProps<T> & {
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'fullWidth' | 'helperText' | 'error'>;
};
export function HookFormColorPicker<T extends FieldValues = FieldValues>(props: HookFormColorPickerProps<T>) {
  const { name, label, rules, fullWidth, control, ControllerProps, TextFieldProps, tooltip } = props;
  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...otherFieldValues }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <Box>
          <TextField
            {...TextFieldProps}
            {...otherFieldValues}
            fullWidth={fullWidth}
            sx={{ mb: '1em' }}
            value={value}
            label={labelValue}
            helperText={error?.message}
            error={!!error}
            onChange={onChange}></TextField>
          <HexColorPicker
            color={value}
            onChange={(newColor) => {
              onChange({
                target: {
                  value: newColor,
                },
              });
            }}
          />
        </Box>
      )}
    />
  );
}

export default HookFormColorPicker;
