import { useTeamContext } from '@local/context/league/team-context';
import { Game } from '@local/graphql/graphql';
import { Card, CardActionArea, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameTeamDisplay } from './GameTeamDisplay';

export type GameDisplayProps = {};

export function GamesListDisplay(props: GameDisplayProps) {
  const { registration } = useTeamContext();
  const navigate = useNavigate();
  const allGames = useMemo(() => {
    if (registration?.home_games && registration?.away_games) {
      return sortBy([...registration.home_games, ...registration.away_games] as Game[], 'date.datetime');
    }
  }, [registration?.away_games, registration?.home_games]);

  return (
    <Grid container columnSpacing={'3px'} rowSpacing={'3px'}>
      {allGames?.map((game) => (
        <Grid xs={12} lg={6} key={game.id}>
          <Card variant="outlined" sx={{ margin: 0, padding: 0 }}>
            <CardActionArea
              onClick={() => {
                navigate(`./game/${game.id}`);
              }}>
              <CardContent>
                <GameTeamDisplay game={game} />
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default GamesListDisplay;
