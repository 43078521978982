import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useDraggable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { TeamLogo } from '@local/components';
import {
  AgeGroup,
  Division,
  LoadRegistrationDocument,
  LoadRegistrationsDocument,
  Registration,
  RemoveRegistrationDocument,
  Team,
  TeamLevel,
  UpdateTeamDocument,
  UpsertRegistrationDocument,
  UpsertRegistrationInput,
} from '@local/graphql/graphql';
import { IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditIcon, VisibleIf, useOperations } from '@seasonticker/dls';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AgeGroupDropData, AgeGroupTree } from '../components';
import RegistrationEditorForm from './RegistrationEditor';

export function Registrations() {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const { operation, confirmedOperation } = useOperations();
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup>();
  const [selectedTeamLevel, setSelectedTeamLevel] = useState<TeamLevel>();
  const [registrations, setRegistrations] = useState<Registration[]>();
  const [selectedRegistration, setSelectedRegistration] = useState<Registration>();
  const [draggingRegistration, setDraggingRegistration] = useState<Registration>();
  const [editorOpen, setEditorOpen] = useState(false);

  const [loadRegistrationsQuery] = useLazyQuery(LoadRegistrationsDocument, { fetchPolicy: 'no-cache' });
  const [loadRegistrationQuery] = useLazyQuery(LoadRegistrationDocument);
  const [upsertRegistrationMutation] = useMutation(UpsertRegistrationDocument);
  const [updateTeamMutation] = useMutation(UpdateTeamDocument);
  const [removeRegistrationMutation] = useMutation(RemoveRegistrationDocument);

  const nodeClick = useCallback((ag: AgeGroup, tl: TeamLevel) => {
    setSelectedAgeGroup(ag);
    setSelectedTeamLevel(tl);
  }, []);

  const registrationsLoader = useCallback(async () => {
    if (selectedAgeGroup && selectedTeamLevel) {
      const response = await loadRegistrationsQuery({ variables: { ageGroupId: selectedAgeGroup.id, teamLevelId: selectedTeamLevel.id } });
      if (response.data?.registrations) {
        setRegistrations(sortBy(response.data.registrations as Registration[], 'team.name'));
      }
    }
  }, [loadRegistrationsQuery, selectedAgeGroup, selectedTeamLevel]);

  const handleEditorClose = useCallback(() => {
    setEditorOpen(false);
  }, []);

  const editClick = useCallback(
    async (reg: Registration) => {
      const results = await loadRegistrationQuery({
        variables: {
          registrationId: reg.id,
        },
      });
      setSelectedRegistration(results.data?.registration as Registration);
      setEditorOpen(true);
    },
    [loadRegistrationQuery]
  );

  const onSubmit = useCallback(
    async (upsert: UpsertRegistrationInput) => {
      const ageGroupId = (upsert.age_group as unknown as AgeGroup)?.id || upsert.age_group;
      const teamLevelId = (upsert.team_level as unknown as TeamLevel)?.id || upsert.team_level;
      const divisionId = (upsert.division as unknown as Division)?.id || upsert.division;
      const team = (upsert.team as unknown as Team) || ({} as Team);
      await operation({
        success: 'Registration updated',
        operation: () => {
          return Promise.all([
            upsertRegistrationMutation({
              variables: {
                upsert: {
                  id: upsert.id,
                  age_group: ageGroupId,
                  team_level: teamLevelId,
                  division: divisionId,
                },
              },
            }),
            updateTeamMutation({
              variables: {
                id: team.id,
                update: {
                  name: team.name,
                },
              },
            }),
          ]);
        },
        after: () => {
          setEditorOpen(false);
          registrationsLoader();
        },
      });
    },
    [operation, registrationsLoader, updateTeamMutation, upsertRegistrationMutation]
  );

  const onDelete = useCallback(
    async (registration: Registration) => {
      await confirmedOperation({
        success: 'The registration was removed',
        operation: () => removeRegistrationMutation({ variables: { registrationId: registration.id } }),
        confirmation: `Are you sure you want to unregister ${registration.team?.name}`,
        after: () => {
          registrationsLoader();
        },
      });
    },
    [confirmedOperation, registrationsLoader, removeRegistrationMutation]
  );

  const onDrag = (event: DragStartEvent) => {
    setDraggingRegistration(event.active?.data?.current as Registration);
  };

  const onDrop = (dropEvent: DragEndEvent) => {
    if (dropEvent.over) {
      const draggingReg = dropEvent.active.data.current as Registration;
      const dropData = dropEvent.over.data.current as AgeGroupDropData;
      const upsertData: UpsertRegistrationInput = {
        id: draggingReg.id,
        age_group: dropData?.age_group?.id,
        team_level: dropData?.team_level?.id,
      };
      onSubmit(upsertData);
      setDraggingRegistration(undefined);
    }
  };

  useEffect(() => {
    registrationsLoader();
  }, [registrationsLoader]);

  /**
   * Draggable Item
   * @TODO make this more generic draggable thing
   * @param props
   * @returns
   */
  const DraggableRegistration = (props: { registration: Registration }) => {
    const { registration } = props;
    const { setNodeRef, isDragging, attributes, listeners } = useDraggable({
      id: registration.id,
      data: registration,
    });
    return (
      <ListItem ref={setNodeRef} {...attributes} {...listeners} key={registration.id} sx={{ opacity: isDragging ? '40%' : '100%' }}>
        <ListItemButton onClick={() => editClick(registration)}>
          <ListItemAvatar>
            <TeamLogo url={registration.team?.logo?.url} />
          </ListItemAvatar>
          <ListItemText primary={registration.team?.name} />
        </ListItemButton>
        <ListItemSecondaryAction>
          <Tooltip title={`Edit ${registration.team?.name}`}>
            <IconButton color="primary" onClick={() => editClick(registration)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Unregister ${registration.team?.name}`}>
            <IconButton color="error" onClick={() => onDelete(registration)}>
              <PlaylistRemoveIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <DndContext onDragEnd={onDrop} sensors={sensors} onDragStart={onDrag} collisionDetection={pointerWithin}>
      <DragOverlay>
        <Typography>{draggingRegistration?.team?.name}</Typography>
      </DragOverlay>
      <Grid container>
        <Grid xs={12} md={3}>
          <AgeGroupTree onClick={nodeClick} />
        </Grid>

        <VisibleIf cond={(selectedTeamLevel?.name?.length || 0) > 0}>
          <Grid xs={12} md={9}>
            <Typography variant="h6">
              {selectedAgeGroup?.name} {selectedTeamLevel?.name}
            </Typography>
            <Typography variant="caption">
              Click on a team to edit the registration details. You can also drag and drop a registration to a level on the tree on the left to move it.
            </Typography>
            <List dense>
              {registrations?.map((registration) => {
                return <DraggableRegistration key={registration.id} registration={registration} />;
              })}
            </List>
          </Grid>
        </VisibleIf>
      </Grid>
      <RegistrationEditorForm
        mode="dialog"
        model={selectedRegistration}
        open={editorOpen}
        handleClose={handleEditorClose}
        onSubmit={onSubmit}
        title="Registration Editor"
      />
    </DndContext>
  );
}

export default Registrations;
