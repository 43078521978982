import { useQuery } from '@apollo/client';
import { useNavigationContext } from '@local/context/league/navigation-context';
import { useSeasonContext } from '@local/context/league/season-context';
import { TeamContextProvider, useTeamContext } from '@local/context/league/team-context';
import { TeamHomeQueryDocument } from '@local/graphql/graphql';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Router from './Router';

export function TeamHomeLoader() {
  const { urlKey = '' } = useParams();
  const { setTeamContext } = useTeamContext();
  const { setSecondaryPages } = useNavigationContext();
  const { season } = useSeasonContext();
  const { data } = useQuery(TeamHomeQueryDocument, {
    variables: {
      urlKey,
      seasonId: season?.id,
    },
    skip: season?.id === undefined,
  });

  useEffect(() => {
    setTeamContext(data?.team_registration);
  }, [setTeamContext, data]);

  return <Router />;
}

export default function TeamHome() {
  return (
    <TeamContextProvider>
      <TeamHomeLoader />
    </TeamContextProvider>
  );
}
