import { TextFieldProps } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { Controller, FieldValues } from 'react-hook-form';
import { useDateFns, useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormDateTimePickerProps<T extends FieldValues = FieldValues> = HookFormBaseProps<T> & {
  DateTimePickerProps?: Omit<DateTimePickerProps<Date>, 'label' | 'renderInput'>;
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'fullWidth' | 'error'>;
};
export function HookFormDateTimePicker<T extends FieldValues = FieldValues>(props: HookFormDateTimePickerProps<T>) {
  const { name, label, defaultValue, rules, control, fullWidth, tooltip, ControllerProps, TextFieldProps, DateTimePickerProps } = props;
  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  const { safeParseJson } = useDateFns();
  return (
    <Controller
      {...ControllerProps}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...fieldProps }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <DateTimePicker
          {...DateTimePickerProps}
          label={labelValue}
          value={safeParseJson(value)}
          onChange={(value) => {
            onChange({
              target: {
                value: value,
              },
            });
          }}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...fieldProps}
        />
      )}
    />
  );
}

export default HookFormDateTimePicker;
