import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useLazyQuery } from '@apollo/client';
import { AgeGroup, Division, LoadTeamsForSchedulingDocument, Registration, TeamLevel } from '@local/graphql/graphql';
import { Box, Button, Card, CardContent, CardHeader, Checkbox } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormAutoComplete } from '@seasonticker/dls';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SchedulingService } from './ScheduleService';
import { ScheduleWizardStepProps } from './ScheduleTypes';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function ScheduleGenerate(props: ScheduleWizardStepProps) {
  const context = useFormContext();
  const age_group: AgeGroup = useWatch({ name: 'age_group' });
  const team_level: TeamLevel = useWatch({ name: 'team_level' });
  const divisions: Division[] = useWatch({ name: 'divisions' });
  const [divisionGames, setDivisionGames] = useState<Division[]>([]);
  const total_games_each: number = useWatch({ name: 'total_games_each' });
  const max_home_games_each: number = useWatch({ name: 'max_home_games_each' });
  const use_out_of_division: boolean = useWatch({ name: 'use_out_of_division' });
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [loadTeamsQuery] = useLazyQuery(LoadTeamsForSchedulingDocument, { fetchPolicy: 'no-cache' });

  const teamLoader = useCallback(
    async (ageGroupId: string, teamLevelId: string) => {
      const response = await loadTeamsQuery({
        variables: {
          ageGroupId: ageGroupId,
          teamLevelId: teamLevelId,
        },
      });
      if (response.data) {
        const registrations = response.data.registrations as Registration[];
        let filteredTeams = registrations;
        if (divisions?.length) {
          filteredTeams = registrations.filter((reg) => divisions.find((div) => div.id === reg.division?.id) !== undefined);
        }
        filteredTeams = sortBy(filteredTeams, 'division.name', 'team.name');
        setRegistrations(filteredTeams);
        context.setValue('teams', filteredTeams);
      }
    },
    [context, divisions, loadTeamsQuery]
  );

  useEffect(() => {
    if (age_group && team_level) {
      teamLoader(age_group?.id, team_level?.id);
    }
  }, [age_group, teamLoader, team_level]);

  useEffect(() => {
    const divisionsCopy = [...divisions];
    registrations?.forEach((registration) => {
      const division = divisionsCopy.find((d) => d.id === registration.division?.id);
      if (division) {
        if (!division.registrations) {
          division.registrations = [];
        }
        division.registrations.push(registration);
      }
    });
    setDivisionGames(divisionsCopy);
  }, [context, divisions, registrations]);

  const generate = () => {
    const scheduleService = new SchedulingService();
    const divisionGamesCopy = [...divisionGames];
    scheduleService.generateSchedule(divisionGamesCopy, {
      attempts: 0,
      maxAttempts: 100,
      gamesEach: max_home_games_each,
      useDivisionTeams: false,
      useOutOfDivisionTeams: use_out_of_division,
      maxGames: total_games_each,
      maxOutOfDivisionGames: 1,
    });
    setDivisionGames(divisionGamesCopy);
  };
  return (
    <Box>
      <Grid container rowSpacing={'.8em'} columnSpacing={'.8em'}>
        <Grid xs={12}>
          <HookFormAutoComplete
            fullWidth
            multiple
            disableCloseOnSelect
            label="Teams"
            tooltip="Select Teams"
            name="teams"
            getOptionLabel={(item) => `${item?.division?.name}: ${item?.team?.name}` || ''}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.division?.name}: {option.team?.name}
              </li>
            )}
            options={registrations}
          />
        </Grid>
        <Grid xs={12}>
          <Button onClick={() => generate()} variant="contained" color="primary">
            Generate
          </Button>
        </Grid>
      </Grid>

      {divisionGames?.map((division) => {
        return (
          <Card key={division?.id}>
            <CardHeader title={division.name} />
            <CardContent>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell>Team</TableCell>
                      <TableCell align="right">Home Games</TableCell>
                      <TableCell align="right">Away Games</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {division.registrations?.map((registration, idx) => (
                      <TableRow key={registration.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{registration.team?.name}</TableCell>
                        <TableCell align="right" scope="row">
                          {registration.home_games?.length}
                        </TableCell>
                        <TableCell align="right">{registration.away_games?.length}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        );
      })}

      {divisionGames?.map((division) => {
        return (
          <Card key={division?.id}>
            <CardHeader title={division.name} />
            <CardContent>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Home Team</TableCell>
                      <TableCell align="right">Away Team</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {division.registrations
                      ?.flatMap((registration) => [...(registration.home_games || [])])
                      .map((row) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.home_team?.team?.name}
                          </TableCell>
                          <TableCell align="right">{row.away_team?.team?.name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        );
      })}

      {/* <Debug data={context.getValues()}></Debug> */}
    </Box>
  );
}

export default ScheduleGenerate;
