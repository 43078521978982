import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import {
  Announcement,
  DeleteMessageDocument,
  LoadAnnouncementsDocument,
  UpsertAnnouncementInput,
  UpsertManyMessagesDocument,
  UpsertMessageDocument,
} from '@local/graphql/graphql';
import { AppBar, Box, Button, ListItemText, Toolbar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  EditableCard,
  HookFormRichEditor,
  HookFormTextField,
  Roles,
  SortableList,
  SortableListItem,
  useAuthz,
  useEditable,
  useSortableList,
} from '@seasonticker/dls';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export function Messages() {
  const { leagueContext } = useLeagueContext();
  const { ifInRole } = useAuthz();
  const [refreshAnnouncements] = useLazyQuery(LoadAnnouncementsDocument, { fetchPolicy: 'no-cache' });
  const [upsertManyAnnouncements] = useMutation(UpsertManyMessagesDocument);
  const [upsertMessage] = useMutation(UpsertMessageDocument);
  const [deleteMessage] = useMutation(DeleteMessageDocument);

  const { list, setList, sorting, onSorted, toggleSorting } = useSortableList<
    Announcement,
    UpsertAnnouncementInput,
    ReturnType<typeof upsertManyAnnouncements>
  >({
    upsertOperation: (items) => () =>
      upsertManyAnnouncements({
        variables: {
          update: items.filter((items) => items.id !== undefined).map((items, idx) => ({ id: items.id, sortOrder: idx })),
        },
      }),
  });

  const { editingItem, setEditingItem, onAdd, onDelete, onSave } = useEditable({
    model: 'Announcement',
    deleteMutation: (item: Announcement) => () => {
      if (item.id) {
        return deleteMessage({ variables: { id: String(item.id) } });
      } else {
        return Promise.resolve(undefined);
      }
    },
    emptyTemplate: {
      title: '',
      message: '',
      sortOrder: -1,
    } as Announcement,
    prepareUpsert: (item) => {
      const { id, title, message, sortOrder } = item;
      const upsertValue: UpsertAnnouncementInput = id === undefined ? { title, message, sortOrder, league: leagueContext?.league?.id } : { id, title, message };
      return upsertValue;
    },
    refresh: async () => {
      const response = await refreshAnnouncements({ variables: { leagueId: String(leagueContext?.league?.id) } });
      setList(response.data?.announcements as Announcement[]);
    },
    upsertMutation: (update: UpsertAnnouncementInput) => () => upsertMessage({ variables: { upsert: update } }),
  });

  const addToList = useCallback(
    (item: Announcement) => {
      setList([item, ...list]);
    },
    [list, setList]
  );

  useEffect(() => {
    if (leagueContext?.league?.announcements) {
      setList(leagueContext.league.announcements);
    }
  }, [leagueContext?.league?.announcements, setList]);

  const canEdit = useMemo(() => {
    return ifInRole({ context: leagueContext?.league?.id, roles: [Roles.admin, Roles.leagueadmin] });
  }, [ifInRole, leagueContext?.league?.id]);

  return (
    <Box>
      {canEdit(
        <AppBar position="relative" color="primary">
          <Toolbar color="primary">
            <Button sx={{ color: '#fff' }} onClick={() => onAdd(addToList)}>
              Create
            </Button>
            <Button sx={{ color: '#fff' }} onClick={toggleSorting}>
              {sorting ? 'Save Order' : 'Sort'}
            </Button>
          </Toolbar>
        </AppBar>
      )}
      {sorting ? (
        <SortableList<Announcement>
          items={list}
          sorting={sorting}
          onSorted={onSorted}
          ItemTemplate={(item) => (
            <SortableListItem key={item.id}>
              <ListItemText primary={item.title} />
            </SortableListItem>
          )}
        />
      ) : (
        list.map((announcement) => {
          return (
            <MessageDisplay
              key={announcement.id}
              announcement={announcement}
              onSubmit={onSave}
              onEditClick={(item) => setEditingItem(item)}
              onDeleteClick={onDelete}
              editing={editingItem?.id === announcement.id}
            />
          );
        })
      )}
    </Box>
  );
}
type MessageDisplayProps = {
  announcement: Announcement;
  editing?: boolean;
  onSubmit: (item: Announcement) => void;
  onEditClick: (item?: Announcement) => void;
  onDeleteClick: (item: Announcement) => void;
};
function MessageDisplay(props: MessageDisplayProps) {
  const { announcement, editing = false, onEditClick, onDeleteClick, onSubmit } = props;
  const { leagueContext } = useLeagueContext();
  const { ifInRole } = useAuthz();
  const canEdit = ifInRole({ context: leagueContext?.league?.id, roles: [Roles.admin, Roles.leagueadmin] });
  const formProps = useForm<Announcement>();

  useEffect(() => {
    formProps.reset(announcement);
  }, [announcement, formProps]);

  return (
    <EditableCard
      canDelete={announcement.id !== undefined}
      editMode={announcement.id === undefined || editing}
      onDeleteClick={() => onDeleteClick(announcement)}
      onEditClick={(e) => onEditClick(e ? announcement : undefined)}
      canEdit={canEdit}
      title={announcement.title || ''}
      viewDisplay={<div dangerouslySetInnerHTML={{ __html: String(announcement.message) }}></div>}
      editDisplay={
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2}>
              <Grid xs={12}>
                <HookFormTextField name="title" label="Title" fullWidth />
              </Grid>
              <Grid xs={12}>
                <HookFormRichEditor name="message" label="Message" height={400} />
              </Grid>
              <Grid xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      }></EditableCard>
  );
}

export default Messages;
