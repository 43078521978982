import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ConfirmProvider } from '@seasonticker/dls';
import { SnackbarProvider } from 'notistack';
import Router from './Router';

export default function Context() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider maxSnack={3} autoHideDuration={5000} variant="success" anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <ConfirmProvider>
          <Router />
        </ConfirmProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}
