import { useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import { FileMeta, League, UpsertLeagueDocument, UpsertLeagueInput } from '@local/graphql/graphql';
import { useFileUpload } from '@local/hooks';
import { Button, Card, CardActions, CardContent, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormDropZone, HookFormRichEditor, HookFormTextField, useOperations } from '@seasonticker/dls';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const LogoImage = styled('img')({ maxHeight: '60px' });

export function LeagueSettings() {
  const { operation } = useOperations();
  const { leagueContext } = useLeagueContext();
  const [updateLeague] = useMutation(UpsertLeagueDocument);
  const formMethods = useForm<League>({
    defaultValues: {
      name: '',
      description: '',
      acronym: '',
    },
  });

  useEffect(() => {
    if (leagueContext?.league) {
      formMethods.reset(leagueContext.league as League);
    }
  }, [leagueContext, formMethods]);
  const { uploadFile } = useFileUpload();
  const onSubmit = async (item: League) => {
    let newFile: FileMeta | null = null;
    if (item.logo) {
      newFile = (await uploadFile(item.logo, { folder: `uploads/league/${leagueContext?.league?.id}` })) as FileMeta;
    }
    const update: UpsertLeagueInput = {
      id: item.id,
      name: item.name,
      description: item.description,
      acronym: item.acronym,
    };
    if (newFile?.id) {
      update.logo = newFile?.id;
    }
    await operation({
      success: 'League successfully saved',
      error: 'There was an error updating the league',
      operation: () =>
        updateLeague({
          variables: {
            update: update,
          },
        }),
    });
  };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={'1em'}>
              <Grid xs={12}>
                <HookFormTextField name="name" label="Name" fullWidth rules={{ required: true }} />
              </Grid>
              <Grid xs={12}>
                <HookFormDropZone
                  label="Logo"
                  name="logo"
                  accept={{
                    'image/*': [],
                  }}
                  preview={(value) => (value ? <LogoImage src={String((value as FileMeta)?.url || '')} /> : <></>)}
                  valueGetter={(value: FileMeta) => value}
                  valueSetter={(value: FileMeta, priorValue: FileMeta) => value}
                />
              </Grid>
              <Grid xs={12}>
                <HookFormTextField name="acronym" label="Acronym" fullWidth rules={{ required: false }} />
              </Grid>
              <Grid xs={12}>
                <HookFormRichEditor label="Description" name="description" />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
}

export default LeagueSettings;
