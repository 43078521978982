import { useDroppable } from '@dnd-kit/core';
import { useSeasonContext } from '@local/context';
import { AgeGroup, TeamLevel } from '@local/graphql/graphql';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import useSorting from '../../hooks/useSorting/useSorting';

export type AgeGroupTreeProps = {
  onClick: (ageGroup: AgeGroup, teamLevel: TeamLevel) => void;
};

export type AgeGroupDropData = {
  age_group: AgeGroup;
  team_level: TeamLevel;
};

export function AgeGroupTree(props: AgeGroupTreeProps) {
  const { season } = useSeasonContext();
  const sort = useSorting();
  const { onClick } = props;

  const DroppableTeamLevelTreeItem = (props: { age_group: AgeGroup; team_level: TeamLevel } & TreeItemProps) => {
    const { age_group, team_level, ...rest } = props;
    const { setNodeRef, isOver } = useDroppable({
      id: team_level.id,
      data: {
        age_group: age_group,
        team_level: team_level,
      } as AgeGroupDropData,
    });
    return (
      <TreeItem {...rest} ref={setNodeRef} sx={(theme) => ({ backgroundColor: isOver ? theme.palette.action.hover : theme.palette.background.default })} />
    );
  };
  return (
    <TreeView aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {sort(season?.age_groups)?.map((ag) => {
        return (
          <TreeItem key={ag.id} nodeId={ag.id} label={ag.name}>
            {sort(ag?.team_levels)?.map((tl) => {
              return <DroppableTeamLevelTreeItem age_group={ag} team_level={tl} key={tl.id} nodeId={tl.id} label={tl.name} onClick={() => onClick(ag, tl)} />;
            })}
          </TreeItem>
        );
      })}
    </TreeView>
  );
}
