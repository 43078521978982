import { AgeGroup, UpsertAgeGroupInput } from '@local/graphql/graphql';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HookFormRichEditor, HookFormTextField, ModalForm, ModalFormImplementationProps } from '@seasonticker/dls';

export function AgeGroupForm(props: ModalFormImplementationProps<AgeGroup, UpsertAgeGroupInput>) {
  const FormFields = () => {
    return (
      <Grid container>
        <Grid xs={12}>
          <HookFormTextField name="name" label="Name" fullWidth rules={{ required: true }} />
        </Grid>
        <Grid xs={12}>
          <HookFormRichEditor label="description" name="description" />
        </Grid>
      </Grid>
    );
  };
  return <ModalForm {...props} FormFields={FormFields} defaultValues={{ name: '' }} />;
}

export default AgeGroupForm;
