import { useLazyQuery, useMutation } from '@apollo/client';
import { AgeGroup, LoadAgeGroupSchedulingDocument, LoadAgeGroupSchedulingQuery, UpsertAgeGroupSchedulingDocument } from '@local/graphql/graphql';
import { AgeGroupSelector } from '@local/league/components';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormSwitch, useOperations } from '@seasonticker/dls';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type SchedulingProps = {};

export function Scheduling(props: SchedulingProps) {
  const form = useForm<LoadAgeGroupSchedulingQuery['age_group']>();
  const [loadScheduling] = useLazyQuery(LoadAgeGroupSchedulingDocument, { fetchPolicy: 'no-cache' });
  const [upsertMutation] = useMutation(UpsertAgeGroupSchedulingDocument);
  const { operation } = useOperations();
  const schedulingLoader = useCallback(
    async (ageGroup: AgeGroup) => {
      if (ageGroup?.id) {
        const response = await loadScheduling({ variables: { ageGroupId: ageGroup.id } });
        if (response?.data?.age_group) {
          form.reset(response.data.age_group);
        }
      }
    },
    [form, loadScheduling]
  );
  const ageGroupChange = useCallback(
    (age_group: AgeGroup) => {
      if (age_group?.id) {
        schedulingLoader(age_group);
      }
    },
    [schedulingLoader]
  );

  const onSubmit = useCallback(
    async (formData: LoadAgeGroupSchedulingQuery['age_group']) => {
      console.log(formData);
      await operation({
        success: 'Scheduling updated successfully',
        operation: () =>
          upsertMutation({
            variables: {
              upsertAgeGroup: {
                id: formData.id,
                schedulingOpen: formData.schedulingOpen,
                inviteSchedulingEnabled: formData.inviteSchedulingEnabled,
                openSchedulingEnabled: formData.openSchedulingEnabled,
              },
            },
          }),
      });
    },
    [operation, upsertMutation]
  );
  return (
    <Box>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container rowSpacing={'1em'}>
            <Grid xs={12}>
              <AgeGroupSelector onChange={ageGroupChange} />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch label="Enable Scheduling" name="schedulingOpen" tooltip={'Enables the ability for teams to schedule games for this age group.'} />
            </Grid>
            {/* <Grid xs={12}>
              <HookFormSwitch
                label="Enable Scheduling"
                name="inviteSchedulingEnabled"
                tooltip={
                  'Enables the ability for teams to invite other teams to schedule their games for this age group. (Requires Scheduling Open to be enabled)'
                }
              />
            </Grid>
            <Grid xs={12}>
              <HookFormSwitch
                label="Enable Open Scheduling"
                name="openSchedulingEnabled"
                tooltip={'Enables the ability for teams to schedule their own games'}
              />
            </Grid> */}
            <Grid xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
}
