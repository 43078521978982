import { ResolveUrlQuery } from '@local/graphql/graphql';
import { createContext, ReactElement, useCallback, useContext, useState } from 'react';
type LeagueResponse = ResolveUrlQuery['vanity_url_resolve'];
export type LeagueState = {
  leagueContext: LeagueResponse;
  setLeagueContext: (league: LeagueResponse) => void;
};

/**
 * Note, this is ONLY to be used in the root app, to get access to the values
 * use the usePortalContext hook
 * @returns
 */
function useLeagueState(): LeagueState {
  const [leagueContext, setLeagueContextState] = useState<LeagueResponse>({} as LeagueResponse);
  const setLeagueContext = useCallback((newLeague: LeagueResponse) => {
    setLeagueContextState(newLeague);
  }, []);

  return {
    leagueContext,
    setLeagueContext,
  };
}

export const LeagueContext = createContext<LeagueState>({} as LeagueState);
export const LeagueContextProvider = ({ children }: { children: ReactElement }) => {
  const leagueState = useLeagueState();
  return <LeagueContext.Provider value={leagueState}>{children}</LeagueContext.Provider>;
};
export const LeagueContextConsumer = LeagueContext.Consumer;

/**
 * Returns the current portal state.  There are a few global values stored in the state to control things like header
 * and page titles
 * @returns The Current PortalState
 */
export function useLeagueContext(): LeagueState {
  return useContext(LeagueContext);
}
