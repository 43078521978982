//<Controller name="registrationOpen" control={control} rules={{ required: true }} render={({ field, fieldState: { error, isDirty }, formState: { isSubmitted } }) => <DatePicker label="Open Registration" renderInput={(params) => <TextField fullWidth helperText={error?.message} error={(isDirty || isSubmitted) && !!error} {...params} />} {...field} />} />

import { TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Controller, FieldValues } from 'react-hook-form';
import { useDateFns, useHelpLabel } from '../hooks';
import { HookFormBaseProps } from './HookFormBaseProps';

export type HookFormDatePickerProps<T extends FieldValues = FieldValues> = HookFormBaseProps<T> & {
  DatePickerProps?: Omit<DatePickerProps<Date>, 'label' | 'renderInput'>;
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'fullWidth' | 'error'>;
};
export function HookFormDatePicker<T extends FieldValues = FieldValues>(props: HookFormDatePickerProps<T>) {
  const { safeParseJson } = useDateFns();
  const { name, label, defaultValue, rules, control, tooltip, ControllerProps, DatePickerProps } = props;
  const labelValue = useHelpLabel(label, rules?.required as boolean, tooltip);
  return (
    <Controller
      {...ControllerProps}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, ...fieldProps }, fieldState: { error, isDirty }, formState: { isSubmitted } }) => (
        <DatePicker
          {...DatePickerProps}
          label={labelValue}
          value={safeParseJson(value)}
          onChange={(value) => {
            onChange({
              target: {
                value: value,
              },
            });
          }}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...fieldProps}
          //<TextField {...TextFieldProps} fullWidth={fullWidth} helperText={error?.message} error={(isDirty || isSubmitted) && !!error} {...params} />
        />
      )}
    />
  );
}

export default HookFormDatePicker;
