import { useLeagueContext } from '@local/context/league/league-context';
import { useSeasonContext } from '@local/context/league/season-context';
import { Season } from '@local/graphql/graphql';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function SeasonSelection() {
  const { season, setSeasonContext } = useSeasonContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { leagueContext } = useLeagueContext();
  const options = leagueContext?.league?.seasons || [];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(options.length - 1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const idx = leagueContext?.league?.seasons?.findIndex((s) => s.id === season?.id);
    if (idx && idx >= 0) {
      setSelectedIndex(idx);
    }
  }, [leagueContext, season]);

  const handleMenuItemClick = (event: MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setSearchParams({ ...searchParams, seasonOverride: options[index].id });
    setSeasonContext(options[index] as Season);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Season Selection">
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}>
          <ListItemText primary={options[selectedIndex]?.name} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}>
        {options.map((option, index) => (
          <MenuItem key={option.id} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
