import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AgeGroup,
  AssignRegistrationDivisionDocument,
  Division,
  LoadTeamLevelDivisionsDocument,
  LoadTeamLevelRegistrationsDocument,
  Registration,
  TeamLevel,
  UpsertDivisionDocument,
  UpsertDivisionInput,
} from '@local/graphql/graphql';
import { AgeGroupTree } from '@local/league/components';
import { Card, CardContent, CardHeader, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddIcon, VisibleIf, useOperations } from '@seasonticker/dls';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSorting from '../../hooks/useSorting/useSorting';
import DivisionForm from '../setup/DivisionForm';

export function AssignDivisions() {
  const { operation } = useOperations();
  const [draggingRegistration, setDraggingRegistration] = useState<Registration>();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup>();
  const [selectedTeamLevel, setSelectedTeamLevel] = useState<TeamLevel>();
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [registrations, setRegistrations] = useState<Registration[]>([]);
  const [loadDivisionsQuery] = useLazyQuery(LoadTeamLevelDivisionsDocument, { fetchPolicy: 'no-cache' });
  const [loadRegistrationsQuery] = useLazyQuery(LoadTeamLevelRegistrationsDocument);
  const [assignDivisionMutation] = useMutation(AssignRegistrationDivisionDocument);
  const [upsertDivisionMutation] = useMutation(UpsertDivisionDocument);
  const nodeClick = useCallback((ag: AgeGroup, tl: TeamLevel) => {
    setSelectedAgeGroup(ag);
    setSelectedTeamLevel(tl);
  }, []);
  const [divisionDialogOpen, setDivisionDialogOpen] = useState(false);
  const handleCloseDivisionDialog = () => {
    setDivisionDialogOpen(false);
  };
  const divisionStats = useMemo(() => {
    const values: { id: string; count: number }[] = divisions?.map((d) => ({
      id: d.id,
      count: registrations.filter((r) => r.division?.id === d.id)?.length || 0,
    }));
    const map: Record<string, number> = {};
    values.forEach((v) => (map[v.id] = v.count));
    return map;
  }, [divisions, registrations]);

  const unassignedTeams = useMemo(() => {
    return sortBy(
      registrations?.filter((r) => divisions?.find((d) => d.id === r.division?.id) === undefined),
      'team.name'
    );
  }, [divisions, registrations]);

  const sort = useSorting();
  const loadDivisions = useCallback(async () => {
    if (selectedTeamLevel?.id) {
      const results = await loadDivisionsQuery({
        variables: {
          teamLevelId: selectedTeamLevel.id,
        },
      });
      if (results.data?.divisions) {
        setDivisions(sort(results.data.divisions as Division[]));
      }
    }
  }, [loadDivisionsQuery, selectedTeamLevel?.id, sort]);

  const loadRegistrations = useCallback(async () => {
    if (selectedTeamLevel?.id) {
      const results = await loadRegistrationsQuery({
        variables: {
          teamLevelId: selectedTeamLevel.id,
        },
      });
      if (results.data?.registrations) {
        setRegistrations(results.data.registrations as Registration[]);
      }
    }
  }, [loadRegistrationsQuery, selectedTeamLevel?.id]);

  const assignDivision = useCallback(
    async (registration: Registration) => {
      await operation({
        success: 'Division Assigned',
        error: 'There was an error assigning the division',
        operation: () =>
          assignDivisionMutation({
            variables: {
              registrationId: registration.id,
              update: {
                division: registration.division?.id,
              },
            },
          }),
      });
    },
    [assignDivisionMutation, operation]
  );

  const onSubmit = async (data: UpsertDivisionInput) => {
    const { name } = data;
    const updateValue: UpsertDivisionInput = {
      name,
      id: data?.id,
    };
    if (data?.id === undefined || data?.id?.length === 0) {
      updateValue.team_level = selectedTeamLevel?.id;
      updateValue.sortOrder = divisions.length;
    }
    await operation({
      success: 'The division was successfully added',
      error: 'There was an error adding the division',
      operation: async () => {
        const results = await upsertDivisionMutation({
          variables: {
            upsert: updateValue,
          },
        });
        return (results.data?.upsert_division || {}) as Division;
      },
      after: (division: Division) => {
        setDivisions([...divisions, division]);
        handleCloseDivisionDialog();
      },
    });
  };

  useEffect(() => {
    loadDivisions();
    loadRegistrations();
  }, [loadDivisions, loadRegistrations]);

  /**
   * Draggable Item
   * @TODO make this more generic draggable thing
   * @param props
   * @returns
   */
  const DraggableRegistration = (props: { registration: Registration }) => {
    const { registration } = props;
    const { setNodeRef, isDragging, attributes, listeners } = useDraggable({
      id: registration.id,
      data: registration,
    });
    return (
      <ListItem ref={setNodeRef} {...attributes} {...listeners} sx={{ visibility: isDragging ? 'hidden' : 'visible' }}>
        <ListItemText primary={registration.team?.name} />
      </ListItem>
    );
  };

  const onDrag = (reg: DragStartEvent) => {
    setDraggingRegistration(reg.active.data.current as Registration);
  };

  const onDrop = (dropEvent: DragEndEvent) => {
    if (dropEvent.over) {
      setRegistrations(
        registrations?.map((r) => {
          if (r.id === dropEvent.active.id) {
            const updatedReg = {
              ...r,
              division: divisions.find((d) => d.id === dropEvent.over?.id),
            };
            assignDivision(updatedReg);
            return updatedReg;
          }
          return r;
        })
      );
      setDraggingRegistration(undefined);
    }
  };

  const DroppableDivision = (props: { division: Division }) => {
    const { division } = props;
    const { setNodeRef, isOver } = useDroppable({
      id: division.id,
      data: division,
    });
    return (
      <Grid xs={12} md={6} xl={4}>
        <Card key={division.id} ref={setNodeRef} sx={(theme) => ({ backgroundColor: isOver ? theme.palette.action.hover : theme.palette.background.default })}>
          <CardHeader title={`${division.name} (${divisionStats[division.id] || 0})`} />
          <CardContent>
            <List
              dense
              sx={{
                height: {
                  xs: `calc(70vh / ${Math.ceil((divisions?.length || 1) / 1)})`,
                  md: `calc(70vh / ${Math.ceil((divisions?.length || 1) / 2)})`,
                  xl: `calc(70vh / ${Math.ceil((divisions?.length || 1) / 3)})`,
                },
                overflowY: 'auto',
              }}>
              {sortBy(
                registrations?.filter((r) => r.division?.id === division.id),
                'team.name'
              ).map((r) => (
                <DraggableRegistration key={r.id} registration={r} />
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <DndContext onDragEnd={onDrop} sensors={sensors} onDragStart={onDrag} collisionDetection={pointerWithin}>
      <DragOverlay>
        <Typography>{draggingRegistration?.team?.name}</Typography>
      </DragOverlay>
      <Grid container>
        <Grid xs={12} md={2}>
          <AgeGroupTree onClick={nodeClick} />
        </Grid>
        <Grid xs={12} md={3} xl={2}>
          <VisibleIf cond={(selectedTeamLevel?.name?.length || 0) > 0}>
            <>
              <Typography variant="h6">
                {selectedAgeGroup?.name} {selectedTeamLevel?.name} - Unassigned ({unassignedTeams?.length})
              </Typography>
              <List dense>
                {unassignedTeams?.map((r) => {
                  return <DraggableRegistration key={r.id} registration={r} />;
                })}
              </List>
            </>
          </VisibleIf>
        </Grid>
        <Grid xs={12} md={7} xl={8}>
          <VisibleIf cond={(selectedTeamLevel?.name?.length || 0) > 0}>
            <>
              <Typography variant="h6">
                Divisions{' '}
                <Tooltip title="Add another division">
                  <IconButton onClick={() => setDivisionDialogOpen(true)}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <DivisionForm mode="dialog" open={divisionDialogOpen} handleClose={handleCloseDivisionDialog} onSubmit={onSubmit} title="Add a Division" />
              <Grid container columnSpacing={'.8em'} rowSpacing={'.8em'}>
                {divisions?.map((d) => {
                  return <DroppableDivision key={d.id} division={d} />;
                })}
              </Grid>
            </>
          </VisibleIf>
        </Grid>
      </Grid>
    </DndContext>
  );
}

export default AssignDivisions;
