import { CircularProgress, Typography } from '@mui/material';

export type LoadingIndicatorProps = {
  loading: boolean;
  text?: string;
  children?: JSX.Element | JSX.Element[];
};

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { loading, children, text } = props;
  return (
    <>
      {loading ? (
        <>
          <CircularProgress /> <Typography variant="body1">{text}</Typography>
        </>
      ) : (
        children
      )}
    </>
  );
}

export default LoadingIndicator;
