import { useLazyQuery, useMutation } from '@apollo/client';
import { useLeagueContext } from '@local/context/league/league-context';
import {
  Carousel,
  DeleteCarouselDocument,
  FileMeta,
  LoadCarouselsDocument,
  UpdateCarouselInput,
  UpsertCarouselDocument,
  UpsertCarouselInput,
  UpsertManyCarouselsDocument,
} from '@local/graphql/graphql';
import { useFileUpload } from '@local/hooks';
import { ListItemText, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EditableList, HookFormDropZone, HookFormTextField, Roles, useAuthz } from '@seasonticker/dls';
import { useCallback } from 'react';

const LogoImage = styled('img')({ maxHeight: '180px' });

export function Carousels() {
  const { leagueContext } = useLeagueContext();
  const [upsertMutation] = useMutation(UpsertCarouselDocument);
  const [upsertManyMutation] = useMutation(UpsertManyCarouselsDocument);
  const [deleteMutation] = useMutation(DeleteCarouselDocument);
  const [refreshCarousels] = useLazyQuery(LoadCarouselsDocument, { fetchPolicy: 'no-cache' });
  const { uploadFile } = useFileUpload();
  const upsertCarousels = useCallback(
    (update: UpdateCarouselInput[]) => () => upsertManyMutation({ variables: { update: update, leagueId: String(leagueContext?.league?.id) } }),
    [leagueContext?.league?.id, upsertManyMutation]
  );
  const { ifInRole } = useAuthz();
  const permissionFunction = ifInRole({
    context: leagueContext?.league?.id,
    roles: [Roles.admin, Roles.leagueadmin],
  });

  const loadCarousels = useCallback(async () => {
    const response = await refreshCarousels({ variables: { leagueId: String(leagueContext?.league?.id) } });
    return response.data?.carousels as Carousel[];
  }, [leagueContext?.league?.id, refreshCarousels]);

  const prepareCarousel = useCallback(
    async (item: Carousel) => {
      const { id, link, title, text, sortOrder } = item;
      let newFile: FileMeta | null = null;
      if (item.image) {
        newFile = (await uploadFile(item.image, { folder: `uploads/league/${leagueContext?.league?.id}/carousels` })) as FileMeta;
      }
      const update: UpsertCarouselInput = { id, link, title, text, sortOrder };
      if (newFile?.id) {
        update.image = newFile?.id;
      }
      return update;
    },
    [leagueContext?.league?.id, uploadFile]
  );

  const upsertCarousel = useCallback(
    (upsert: UpsertCarouselInput) => () => upsertMutation({ variables: { upsert: upsert, leagueId: String(leagueContext?.league?.id) } }),
    [leagueContext?.league?.id, upsertMutation]
  );

  const deleteCarousel = useCallback(
    (item: Carousel) => () => deleteMutation({ variables: { leagueId: String(leagueContext?.league?.id), id: item.id } }),
    [deleteMutation, leagueContext?.league?.id]
  );

  const carouselForm = (
    <Grid container rowSpacing={'1em'} columnSpacing={'1em'}>
      <Grid xs={12}>
        <HookFormTextField name="title" label="Title" rules={{ required: 'Name is required' }} fullWidth />
      </Grid>
      <Grid xs={12}>
        <HookFormDropZone
          label="Image"
          name="image"
          accept={{
            'image/*': [],
          }}
          preview={(value) => (value ? <LogoImage src={String((value as FileMeta)?.url || '')} /> : <></>)}
          valueGetter={(value: FileMeta) => value}
          valueSetter={(value: FileMeta, priorValue: FileMeta) => value}
        />
      </Grid>
      <Grid xs={12}>
        <HookFormTextField name="link" label="Link" fullWidth />
      </Grid>
      <Grid xs={12}>
        <HookFormTextField name="text" label="Link Text" fullWidth />
      </Grid>
    </Grid>
  );

  const carouselDisplay = (item: Carousel) => <></>;
  const emptyTemplate = {
    title: '',
    text: '',
    image: {},
    link: '',
    sortOrder: -1,
  } as Carousel;

  return (
    <EditableList<Carousel, UpsertCarouselInput, ReturnType<typeof upsertMutation>, ReturnType<typeof upsertManyMutation>, ReturnType<typeof deleteMutation>>
      model="Carousels"
      refresh={loadCarousels}
      refreshList={loadCarousels}
      deleteMutation={deleteCarousel}
      prepareUpsert={prepareCarousel}
      upsertMutation={upsertCarousel}
      upsertOperation={upsertCarousels}
      canEdit={permissionFunction}
      listItemTemplate={(item) => <ListItemText primary={item?.title} />}
      editElement={carouselForm}
      itemTemplate={carouselDisplay}
      emptyTemplate={emptyTemplate}
      editable
      sortable
      deletable
    />
  );
}

export default Carousels;
