import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { HookFormSwitch, HookFormTextField } from '@seasonticker/dls';
import { ScheduleSteps, ScheduleWizardStepProps } from './ScheduleTypes';

export function ScheduleSettings(props: ScheduleWizardStepProps) {
  const { goToStep } = props;
  return (
    <Box>
      <Grid container rowSpacing={'.8em'} columnSpacing={'.8em'}>
        <Grid xs={12} md={6}>
          <HookFormTextField type="number" name="total_games_each" label="Total Games Each Team" tooltip="Total Games Each Team" fullWidth />
        </Grid>
        <Grid xs={12} md={6}>
          <HookFormTextField type="number" name="max_home_games_each" label="Max home games each" tooltip="Total Home Games For Each Team" fullWidth />
        </Grid>
        <Grid xs={12}>
          <HookFormSwitch name="use_out_of_division" label="Use out of Division" tooltip="Use out of Division" fullWidth />
        </Grid>
        <Grid xs={12}>
          <Button onClick={() => goToStep(ScheduleSteps.Generate)} variant="contained" color="primary">
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
