import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';

export type ConfirmDialogProps = {
  open?: boolean;
  /**
   * The message that will appear across the top of the dialog window
   * @default Confirmation
   */
  title?: React.ReactNode;
  /**
   * Any additional props to pass to the DialogTitle component
   */
  titleProps?: DialogTitleProps;
  /**
   * The main text to display in the confirm dialog area.  The logic is
   * to display either the description property OR the content property
   */
  description?: React.ReactNode;
  /**
   * Any React Elemement to display as the dialog contents.  The logic is
   * to display either the description property OR the content property
   */
  content?: React.ReactNode | null;
  /**
   * Any additional props to pass to the DialogContent component
   */
  contentProps?: DialogContentProps;
  /**
   * The label for the confirmation button
   * @default Ok
   */
  confirmationText?: React.ReactNode;
  /**
   * The label for the cancel button
   * @default Cancel
   */
  cancellationText?: React.ReactNode;
  /**
   * Any additional properties to pass to the Dialog component
   */
  dialogProps?: Omit<DialogProps, 'open'>;

  /**
   * Any additional properties to pass to the confirmation Button component
   */
  confirmationButtonProps?: ButtonProps;
  /**
   * Any additional properties to pass to the cancellation Button component
   */
  cancellationButtonProps?: ButtonProps;
  /**
   * Allow dialog to be closed.  If set to true, the user can escape or click away from the dialog
   * @default false
   */
  allowClose?: boolean;

  /**
   * Event fired when the dialog is cancelled
   */
  onCancel?: () => void;
  /**
   * Event fired when the confirm button is clicked
   */
  onConfirm?: () => void;

  /**
   * Event fired when the dialog is closed without choosing confirm or cancel.  Should be treated as cancel normally
   */
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void | undefined;

  /**
   * Any additional properties to pass to the Action component
   */
  actionProps?: DialogActionsProps;
};

export const ConfirmationDialog = (props: ConfirmDialogProps) => {
  const {
    open = false,
    onCancel,
    onConfirm,
    onClose,
    title = 'Confirmation',
    description,
    content,
    confirmationText = 'Ok',
    cancellationText = 'Cancel',
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    actionProps,
    allowClose = false,
  } = props;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : () => {}}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>{content}</DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions {...actionProps}>
        <Button color="secondary" variant="outlined" {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </Button>
        <Button color="primary" variant="contained" {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
