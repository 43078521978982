import { useLazyQuery } from '@apollo/client';
import { AgeGroupContextProvider, useAgeGroupContext } from '@local/context/league/age-group-context';
import { LeagueContextProvider, useLeagueContext } from '@local/context/league/league-context';
import { NavigationContextProvider } from '@local/context/league/navigation-context';
import { SeasonContextProvider, useSeasonContext } from '@local/context/league/season-context';
import { SettingContextProvider } from '@local/context/league/settings-context';
import { AgeGroup, ResolveUrlDocument, Season } from '@local/graphql/graphql';
import { orderBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDocumentTitle } from '../hooks';
import Theme from './Theme';

function LeagueLoader() {
  const [searchParams] = useSearchParams();
  const { leagueContext, setLeagueContext } = useLeagueContext();
  const { setSeasonContext } = useSeasonContext();
  const { setAgeGroupContext } = useAgeGroupContext();
  const { setDocumentTitle } = useDocumentTitle();

  const [vanityResolver] = useLazyQuery(ResolveUrlDocument, { fetchPolicy: 'no-cache' });

  const loadLeagueData = useCallback(async () => {
    const response = await vanityResolver({ variables: { domain: window.location.hostname } });
    setLeagueContext(response.data?.vanity_url_resolve);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLeagueContext]);

  useEffect(() => {
    loadLeagueData();
  }, [loadLeagueData]);

  useEffect(() => {
    if (leagueContext?.league?.id) {
      const seasons = leagueContext?.league?.seasons || [];
      const sortedSeasons = orderBy(seasons, 'end');
      let currentActiveSeason = sortedSeasons.find((s) => s.end > new Date());

      const lastSelectedSeason = sessionStorage.getItem('seasonId');
      if (lastSelectedSeason) {
        currentActiveSeason = sortedSeasons.find((s) => s.id === lastSelectedSeason) || currentActiveSeason;
      }

      const seasonOverride = searchParams.get('seasonOverride');
      if (seasonOverride) {
        currentActiveSeason = sortedSeasons.find((s) => s.id === seasonOverride) || currentActiveSeason;
      }
      if (!currentActiveSeason) {
        currentActiveSeason = sortedSeasons.pop();
      }
      setSeasonContext(currentActiveSeason as Season);
      setDocumentTitle(leagueContext?.league?.acronym ? `${leagueContext?.league?.acronym} - season ticker` : 'Season Ticker');
      const savedAgeGroupId = localStorage.getItem('ageGroupId');
      const savedAgeGroup = currentActiveSeason?.age_groups?.find((ag) => ag.id === savedAgeGroupId);
      if (savedAgeGroupId && savedAgeGroup) {
        setAgeGroupContext(savedAgeGroup as AgeGroup);
      }
    }
  }, [leagueContext, searchParams, setAgeGroupContext, setDocumentTitle, setSeasonContext]);

  return <Theme />;
}

export default function League() {
  return (
    <LeagueContextProvider>
      <SeasonContextProvider>
        <SettingContextProvider>
          <AgeGroupContextProvider>
            <NavigationContextProvider>
              <LeagueLoader />
            </NavigationContextProvider>
          </AgeGroupContextProvider>
        </SettingContextProvider>
      </SeasonContextProvider>
    </LeagueContextProvider>
  );
}
